<mat-toolbar class="app-header">
  <app-route-progress></app-route-progress>
  <button mat-icon-button color="primary" (click)="locationService.backWithAppState()">
    <mat-icon>arrow_back</mat-icon>
  </button>
  <h2 class="page-title" (click)="log(paymentForm)">案件稼働実績</h2>
  <div class="right-suffix"></div>
</mat-toolbar>

<div class="page-content">
  <div class="payment-txt">こちらの画面ではご依頼が確定した案件を表示しています。支払いタイミングは、案件により異なります。</div>
  <form [formGroup]="paymentForm">
    <div class="payment-form-field">
      <div class="payment-form-label">月を選択</div>
      <mat-form-field appearance="outline" class="custom-form-field payment-form-value">
        <mat-select formControlName="term">
          <mat-option *ngFor="let term of termOptions" [value]="term">
            {{term | date:'yyyy年MM月'}}（{{isCurrentTerm(term) ? '未確定' : '確定'}}）</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </form>

  <div class="payment-summary" id="paymentSummary" #paymentSummary>
    <div class="payment-total">
      <span class="payment-total-amount">{{pending ? '-' : total | currency:'JPY'}}</span>
    </div>

    <table class="payment-summary-table">
      <thead>
        <tr>
          <th>完了報告日</th>
          <th>案件名</th>
          <th>金額</th>
        </tr>
      </thead>
      <tbody *ngIf="payments?.length">
        <tr *ngFor="let payment of payments">
          <td class="timestamp">{{payment.reportedAt ? (payment.reportedAt | date:'yyyy/MM/dd') : '-'}}</td>
          <td>
            <a [routerLink]="[path.order.detailBase | rootPathPrefix, payment.project.id]">{{payment.project.projectName}}</a>
          </td>
          <td class="amount">{{
            (payment.amount || 0) | currency:(payment.currency || 'JPY')
            }}</td>
        </tr>
      </tbody>
    </table>

    <!-- Loader -->
    <mat-progress-spinner *ngIf="pending" class="payment-loading-spinner" color="primary" mode="indeterminate"
      strokeWidth="15"></mat-progress-spinner>

    <!-- Not found -->
    <div *ngIf="!pending && !payments?.length" class="payment-not-found">
      ご指定の月の案件稼働実績がありません。
    </div>
  </div>

  <button mat-flat-button color="primary" class="button-download-pdf" [disabled]="!payments?.length"
    (click)="downloadPdf()">PDFをダウンロード</button>
</div>