<mat-toolbar class="app-header app-fixed-header">
  <app-route-progress></app-route-progress>
  <button
    mat-icon-button
    color="primary"
    (click)="locationService.backWithAppState()">
    <mat-icon>arrow_back</mat-icon>
  </button>
  <h2
    class="page-title"
    (click)="log(profileForm);
             log(getInvalidControls(profileForm))">プロフィール<br>
             <span class="warn-text">※は必須入力です</span> </h2>
  <button
    mat-flat-button
    class="button-submit"
    [disableRipple]="profileForm.pristine || profileForm.invalid"
    [ngClass]="{
      'button-submit-disabled': profileForm.pristine || profileForm.invalid
    }"
    (click)="updateProfile()">保存</button>
</mat-toolbar>

<div class="page-content-wrapper">
  <!-- main form -->
  <form [formGroup]="profileForm">
    <div class="member-portrait">
      <div class="member-image">
      <input #fileInput
        type="file"
        hidden
        [accept]="accept"
        [formControl]="imageForm.get('data')"
        (change)="fileSelectChange($event);
                  displayPreview($event, memberImageContainer)">
        <div
          #memberImageContainer
          class="member-image-container"
          [ngClass]="{'no-image': !member.image1?.url}"
          [style.backgroundImage]="member.image1?.url ? 'url(' + member.image1?.url + ')' : null"
          (click)="browseFile($event)">
          <mat-icon class="photo-overlay">add_a_photo</mat-icon>
        </div>
      </div>
      <div class="member-headline">
        <div class="member-segment">{{segment?.name || '-'}}</div>
        <div class="member-full-name">
          {{member.fullName}}
          <span class="ruby">{{member.fullNameKana}}</span>
        </div>
      </div>
    </div>
    <h3 class="profile-form-headline">ログイン・電話番号・基本情報</h3>
    <!-- ニックネーム(表示名) -->
    <div class="profile-form-item">
      <div class="profile-form-item-inner">
        <div class="profile-form-item-label">
          <span class="required">ニックネーム（表示名）</span>
          <span *ngIf="member.displayName">（この項目は編集できません）</span>
        </div>
        <mat-form-field
          appearance="outline"
          class="custom-form-field expand">
          <input
            matInput
            [readonly]="member.displayName"
            [formControl]="memberForm.get('displayName')">
        </mat-form-field>
      </div>
    </div>
    <!-- メールアドレス1 -->
    <div class="profile-form-item">
      <div class="profile-form-item-inner">
        <div class="profile-form-item-label">
          <span class="required">メールアドレス①（ログイン）</span>
        </div>
        <a
          class="form-link"
          [routerLink]="[path.auth.action | rootPathPrefix]"
          [queryParams]="{mode: authAction.sendUpdateEmailMail}">
          {{member.email1}}
          <mat-icon
            color="primary"
            class="caret">keyboard_arrow_right</mat-icon>
        </a>
      </div>
    </div>
    <!-- メールアドレス2 -->
    <div class="profile-form-item">
      <div class="profile-form-item-inner">
        <div class="profile-form-item-label">メールアドレス②（任意）</div>
        <mat-form-field
          appearance="outline"
          class="custom-form-field expand">
          <input
            matInput
            oninput="this.value = this.value.toLowerCase()"
            [formControl]="getControls(memberForm.get('email2'))">
        </mat-form-field>
      </div>
    </div>
    <!-- パスワード -->
    <div class="profile-form-item">
      <div class="profile-form-item-inner">
        <div class="profile-form-item-label">パスワード</div>
        <a
          class="form-link"
          [routerLink]="[path.auth.action | rootPathPrefix]"
          [queryParams]="{
            mode: authService.signInProvider === 'email' ?
                  authAction.sendSetPasswordMail :
                  authAction.sendChangePasswordMail
          }">
          {{ authService.signInProvider === 'email' ? 'パスワード設定' : 'パスワード変更' }}
          <mat-icon
            color="primary"
            class="caret">keyboard_arrow_right</mat-icon>
        </a>
      </div>
    </div>
    <!-- LINE連携 -->
    <div class="profile-form-item">
      <div class="profile-form-item-inner">
        <div class="profile-form-item-label">LINE連携</div>
        <a
          *ngIf="(!lineId || !lineDisplayName) else LINE"
          class="form-link"
          matRipple
          (click)="LINELogin();
                   $event.preventDefault()">
          {{ pendingLINELogin ? 'しばらくお待ちください...' : 'IDを連携してください' }}
          <mat-icon
            color="primary"
            class="caret">keyboard_arrow_right</mat-icon>
        </a>
        <!-- Connected -->
        <ng-template #LINE>
          <div
            class="profile-form-item-text profile-form-teim-provider-line">
            <span
              class="line-add-friend-button"
              [innerHTML]="sanitizedLINEAddFriendHTML"></span>
            <div
              class="line-user-name"
              (click)="confirmDisconnectLINE()">
              <span>{{ pendingLINELogin ? 'しばらくお待ちください...' : lineDisplayName }}</span>
              <mat-icon
                color="primary"
                class="caret">keyboard_arrow_right</mat-icon>
            </div>
            <div class="line-add-friend-hint">LINEで通知を受け取るには、公式アプリを友だちに追加してください。</div>
          </div>
        </ng-template>
      </div>
    </div>
    <!-- 電話番号1 -->
    <div class="profile-form-item">
      <div class="profile-form-item-inner">
        <div class="profile-form-item-label"><span class="required">電話番号①</span></div>
        <mat-form-field
          appearance="outline"
          class="custom-form-field expand">
          <input
            type="tel"
            matInput
            [formControl]="getControls(memberForm.get('phoneNumber1'))">
        </mat-form-field>
      </div>
    </div>
    <!-- 電話番号2 -->
    <div class="profile-form-item">
      <div class="profile-form-item-inner">
        <div class="profile-form-item-label">電話番号②（任意）</div>
        <mat-form-field
          appearance="outline"
          class="custom-form-field expand">
          <input
            type="tel"
            matInput
            [formControl]="getControls(memberForm.get('phoneNumber2'))">
        </mat-form-field>
      </div>
    </div>
    <!-- 性別 gender -->
    <div class="profile-form-item">
      <div class="profile-form-item-inner">
        <div class="profile-form-item-label">性別</div>
        <mat-form-field
          appearance="outline"
          class="custom-form-field expand">
          <mat-select
            class="custom-select-box"
            color="primary"
            [formControl]="memberForm.get('gender')">
            <mat-option
              *ngFor="let gender of genderList"
              [value]="gender.value">{{gender.label}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <!-- 生年月日 -->
    <div class="profile-form-item">
      <div class="profile-form-item-inner">
        <div class="profile-form-item-label">
          <span class="required">生年月日
          </span>
          <span *ngIf="member.birthDay">（この項目は編集できません）</span>
        </div>
        <mat-form-field
          appearance="outline"
          class="custom-form-field expand">
          <input matInput
            [max]="today"
            [matDatepicker]="birthDayPicker"
            [readonly]="member.birthDay"
            placeholder="生年月日"
            [formControl]="memberForm.get('birthDay')">
          <mat-datepicker-toggle
            matSuffix
            [for]="birthDayPicker"
            [disabled]="member.birthDay"></mat-datepicker-toggle>
          <mat-datepicker #birthDayPicker></mat-datepicker>
          </mat-form-field>
      </div>
    </div>
    <!-- 身長 -->
    <div class="profile-form-item">
      <div class="profile-form-item-inner">
        <div class="profile-form-item-label">身長</div>
        <mat-form-field
          appearance="outline"
          class="custom-form-field expand">
          <input
            matInput
            type="number"
            minlength="0"
            [formControl]="memberForm.get('height')">
          <span matSuffix>cm</span>
        </mat-form-field>
      </div>
    </div>
    <!-- 体重 -->
    <div class="profile-form-item">
      <div class="profile-form-item-inner">
        <div class="profile-form-item-label">体重</div>
        <mat-form-field
          appearance="outline"
          class="custom-form-field expand">
          <input
            matInput
            type="number"
            minlength="0"
            [formControl]="memberForm.get('weight')">
          <span matSuffix>kg</span>
        </mat-form-field>
      </div>
    </div>
    <!-- 結婚状況 -->
    <div class="profile-form-item">
      <div class="profile-form-item-inner">
        <div class="profile-form-item-label">結婚状況</div>
        <mat-radio-group
          name="married"
          color="primary"
          [formControl]="memberForm.get('married')">
          <mat-radio-button [value]="false">独身</mat-radio-button>
          <mat-radio-button [value]="true">既婚</mat-radio-button>
          <mat-radio-button [value]="null">未設定</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <!-- 子供 -->
    <div class="profile-form-item">
      <div class="profile-form-item-inner">
        <div class="profile-form-item-label">子供</div>
        <mat-radio-group
          name="hasChildren"
          color="primary"
          [formControl]="memberForm.get('hasChildren')">
          <mat-radio-button [value]="true">あり</mat-radio-button>
          <mat-radio-button [value]="false">なし</mat-radio-button>
          <mat-radio-button [value]="null">未設定</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <!-- 子供の生年月日・性別 -->
    <div
      *ngIf="memberForm.get('hasChildren').value"
      class="profile-form-item">
      <div class="profile-form-item-inner">
        <div class="profile-form-item-label">子供の生年月日・性別</div>
        <div class="children-form-field">
          <div
            *ngFor="let ctrl of (childrenForm | get:'controls');
                    let index = index">
            <div class="profile-form-item-label">
              <button
                type="button"
                class="button-child-remove"
                mat-stroked-button
                (click)="removeChild(index)"><mat-icon>close</mat-icon></button>
              子供&nbsp;({{index + 1}}人目)
            </div>
            <div class="child-form-field">
              <!-- 性別 gender -->
              <mat-form-field
                appearance="outline"
                class="custom-form-field">
                <mat-label>
                  <span class="label-outline">性別</span>
                  性別
                </mat-label>
                <mat-select
                  placeholder="性別"
                  [formControl]="ctrl.get('gender')">
                  <mat-option
                    *ngFor="let gender of genderList"
                    [value]="gender.value">{{gender.label}}</mat-option>
                </mat-select>
              </mat-form-field>
              <!-- 生年月日 -->
              <mat-form-field
                appearance="outline"
                class="custom-form-field">
                <mat-label>
                  <span class="label-outline">生年月日</span>
                  生年月日
                </mat-label>
                <input matInput
                  [max]="today"
                  [matDatepicker]="birthDayPicker"
                  placeholder="生年月日"
                  [formControl]="ctrl.get('birthDay')">
                <mat-datepicker-toggle
                  matSuffix
                  [for]="birthDayPicker"></mat-datepicker-toggle>
                <mat-datepicker #birthDayPicker></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
          <button
            type="button"
            class="button-child-add"
            mat-stroked-button
            (click)="addChild(childrenForm.value?.length)"><mat-icon>add</mat-icon></button>
        </div>
      </div>
    </div>
    <!-- 職業 -->
    <div class="profile-form-item">
      <div class="profile-form-item-inner">
        <div class="profile-form-item-label">職業</div>
        <mat-form-field
          appearance="outline"
          class="custom-form-field expand">
          <mat-select
            class="custom-select-box"
            color="primary"
            [formControl]="memberForm.get('occupation_master')">
            <mat-option [value]="null"></mat-option>
            <mat-option
              *ngFor="let occupation of occupationList"
              [value]="occupation.id">{{occupation.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <!-- 業種 -->
    <div class="profile-form-item">
      <div class="profile-form-item-inner">
        <div class="profile-form-item-label">業種</div>
        <mat-form-field
          appearance="outline"
          class="custom-form-field expand">
          <mat-select
            class="custom-select-box"
            color="primary"
            [formControl]="memberForm.get('industry_master')">
            <mat-option [value]="null"></mat-option>
            <mat-option
              *ngFor="let industry of industryList"
              [value]="industry.id">{{industry.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <!-- 職種 -->
    <div class="profile-form-item">
      <div class="profile-form-item-inner">
        <div class="profile-form-item-label">職種</div>
        <mat-form-field
          appearance="outline"
          class="custom-form-field expand">
          <mat-select
            class="custom-select-box"
            color="primary"
            [formControl]="memberForm.get('job_master')">
            <mat-option [value]="null"></mat-option>
            <mat-option
              *ngFor="let job of jobList"
              [value]="job.id">{{job.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <!-- キャッチコピー -->
    <div class="profile-form-item">
      <div class="profile-form-item-inner">
        <div class="profile-form-item-label">キャッチコピー</div>
        <mat-form-field
          appearance="outline"
          class="custom-form-field expand custom-form-field-has-script">
          <textarea
            matInput
            color="primary"
            [formControl]="memberForm.get('catchphrase')"
            matTextareaAutosize></textarea>
          <mat-hint align="end">{{memberForm.get('catchphrase').value?.length || 0}}/50文字</mat-hint>
        </mat-form-field>
      </div>
    </div>
    <!-- プロフィール -->
    <div class="profile-form-item">
      <div class="profile-form-item-inner">
        <div class="profile-form-item-label">プロフィール</div>
        <mat-form-field
          appearance="outline"
          class="custom-form-field expand custom-form-field-has-script">
          <textarea
            matInput
            color="primary"
            [formControl]="memberForm.get('profile')"
            matTextareaAutosize></textarea>
          <mat-hint align="end">{{memberForm.get('profile').value?.length || 0}}/200文字</mat-hint>
        </mat-form-field>
      </div>
    </div>
    <div *ngIf="showMedia">
      <div class="profile-form-item"
        *ngFor="let label of specialMediaGroups[showMedia]; index as i;">
        <div class="profile-form-item-inner">
          <div class="profile-form-item-label">{{label}}</div>
          <mat-form-field appearance="outline"
            class="custom-form-field expand custom-form-field-has-script">
            <textarea matInput
              color="primary"
              [formControl]="memberForm.get('remarks' + (i + 1))"
              matTextareaAutosize></textarea>
          </mat-form-field>
        </div>
      </div>
    </div>


    <h3 class="profile-form-headline">ブログ・SNS情報</h3>
    <!-- BLOG URL -->
    <div class="profile-form-item">
      <div class="profile-form-item-inner">
        <div class="profile-form-item-label">BLOG URL</div>
        <mat-form-field
          appearance="outline"
          class="custom-form-field expand">
          <input
            placeholder="https://www.blog.com"
            matInput
            [formControl]="getControls(memberForm.get('mainSiteURL'))">
        </mat-form-field>
      </div>
    </div>
    <!-- Twitter -->
    <!-- <div
      *ngFor="let ctrl of getControls(connectionsForm.get('twitter'))"
      class="profile-form-item">
      <div class="profile-form-item-inner sns-blog">
        <div class="profile-form-item-label">Twitterアカウント</div>
        <mat-form-field
          appearance="outline"
          class="custom-form-field expand">
          <span matPrefix>https://twitter.com/</span>
          <input
            matInput
            [formControl]="ctrl.get('screenName')">
        </mat-form-field>
      </div>
    </div> -->
    <!-- Instatram -->
    <!-- <div
      *ngFor="let ctrl of getControls(connectionsForm.get('instagram'));
              let index = index;"
      class="profile-form-item">
      <div class="profile-form-item-inner sns-blog">
        <div class="profile-form-item-label">Instagramアカウント{{index + 1 | circleNumber}}</div>
        <mat-form-field
          appearance="outline"
          class="custom-form-field expand">
          <span matPrefix>https://instagram.com/</span>
          <input
            matInput
            [formControl]="ctrl.get('username')">
        </mat-form-field>
      </div>
    </div> -->
    <!-- TikTok -->
    <!-- <div
      *ngFor="let ctrl of getControls(connectionsForm.get('tiktok'))"
      class="profile-form-item">
      <div class="profile-form-item-inner sns-blog">
        <div class="profile-form-item-label">TikTokアカウント</div>
        <mat-form-field
          appearance="outline"
          class="custom-form-field expand">
          <span matPrefix>https://tiktok.com/@</span>
          <input
            matInput
            [formControl]="ctrl.get('uniqueId')">
        </mat-form-field>
      </div>
    </div> -->
    <!-- Youtube -->
    <!-- <div
      *ngFor="let ctrl of getControls(connectionsForm.get('youtube'))"
      class="profile-form-item">
      <div class="profile-form-item-inner sns-blog">
        <div class="profile-form-item-label">Youtubeチャンネル</div>
        <mat-form-field
          appearance="outline"
          class="custom-form-field expand">
          <span matPrefix>https://youtube.com/channel/</span>
          <input
            matInput
            [formControl]="ctrl.get('id')">
        </mat-form-field>
      </div>
    </div> -->
    <!-- その他URL -->
    <!-- Twitter -->
    <div  class="profile-form-item">
      <div class="profile-form-item-inner sns-blog">
        <div class="profile-form-item-label">Twitterアカウント</div>
        <mat-form-field
          appearance="outline"
          class="custom-form-field expand">
          <span matPrefix>https://twitter.com/</span>
          <input
            matInput
            [formControl]="connectionsForm.get('twitter.screenName')">
        </mat-form-field>
      </div>
    </div>
    <!-- Instatram -->
    <div
      *ngFor="let ctrl of (instagramForm | get:'controls'); let index = index"
      class="profile-form-item">
      <div class="profile-form-item-inner sns-blog">
        <div class="profile-form-item-label">Instagramアカウント{{index + 1 | circleNumber}}</div>
        <mat-form-field
          appearance="outline"
          class="custom-form-field expand">
          <span matPrefix>https://instagram.com/</span>
          <input
            matInput
            [formControl]="ctrl.get('username')">
        </mat-form-field>
      </div>
    </div>
    <!-- TikTok -->
    <div class="profile-form-item">
      <div class="profile-form-item-inner sns-blog">
        <div class="profile-form-item-label">TikTokアカウント</div>
        <mat-form-field
          appearance="outline"
          class="custom-form-field expand">
          <span matPrefix>https://tiktok.com/@</span>
          <input
            matInput
            [formControl]="connectionsForm.get('tiktok.uniqueId')">
        </mat-form-field>
      </div>
    </div>
    <!-- Youtube -->
    <div class="profile-form-item">
      <div class="profile-form-item-inner sns-blog">
        <div class="profile-form-item-label">Youtubeチャンネル</div>
        <mat-form-field
          appearance="outline"
          class="custom-form-field expand">
          <span matPrefix>https://youtube.com/channel/</span>
          <input
            matInput
            [formControl]="connectionsForm.get('youtube.idOfResponse')">
        </mat-form-field>
      </div>
    </div>

    <div class="profile-form-item">
      <div class="profile-form-item-inner sns-blog">
        <div class="profile-form-item-label">その他URL①</div>
        <mat-form-field
          appearance="outline"
          class="custom-form-field expand">
          <input
            placeholder="https://www.blog.com"
            matInput
            [formControl]="getControls(memberForm.get('otherSiteURL1'))">
        </mat-form-field>
      </div>
    </div>
    <div class="profile-form-item">
      <div class="profile-form-item-inner sns-blog">
        <div class="profile-form-item-label">その他URL②</div>
        <mat-form-field
          appearance="outline"
          class="custom-form-field expand">
          <input 
            placeholder="https://www.blog.com"
            matInput
            [formControl]="getControls(memberForm.get('otherSiteURL2'))">
        </mat-form-field>
      </div>
    </div>
    <div class="profile-form-item">
      <div class="profile-form-item-inner sns-blog">
        <div class="profile-form-item-label">その他URL③</div>
        <mat-form-field
          appearance="outline"
          class="custom-form-field expand">
          <input
            placeholder="https://www.blog.com"
            matInput
            [formControl]="memberForm.get('otherSiteURL3')">
        </mat-form-field>
      </div>
    </div>

<!-- 
    <div
      *ngFor="let ctrl of getControls(memberForm.get('URLs'));
              let index = index;"
      class="profile-form-item">
      <div class="profile-form-item-inner">
        <div class="profile-form-item-label">{{
          !ctrl.value.name ? 'その他URL' + (index + 1 | circleNumber) : ctrl.value.name
        }}</div>
        <mat-form-field
          appearance="outline"
          class="custom-form-field expand">
          <input
            placeholder="https://www.blog.com"
            matInput
            [formControl]="ctrl.get('URL')">
        </mat-form-field>
      </div>
    </div> -->

    <!-- <h3 class="profile-form-headline">住所・振込先情報</h3> -->
    <h3 class="profile-form-headline">住所情報</h3>
    <!-- 住所 -->
    <div class="profile-form-item profile-form-address">
      <div class="profile-form-item-inner">
        <div class="profile-form-item-label">
          <span class="required">住所</span>
        </div>
        <mat-form-field
          appearance="outline"
          class="custom-form-field">
          <input
            inputmode="numeric"
            placeholder="郵便番号"
            matInput
            [formControl]="addressForm.get('postalCode')">
        </mat-form-field>
        <mat-form-field
          appearance="outline"
          class="custom-form-field expand">
          <input
            placeholder="都道府県市区町村番地 建物名"
            matInput
            [formControl]="addressForm.get('address')">
        </mat-form-field>
      </div>
    </div>
    <!-- お振り込み口座 -->
    <!-- <div class="profile-form-item">
      <div class="profile-form-item-inner">
        <div class="profile-form-item-label">
          <span>お振り込み口座</span>
        </div>
        <mat-radio-group [formControl]="hasBankAccount">
          <mat-radio-button
            color="primary"
            [value]="true">集英社に登録あり</mat-radio-button>
          <mat-radio-button
            color="primary"
            [value]="false">集英社に登録なし</mat-radio-button>
        </mat-radio-group>
      </div>
    </div> -->
    <!-- end form fields -->
  </form>
</div>