import { Component, OnInit, ViewChild, ElementRef, HostBinding, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { union } from 'lodash';
import { Path } from '@lu/path';

@Component({
  selector: 'app-search-header',
  templateUrl: './search-header.component.html',
  styleUrls: ['./search-header.component.scss']
})
export class SearchHeaderComponent implements OnInit {
  @ViewChild('searchInput', {static: true}) searchInput: ElementRef<HTMLInputElement>;
  @HostBinding('class.search-field-focus') searchFocused = false;
  @Output() searchFormEmit = new EventEmitter();
  public searchForm = new FormGroup({
    keyword: new FormControl('')
  });
  public recentlySearchKeywords: string[];
  public readonly path = Path;

  constructor() { }

  ngOnInit() {
    this.recentlySearchKeywords = this.getRecentlySearchKeywords();
  }

  emitSearchKeyword() {
    const {keyword} = this.searchForm.value;
    const trimedKeyword = (keyword as string).replace(/\s+/g, ' ').replace(/(^\s+|\s+$)/g, '');
    this.searchForm.patchValue({keyword: trimedKeyword});
    if (trimedKeyword) {
      this.addRecentlySearchKeywords(trimedKeyword);
    }
    this.searchInput.nativeElement.blur();
    this.blurSearch();
    this.searchFormEmit.emit(trimedKeyword);
  }

  focusSearch() {
    this.searchFocused = true;
  }

  blurSearch() {
    this.searchFocused = false;
  }

  clearSearchField(event: Event) {
    event.stopPropagation();
    this.searchForm.patchValue({keyword: null});
    this.blurSearch();
  }

  getRecentlySearchKeywords(): string[] {
    const local = localStorage.getItem('recently-search');
    try {
      const list = JSON.parse(local);
      return Array.isArray(list) ? list : [];
    } catch (e) {
      console.error(e);
      return [];
    }
  }

  addRecentlySearchKeywords(keyword: string) {
    let list = this.getRecentlySearchKeywords();
    const limit = 25;
    list.unshift(keyword);
    list = union(list);
    if (list.length > limit) {
      list.splice(limit);
    }
    localStorage.setItem('recently-search', JSON.stringify(list));
    this.recentlySearchKeywords = list;
  }

  applyRecentlySearchKeyword(keyword: string) {
    this.searchForm.patchValue({keyword});
    this.searchInput.nativeElement.focus();
  }

}
