import { Component, OnInit, Input } from '@angular/core';
import { FavoriteService } from '@lu/services/favorite.service';

@Component({
  selector: 'app-order-favorite',
  templateUrl: './order-favorite.component.html',
  styleUrls: ['./order-favorite.component.scss']
})
export class OrderFavoriteComponent implements OnInit {
  @Input() orderId: number;

  constructor(
    private fav: FavoriteService
  ) {}

  ngOnInit() {}

  toggleFavorite() {
    if (!this.orderId) {
      return;
    }
    this.favorite = !this.favorite;
  }

  get favorite(): boolean {
    return this.fav.isFavoriteOrder(this.orderId);
  }

  set favorite(fav: boolean) {
    if (fav === this.favorite) {
      return;
    }
    if (fav) {
      this.fav.addOrderToFavorite(this.orderId);
    } else {
      this.fav.removeOrderFromFavorite(this.orderId);
    }
  }
}
