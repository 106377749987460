<mat-toolbar class="app-header app-fixed-header">
  <app-route-progress></app-route-progress>
  <button
    mat-icon-button
    color="primary"
    (click)="locationService.backWithAppState()">
    <mat-icon>arrow_back</mat-icon>
  </button>
  <h2 class="page-title">メンバー規約</h2>
  <div class="right-suffix"></div>
</mat-toolbar>

<article class="page-content article">
  <h2 class="article-title">
    HAPPY PLUS COMMUNITY メンバー規約<br />
    <span class="article-timestamp">最終更新日: 2022年4月5日</span>
  </h2>
  <h3 class="section-title">第１条（目的）</h3>
  <section
    class="section"
    [innerHTML]="'本規約は、株式会社集英社（以下「当社」と言います。）が運営するWEBサイト「HAPPY PLUS」にて、ブロガーおよびインフルエンサー（以下「メンバー」）として活動する際の条件および、その活動において使用する「HAPPY PLUS COMMUNITY（略称：ハピプラ・コミュニティ、ハピコミュ）」の各機能（以下「当ツール」）をご利用いただく際の条件を定めるものです。なお、各個別媒体における活動ルールについては別紙（各媒体の公式ブロガーガイドライン等）に定めることとします。'
    | breakLine">
  </section>
  <h3 class="section-title">第２条（定義）</h3>
  <section
    class="section"
    [innerHTML]="'本規約の中で使用される用語は、それぞれ以下の意味を有するものとします。
    1.「メンバー」とは、「HAPPY PLUS」のWebサイト（MAQUIA ONLINE、Daily MORE、non-noWeb、＠BAILA、Marisol ONLINE、Web ecalt）に所属するブロガーおよびインフルエンサーを指します。
    2.「当ツール」とは、メンバーの情報管理システム「HAPPY PLUS COMMUNITY（略称：ハピプラ・コミュニティ、ハピコミュ）」においてメンバーが利用できる各機能を指し、当社のWebサイトのブログ投稿機能とは別に、メンバーが自身のプロフィール情報等を管理するほか、当社担当者とのコミュニケーションを取るためのツールを指します。
    '
    | breakLine">
  </section>
  <h3 class="section-title">第３条（メンバーの条件）</h3>
  <section
    class="section"
    [innerHTML]="'メンバーとして登録できるのは、当社がブロガーおよびインフルエンサーとして認めた方にかぎります。'
    | breakLine">
  </section>
  <h3 class="section-title">第４条（メンバーの活動内容）</h3>
  <section
    class="section"
    [innerHTML]="'1. メンバーは、当社のWebサイトのブログ投稿機能（以下「ブログ投稿機能」と言います。）を使い、写真や記事をアップロードする事で、ブロガーおよびインフルエンサーとしての活動を行うものとします。
    2. ブログ投稿内容に関する詳細なルールやメンバーとしての活動期間は、別途各媒体が定める「公式ブロガーガイドライン」に従うものとします。
    3. メンバーとしての活動について、他社のサイトや他社の雑誌・ウェブサイトなどの媒体で発表するときは、必ず事前に当社の担当者に相談するものとします。
    4. その他、当ツール上で、編集部またはブランド事業部からアンケートやモニター、イベント参加をお願いすることがあった場合、メンバーは可能な限りそれに協力するものとします。
    '
    | breakLine">
  </section>
  <h3 class="section-title">第5条（禁止事項）</h3>
  <section
    class="section"
    [innerHTML]="'メンバーは、下記の行為について行わないものとします。違反した場合、当社は当社の裁量により、本人への事前通知なしに本人による投稿を修正または削除すると同時に、ブログ投稿機能および当ツールの利用を制限し、違反の回数や態様によってはメンバー登録の取り消しなど必要な措置を取らせていただくことがあります。メンバーはこれに異義を唱えないものとします。
    1. 別途定めるメンバーが所属する媒体の「公式ブロガーガイドライン」に違反する行為。（公式ブロガーの立場を利用した経済活動やPR行為、所属媒体から提供された商品の転売行為、イベントや撮影への連絡のない遅刻・無断欠席など）
    '
    | breakLine">
  </section>
  <h3 class="section-title">第６条（著作権等）</h3>
  <section
    class="section"
    [innerHTML]="'1. メンバーが投稿したコンテンツ（文章・静止画、動画その他一切の情報。以下、同じ）のうち、第3項に定めるもの以外のコンテンツに関する著作権を含む知的財産権については、当該コンテンツの投稿以降もメンバーに帰属するものとしますが、メンバーは、当社が当該コンテンツを期間の定めなく無償で独占的に自由に利用することを許諾するものとします。なお、その利用については、出版・公衆送信、上映・上演、映像化、及び二次的利用にかかる権利の行使を含み、メンバーは、それに対して、著作者人格権を行使しないものとします。
    2. 前項の利用において、メンバーは、投稿内容が、当社が許諾する第三者が提供する媒体（雑誌、SNS、キュレーションサイト・ニュースサイト等を含む）、サービス、広告、マーケティング活動などで再利用される場合があることを承諾することといたします。また、その再利用に際して、当社は投稿したメンバー名を表示する義務を負わず、メンバーはこれらの利用・提供について、著作者人格権を行使しないものとします。
    3. メンバーが投稿したコンテンツ（文章・静止画、動画その他一切の情報。以下、同じ）のうち、当社がメンバーに対し、あらかじめ本規約の第9条第2項に記載された「広告案件」であることを告知し、投稿を依頼したコンテンツに関する著作権（著作権法27条および28条に規定する権利を含む）を含む知的財産権（特許権、実用新案権、意匠権、商標権などを含みますがこれらに限りません）については、コンテンツの投稿時に、メンバーは当社に対し独占的に無償で譲渡するものとします。また、メンバーは、譲渡したコンテンツに対し、著作者人格権を行使しないものとします。
    4. 第1項におけるコンテンツの利用及び第2項における再利用に際してメンバーは当社に対価を請求せず、投稿内容と、今後当社が開発・発表・販売する商品が類似していても、メンバーに対する報酬および開発に伴う権利等は一切発生しないことに同意するものとします。'
    | breakLine">
  </section>
  <h3 class="section-title">第７条（免責等）</h3>
  <section
    class="section"
    [innerHTML]="'1. ブログ投稿機能および当ツールは、サーバやシステム上の都合または運営上の施策により、事前の通知なくこれを変更・停止・終了することがあります。当社はブログ投稿機能および当ツールの変更・停止・終了によって生じたいかなる損害についても、一切の責任を負いません。
    2. メンバー間でのトラブル（個人的なやりとりや個人情報を明かすこと、金銭や物の授受・貸し借りなどが原因で生じたトラブル）について、当社は一切の責任を負いません。メンバー間でのやりとりは、ご自身の判断で慎重に行ってください。
    3. 当社は、システム障害及び保守、停電や火災などの天変地異その他技術上・運営上の理由により、ブログ投稿機能および当ツールの提供が困難であると判断した場合、メンバーへの事前の通知を行わず、サービスの中断（一時停止）を行う場合があります。'
    | breakLine">
  </section>
  <h3 class="section-title">第８条（当ツールへの登録、入力情報の取り扱い）</h3>
  <section
    class="section">
    1. メンバーは、メンバーとして活動開始するにあたり当ツールに登録し、自身のプロフィールの適切な管理や編集部とのコミュニケーション等をスムーズに行う目的において当ツールを利用するものとします。<br>
    2. メンバーは、当社から承認されたメールアドレスおよびパスワードを使って、当ツールへの登録、当ツールの利用を行うものとします。メールアドレスおよびパスワードは、メンバーが自己の責任において管理するものとし、第三者へ譲渡、貸与、その他の方法で使用させることはできません。<br>
    3. メンバーは、当ツールに登録するにあたり、すべての情報に関して、真実かつ正確な、最新の情報を提供するものとし、変更があった場合には、すみやかに担当者へ連絡するものとします。<br>
    4. 当社は、メンバーが登録・入力した情報に含まれる個人情報（氏名、住所、電話番号、メールアドレスなどを含みますがこれらに限りません）を、別途定める「個人情報の取り扱いについて（集英社プライバシーガイドライン）」に従って取り扱います。詳しくは【<a target="_blank" rel="noopener noreferrer" href="https://www2.shueisha.co.jp/privacy/privacy.html">https://www2.shueisha.co.jp/privacy/privacy.html</a>】をご覧ください。<br>
    5. メンバーが、自身または他のメンバーのメールアドレスおよびパスワードを不正に利用したり、当ツールに登録・入力された情報を無断で書き換えたりすることを禁止します。これらを行った場合、当社はメンバーに事前に通知することなく、当ツールの利用停止、メンバー登録の抹消を行うことができるものとします。<br>
    6. メンバーは、メールアドレスおよびパスワードの不正使用、当ツール上での不正なログインまたは情報の不正な書き換え等を知った場合は、ただちに当社に知らせるものとします。<br>
  </section>
  <h3 class="section-title">第９条（当ツールによる案件の募集と採用）</h3>
  <section
    class="section"
    [innerHTML]="'1. 当社がメンバーに依頼する、記事の執筆および情報発信を案件といいます。
    2. 案件には、編集案件・広告案件の２種類があり、当社および協賛企業の担当者は、メンバーに対し、当ツールを用いて案件の詳細や条件（時間や場所の指定・報酬の有無他）を提示し、参加を募ることができます。
    3. メンバーは、参加したい案件に対してエントリーすることで、参加意向を示すことができます。
    4. 当社の担当者は、エントリーしたメンバーの中から採用するメンバーを決定します。これらの結果について、メンバーは理由の開示や異議申し立てはできないものとします。
    5. 案件によっては報酬が設定されている場合があります。報酬は、レポートの提出など案件詳細に記載された報酬支払い条件を満たした場合にかぎり、支払うものとします。
    6. 諸事情により案件を中止とする場合には、当社の担当者はメンバーに対し、ただちに通知するものとします。また案件の作業が進行中であっても、当社は、別途定めのない限り、当該案件に対する報酬支払いの義務を負わず、中止に伴う損害等の補償にも応じないものとします。
    '
    | breakLine">
  </section>
  <h3 class="section-title">第10条（問合せ）</h3>
  <section
    class="section">
    1. 他のメンバーの投稿内容・活動内容についてのご質問、お問合せには対応いたしかねます。<br>
    2. 当ツール対するご質問、お問合せはこちらまでお寄せください。<br>
    お問い合わせ：（<a href="mailto:hpplus.community-inquiry@shueisha.co.jp">hpplus.community-inquiry@shueisha.co.jp</a>）<br>
    3. 個別の案件に関するお問い合わせは、当社の各案件の担当者にお問い合わせください。<br>
  </section>
  <h3 class="section-title">第11条（規約の変更）</h3>
  <section
    class="section"
    [innerHTML]="'当社は、本規約を変更することがあります。変更はメンバーの利益に適合するとき、もしくはメンバーとの契約の目的に反せずに、変更の必要性、変更後の内容の相当性、その他の事情に照らして合理性があると判断されたときにいたします。この場合にはメンバーの承諾は要せず、変更できるものとし、メンバーのブログ投稿機能および当ツールの利用条件は、変更後の規約によるものとします。また、変更する場合には、あらかじめ合理的な事前周知期間を設け、変更する旨、変更の内容、並びにその効力発生時期をメンバーに一定期間の告知をした後に実施します。'
    | breakLine">
  </section>
  <!-- section template -->
  <!-- <h3 class="section-title"></h3>
  <section
    class="section"
    [innerHTML]="''
    | breakLine">
  </section> -->
</article>
