<div class="page-content-wrapper">
  <div class="headline">
    <h1>
      <img
        class="logo"
        alt="SHUEISHA ハッピープラス COMMUNITY"
        src="assets/icon/happyplus_community_logo_A.png">
    </h1>
    <p class="headline-text">ログイン</p>
  </div>

  <div class="auth-form">
    <form
      [formGroup]="authForm"
      (submit)="signIn()">
      <mat-form-field
        appearance="outline">
        <mat-label>
          <span class="label-outline">メールアドレス</span>
          メールアドレス
        </mat-label>
        <input
          matInput
          placeholder="example@email.com"
          formControlName="email">
      </mat-form-field>
      <!-- When email-link signin -->
      <ng-container *ngIf="mode !== 'signIn'">
        <div class="adhesive"></div>
        <mat-form-field
          appearance="outline">
          <mat-label>
            <span class="label-outline">パスワード</span>
            パスワード
          </mat-label>
          <input
            type="password"
            matInput
            placeholder="パスワード"
            formControlName="password">
        </mat-form-field>
      </ng-container>

      <div class="auth-options">
        <mat-checkbox
          color="primary"
          (change)="persistanceChange($event)">ログイン情報を記録する</mat-checkbox>
      </div>

      <div
        class="error-message"
        [ngClass]="{'hidden': !authError}">
        <span class="message">{{authError?.message}}</span>
      </div>

      <button
        class="app-button-submit"
        mat-flat-button
        color="primary"
        type="submit"
        [disabled]="authForm.invalid || pending">ログイン</button>
    </form>

    <div class="auth-actions">
      <span
        class="reset-password"
        [routerLink]="[path.auth.action | rootPathPrefix]"
        [queryParams]="{mode: authAction.sendResetPasswordMail}">パスワードをお忘れですか？</span>
    </div>

    <div>
      <span>※ドメイン指定、迷惑メール設定によりメールが届かない場合がございます。お手数ではありますが、<br/>「shueisha.co.jp」および<br/>「member-community.hpplus.jp」からのメールを受信できるように設定してください。</span>
    </div>

    <div class="link-items">
      <span class="link-item"><a [routerLink]="[path.terms.root | rootPathPrefix]">メンバー規約</a></span>
      <span class="link-item">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www2.shueisha.co.jp/privacy/privacy.html">集英社プライバシーガイドライン</a>
      </span>
    </div>
  </div>
</div>
