import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';

import { merge, of } from 'rxjs';
import { filter, skip, tap, take, takeUntil, map, switchMap } from 'rxjs/operators';
import { MatchingService } from './matching.service';


@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  public unreadLatest = false;

  constructor(
    private afAuth: AngularFireAuth,
    private apiService: MatchingService
  ) {
    this.afAuth.authState
      .pipe(filter(user => !!user))
      .subscribe(user => {
        this.watchUnreadState();
      });
  }

  watchUnreadState() {
    let unread;
    let read;
    const unreadRef = this.apiService.getNotification({
      read: false,
      _sort: 'created_at:DESC',
      _limit: 1
    });
    const readRef = this.apiService.getNotification({
      read: true,
      _sort: 'created_at:DESC',
      _limit: 1
    });
    merge(
      unreadRef
        .pipe(
          takeUntil(this.afAuth.authState.pipe(skip(1))),
          map(docs => docs[0]),
          tap(doc => unread = doc),
        ),
      readRef
        .pipe(
          takeUntil(this.afAuth.authState.pipe(skip(1))),
          map(docs => docs[0]),
          tap(doc => read = doc),
        ),
    ).subscribe(docs => {
      if (!unread) {
        this.unreadLatest = false;
        return;
      }
      if (!read) {
        this.unreadLatest = true;
        return;
      }

      const [unreadAt, readAt] = [new Date(unread.created_at), new Date(read.created_at)];
      if (unreadAt.valueOf() - readAt.valueOf() > 0) {
        this.unreadLatest = true;
      } else {
        this.unreadLatest = false;
      }
    }, err => console.error(err));
  }

  readLatest() {
    const params = {
      _sort: 'created_at:DESC',
      _limit: 1
    };
    return this.apiService.getNotification(params)
      .pipe(
        take(1),
        filter(snaps => snaps.length > 0),
        map(snaps => snaps[0]),
        switchMap((snap) => {
          // this.watchUnreadState();
          if (snap.read) {
            return of(null);
          }
          return this.apiService.updateNotification(snap.id, { read: true });
        })
      );
  }
}
