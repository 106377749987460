/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./update-email.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i3 from "@angular/material/progress-spinner";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/common";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "../../pipes/root-path-prefix.pipe";
import * as i8 from "@angular/forms";
import * as i9 from "@angular/router";
import * as i10 from "./update-email.component";
import * as i11 from "@angular/common/http";
import * as i12 from "../../services/auth.service";
var styles_UpdateEmailComponent = [i0.styles];
var RenderType_UpdateEmailComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UpdateEmailComponent, data: {} });
export { RenderType_UpdateEmailComponent as RenderType_UpdateEmailComponent };
function View_UpdateEmailComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9\u306E\u66F4\u65B0\u304C\u5B8C\u4E86\u3057\u307E\u3057\u305F\u3002 "]))], null, null); }
function View_UpdateEmailComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-progress-spinner", [["class", "loading-spinner text-side-spinner mat-progress-spinner"], ["color", "primary"], ["mode", "indeterminate"], ["role", "progressbar"], ["strokeWidth", "15"]], [[4, "fontSize", "em"], [2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"], [1, "aria-valuemin", 0], [1, "aria-valuemax", 0], [1, "aria-valuenow", 0], [1, "mode", 0]], null, null, i2.View_MatProgressSpinner_0, i2.RenderType_MatProgressSpinner)), i1.ɵdid(1, 114688, null, 0, i3.MatProgressSpinner, [i1.ElementRef, i4.Platform, [2, i5.DOCUMENT], [2, i6.ANIMATION_MODULE_TYPE], i3.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], { color: [0, "color"], strokeWidth: [1, "strokeWidth"], mode: [2, "mode"] }, null)], function (_ck, _v) { var currVal_8 = "primary"; var currVal_9 = "15"; var currVal_10 = "indeterminate"; _ck(_v, 1, 0, currVal_8, currVal_9, currVal_10); }, function (_ck, _v) { var currVal_0 = 1.5; var currVal_1 = i1.ɵnov(_v, 1)._noopAnimations; var currVal_2 = i1.ɵnov(_v, 1).diameter; var currVal_3 = i1.ɵnov(_v, 1).diameter; var currVal_4 = ((i1.ɵnov(_v, 1).mode === "determinate") ? 0 : null); var currVal_5 = ((i1.ɵnov(_v, 1).mode === "determinate") ? 100 : null); var currVal_6 = ((i1.ɵnov(_v, 1).mode === "determinate") ? i1.ɵnov(_v, 1).value : null); var currVal_7 = i1.ɵnov(_v, 1).mode; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
export function View_UpdateEmailComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i7.RootPathPrefixPipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 15, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, null, 0, i8.ɵangular_packages_forms_forms_z, [], null, null), i1.ɵdid(3, 540672, null, 0, i8.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i8.ControlContainer, null, [i8.FormGroupDirective]), i1.ɵdid(5, 16384, null, 0, i8.NgControlStatusGroup, [[4, i8.ControlContainer]], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [], [[4, "textAlign", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UpdateEmailComponent_1)), i1.ɵdid(8, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UpdateEmailComponent_2)), i1.ɵdid(10, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 5, "div", [["class", "error-message"]], null, null, null, null, null)), i1.ɵprd(512, null, i5.ɵNgClassImpl, i5.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(13, 278528, null, 0, i5.NgClass, [i5.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(14, { "hidden": 0 }), (_l()(), i1.ɵeld(15, 0, null, null, 1, "span", [["class", "message"]], null, null, null, null, null)), (_l()(), i1.ɵted(16, null, ["", ""])), (_l()(), i1.ɵeld(17, 0, null, null, 5, "div", [["class", "auth-actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 4, "span", [["class", "reset-password"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 19).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(19, 16384, null, 0, i9.RouterLink, [i9.Router, i9.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵppd(20, 1), i1.ɵpad(21, 1), (_l()(), i1.ɵted(-1, null, ["\u30C8\u30C3\u30D7\u3078\u623B\u308B"]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.authForm; _ck(_v, 3, 0, currVal_7); var currVal_9 = _co.completed; _ck(_v, 8, 0, currVal_9); var currVal_10 = (_co.pending && !_co.completed); _ck(_v, 10, 0, currVal_10); var currVal_11 = "error-message"; var currVal_12 = _ck(_v, 14, 0, !_co.authError); _ck(_v, 13, 0, currVal_11, currVal_12); var currVal_14 = _ck(_v, 21, 0, i1.ɵunv(_v, 19, 0, _ck(_v, 20, 0, i1.ɵnov(_v, 0), _co.path.feed.root))); _ck(_v, 19, 0, currVal_14); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 5).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 5).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 5).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 5).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 5).ngClassValid; var currVal_5 = i1.ɵnov(_v, 5).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 5).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_8 = "center"; _ck(_v, 6, 0, currVal_8); var currVal_13 = ((_co.authError == null) ? null : _co.authError.message); _ck(_v, 16, 0, currVal_13); }); }
export function View_UpdateEmailComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-update-email", [], null, null, null, View_UpdateEmailComponent_0, RenderType_UpdateEmailComponent)), i1.ɵdid(1, 114688, null, 0, i10.UpdateEmailComponent, [i9.Router, i9.ActivatedRoute, i11.HttpClient, i12.AuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UpdateEmailComponentNgFactory = i1.ɵccf("app-update-email", i10.UpdateEmailComponent, View_UpdateEmailComponent_Host_0, {}, {}, []);
export { UpdateEmailComponentNgFactory as UpdateEmailComponentNgFactory };
