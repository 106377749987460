/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./feed.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/tabs/typings/index.ngfactory";
import * as i3 from "@angular/material/tabs";
import * as i4 from "../../components/order-list/order-list.component.ngfactory";
import * as i5 from "../../components/order-list/es-order-virutual-scroll-strategy";
import * as i6 from "../../services/matching.service";
import * as i7 from "../../components/order-list/order-list.component";
import * as i8 from "@angular/fire/auth";
import * as i9 from "../../services/storage.service";
import * as i10 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i11 from "@angular/material/icon";
import * as i12 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i13 from "@angular/material/button";
import * as i14 from "@angular/cdk/a11y";
import * as i15 from "@angular/platform-browser/animations";
import * as i16 from "@angular/material/dialog";
import * as i17 from "@angular/common";
import * as i18 from "angular-pipes";
import * as i19 from "../../components/search-header/search-header.component.ngfactory";
import * as i20 from "../../components/search-header/search-header.component";
import * as i21 from "../../directives/swipable-tab.directive";
import * as i22 from "@angular/material/core";
import * as i23 from "@angular/cdk/platform";
import * as i24 from "./feed.component";
var styles_FeedComponent = [i0.styles];
var RenderType_FeedComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FeedComponent, data: {} });
export { RenderType_FeedComponent as RenderType_FeedComponent };
function View_FeedComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 6, "mat-tab", [], null, null, null, i2.View_MatTab_0, i2.RenderType_MatTab)), i1.ɵdid(1, 770048, [[4, 4]], 2, i3.MatTab, [i1.ViewContainerRef], { textLabel: [0, "textLabel"] }, null), i1.ɵqud(603979776, 5, { templateLabel: 0 }), i1.ɵqud(335544320, 6, { _explicitContent: 0 }), (_l()(), i1.ɵeld(4, 0, null, 0, 2, "app-order-list", [], null, null, null, i4.View_OrderListComponent_0, i4.RenderType_OrderListComponent)), i1.ɵprd(512, null, i5.EsOrderVirutualScrollStrategy, i5.EsOrderVirutualScrollStrategy, [i6.MatchingService]), i1.ɵdid(6, 114688, [[3, 4], ["orderList", 4]], 0, i7.OrderListComponent, [i5.EsOrderVirutualScrollStrategy, i8.AngularFireAuth, i9.StorageService, i6.MatchingService], { tabGroup: [0, "tabGroup"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.label; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.tabGroup; _ck(_v, 6, 0, currVal_1); }, null); }
function View_FeedComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mat-icon", [["class", "icon-checked mat-icon notranslate"], ["color", "primary"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i10.View_MatIcon_0, i10.RenderType_MatIcon)), i1.ɵdid(1, 9158656, null, 0, i11.MatIcon, [i1.ElementRef, i11.MatIconRegistry, [8, null], [2, i11.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["checked"]))], function (_ck, _v) { var currVal_2 = "primary"; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).inline; var currVal_1 = (((i1.ɵnov(_v, 1).color !== "primary") && (i1.ɵnov(_v, 1).color !== "accent")) && (i1.ɵnov(_v, 1).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_FeedComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "button", [["class", "sort-menu-items"], ["mat-flat-button", ""], ["matDialogClose", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0], [1, "type", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).dialogRef.close(i1.ɵnov(_v, 2).dialogResult) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        _co.sortOptionSelected(_v.context.$implicit.value);
        var pd_1 = ((_co.sortSelection = _v.context.$implicit.value) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i12.View_MatButton_0, i12.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i13.MatButton, [i1.ElementRef, i14.FocusMonitor, [2, i15.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(2, 606208, null, 0, i16.MatDialogClose, [[2, i16.MatDialogRef], i1.ElementRef, i16.MatDialog], { _matDialogClose: [0, "_matDialogClose"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_FeedComponent_4)), i1.ɵdid(4, 16384, null, 0, i17.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(5, 0, ["", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_4 = ""; _ck(_v, 2, 0, currVal_4); var currVal_5 = (_co.sortSelection === _v.context.$implicit.value); _ck(_v, 4, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); var currVal_2 = (i1.ɵnov(_v, 2).ariaLabel || null); var currVal_3 = i1.ɵnov(_v, 2).type; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_6 = _v.context.$implicit.name; _ck(_v, 5, 0, currVal_6); }); }
function View_FeedComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "sort-dialog"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "sort-menu"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FeedComponent_3)), i1.ɵdid(3, 278528, null, 0, i17.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.sortOption; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_FeedComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i18.ToArrayPipe, []), i1.ɵqud(402653184, 1, { tabGroup: 0 }), i1.ɵqud(402653184, 2, { sortDialog: 0 }), i1.ɵqud(671088640, 3, { orderLists: 1 }), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-search-header", [], [[2, "search-field-focus", null]], [[null, "searchFormEmit"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("searchFormEmit" === en)) {
        var pd_0 = (_co.searchFormSubmit($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i19.View_SearchHeaderComponent_0, i19.RenderType_SearchHeaderComponent)), i1.ɵdid(5, 114688, null, 0, i20.SearchHeaderComponent, [], null, { searchFormEmit: "searchFormEmit" }), (_l()(), i1.ɵeld(6, 0, null, null, 6, "mat-tab-group", [["appSwipableTab", ""], ["class", "mat-tab-group"], ["mat-stretch-tabs", ""]], [[2, "mat-tab-group-dynamic-height", null], [2, "mat-tab-group-inverted-header", null]], [[null, "selectedTabChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedTabChange" === en)) {
        var pd_0 = (_co.selectedTabChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatTabGroup_0, i2.RenderType_MatTabGroup)), i1.ɵdid(7, 3325952, [[1, 4]], 1, i3.MatTabGroup, [i1.ElementRef, i1.ChangeDetectorRef, [2, i3.MAT_TABS_CONFIG], [2, i15.ANIMATION_MODULE_TYPE]], null, { selectedTabChange: "selectedTabChange" }), i1.ɵqud(603979776, 4, { _tabs: 1 }), i1.ɵdid(9, 4210688, null, 0, i21.SwipableTabDirective, [i1.ElementRef, i3.MatTabGroup, i1.Renderer2], null, null), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_FeedComponent_1)), i1.ɵdid(11, 278528, null, 0, i17.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵppd(12, 1), (_l()(), i1.ɵeld(13, 0, null, null, 6, "div", [["class", "sort-options"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 0, "div", [["class", "virtual-shadow"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 2, "span", [["class", "sort-option mat-ripple"], ["color", "primary"], ["matRipple", ""]], [[2, "mat-ripple-unbounded", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openSortDialog() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(16, 212992, null, 0, i22.MatRipple, [i1.ElementRef, i1.NgZone, i23.Platform, [2, i22.MAT_RIPPLE_GLOBAL_OPTIONS], [2, i15.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, null, ["\u4E26\u3073\u66FF\u3048"])), (_l()(), i1.ɵeld(18, 0, null, null, 1, "mat-icon", [["class", "funnel-icon mat-icon notranslate"], ["role", "img"], ["svgIcon", "funnel"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i10.View_MatIcon_0, i10.RenderType_MatIcon)), i1.ɵdid(19, 9158656, null, 0, i11.MatIcon, [i1.ElementRef, i11.MatIconRegistry, [8, null], [2, i11.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵand(0, [[2, 2], ["sortDialog", 2]], null, 0, null, View_FeedComponent_2))], function (_ck, _v) { var _co = _v.component; _ck(_v, 5, 0); var currVal_3 = i1.ɵunv(_v, 11, 0, _ck(_v, 12, 0, i1.ɵnov(_v, 0), _co.tabList)); _ck(_v, 11, 0, currVal_3); _ck(_v, 16, 0); var currVal_7 = "funnel"; _ck(_v, 19, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 5).searchFocused; _ck(_v, 4, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 7).dynamicHeight; var currVal_2 = (i1.ɵnov(_v, 7).headerPosition === "below"); _ck(_v, 6, 0, currVal_1, currVal_2); var currVal_4 = i1.ɵnov(_v, 16).unbounded; _ck(_v, 15, 0, currVal_4); var currVal_5 = i1.ɵnov(_v, 19).inline; var currVal_6 = (((i1.ɵnov(_v, 19).color !== "primary") && (i1.ɵnov(_v, 19).color !== "accent")) && (i1.ɵnov(_v, 19).color !== "warn")); _ck(_v, 18, 0, currVal_5, currVal_6); }); }
export function View_FeedComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-feed", [], null, null, null, View_FeedComponent_0, RenderType_FeedComponent)), i1.ɵdid(1, 4308992, null, 0, i24.FeedComponent, [i16.MatDialog, i8.AngularFireAuth, i6.MatchingService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FeedComponentNgFactory = i1.ɵccf("app-feed", i24.FeedComponent, View_FeedComponent_Host_0, {}, {}, []);
export { FeedComponentNgFactory as FeedComponentNgFactory };
