/**
 * DOCUMENTATION
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact-email@something.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface NewMailActivity {
    email: string;
    trackingId?: string;
    type: NewMailActivity.TypeEnum;
    uid?: string;
    result?: NewMailActivity.ResultEnum;
    timestamp?: Date;
    message?: string;
    createdBy?: string;
    updatedBy?: string;
}
// tslint:disable-next-line: no-namespace
export namespace NewMailActivity {
    export type TypeEnum = 'member' | 'admin' | 'directInput';
    export const TypeEnum = {
        Member: 'member' as TypeEnum,
        Admin: 'admin' as TypeEnum,
        DirectInput: 'directInput' as TypeEnum
    };
    export type ResultEnum = 'bounce' | 'deferred' | 'delivered' | 'dropped' | 'processed';
    export const ResultEnum = {
        Bounce: 'bounce' as ResultEnum,
        Deferred: 'deferred' as ResultEnum,
        Delivered: 'delivered' as ResultEnum,
        Dropped: 'dropped' as ResultEnum,
        Processed: 'processed' as ResultEnum
    };
}


