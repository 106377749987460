import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Path } from '@lu/path';
import { AuthAction } from '@lu/auth-action';
import { UpdateEmailComponent } from '@lu/components/update-email/update-email.component';
import { ResetPasswordComponent } from '@lu/components/reset-password/reset-password.component';
import { SendResetPasswordMailComponent } from '@lu/components/send-reset-password-mail/send-reset-password-mail.component';
import { SendUpdateEmailMailComponent } from '@lu/components/send-update-email-mail/send-update-email-mail.component';
import { VerifyEmailComponent } from '@lu/components/verify-email/verify-email.component';
import { LocationService } from '@lu/services/location.service';

@Component({
  selector: 'app-auth-action',
  templateUrl: './auth-action.component.html',
  styleUrls: ['./auth-action.component.scss']
})
export class AuthActionComponent implements OnInit {
  public mode: string;
  public formComponent: any;
  public completed = false;
  public readonly path = Path;
  public readonly authAaction = AuthAction;

  constructor(
    private aRoute: ActivatedRoute,
    private router: Router,
    public locationService: LocationService
  ) {}

  ngOnInit() {
    this.mode = this.aRoute.snapshot.queryParams.mode;
    if (!Object.getOwnPropertyNames(AuthAction).includes(this.mode)) {
      this.router.navigate([`/${this.path.auth.root}`]);
    }
    this.setAuthActionForm();
  }

  setAuthActionForm() {
    switch (this.mode) {
      case AuthAction.resetPassword:
        this.formComponent = ResetPasswordComponent;
        break;
      case AuthAction.sendSetPasswordMail:
      case AuthAction.sendChangePasswordMail:
      case AuthAction.sendResetPasswordMail:
        this.formComponent = SendResetPasswordMailComponent;
        break;
      case AuthAction.sendUpdateEmailMail:
        this.formComponent = SendUpdateEmailMailComponent;
        break;
      case AuthAction.updateEmail:
        this.formComponent = UpdateEmailComponent;
        break;
      case AuthAction.verifyEmail:
        this.formComponent = VerifyEmailComponent;
        break;
    }
  }
}
