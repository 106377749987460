<nav>
  <h1 [routerLink]="[path.feed.root | rootPathPrefix]">
    <img
      class="logo"
      alt="SHUEISHA ハッピープラス COMMUNITY"
      src="assets/icon/happyplus_community_logo_A.png">
  </h1>
  <a [routerLink]="[path.profile.root | rootPathPrefix]">プロフィール</a>
  <a [routerLink]="[path.terms.root | rootPathPrefix]">メンバー規約</a>
  <a
    target="_blank"
    rel="noopener noreferrer"
    href="https://www2.shueisha.co.jp/privacy/privacy.html">集英社プライバシーガイドライン</a>
  <a (click)="signOut()">ログアウト</a>
</nav>
