import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class MatchingService extends ApiService {

  // project
  getProject(data: any): Observable<any> {
    const queryUri = this.apiEndpoint + '/projects';
    const body = data;
    return this.apiConnecter('GET', queryUri, body);
  }

  getOneProject(id: number): Observable<any> {
    const queryUri = this.apiEndpoint + '/projects/' + id;
    const body = {};
    return this.apiConnecter('GET', queryUri, body);
  }

  getAdminUser(data: {}): Observable<any> {
    const queryUri = this.apiEndpoint + '/_admin-users';
    return this.apiConnecter('GET', queryUri, data);
  }

  /*for member*/
  getMember(data: {}): Observable<any> {
    const queryUri = this.apiEndpoint + '/' + 'members';
    return this.apiConnecter('GET', queryUri, data);
  }

  getEachMember(id: string, data: {}): Observable<any> {
    const queryUri = this.apiEndpoint + '/' + 'members' + '/' + id;
    return this.apiConnecter('GET', queryUri, data);
  }

  updateMember(data: {}): Observable<any> {
    const queryUri = this.apiEndpoint + '/' + 'members/me';
    return this.apiConnecter('PUT', queryUri, data);
  }

  createAdminUser(data: any): Observable<any> {
    const queryUri = this.apiEndpoint + '/_admin-users';
    const body = data;
    return this.apiConnecter('POST', queryUri, data);
  }

  getAdminUserWithId(id: number): Observable<any> {
    const queryUri = this.apiEndpoint + '/_admin-users/' + id;
    const data = {};
    return this.apiConnecter('GET', queryUri, data);
  }

  getAdminUserWithEmail(data: any): Observable<any> {
    const queryUri = this.apiEndpoint + '/_admin-users' ;
    const body = data;
    return this.apiConnecter('GET', queryUri, data);
  }

  /* update connection account */
  updateConnection(type: string, id: number, data: any): Observable<any> {
    const queryUri = this.apiEndpoint + '/' + type + '/' + id;
    const body = data;
    return this.apiConnecter('PUT', queryUri, body);
  }

  /* add connection account */
  createConnection(type: string, data: any): Observable<any> {
    const queryUri = this.apiEndpoint + '/' + type;
    const body = data;
    return this.apiConnecter('POST', queryUri, body);
  }

  /* delete connection account */
  deleteConnection(id: number, accType: string): Observable<any> {
    const queryUri = this.apiEndpoint + '/' + accType + '/' + id;
    const body = {};
    return this.apiConnecter('DELETE', queryUri, body);
  }

  /* for address */
  getAddress(data: {}): Observable<any> {
    const queryUri = this.apiEndpoint + '/' + 'addresses';
    return this.apiConnecter('GET', queryUri, data);
  }

  updateAddress(id: number, data: {}): Observable<any> {
    const queryUri = this.apiEndpoint + '/addresses/' + id;
    const body = data;
    return this.apiConnecter('PUT', queryUri, body);
  }

  /* for login */
  loginUser(data: {}): Observable<any> {
    const queryUri = this.apiEndpoint + '/auth' + '/local';
    const body = data;
    console.log('data ==> ', data);
    return this.apiConnecter('POST', queryUri, body);
  }

  getLoginUser(): Observable<any> {
    const queryUri = this.apiEndpoint + '/users' + '/me';
    const body = {};
    return this.apiConnecter('GET', queryUri, body);
  }

  // for master
  getMaster(kind: string, data: {}): Observable<any> {
    const queryUri = this.apiEndpoint + '/' + kind;
    const body = data;
    return this.apiConnecter('GET', queryUri, body);
  }

  /**
   *
   * For Entry
   */
  getEntries(data): Observable<any> {
    const queryUri = this.apiEndpoint + '/entries';
    const body = data;
    return this.apiConnecter('GET', queryUri, body);
  }

  createEntry(data): Observable<any> {
    const queryUri = this.apiEndpoint + '/entries';
    const body = data;
    return this.apiConnecter('POST', queryUri, body);
  }

  deleteEntry(id: number): Observable<any> {
    const queryUri = this.apiEndpoint + '/entries/' + id;
    const body = {};
    return this.apiConnecter('DELETE', queryUri, body);
  }

  editEntry(id: number, data: any): Observable<any> {
    const queryUri = this.apiEndpoint + '/entries/' + id;
    const body = data;
    return this.apiConnecter('PUT', queryUri, body);
  }

  /**
   *
   * For Reward
   */
  getReward(data: any): Observable<any> {
    const queryUri = this.apiEndpoint + '/rewards';
    const body = data;
    return this.apiConnecter('GET', queryUri, body);
  }

  /**
   *
   * For Report
   */
  getReport(data): Observable<any> {
    const queryUri = this.apiEndpoint + '/reports';
    const body = data;
    return this.apiConnecter('GET', queryUri, body);
  }

  createReport(data: any): Observable<any> {
    const queryUri = this.apiEndpoint + '/reports';
    const body = data;
    return this.apiConnecter('POST', queryUri, body);
  }

  editReport(id: string, data: any): Observable<any> {
    const queryUri = this.apiEndpoint + '/reports/' + id;
    const body = data;
    return this.apiConnecter('PUT', queryUri, body);
  }

  /**
   * file upload
   */
  uploadFile(data: any): Observable<any> {
    const queryUri = this.apiEndpoint + '/upload';
    const body = data;
    return this.apiConnecter('POST', queryUri, body);
  }

  getUploadFile(): Observable<any> {
    const queryUri = this.apiEndpoint + '/upload/files';
    const body = {};
    return this.apiConnecter('GET', queryUri, body);
  }

  removeFile(id: number): Observable<any> {
    const queryUri = this.apiEndpoint + '/upload/files/' + id;
    const body = {};
    return this.apiConnecter('DELETE', queryUri, body);
  }

  /* for payment */
  getPayment(data): Observable<any> {
    const queryUri = this.apiEndpoint + '/payments';
    const body = data;
    return this.apiConnecter('GET', queryUri, body);
  }

  /**
   * for notification
   */
  getNotification(data): Observable<any> {
    const queryUri = this.apiEndpoint + '/notifications/me';
    const body = data;
    return this.apiConnecter('GET', queryUri, body);
  }

  updateNotification(id: number, data: any): Observable<any> {
    const queryUri = this.apiEndpoint + '/notifications/' + id;
    const body = data;
    return this.apiConnecter('PUT', queryUri, body);
  }

  // for Line
  getLineAuth(data): Observable<any> {
    const queryUri = this.apiEndpoint + '/line/oauth';
    const body = data;
    return this.apiConnecter('GET', queryUri, body);
  }

  deleteProvider(id: number): Observable<any> {
    const queryUri = this.apiEndpoint + '/providers/' + id;
    const body = {};
    return this.apiConnecter('DELETE', queryUri, body);
  }

  // for password and email update
  sendResetPasswordMail(data: any): Observable<any> {
    const queryUri = this.apiEndpoint + '/auth/action/request/resetpassword';
    const body = data;
    return this.apiConnecter('POST', queryUri, body);
  }

  sendUpdateEamilMail(data: any): Observable<any> {
    const queryUri = this.apiEndpoint + '/auth/action/request/updateemail';
    const body = data;
    return this.apiConnecter('POST', queryUri, body);
  }

  applyEmailUpdate(data: any): Observable<any> {
    const queryUri = this.apiEndpoint + '/auth/action/update/email';
    const body = data;
    return this.apiConnecter('POST', queryUri, body);
  }
}

