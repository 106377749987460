var ɵ0 = {
    send_page_view: false,
    allow_ad_personalization_signals: false,
    anonymize_ip: true
};
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0 };
