<form [formGroup]="resetPasswordForm">
  <div
    *ngIf="completed"
    class="completed-message">新しいパスワードでログインできるようになりました。</div>

  <ng-container *ngIf="codeVerified && !completed">
    <mat-form-field
      appearance="outline">
      <mat-label>
        <span class="label-outline">新しいパスワード</span>
        新しいパスワード
      </mat-label>
      <input
        matInput
        type="password"
        autocomplete="new-password"
        [formControl]="resetPasswordForm.get('password')">
    </mat-form-field>
    <div class="adhesive"></div>
    <mat-form-field
      appearance="outline">
      <mat-label>
        <span class="label-outline">パスワードの再入力</span>
        パスワードの再入力
      </mat-label>
      <input
        #confirmPassword
        matInput
        type="password"
        autocomplete="new-password"
        [formControl]="resetPasswordForm.get('confirmedPassword')">
    </mat-form-field>

    <button
      class="app-button-submit"
      mat-flat-button
      color="primary"
      type="submit"
      [disabled]="checkButtonIsDisabled()"
      (click)="resetPasswordAndSignInDirectly()">確定</button>
  </ng-container>

  <div
    class="error-message"
    [ngClass]="{'hidden': !authError}">
    <span class="message">{{authError?.message}}</span>
  </div>

  <div class="auth-actions">
    <span
      class="reset-password"
      [routerLink]="[path.feed.root | rootPathPrefix]">トップへ戻る</span>
  </div>
</form>