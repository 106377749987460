import { Injectable } from '@angular/core';
import {
  Router,
  CanActivateChild,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  ActivatedRoute
} from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AngularFireAuth } from '@angular/fire/auth';

import { assign, cloneDeep, pick, isEqual } from 'lodash';
import { Observable, of } from 'rxjs';
import { map, take, switchMap, delayWhen } from 'rxjs/operators';

import { Path } from '@lu/path';
import { DialogService } from '@lu/services/dialog.service';
import { environment } from '@lu/environment';

@Injectable({
  providedIn: 'root'
})
export class EmailVerificationGuard implements CanActivateChild {
  constructor(
    private http: HttpClient,
    private router: Router,
    private aRoute: ActivatedRoute,
    private afAuth: AngularFireAuth,
    private dialog: DialogService,
  ) { }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.afAuth.authState.pipe(
      take(1),
      // ルーティングの関係でここで呼ぶ
      delayWhen(() => this.overwriteEnvValues()),
      switchMap(user => {
        if (user.emailVerified) {
          return of(true);
        } else {
          const dalog = this.dialog.openConfirmDialog({
            disableClose: true,
            data: {
              text: 'メールアドレスが認証されていません。登録アドレスに認証メールを送信しますか？',
              applyText: '送信',
            }
          });
          return dalog.afterClosed()
            .pipe(map(send => {
              if (send) {
                const redirectTo = 'member';
                this.http.post(`${environment.apiEndpoint}/auth/action/request/verifyemail`, {redirectTo})
                  .subscribe(() => {}, err => console.error(err));
              }
              this.router.navigate([`/${Path.auth.login}`]);
              return false;
            }));
        }
      })
    );
  }

  overwriteEnvValues(): Observable<void> {
    // Overwrite environment values only when develop and emulate in local.
    if (!environment.firebaseConfig.projectId.match(/^dott-lu-(dev|ci)$/)) {
      return of(null);
    }
    // ActivatedRouteが呼ばれるタイミングで動いてないため
    // https://qiita.com/ttiger55/items/22e0f676ff6101336eaf
    const queryParams = window.location.search
      .substring(1).split('&').map((p) => p.split('='))
      .reduce((obj, e) => ({...obj, [e[0]]: e[1]}), {});
    const values = pick(queryParams, Object.keys(environment));
    const after = assign(cloneDeep(environment), values);
    if (!Object.keys(values).length || isEqual(environment, after)) {
      return of(null);
    }
    assign(environment, values);
    return this.dialog.openTextDialog({
      autoFocus: false,
      data: {text: '環境設定値を上書きしました（開発環境のみ有効）'}
    }).afterClosed();
  }
}
