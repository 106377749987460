import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Router, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';

import { filter } from 'rxjs/operators';

import { Path } from '@lu/path';

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  private tracking = false;
  public prevLocation: string;
  public currentLocation: string;
  private readonly path = Path;

  constructor(
    private router: Router,
    private location: Location,
  ) {}

  trackRouting() {
    if (this.tracking) {
      return;
    }
    this.tracking = true;
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd
                         || event instanceof NavigationCancel))
      .subscribe((event: NavigationEnd | NavigationCancel) => {
        this.prevLocation = this.currentLocation;
        this.currentLocation = event.url;
      }, err => {
        console.error(err);
      }, () => {
        this.tracking = false;
      });
  }

  navigateToErrorPage() {
    this.router.events
      .pipe(filter(event => event instanceof NavigationError))
      .subscribe(event => {
        this.router.navigate([`/${this.path.error.root}`]);
      });
  }

  backWithAppState() {
    if (!this.prevLocation) {
      this.router.navigate(['/']);
    } else {
      this.location.back();
    }
  }
}
