import * as tslib_1 from "tslib";
import { merge, of } from 'rxjs';
import { filter, skip, tap, take, takeUntil, map, switchMap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/auth";
import * as i2 from "./matching.service";
var NotificationService = /** @class */ (function () {
    function NotificationService(afAuth, apiService) {
        var _this = this;
        this.afAuth = afAuth;
        this.apiService = apiService;
        this.unreadLatest = false;
        this.afAuth.authState
            .pipe(filter(function (user) { return !!user; }))
            .subscribe(function (user) {
            _this.watchUnreadState();
        });
    }
    NotificationService.prototype.watchUnreadState = function () {
        var _this = this;
        var unread;
        var read;
        var unreadRef = this.apiService.getNotification({
            read: false,
            _sort: 'created_at:DESC',
            _limit: 1
        });
        var readRef = this.apiService.getNotification({
            read: true,
            _sort: 'created_at:DESC',
            _limit: 1
        });
        merge(unreadRef
            .pipe(takeUntil(this.afAuth.authState.pipe(skip(1))), map(function (docs) { return docs[0]; }), tap(function (doc) { return unread = doc; })), readRef
            .pipe(takeUntil(this.afAuth.authState.pipe(skip(1))), map(function (docs) { return docs[0]; }), tap(function (doc) { return read = doc; }))).subscribe(function (docs) {
            if (!unread) {
                _this.unreadLatest = false;
                return;
            }
            if (!read) {
                _this.unreadLatest = true;
                return;
            }
            var _a = tslib_1.__read([new Date(unread.created_at), new Date(read.created_at)], 2), unreadAt = _a[0], readAt = _a[1];
            if (unreadAt.valueOf() - readAt.valueOf() > 0) {
                _this.unreadLatest = true;
            }
            else {
                _this.unreadLatest = false;
            }
        }, function (err) { return console.error(err); });
    };
    NotificationService.prototype.readLatest = function () {
        var _this = this;
        var params = {
            _sort: 'created_at:DESC',
            _limit: 1
        };
        return this.apiService.getNotification(params)
            .pipe(take(1), filter(function (snaps) { return snaps.length > 0; }), map(function (snaps) { return snaps[0]; }), switchMap(function (snap) {
            // this.watchUnreadState();
            if (snap.read) {
                return of(null);
            }
            return _this.apiService.updateNotification(snap.id, { read: true });
        }));
    };
    NotificationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NotificationService_Factory() { return new NotificationService(i0.ɵɵinject(i1.AngularFireAuth), i0.ɵɵinject(i2.MatchingService)); }, token: NotificationService, providedIn: "root" });
    return NotificationService;
}());
export { NotificationService };
