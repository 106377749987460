import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { of, zip } from 'rxjs';
import { catchError, take, map } from 'rxjs/operators';
import { firestore, auth } from 'firebase/app';
import { cloneDeepWith, flatten } from 'lodash';
// import { Connection } from '@lu/models';

// type any = Connection[keyof Connection];
const timestampConverter = (o) => o instanceof firestore.Timestamp ? o.toDate() : undefined;

@Injectable()
export class OwnConnectionsResolver implements Resolve<Array<any & {_id: string}>> {
 constructor(
    private afStore: AngularFirestore,
  ) {}

 resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    const {uid} = auth().currentUser;
    const path = `members/${uid}/connections`;
    // get each latest documents.
    return zip(
        // Instagram
        this.afStore.collection<any>(path, ref => {
          return ref.where('serviceName', '==', 'instagram')
                    .orderBy('created.at', 'desc').limit(2);
        }).valueChanges({idField: '_id'}),
        // Twitter
        this.afStore.collection<any>(path, ref => {
          return ref.where('serviceName', '==', 'twitter')
                    .orderBy('created.at', 'desc').limit(1);
        }).valueChanges({idField: '_id'}),
        // Youtube
        this.afStore.collection<any>(path, ref => {
          return ref.where('serviceName', '==', 'youtube')
                    .orderBy('created.at', 'desc').limit(1);
        }).valueChanges({idField: '_id'}),
        // tikTok
        this.afStore.collection<any>(path, ref => {
          return ref.where('serviceName', '==', 'tiktok')
                    .orderBy('created.at', 'desc').limit(1);
        }).valueChanges({idField: '_id'}),
      )
      .pipe(
        take(1),
        map(docsList => flatten(docsList)),
        map(docs => docs.sort((a, b) => a.order - b.order)),
        map(docs => cloneDeepWith(docs, timestampConverter) as Array<any & {_id: string}>),
        catchError(err => {
          console.error(err);
          return of([]);
        })
      );
  }
}
