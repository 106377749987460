import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  protected apiEndpoint: string = environment.apiEndpoint;

  constructor(
    protected http: HttpClient
  ) { }

  protected getQueryFilter(queryObject: any) {
    let query = '';
    const keyArray = Object.keys(queryObject);
    const length = keyArray.length;
    keyArray.forEach((key, i) => {
      if (queryObject[key] === '' || queryObject[key] === null) {
        return;
      }
      if (queryObject[key] instanceof Array) {
        const ObjectArray = queryObject[key];
        for (const eachObj of ObjectArray) {
          query += key + '=' + encodeURI(eachObj);
          query += '&';
        }
      } else {
        query += key + '=' + encodeURI(queryObject[key]);
        query += '&';
      }
    });
    if (query !== '') {
      query = query.slice(0, -1);
      query = '?' + query;
    }
    return query;
  }

  protected convertEmptyValueToNull(queryObject: any) {
    // tslint:disable-next-line:curly
    if (queryObject === null || queryObject === '') return null;
    if (typeof queryObject === 'number' || typeof queryObject === 'boolean' || typeof queryObject === 'string') {
      return queryObject;
    }
    const keyArray = Object.keys(queryObject);
    keyArray.forEach((key, i) => {
      if (typeof queryObject[key] === 'object' && queryObject[key] !== null) {
        if (Array.isArray(queryObject[key])) {
        } else {
          queryObject[key] = this.convertEmptyValueToNull(queryObject[key]);
        }
        return;
      }
      if (queryObject[key] === '') {
        queryObject[key] = null;
      }
    });
    return queryObject;
  }

  protected apiConnecter(method: string, queryUri: string, body: object, file = false): Observable<any> {
    const headerOptions = new HttpHeaders()
      .set('Content-Type', 'application/json;charset=Shift_JIS;');
    const options = { headers: headerOptions };

    switch (method) {
      case 'GET':
        queryUri += this.getQueryFilter(body);
        // console.log('======= queryUri =======', queryUri);
        return this.http.get(queryUri, body).pipe(
          map(data => data),
          catchError(this.handleError));

      case 'POST':
        body = this.convertEmptyValueToNull(body);
        return this.http.post(queryUri, body).pipe(
          map(data => data),
          catchError(this.handleError));

      case 'PUT':
        body = this.convertEmptyValueToNull(body);
        return this.http.put(queryUri, body).pipe(
          map(data => data),
          catchError(this.handleError));

      case 'PATCH':
        body = this.convertEmptyValueToNull(body);
        return this.http.patch(queryUri, body).pipe(
          map(data => data),
          catchError(this.handleError));

      case 'DELETE':
        queryUri += this.getQueryFilter(body);
        return this.http.delete(queryUri, options).pipe(
          map(data => data),
          catchError(this.handleError));

      default:
        break;
    }
    return this.http.post(queryUri, body, options).pipe(
      map(data => data),
      catchError(this.handleError));
  }

  protected handleError(error: HttpErrorResponse | any) {
    return throwError(error);
  }

  protected handleErrorType(error) { }
}
