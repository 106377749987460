import { map, take } from 'rxjs/operators';
import { Path } from '@lu/path';
import { AuthAction } from '@lu/auth-action';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/fire/auth";
var AuthActionGuard = /** @class */ (function () {
    function AuthActionGuard(router, afAuth) {
        this.router = router;
        this.afAuth = afAuth;
    }
    AuthActionGuard.prototype.canActivate = function (next, state) {
        var _this = this;
        return this.afAuth.authState.pipe(take(1), map(function (user) {
            var mode = next.queryParams.mode;
            var redirectTo = state.url;
            switch (mode) {
                // case AuthAction.updateEmail:
                case AuthAction.sendUpdateEmailMail:
                    if (!user) {
                        return _this.router.createUrlTree(Path.auth.login.split('/'), { queryParams: { redirectTo: redirectTo } });
                    }
                    return true;
            }
            return true;
        }));
    };
    AuthActionGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthActionGuard_Factory() { return new AuthActionGuard(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.AngularFireAuth)); }, token: AuthActionGuard, providedIn: "root" });
    return AuthActionGuard;
}());
export { AuthActionGuard };
