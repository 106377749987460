import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { map } from 'rxjs/operators';
import { cloneDeepWith } from 'lodash';
import { Project } from '@lu/models';
import { MatchingService } from '@lu/services/matching.service';

@Injectable()
export class OrderResolver implements Resolve<Project & { _id: number }> {
  constructor(
    private apiService: MatchingService,
  ) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    const { orderId } = route.params;
    return this.apiService.getOneProject(orderId)
      .pipe(
        map(order => {
          const parsedOrder: Project & { _id: number } = cloneDeepWith(order);
          // tslint:disable-next-line: radix
          parsedOrder._id = parseInt(orderId);
          return parsedOrder;
        })
      );
  }
}
