import * as tslib_1 from "tslib";
import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject, Subscription } from 'rxjs';
import { cloneDeep } from 'lodash';
var EsOrderVirutualScrollStrategy = /** @class */ (function (_super) {
    tslib_1.__extends(EsOrderVirutualScrollStrategy, _super);
    function EsOrderVirutualScrollStrategy(apiService) {
        var _this = _super.call(this) || this;
        _this.apiService = apiService;
        _this.dataStream = new BehaviorSubject([]);
        _this.dataStream$ = _this.dataStream.asObservable();
        _this.fetchedPages = new Set();
        _this.subscription = new Subscription();
        _this.pageSize = 50;
        _this.hasNext = true;
        _this.pending = false;
        _this.query = { search: {} };
        return _this;
    }
    // reference https://material.angular.io/cdk/scrolling/overview#creating-items-in-the-viewport
    EsOrderVirutualScrollStrategy.prototype.connect = function (collectionViewer) {
        var _this = this;
        // tslint:disable-next-line: deprecation
        this.subscription.add(collectionViewer.viewChange.subscribe(function (range) {
            var startPage = _this.getPageForIndex(range.start);
            var endPage = _this.getPageForIndex(range.end);
            for (var i = startPage; i <= endPage; i++) {
                _this.fetchPage(i);
            }
        }));
        return this.dataStream;
    };
    EsOrderVirutualScrollStrategy.prototype.disconnect = function () {
        this.subscription.unsubscribe();
    };
    EsOrderVirutualScrollStrategy.prototype.getPageForIndex = function (index) {
        return Math.floor(index / this.pageSize);
    };
    EsOrderVirutualScrollStrategy.prototype.fetchPage = function (page) {
        if (this.fetchedPages.has(page)) {
            return;
        }
        this.fetchedPages.add(page);
        this.searchNextOrders();
    };
    Object.defineProperty(EsOrderVirutualScrollStrategy.prototype, "theEnd", {
        get: function () {
            return !this.hasNext;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EsOrderVirutualScrollStrategy.prototype, "isPending", {
        get: function () {
            return this.pending;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EsOrderVirutualScrollStrategy.prototype, "dataLength", {
        get: function () {
            return Array.isArray(this.cachedList) ? this.cachedList.length : 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EsOrderVirutualScrollStrategy.prototype, "hitLength", {
        get: function () {
            return this.queryTotal;
        },
        enumerable: true,
        configurable: true
    });
    EsOrderVirutualScrollStrategy.prototype.sortedOrderByReward = function (order) {
        var orderList = order.sort(function (a, b) {
            if (a.reward && b.reward) {
                var reward1 = a.reward;
                var reward2 = b.reward;
                return Number(reward2.amount) - Number(reward1.amount);
            }
            return -1;
        });
        return orderList;
    };
    /** With refresh order list. */
    EsOrderVirutualScrollStrategy.prototype.searchOrders = function (selectedTab, uid, query, nextData) {
        if (query === void 0) { query = {
            search: {}
            // tslint:disable-next-line: align
        }; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.pending = true;
                        this.hasNext = true;
                        this.queryTotal = null;
                        this.cachedList = null;
                        this.query = cloneDeep(query);
                        this.pageSize = this.query.search._limit;
                        this.fetchedPages.clear();
                        this.dataStream.next([]);
                        this.fetchPage(0);
                        return [4 /*yield*/, this.apiService.getProject(this.query.search)
                                // tslint:disable-next-line: deprecation
                                .subscribe(function (result) {
                                if (selectedTab === 0) {
                                    var entrieddata_1 = result.filter(function (data) {
                                        var e_1, _a;
                                        try {
                                            for (var _b = tslib_1.__values(data.entries), _c = _b.next(); !_c.done; _c = _b.next()) {
                                                var entry = _c.value;
                                                if (entry.member === uid) {
                                                    return data;
                                                }
                                            }
                                        }
                                        catch (e_1_1) { e_1 = { error: e_1_1 }; }
                                        finally {
                                            try {
                                                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                                            }
                                            finally { if (e_1) throw e_1.error; }
                                        }
                                    });
                                    result = result.filter(function (item) {
                                        return !entrieddata_1.includes(item);
                                    });
                                }
                                else if (selectedTab === 1) {
                                    result = result.filter(function (data) {
                                        return !((data.status === 'closed' || data.status === 'completed') && data.closedStatus === 'canceled');
                                    });
                                }
                                else if (selectedTab === 2) {
                                    result = result.filter(function (data) {
                                        return !((data.status === 'closed' || data.status === 'completed') && data.closedStatus === 'canceled');
                                    });
                                }
                                else if (selectedTab === 3) {
                                    result = result.filter(function (data) {
                                        return !((data.status === 'closed' || data.status === 'completed') && data.closedStatus === 'canceled');
                                    });
                                }
                                else if (selectedTab === 4) {
                                    result = result.filter(function (data) {
                                        return !((data.status === 'closed' || data.status === 'completed') && data.closedStatus === 'canceled');
                                    });
                                }
                                else if (selectedTab === 5) {
                                    result = result.filter(function (data) {
                                        return !((data.status === 'closed' || data.status === 'completed') && data.closedStatus === 'canceled');
                                    });
                                }
                                _this.pending = false;
                                if (nextData) {
                                    _this.cachedList = tslib_1.__spread(_this.cachedList, result);
                                    // tslint:disable-next-line: no-string-literal
                                    _this.cachedList = _this.cachedList.filter(function (v, i, a) { return a.findIndex(function (t) { return (t['_id'] === v['_id']); }) === i; });
                                    _this.queryTotal += _this.cachedList.length;
                                }
                                else {
                                    _this.queryTotal = result.total;
                                    _this.cachedList = result;
                                }
                                _this.cachedList = result;
                                if (_this.query.search._sort === 'reward:DESC') {
                                    _this.cachedList = _this.sortedOrderByReward(_this.cachedList);
                                }
                                _this.dataStream.next(_this.cachedList);
                                if (_this.pageSize === result.length) {
                                    _this.hasNext = true;
                                }
                                else if (result.length === 0) {
                                    _this.hasNext = false;
                                }
                                else {
                                    _this.hasNext = false;
                                }
                            }, function (err) {
                                console.error(err);
                                _this.pending = false;
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /** Append results to order list */
    EsOrderVirutualScrollStrategy.prototype.searchNextOrders = function () {
        var _this = this;
        if (!this.query
            || !this.hasNext
            || this.pending) {
            return;
        }
        this.pending = true;
        this.query.search._start = this.cachedList.length;
        // tslint:disable-next-line: deprecation
        this.apiService.getProject(this.query.search).subscribe(function (orderItem) {
            var _a;
            _this.pending = false;
            (_a = _this.cachedList).push.apply(_a, tslib_1.__spread(orderItem));
            _this.dataStream.next(_this.cachedList);
            if (_this.pageSize === orderItem.length) {
                _this.hasNext = true;
            }
            else if (orderItem.length === 0) {
                _this.hasNext = false;
            }
            else {
                _this.hasNext = false;
            }
        }, function (error) {
            console.error(error);
            _this.pending = false;
        });
    };
    return EsOrderVirutualScrollStrategy;
}(DataSource));
export { EsOrderVirutualScrollStrategy };
