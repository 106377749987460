import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { AngularFireAuth } from '@angular/fire/auth';

import { of, Observable, NEVER } from 'rxjs';
import { map, catchError, take } from 'rxjs/operators';
import { find, get } from 'lodash';

import { Path } from '@lu/path';
import { Project } from '@lu/models';
import { EsOrderVirutualScrollStrategy } from './es-order-virutual-scroll-strategy';
import { SearchOrderResult } from '@lu/pages/feed/feed.component';
import { StorageService } from '@lu/services/storage.service';
import { MatchingService } from '@lu/services/matching.service';

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.scss'],
  providers: [EsOrderVirutualScrollStrategy]
})
export class OrderListComponent implements OnInit {
  @Input() orders: Array<any>;
  @Input() tabGroup;
  @ViewChild(CdkVirtualScrollViewport, { static: true }) virtualScroll: CdkVirtualScrollViewport;
  public readonly path = Path;
  private uid: number;

  constructor(
    public dataSource: EsOrderVirutualScrollStrategy,
    private afAuth: AngularFireAuth,
    private storageService: StorageService,
    private apiService: MatchingService,
  ) { }

  async ngOnInit() {
    this.uid = await this.getUid();
  }

  async getUid() {
    const { uid } = this.afAuth.auth.currentUser;
    const getLoginMember = () => {
      return new Promise<number>(resolve => {
        this.apiService.getMember({ uid })
          .pipe(take(1), map(docSnap => docSnap[0].id))
          // tslint:disable-next-line: deprecation
          .subscribe(
            doc => {
              resolve(doc);
            }
          );
      });
    };
    return await getLoginMember();
  }

  async search(search: {}, nextData = false) {
    await this.dataSource.searchOrders(this.tabGroup.selectedIndex, this.uid, { search }, nextData);
  }

  getOwnEntry(esOrder: SearchOrderResult) {
    const entries = esOrder.entries;
    if (!Array.isArray(entries)) {
      return void 0;
    }
    const ownEntry = find(entries, ['member', this.uid]);
    return ownEntry;
  }

  getReport(project: Project) {
    const reports = project.reports;
    if (!Array.isArray(reports)) {
      return void 0;
    }
    const ownReport = find(reports, ['member', this.uid]);
    return ownReport;
  }

  isImage(data: any): boolean {
    if (data === null) {
      return false;
    }
    if (typeof data.mime !== 'string'
      || !/image\/.+/.test(data.mime)) {
      return false;
    }
    return true;
  }

  hasImage(order: Project): boolean {
    if (!order
      || (!order.file1
        && !order.file2)) {
      return false;
    }
    const imageFile = [order.file1, order.file2];
    const files = imageFile as any[];
    const file = files.reduce((prev, current) => this.isImage(prev) ? prev : current);
    const url = get(file, 'url');
    return !!url;
  }

  getImage(order: any): Observable<string> {
    if (!order
      || (!order.file1
        && !order.file2)) {
      return of(void 0);
    }
    const imageFile = [order.file1, order.file2];
    const files = imageFile as any[];
    const file = files.reduce((prev, current) => this.isImage(prev) ? prev : current);
    const url = get(file, 'url');
    return of(url);
  }
}
