import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '@lu/environment';
import { DialogService } from '@lu/services/dialog.service';
import { MatchingService } from '@lu/services/matching.service';

@Component({
  selector: 'app-send-update-email-mail',
  templateUrl: './send-update-email-mail.component.html',
  styleUrls: ['./send-update-email-mail.component.scss']
})
export class SendUpdateEmailMailComponent implements OnInit {
  public requestForm = new FormGroup({
    email: new FormControl(null, [
      Validators.required,
      Validators.email,
      Validators.pattern(/@{1}([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/),
    ]),
  });
  public completed = false;
  public pending = false;
  public requestError: {
    status: number;
    code: string;
    message: string;
    error: any;
  } = null;

  constructor(
    private http: HttpClient,
    private dialog: DialogService,
    private apiService: MatchingService
  ) { }

  ngOnInit() { }

  requestUpdateEmail() {
    const { email: newEmail } = this.requestForm.value;
    const member = () => {
      return new Promise((memberresolve) => {
        this.apiService.getMember({ email1: newEmail })
          .subscribe(members => {
            if (members.length > 0) {
              memberresolve(true);
            }
          });
      });
    };
    const isMember = async () => { await member(); };
    const redirectTo = isMember ? 'member' : 'admin';
    this.pending = true;
    this.http.post(`${environment.apiEndpoint}/auth/action/request/updateemail`, { newEmail, redirectTo })
      .subscribe(() => {
        // 重複送信防止のため意図的に pending はそのままにする
        this.completed = true;
      }, (err: HttpErrorResponse) => {
        const { status, message: code } = err;
        console.error(err);
        this.pending = false;
        this.requestError = { status, code, error: err, message: '' };
        switch (err.status) {
          case 400:
          case 409:
            this.requestError.message = '使用できないメールアドレスです。';
            break;
          default:
            this.requestError.message = 'メールを送信できませんでした。';
        }
      });
  }
}
