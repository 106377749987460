import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Path } from '@lu/path';
var VerifyEmailComponent = /** @class */ (function () {
    function VerifyEmailComponent(aRoute, afAuth) {
        this.aRoute = aRoute;
        this.afAuth = afAuth;
        this.authError = null;
        this.path = Path;
        this.pending = false;
        this.completed = false;
    }
    VerifyEmailComponent.prototype.ngOnInit = function () {
        var oobCode = this.aRoute.snapshot.queryParams.oobCode;
        this.applyActionCode(oobCode);
    };
    VerifyEmailComponent.prototype.applyActionCode = function (code) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var resp, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.pending = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, this.afAuth.auth.applyActionCode(code)];
                    case 2:
                        resp = _a.sent();
                        this.completed = true;
                        console.log(resp);
                        return [2 /*return*/];
                    case 3:
                        err_1 = _a.sent();
                        console.error(err_1);
                        switch (err_1.code) {
                            case 'auth/expired-action-code':
                                this.authError = { status: 400, code: err_1.code, message: '', error: err_1 };
                                this.authError.message = '認証コード有効期限が切れています。';
                                break;
                            default:
                                this.authError = { status: 400, code: err_1.code, message: '', error: err_1 };
                                this.authError.message = '無効な認証コードです。';
                        }
                        return [3 /*break*/, 5];
                    case 4:
                        this.pending = false;
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    return VerifyEmailComponent;
}());
export { VerifyEmailComponent };
