import { Component, OnInit, ViewChildren, Query, OnDestroy, ViewChild, ElementRef } from '@angular/core';

import { Notification } from '@lu/models';
import { LocationService } from '@lu/services/location.service';
import { NotificationService } from '@lu/services/notification.service';
import { NotificationsVirutualScrollStrategy } from './notifications-virutual-scroll-strategy';

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss'],
  providers: [NotificationsVirutualScrollStrategy]
})
export class NotificationListComponent implements OnInit, OnDestroy {
  @ViewChildren('notificationItem') notificationItem: Query;

  public notificationType = Notification.TypeEnum;
  public notifications: Array<Notification & { _id: string }>;
  private thresholdPercent = .7;

  constructor(
    public notificationService: NotificationService,
    public locationService: LocationService,
    public dataSource: NotificationsVirutualScrollStrategy,
  ) { }

  ngOnInit() {
    this.dataSource.subscribe();
  }

  ngOnDestroy() {
    // 離脱時に全既読にする
    this.dataSource.updateAllRead();
  }

  onScroll(event: WheelEvent) {
    const element = event.target as HTMLElement;
    const threshold = this.thresholdPercent * 100 * element.scrollHeight / 100;
    const current = element.scrollTop + element.clientHeight;
    if (current >= threshold) {
      this.dataSource.next();
    }
  }

  fetchNext() { }
}
