<app-search-header
  (searchFormEmit)="searchFormSubmit($event)"></app-search-header>

<mat-tab-group
  appSwipableTab
  mat-stretch-tabs
  (selectedTabChange)="selectedTabChange($event)">
  <mat-tab
    *ngFor="let item of tabList | toArray"
    [label]="item.label">
    <app-order-list [tabGroup]="tabGroup" #orderList></app-order-list>
  </mat-tab>
</mat-tab-group>

<div class="sort-options">
  <div class="virtual-shadow"></div>
  <span
    class="sort-option"
    matRipple
    color="primary"
    (click)="openSortDialog()">並び替え</span>
    <mat-icon svgIcon="funnel" class="funnel-icon"></mat-icon>
</div>

<ng-template #sortDialog>
  <div class="sort-dialog">
    <div class="sort-menu">
      <button
        *ngFor="let option of sortOption"
        class="sort-menu-items"
        mat-flat-button
        matDialogClose
        (click)="sortOptionSelected(option.value);
                 sortSelection = option.value">
        <mat-icon
          *ngIf="sortSelection === option.value"
          class="icon-checked"
          color="primary">checked</mat-icon>{{option.name}}
      </button>
    </div>
  </div>
</ng-template>