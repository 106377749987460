import { findIndex, cloneDeep } from 'lodash';
import * as i0 from "@angular/core";
var FavoriteService = /** @class */ (function () {
    function FavoriteService() {
        this.favoriteOrderList = this.getFavoriteOrderList();
    }
    Object.defineProperty(FavoriteService.prototype, "favoriteOrderList", {
        get: function () {
            return cloneDeep(this.privateFavoriteOrderList);
        },
        set: function (list) {
            this.privateFavoriteOrderList = cloneDeep(list);
        },
        enumerable: true,
        configurable: true
    });
    FavoriteService.prototype.getFavoriteOrderList = function () {
        var listStr = localStorage.getItem('list/favoriteOrder');
        try {
            var list = JSON.parse(listStr);
            return list.map(function (data) {
                return {
                    ref: data.id,
                    modifiedAt: new Date(data.modifiedAt)
                };
            });
        }
        catch (_a) {
            return [];
        }
    };
    FavoriteService.prototype.setFavoriteOrderList = function (list) {
        var newlist = [];
        list.forEach(function (item) {
            newlist.push({ id: item.ref, modifiedAt: item.modifiedAt.toString() });
        });
        localStorage.setItem('list/favoriteOrder', JSON.stringify(newlist));
    };
    FavoriteService.prototype.addOrderToFavorite = function (orderId) {
        var list = cloneDeep(this.favoriteOrderList);
        list.unshift({
            ref: orderId,
            modifiedAt: new Date()
        });
        this.favoriteOrderList = list;
        this.setFavoriteOrderList(this.favoriteOrderList);
    };
    FavoriteService.prototype.removeOrderFromFavorite = function (orderId) {
        var list = cloneDeep(this.favoriteOrderList);
        var index = findIndex(list, ['ref', orderId]);
        if (index === -1) {
            return;
        }
        list.splice(index, 1);
        this.favoriteOrderList = list;
        this.setFavoriteOrderList(this.favoriteOrderList);
    };
    FavoriteService.prototype.isFavoriteOrder = function (orderId) {
        return this.privateFavoriteOrderList.some(function (item) { return item.ref === orderId; });
    };
    FavoriteService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FavoriteService_Factory() { return new FavoriteService(); }, token: FavoriteService, providedIn: "root" });
    return FavoriteService;
}());
export { FavoriteService };
