<form [formGroup]="authForm">

<div [style.textAlign]="'center'">
  <ng-container *ngIf="completed">
    メールアドレスの更新が完了しました。
  </ng-container>
  <mat-progress-spinner
    *ngIf="pending && !completed"
    class="loading-spinner text-side-spinner"
    color="primary"
    mode="indeterminate"
    strokeWidth="15"
    [style.fontSize.em]="1.5"></mat-progress-spinner>
</div>

  <div
    class="error-message"
    [ngClass]="{'hidden': !authError}">
    <span class="message">{{authError?.message}}</span>
  </div>
</form>

<div class="auth-actions">
  <span
    class="reset-password"
    [routerLink]="[path.feed.root | rootPathPrefix]">トップへ戻る</span>
</div>