import * as tslib_1 from "tslib";
import { PipeTransform } from '@angular/core';
var DateAgoPipe = /** @class */ (function () {
    function DateAgoPipe() {
    }
    DateAgoPipe.prototype.transform = function (value, threshould) {
        var e_1, _a;
        if (threshould === void 0) { threshould = 60 * 60 * 24; }
        if (!value) {
            return null;
        }
        var seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
        var intervals = {
            year: 31536000,
            month: 2592000,
            week: 604800,
            day: 86400,
            hour: 3600,
            minute: 60,
            second: 1
        };
        var intervalOrders = ['year', 'month', 'week', 'day', 'hour', 'minute', 'second'];
        try {
            for (var intervalOrders_1 = tslib_1.__values(intervalOrders), intervalOrders_1_1 = intervalOrders_1.next(); !intervalOrders_1_1.done; intervalOrders_1_1 = intervalOrders_1.next()) {
                var name_1 = intervalOrders_1_1.value;
                var counter = Math.floor(seconds / intervals[name_1]);
                if (seconds / threshould > 1) {
                    return null;
                }
                if (counter > 0) {
                    // if (counter >= 2) {} // plural
                    return this.getDateAgoString(counter, name_1, new Date(value));
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (intervalOrders_1_1 && !intervalOrders_1_1.done && (_a = intervalOrders_1.return)) _a.call(intervalOrders_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    DateAgoPipe.prototype.getDateAgoString = function (counter, name, date) {
        if (counter === 1) {
            switch (name) {
                case 'day': return "\u6628\u65E5 " + String(date.getHours()).padStart(2, '0') + ":" + String(date.getMinutes()).padStart(2, '0');
            }
        }
        switch (name) {
            case 'second': return counter + "\u79D2\u524D";
            case 'minute': return counter + "\u5206\u524D";
            case 'hour': return counter + "\u6642\u9593\u524D";
            case 'day': return counter + "\u65E5\u524D";
            case 'week': return counter + "\u9031\u9593\u524D ";
            case 'month': return counter + "\u30F6\u6708\u524D";
            case 'year': return counter + "\u5E74\u524D";
        }
    };
    return DateAgoPipe;
}());
export { DateAgoPipe };
