import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { MatSharedModule } from './mat-shared.module';
import { ComponentSharedModule } from './components/component-shared.module';
import { ReactiveFormsModule } from '@angular/forms';
// Path definitions
import { Path } from './path';
// guards
import { AuthGuard } from '@lu/guards/auth.guard';
import { AuthActionGuard } from '@lu/guards/auth-action.guard';
import { EmailVerificationGuard } from '@lu/guards/email-verification.guard';
import { CanDeactivateGuard } from './guards/can-deactivate.guard';
// Resolvers
import { SleepResolver } from '@lu/resolvers/sleep';
import { OrderResolver } from '@lu/resolvers/order';
import { OrderReportResolver } from '@lu/resolvers/order-report';
import { OrderRewardsResolver } from '@lu/resolvers/order-rewards';
import { OrderRewardResolver } from '@lu/resolvers/order-reward';
import { OrderPublicConfidentialsResolver } from '@lu/resolvers/order-publlc-confidentials';
import { OwnselfAsMemberResolver } from '@lu/resolvers/ownself-as-member';
import { OwnAddressResolver } from '@lu/resolvers/own-address';
import { OwnBankAccountResolver } from '@lu/resolvers/own-bank-account';
import { OwnConnectionsResolver } from '@lu/resolvers/own-connections';
import { OwnProvidersResolver } from '@lu/resolvers/own-providers';
// Pipes Module
import { PipesModule } from '@lu/pipes/pipes.module';
// Directive Module
import { DirectiveModule } from '@lu/directives/directive.module';
// Components
import { NotFoundComponent } from '@lu/pages/not-found/not-found.component';
import { AuthActionComponent } from './pages/auth-action/auth-action.component';
import { AuthLoginComponent } from './pages/auth-login/auth-login.component';
import { TabComponent } from './pages/tab/tab.component';
import { FeedComponent } from './pages/feed/feed.component';
import { PaymentComponent } from './pages/payment/payment.component';
import { OrderDetailComponent } from './pages/order-detail/order-detail.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { NotificationListComponent } from './pages/notification-list/notification-list.component';
import { TermsComponent } from './pages/terms/terms.component';
import { ServicePolicyComponent } from './pages/service-policy/service-policy.component';
import { FavoriteListComponent } from './pages/favorite-list/favorite-list.component';
import { OrderReportComponent } from './pages/order-report/order-report.component';

const routes: Routes = [
  {
    path: Path.auth.login,
    component: AuthLoginComponent,
  },
  {
    path: Path.auth.action,
    component: AuthActionComponent,
    canActivate: [AuthActionGuard],
  },
  {
    path: 'auth',
    redirectTo: Path.auth.login,
    pathMatch: 'full'
  },
  {
    path: Path.terms.root,
    component: TermsComponent,
  },
  {
    path: Path.policy.root,
    component: ServicePolicyComponent,
  },
  {
    path: '',
    component: TabComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard, EmailVerificationGuard ],
    children: [
      {
        path: Path.feed.root,
        component: FeedComponent,
      },
      {
        path: Path.order.detail,
        component: OrderDetailComponent,
        resolve: {
          order: OrderResolver,
          // rewards: OrderRewardsResolver,
          // confidentials: OrderPublicConfidentialsResolver,
        },
      },
      {
        path: Path.order.report,
        component: OrderReportComponent,
        resolve: {
          order: OrderResolver,
          report: OrderReportResolver,
          // reward: OrderRewardResolver,
        }
      },
      {
        path: Path.notification.list,
        component: NotificationListComponent
      },
      {
        path: Path.favorite.root,
        component: FavoriteListComponent,
        resolve: {
          sleep: SleepResolver, // dummy
        }
      },
      {
        path: Path.payment.detail,
        component: PaymentComponent,
        resolve: {
          sleep: SleepResolver, // dummy
        }
      },
      {
        path: Path.profile.root,
        component: ProfileComponent,
        resolve: {
          member: OwnselfAsMemberResolver,
          // address: OwnAddressResolver,
          // bankAccount: OwnBankAccountResolver,
          // connections: OwnConnectionsResolver,
          // providers: OwnProvidersResolver,
        },
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: '',
        redirectTo: Path.feed.root,
        pathMatch: 'full'
      },
      {
        path: Path.error.root,
        component: NotFoundComponent,
      },
      {
        path: '**',
        redirectTo: Path.error.root,
        pathMatch: 'full'
      },
    ]
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes),
    MatSharedModule,
    ComponentSharedModule,
    ReactiveFormsModule,
    PipesModule,
    DirectiveModule,
  ],
  exports: [RouterModule],
  declarations: [
    NotFoundComponent,
    AuthActionComponent,
    AuthLoginComponent,
    TabComponent,
    FeedComponent,
    PaymentComponent,
    OrderDetailComponent,
    ProfileComponent,
    NotificationListComponent,
    TermsComponent,
    ServicePolicyComponent,
    FavoriteListComponent,
    OrderReportComponent,
  ],
  providers: [
    SleepResolver,
    OrderResolver,
    OrderReportResolver,
    OrderRewardResolver,
    OrderRewardsResolver,
    OrderPublicConfidentialsResolver,
    OwnselfAsMemberResolver,
    OwnAddressResolver,
    OwnBankAccountResolver,
    OwnConnectionsResolver,
    OwnProvidersResolver
,  ]
})
export class AppRoutingModule { }
