import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

import { MatSharedModule } from 'src/app/mat-shared.module';
import { PipesModule } from '@lu/pipes/pipes.module';
import { OrderListComponent } from '@lu/components/order-list/order-list.component';
import { SideNavComponent } from '@lu/components/side-nav/side-nav.component';
import { RouteProgressComponent } from './route-progress/route-progress.component';
import { SearchHeaderComponent } from './search-header/search-header.component';
import { OrderFavoriteComponent } from './order-favorite/order-favorite.component';
import { TextDialogComponent } from './text-dialog/text-dialog.component';
import { SendResetPasswordMailComponent } from './send-reset-password-mail/send-reset-password-mail.component';
import { SendUpdateEmailMailComponent } from './send-update-email-mail/send-update-email-mail.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { UpdateEmailComponent } from './update-email/update-email.component';
import { FileChooserComponent } from './file-chooser/file-chooser.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { LoadingDialogComponent } from './loading-dialog/loading-dialog.component';
import { OrderStatusChipComponent } from './order-status-chip/order-status-chip.component';
import { VerifyEmailComponent } from './verify-email/verify-email.component';

@NgModule({
  declarations: [
    OrderListComponent,
    SideNavComponent,
    RouteProgressComponent,
    SearchHeaderComponent,
    OrderFavoriteComponent,
    TextDialogComponent,
    SendResetPasswordMailComponent,
    SendUpdateEmailMailComponent,
    ResetPasswordComponent,
    UpdateEmailComponent,
    FileChooserComponent,
    ConfirmDialogComponent,
    LoadingDialogComponent,
    OrderStatusChipComponent,
    VerifyEmailComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatSharedModule,
    ReactiveFormsModule,
    PipesModule,
  ],
  exports: [
    OrderListComponent,
    SideNavComponent,
    RouteProgressComponent,
    SearchHeaderComponent,
    OrderFavoriteComponent,
    TextDialogComponent,
    SendResetPasswordMailComponent,
    SendUpdateEmailMailComponent,
    ResetPasswordComponent,
    UpdateEmailComponent,
    FileChooserComponent,
    ConfirmDialogComponent,
    LoadingDialogComponent,
    OrderStatusChipComponent,
    VerifyEmailComponent,
  ],
  entryComponents: [
    TextDialogComponent,
    SendResetPasswordMailComponent,
    SendUpdateEmailMailComponent,
    ResetPasswordComponent,
    UpdateEmailComponent,
    ConfirmDialogComponent,
    LoadingDialogComponent,
    VerifyEmailComponent,
  ]
})
export class ComponentSharedModule { }
