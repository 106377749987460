import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { TextDialogComponent, TextDialogComponentData } from '@lu/components/text-dialog/text-dialog.component';
import { ConfirmDialogComponent, ConfirmDialogComponentData } from '@lu/components/confirm-dialog/confirm-dialog.component';
import { LodingDialogData, LoadingDialogComponent } from '@lu/components/loading-dialog/loading-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(
    private dialog: MatDialog,
  ) {}

  openLoadingDialog(config: MatDialogConfig<LodingDialogData>) {
    return this.dialog.open<LoadingDialogComponent, LodingDialogData>(LoadingDialogComponent, config);
  }

  openTextDialog(config: MatDialogConfig<TextDialogComponentData> = {}) {
    const dialog = this.dialog.open<TextDialogComponent, TextDialogComponentData>(
      TextDialogComponent,
      {...config, maxHeight: '90vh'}
    );
    return dialog;
  }

  openConfirmDialog(config: MatDialogConfig<ConfirmDialogComponentData> = {}) {
    const dialog = this.dialog.open<ConfirmDialogComponent, ConfirmDialogComponentData>(
      ConfirmDialogComponent,
      {...config}
    );
    return dialog;
  }
}
