import * as tslib_1 from "tslib";
import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject, Subscription } from 'rxjs';
import { flatten } from 'lodash';
var NotificationsVirutualScrollStrategy = /** @class */ (function (_super) {
    tslib_1.__extends(NotificationsVirutualScrollStrategy, _super);
    function NotificationsVirutualScrollStrategy(apiService, notificationService) {
        var _this = _super.call(this) || this;
        _this.apiService = apiService;
        _this.notificationService = notificationService;
        _this.dataStream = new BehaviorSubject([]);
        _this.dataStream$ = _this.dataStream.asObservable();
        _this.fetchedPages = new Set();
        _this.subscription = new Subscription();
        _this.lastPage = 0;
        _this.pageSize = 10;
        _this.hasNext = true;
        _this.pending = false;
        return _this;
    }
    // reference https://material.angular.io/cdk/scrolling/overview#creating-items-in-the-viewport
    NotificationsVirutualScrollStrategy.prototype.connect = function (collectionViewer) {
        var _this = this;
        this.subscription.add(collectionViewer.viewChange.subscribe(function (range) {
            var startPage = _this.getPageForIndex(range.start);
            var endPage = _this.getPageForIndex(range.end - 1);
            for (var i = startPage; i <= endPage; i++) {
                _this.fetchPage(i);
            }
        }));
        return this.dataStream;
    };
    NotificationsVirutualScrollStrategy.prototype.disconnect = function () {
        this.subscription.unsubscribe();
    };
    NotificationsVirutualScrollStrategy.prototype.getPageForIndex = function (index) {
        return Math.floor(index / this.pageSize);
    };
    NotificationsVirutualScrollStrategy.prototype.fetchPage = function (page) {
        if (this.fetchedPages.has(page)) {
            return;
        }
        this.lastPage = page;
        this.fetchedPages.add(page);
        this.next();
    };
    Object.defineProperty(NotificationsVirutualScrollStrategy.prototype, "theEnd", {
        get: function () {
            return !this.hasNext;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NotificationsVirutualScrollStrategy.prototype, "isPending", {
        get: function () {
            return this.pending;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NotificationsVirutualScrollStrategy.prototype, "dataLength", {
        get: function () {
            return Array.isArray(this.cachedList) ? flatten(this.cachedList).length : 0;
        },
        enumerable: true,
        configurable: true
    });
    NotificationsVirutualScrollStrategy.prototype.updateAllRead = function () {
        var _this = this;
        this.pending = true;
        var params = {
            read: 'false'
        };
        this.apiService.getNotification(params).subscribe(function (data) {
            if (data && data.length > 0) {
                data.forEach(function (noti) {
                    _this.apiService.updateNotification(noti.id, { read: true })
                        .subscribe(function () {
                        _this.subscribe();
                        _this.notificationService.watchUnreadState();
                    });
                }, function (err) {
                    console.error(err);
                    _this.pending = false;
                });
            }
            else {
                _this.subscribe();
            }
        });
    };
    /** With refresh order list. */
    NotificationsVirutualScrollStrategy.prototype.subscribe = function () {
        var _this = this;
        this.pending = true;
        this.hasNext = true;
        this.cachedList = null;
        this.fetchedPages.clear();
        this.dataStream.next([]);
        this.fetchPage(0);
        var params = {
            _limit: this.pageSize,
            _start: this.lastPage,
            _sort: 'created_at:DESC'
        };
        this.subscription.add(this.apiService.getNotification(params)
            .subscribe(function (docs) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.pending = false;
                this.cachedList = [];
                this.cachedList = docs;
                this.dataStream.next(this.cachedList);
                if (this.pageSize === docs.length) {
                    this.hasNext = true;
                }
                else if (docs.length === 0) {
                    this.hasNext = false;
                }
                else {
                    this.hasNext = false;
                }
                return [2 /*return*/];
            });
        }); }, function (err) {
            console.error(err);
            _this.pending = false;
        }));
    };
    /** Append results to order list */
    NotificationsVirutualScrollStrategy.prototype.next = function () {
        var _this = this;
        if (!this.hasNext || this.pending) {
            return;
        }
        var params = {
            _limit: this.pageSize,
            _start: this.cachedList.length,
            _sort: 'created_at:DESC'
        };
        this.pending = true;
        this.subscription.add(this.apiService.getNotification(params)
            .subscribe(function (docs) {
            var _a;
            _this.pending = false;
            (_a = _this.cachedList).push.apply(_a, tslib_1.__spread(docs));
            _this.dataStream.next(_this.cachedList);
            if (_this.pageSize === docs.length) {
                _this.hasNext = true;
            }
            else if (docs.length === 0) {
                _this.hasNext = false;
            }
            else {
                _this.hasNext = false;
            }
        }, function (err) {
            console.error(err);
            _this.pending = false;
        }));
    };
    return NotificationsVirutualScrollStrategy;
}(DataSource));
export { NotificationsVirutualScrollStrategy };
