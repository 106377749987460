<form [formGroup]="requestForm">
  <!-- Messge completed -->
  <div
    *ngIf="completed else mailForm"
    class="completed-message">{{
      mode == authAction.sendSetPasswordMail ?
      '入力いただいたメールアドレスにパスワード設定のリンクをお送りしました。' :
      '入力いただいたメールアドレスにパスワード再設定のリンクをお送りしました。'
    }}</div>
  <!-- Form -->
  <ng-template #mailForm>
    <div class="form-hint">{{
      mode == authAction.sendSetPasswordMail ?
      '入力いただいたメールアドレスに、パスワード設定のリンクをお送りします。' :
      '入力いただいたメールアドレスに、パスワード再設定のリンクをお送りします。'
    }}</div>
    <mat-form-field
      appearance="outline">
      <mat-label>
        <span class="label-outline">メールアドレス</span>
        メールアドレス
      </mat-label>
      <input
        matInput
        placeholder="example@email.com"
        [formControl]="requestForm.get('email')">
    </mat-form-field>

    <div
      class="error-message"
      [ngClass]="{'hidden': !requestError}">
      <span class="message">{{requestError?.message}}</span>
    </div>

    <button
      class="app-button-submit"
      mat-flat-button
      color="primary"
      type="submit"
      [disabled]="requestForm.invalid || pending"
      (click)="sendResetPasswordMail()">送信</button>
  </ng-template>
</form>