<mat-toolbar
  class="app-header app-flat-header app-fixed-header">
  <app-route-progress></app-route-progress>
  <a
    class="logo"
    [routerLink]="[path.feed.root | rootPathPrefix]">
    <img
      class="logo"
      alt="SHUEISHA ハッピープラス COMMUNITY"
      src="assets/icon/happyplus_community_logo_A.png">
  </a>
  <form
    class="search-field-wrapper"
    [formGroup]="searchForm"
    (submit)="emitSearchKeyword()">
    <div class="search-field">
      <div
        class="submit-bounce-box"
        (click)="this.searchFocused ? emitSearchKeyword() : null"></div>
      <input
        #searchInput
        placeholder="案件を検索"
        class="search-input"
        type="text"
        matInput
        formControlName="keyword"
        (focus)="focusSearch()">
    </div>
  </form>
  <div
    class="button-search-clear"
    matRipple
    (click)="blurSearch()">キャンセル</div>
  <button
    class="button-icon-fav"
    mat-icon-button
    [routerLink]="[path.favorite.root | rootPathPrefix]">
    <mat-icon>favorite</mat-icon>
  </button>
</mat-toolbar>

<ng-container *ngIf="searchFocused then recentSearch"></ng-container>

<ng-template #recentSearch>
  <div
    class="recently-search">
    <h4 class="recently-search-title">最近の検索</h4>
    <div
      *ngFor="let keyword of recentlySearchKeywords"
      class="recently-keyword">
      <span class="keyword">{{keyword}}</span>
      <button
        mat-icon-button
        (click)="applyRecentlySearchKeyword(keyword)">
        <mat-icon color="primary">call_made</mat-icon>
      </button>
    </div>
  </div>
</ng-template>