import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'dateAgo',
})
export class DateAgoPipe implements PipeTransform {
  transform(value: Date | string, threshould = 60 * 60 * 24): Date | string | null {
    if (!value) {
      return null;
    }

    const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
    const intervals = {
      year: 31536000,
      month: 2592000,
      week: 604800,
      day: 86400,
      hour: 3600,
      minute: 60,
      second: 1
    };
    const intervalOrders = ['year', 'month', 'week', 'day', 'hour', 'minute', 'second'];
    for (const name of intervalOrders) {
      const counter = Math.floor(seconds / intervals[name]);
      if (seconds / threshould > 1) {
        return null;
      }
      if (counter > 0) {
        // if (counter >= 2) {} // plural
        return this.getDateAgoString(counter, name, new Date(value));
      }
    }
  }

  getDateAgoString(counter: number, name: string, date: Date): string {
    if (counter === 1) {
      switch (name) {
        case 'day': return `昨日 ${
          String(date.getHours()).padStart(2, '0')
        }:${
          String(date.getMinutes()).padStart(2, '0')
        }`;
      }
    }
    switch (name) {
      case 'second': return `${counter}秒前`;
      case 'minute': return `${counter}分前`;
      case 'hour': return `${counter}時間前`;
      case 'day': return `${counter}日前`;
      case 'week': return `${counter}週間前 `;
      case 'month': return `${counter}ヶ月前`;
      case 'year': return `${counter}年前`;
    }
  }
}
