import * as tslib_1 from "tslib";
import { ElementRef, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { FormArray, FormGroup, FormControl, Validators } from '@angular/forms';
import { cloneDeep, get, forEach, isNil } from 'lodash';
import { merge, Subject } from 'rxjs';
import { map, takeUntil, filter } from 'rxjs/operators';
import * as _ from 'lodash';
import { environment, LINEAddFriendHTMLElement } from '@lu/environment';
import { Member, BankAccount, Child, ConnectionTwitter, ConnectionInstagram, ConnectionTikTok, ConnectionYoutube, } from '@lu/models';
import { Path } from '@lu/path';
import { AuthAction } from '@lu/auth-action';
import { FileChooserComponent } from '@lu/components/file-chooser/file-chooser.component';
var URLValidator = function () { return Validators.pattern(/^https?:\/\/(www\.)?.+\..+(\/.+)?$/); };
var ɵ0 = URLValidator;
var FiniteValidator = function (ctrl) {
    if (ctrl.value === null) {
        return null;
    }
    if (!Number.isFinite(+ctrl.value)) {
        return {
            numberic: {
                actual: ctrl.value,
                type: typeof ctrl.value
            }
        };
    }
    return null;
};
var ɵ1 = FiniteValidator;
var TwitterNameValidator = function () { return Validators.pattern(/^[a-zA-Z0-9_]*$/); };
var ɵ2 = TwitterNameValidator;
var IDValidator = function () { return Validators.pattern(/^[a-zA-Z0-9_.]*$/); };
var ɵ3 = IDValidator;
var newId = function (autoId, size) {
    if (autoId === void 0) { autoId = ''; }
    if (size === void 0) { size = 20; }
    var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (var i = 0; i < size; i++) {
        autoId += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return autoId;
};
var ɵ4 = newId;
var extension = function (filename) {
    return '.' + filename.split('.').pop();
};
var ɵ5 = extension;
var ProfileComponent = /** @class */ (function () {
    function ProfileComponent(http, aRoute, afAuth, afStorage, locationService, dialogService, renderer2, authService, domSanitizer, apiService, router) {
        this.http = http;
        this.aRoute = aRoute;
        this.afAuth = afAuth;
        this.afStorage = afStorage;
        this.locationService = locationService;
        this.dialogService = dialogService;
        this.renderer2 = renderer2;
        this.authService = authService;
        this.domSanitizer = domSanitizer;
        this.apiService = apiService;
        this.router = router;
        this.profileForm = new FormGroup({});
        this.memberForm = new FormGroup({
            address: new FormControl(null),
            uid: new FormControl(null),
            order: new FormControl(0),
            id: new FormControl(0),
            fullName: new FormControl(null, Validators.required),
            fullNameKana: new FormControl(null),
            displayName: new FormControl(null, Validators.required),
            email1: new FormControl(null, [
                Validators.required,
                Validators.email,
                Validators.pattern(/@{1}([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/),
            ]),
            email2: new FormControl(null, [
                Validators.email,
                Validators.pattern(/@{1}([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/),
            ]),
            phoneNumber1: new FormControl(null, [Validators.pattern(/^(\+?\d+\s)?\d+(-?\d+)*$/), Validators.required]),
            phoneNumber2: new FormControl(null, Validators.pattern(/^(\+?\d+\s)?\d+(-?\d+)*$/)),
            summary: new FormControl(null, Validators.maxLength(10000)),
            catchphrase: new FormControl(null, Validators.maxLength(50)),
            profile: new FormControl(null, Validators.maxLength(200)),
            remarks1: new FormControl(null),
            remarks2: new FormControl(null),
            member_files: new FormControl(null),
            bank_account: new FormControl(null),
            image1: new FormControl(null),
            lineId: new FormControl(null),
            lineDisplayName: new FormControl(null),
            groups: new FormControl([]),
            occupation_master: new FormControl(null),
            industry_master: new FormControl(null),
            job_master: new FormControl(null),
            member_confidential: new FormControl(null),
            provider: new FormControl(null),
            sex: new FormControl(null),
            gender: new FormControl(null),
            height: new FormControl(null, [Validators.min(0), FiniteValidator]),
            weight: new FormControl(null, [Validators.min(0), FiniteValidator]),
            birthDay: new FormControl(null, Validators.required),
            uncertainAge: new FormControl(false),
            married: new FormControl(null),
            hasChildren: new FormControl(null),
            children: new FormControl([]),
            mainSiteURL: new FormControl(null, URLValidator()),
            otherSiteURL1: new FormControl(null, URLValidator()),
            otherSiteURL2: new FormControl(null, URLValidator()),
            otherSiteURL3: new FormControl(null, URLValidator()),
            mainSiteName: new FormControl([]),
            leaved: new FormControl(false),
            leavedAt: new FormControl(null),
        });
        this.childrenForm = new FormArray([]);
        this.addressForm = new FormGroup({
            id: new FormControl(null),
            postalCode: new FormControl(null, [
                Validators.pattern(/^\d{3}-?\d{4}$/),
                Validators.required
            ]),
            address: new FormControl(null, Validators.required),
        });
        this.hasBankAccount = new FormControl(false);
        // bankAccountForm don't have no use for now, but remain for format.
        this.bankAccountForm = new FormGroup({
            id: new FormControl(null),
            bankName: new FormControl(null),
            bankAccountNumber: new FormControl(null, Validators.pattern(/^\d+$/)),
            bankBranchName: new FormControl(null),
            bankAccountOwnerName: new FormControl(null),
            bankAccountOwnerNameKana: new FormControl(null, Validators.pattern(/^[\u30a1-\u30f6\u30fc\uff66-\uff9f\s]*$/)),
        });
        this.connectionsForm = new FormGroup({
            twitter: new FormGroup({
                id: new FormControl(null),
                idOfResponse: new FormControl(0),
                idStr: new FormControl(null),
                name: new FormControl(null),
                screenName: new FormControl(null, TwitterNameValidator()),
                protected: new FormControl(null),
                verified: new FormControl(null),
                followersCount: new FormControl(0),
                friendsCount: new FormControl(0),
                member: new FormControl(null),
                profileImageURLHTTPS: new FormControl(null),
            }, null),
            tiktok: new FormGroup({
                id: new FormControl(null),
                userId: new FormControl(null),
                uniqueId: new FormControl(null, IDValidator()),
                nickName: new FormControl(null),
                fans: new FormControl(0),
                hearts: new FormControl(0),
                member: new FormControl(null),
                verified: new FormControl(false),
            }, null),
            youtube: new FormGroup({
                id: new FormControl(null),
                idOfResponse: new FormControl(null),
                viewCount: new FormControl(0),
                commentCount: new FormControl(0),
                member: new FormControl(null),
                subscriberCount: new FormControl(0),
            }, null)
        });
        this.instagramForm = new FormArray([
            new FormGroup({
                id: new FormControl(null),
                idOfResponse: new FormControl(null),
                username: new FormControl(null, IDValidator()),
                followersCount: new FormControl(0),
                accountType: new FormControl(null),
                member: new FormControl(null),
                order: new FormControl(0)
            }),
            new FormGroup({
                id: new FormControl(null),
                idOfResponse: new FormControl(null),
                username: new FormControl(null, IDValidator()),
                followersCount: new FormControl(0),
                accountType: new FormControl(null),
                member: new FormControl(null),
                order: new FormControl(1)
            })
        ], []);
        this.imageForm = new FormGroup({ data: new FormControl(null) });
        this.genderList = [
            { value: null, label: null },
            { value: Member.GenderEnum.Female, label: '女性' },
            { value: Member.GenderEnum.Male, label: '男性' },
            { value: 'unanswered', label: '未回答' },
        ];
        this.path = Path;
        this.authAction = AuthAction;
        this.pendingLINELogin = false;
        this.onDestroy$ = new Subject();
        this.accept = '.png, .jpg, .gif, .jpe, .jpeg, .JPG, .JPEG, .PNG, .GIF';
        this.acceptType = ['png', 'jpg', 'gif', 'jpe', 'jpeg', 'JPG', 'JPEG', 'PNG', 'GIF'];
        this.notDeactive = false;
        this.specialMediaGroups = {
            'more': ['出身地', '職業（自由入力）']
        };
        this.showMedia = null;
    }
    ProfileComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.addForms();
        this.watchChildrenStateChange();
        this.subscribeOccupations();
        this.subscribeIndustries();
        this.subscribeJobs();
        // tslint:disable-next-line: deprecation
        this.aRoute.data.subscribe(function (data) {
            console.log(data);
            _this.member = data.member[0];
            // set JST time
            if (_this.member.birthDay) {
                _this.member.birthDay = _this.setJST(_this.member.birthDay);
            }
            else {
                _this.member.birthDay = null;
            }
            if (_this.member.children && _this.member.children.length > 0) {
                _this.member.children = _.map(_this.member.children, function (children) {
                    if (children.birthDay) {
                        children.birthDay = _this.setJST(children.birthDay);
                    }
                    else {
                        children.birthDay = null;
                    }
                    return children;
                });
            }
            _this.address = _this.member.address;
            _this.connections = data.connections;
            _this.segment = _this.member.member_status_master;
            _this.instagram = _this.member.connectionInstagrams;
            _this.bankAccount = _this.member.bankAccount;
            _this.lineId = _this.member.lineId;
            _this.lineDisplayName = _this.member.lineDisplayName;
            var specialMedia = _this.getUserSpecialMedia();
            if (specialMedia.length > 0) {
                _this.showMedia = specialMedia[0];
            }
            _this.assignValuesToForms();
            _this.setConnectionDocumentsToForms();
            _this.fixAuthDocument();
        });
        this.sanitizedLINEAddFriendHTML = this.domSanitizer.bypassSecurityTrustHtml(LINEAddFriendHTMLElement);
    };
    ProfileComponent.prototype.ngOnDestroy = function () {
        this.onDestroy$.next();
    };
    ProfileComponent.prototype.fixAuthDocument = function () {
        // Fix auth info
        var email = this.afAuth.auth.currentUser.email;
        if (this.member.email1 !== email) {
            this.authService.setCurrentAuth();
        }
    };
    ProfileComponent.prototype.getUserSpecialMedia = function () {
        var specialMedia = Object.keys(this.specialMediaGroups);
        var userMedia = this.member.groups.map(function (group) { return group.groupName.toLowerCase(); });
        return specialMedia.filter(function (media) { return _.includes(userMedia, media); });
    };
    ProfileComponent.prototype.canDeactivate = function () {
        if (!this.afAuth.auth.currentUser
            || this.profileForm.pristine
            || this.notDeactive) {
            return true;
        }
        return this.dialogService.openConfirmDialog({
            autoFocus: false,
            data: {
                text: "\u30D7\u30ED\u30D5\u30A3\u30FC\u30EB\u306B\u672A\u4FDD\u5B58\u306E\u5909\u66F4\u304C\u3042\u308A\u307E\u3059\u3002\u5909\u66F4\u3092\u7834\u68C4\u3057\u3066\u79FB\u52D5\u3057\u307E\u3059\u304B\uFF1F",
                applyText: '破棄',
                cancelText: '編集を続ける'
            }
        }).afterClosed()
            .pipe(map(function (result) { return !!result; }));
    };
    Object.defineProperty(ProfileComponent.prototype, "today", {
        get: function () {
            return new Date();
        },
        set: function (x) { },
        enumerable: true,
        configurable: true
    });
    ProfileComponent.prototype.addForms = function () {
        this.connectionsForm.addControl('instagram', this.instagramForm);
        this.profileForm.addControl('member', this.memberForm);
        this.profileForm.addControl('image', this.imageForm);
        this.profileForm.addControl('bankAccount', this.bankAccountForm);
        this.profileForm.addControl('address', this.addressForm);
        this.profileForm.addControl('connections', this.connectionsForm);
        // propagate status changes
        this.profileForm.addControl('_children', this.childrenForm);
        this.profileForm.addControl('_hasBankAccount', this.hasBankAccount);
        console.log(this.connectionsForm);
    };
    ProfileComponent.prototype.assignValuesToForms = function () {
        var _this = this;
        var uid = this.afAuth.auth.currentUser.uid;
        var currentmember = this.member;
        var occupation = currentmember.occupation_master;
        var industry = currentmember.industry_master;
        var job = currentmember.job_master;
        this.memberForm.patchValue(cloneDeep(this.member));
        this.memberForm.patchValue({
            occupation_master: occupation ? occupation.id : ''
        });
        this.memberForm.patchValue({
            industry_master: industry ? industry.id : ''
        });
        this.memberForm.patchValue({
            job_master: job ? job.id : ''
        });
        if (this.bankAccount) {
            this.hasBankAccount.setValue(true);
            this.bankAccountForm.patchValue({ ownerId: uid }); // Set current user if bank owner not defined.
            this.bankAccountForm.patchValue(this.bankAccount);
        }
        if (this.address) {
            this.memberForm.patchValue({
                address: this.address
            });
            this.addressForm.patchValue(this.address);
        }
        if (this.member.hasChildren) {
            forEach(this.member.children, function () {
                _this.addChild();
            });
            this.childrenForm.patchValue(this.member.children);
        }
    };
    ProfileComponent.prototype.setConnectionDocumentsToForms = function () {
        var _this = this;
        var memberAcc = this.member;
        memberAcc.connection_twitter !== null ?
            this.connectionsForm.patchValue({
                twitter: memberAcc.connection_twitter
            }) :
            this.connectionsForm.value.twitter = {};
        if (memberAcc.connection_instagrams && memberAcc.connection_instagrams.length > 0) {
            this.member.connection_instagrams.forEach(function (insta) {
                if (insta.order === 0) {
                    _this.instagramForm.at(Number(0)).patchValue(_.cloneDeep(insta));
                }
                else {
                    _this.instagramForm.at(Number(1)).patchValue(_.cloneDeep(insta));
                }
            });
        }
        if (memberAcc.connection_tik_tok) {
            this.connectionsForm.patchValue({
                tiktok: memberAcc.connection_tik_tok
            });
        }
        if (memberAcc.connection_youtube) {
            this.connectionsForm.patchValue({
                youtube: memberAcc.connection_youtube
            });
        }
    };
    ProfileComponent.prototype.subscribeOccupations = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.apiService.getMaster('occupation-masters', {
                            parentMasterGroupId_null: false, _sort: 'order:ASC'
                        })
                            .pipe(takeUntil(this.onDestroy$))
                            // tslint:disable-next-line: deprecation
                            .subscribe(function (list) { return _this.occupationList = list; }, function (err) { return console.error(err); })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ProfileComponent.prototype.subscribeIndustries = function () {
        var _this = this;
        this.apiService.getMaster('industry-masters', { parentMasterGroupId_null: false, _sort: 'order:ASC' })
            .pipe(takeUntil(this.onDestroy$))
            // tslint:disable-next-line: deprecation
            .subscribe(function (list) { return _this.industryList = list; }, function (err) { return console.error(err); });
    };
    ProfileComponent.prototype.subscribeJobs = function () {
        var _this = this;
        this.apiService.getMaster('job-masters', { parentMasterGroupId_null: false, _sort: 'order:ASC' })
            .pipe(takeUntil(this.onDestroy$))
            // tslint:disable-next-line: deprecation
            .subscribe(function (list) { return _this.jobList = list; }, function (err) { return console.error(err); });
    };
    // buildConnectionControl(serviceName: string, ...args: [[string, AbstractControl] | any]) {
    //   const commonCtrl = new FormGroup({
    //     _id: new FormControl(null), // Client only temporary control.
    //     serviceName: new FormControl(serviceName),
    //     order: new FormControl(0),
    //     created: new FormGroup(null),
    //     modified: new FormGroup(null),
    //     fetch: new FormGroup({
    //       id: new FormControl(null),
    //       statusCode: new FormControl(null),
    //       message: new FormControl(null),
    //       at: new FormControl(null)
    //     } as { [K in keyof ConnectionFetch]: AbstractControl }),
    //   } as { [K in keyof Pick<ConnectionTwitter, connectionCreateFields>]: AbstractControl });
    //   // add specified controls
    //   for (const arg of args) {
    //     // Additional form controls
    //     if (Array.isArray(arg)
    //       && typeof arg[0] === 'string'
    //       && arg[1] instanceof AbstractControl) {
    //       commonCtrl.addControl(arg[0], arg[1]);
    //     }
    //   }
    //   return commonCtrl;
    // }
    ProfileComponent.prototype.isEmptyConnectionIdentifier = function (value) {
        switch (value.serviceName) {
            case 'twitter':
                return !value.screenName;
            case 'instagram':
                return !value.username;
            case 'tiktok':
                return !value.uniqueId;
            case 'youtube':
                return !value.id;
            default:
                return true;
        }
    };
    ProfileComponent.prototype.fileSelectChange = function (event) {
        var target = event.target;
        if (target.files.length === 0
            || this.imageForm.value.data === target.files[0]) {
            return;
        }
        this.imageForm.value.data = target.files[0];
        var maxSize = 50 * Math.pow(1024, 2);
        var fileType = this.imageForm.value.data.name.split('.').splice(-1)[0];
        if (this.imageForm.value.data instanceof File
            && this.imageForm.value.data.size > maxSize) {
            if (_.includes(this.acceptType, fileType)) {
                this.removeFile();
                return alert('ファイルサイズが50MBを越えています');
            }
        }
        var isInclude = _.includes(this.acceptType, fileType);
        if (this.imageForm.value.data instanceof File
            && !isInclude) {
            this.removeFile();
            return alert('フォーマットが異なっています。正しいフォーマットをアップロードしてください。\n[png, jpg, gif]');
        }
        this.imageForm.markAsDirty();
    };
    ProfileComponent.prototype.browseFile = function (event) {
        this.fileInput.nativeElement.click();
        event.preventDefault();
        event.stopPropagation();
    };
    ProfileComponent.prototype.removeFile = function () {
        this.imageForm.value.data = undefined;
        this.fileInput.nativeElement.value = '';
    };
    ProfileComponent.prototype.displayPreview = function (event, container) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var component, url;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(this.imageForm.value.data instanceof File && this.imageForm.value.data.type.match(/image\/.*/))) return [3 /*break*/, 2];
                        component = new FileChooserComponent();
                        return [4 /*yield*/, component.convertToDataURL(this.imageForm.value.data)];
                    case 1:
                        url = _a.sent();
                        this.renderer2.setStyle(container, 'background-image', "url(" + url + ")");
                        return [3 /*break*/, 3];
                    case 2:
                        this.renderer2.setStyle(container, 'background-image', "url(" + this.member.image1);
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ProfileComponent.prototype.uploadObject = function (path, data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var task, url;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.afStorage.upload(path, data)];
                    case 1:
                        task = _a.sent();
                        return [4 /*yield*/, task.ref.getDownloadURL()];
                    case 2:
                        url = (_a.sent()).replace(/&?token=.+&?/, '');
                        return [2 /*return*/, { task: task, url: url }];
                }
            });
        });
    };
    /**
     * Upload files if some new file attached.
     */
    ProfileComponent.prototype.prepareFiles = function (path) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) {
                        var emptyModelFile = {
                            url: null,
                            thumbnailURL: null,
                            fileName: null,
                            contentType: null,
                            size: 0,
                        };
                        var data = _this.imageForm.value.data;
                        var isFile = data instanceof File;
                        var prevModelFile = _.nth(_.get(_this.member, 'images'), 0);
                        if (!isFile) {
                            // if image isn't specified, treat as empty item.
                            resolve(emptyModelFile);
                        }
                        else if (_this.imageForm.pristine && prevModelFile) {
                            // if image is unchanged and exists, return current data;
                            resolve(prevModelFile);
                        }
                        else {
                            _this.uploadObject(path + "/" + (newId() + extension(data.name)), data)
                                .then(function (_a) {
                                var task = _a.task, url = _a.url;
                                var _b = tslib_1.__read([data.name, task.metadata.contentType, task.totalBytes], 3), fileName = _b[0], contentType = _b[1], size = _b[2];
                                resolve(tslib_1.__assign({}, emptyModelFile, { url: url, fileName: fileName, contentType: contentType, size: size }));
                            });
                        }
                        // Upload new file.
                    })];
            });
        });
    };
    ProfileComponent.prototype.getControls = function (ctrl) {
        return get(ctrl, 'controls') || ctrl;
    };
    ProfileComponent.prototype.setJST = function (birthDate) {
        var date = new Date(birthDate);
        // console.log('current : ', date);
        var localTime = date.getTime();
        var localOffset = date.getTimezoneOffset() * 60000;
        var utc = localTime + localOffset;
        // japan UTC + 9
        var offset = 9;
        var japanTime = utc + (3600000 * offset);
        var nd = new Date(japanTime);
        nd.setHours(0);
        nd.setMinutes(0);
        nd.setSeconds(0);
        // console.log('nihon : ', nd);
        return nd;
    };
    ProfileComponent.prototype.updateProfile = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var uid, member, authFailDialog, mainImage, param_1, imagedata, image, tiktok, youtube, updateData, e_1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.profileForm.pristine || this.profileForm.invalid) {
                            return [2 /*return*/];
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        uid = this.afAuth.auth.currentUser.uid;
                        member = this.profileForm.value.member;
                        if (isNil(this.afAuth.auth.currentUser)) {
                            this.notDeactive = true;
                            authFailDialog = this.dialogService.openConfirmDialog({
                                autoFocus: false,
                                panelClass: ['align-top'],
                                data: {
                                    text: "\u8A8D\u8A3C\u306E\u671F\u9650\u304C\u5207\u308C\u307E\u3057\u305F\u3002\u518D\u5EA6\u30ED\u30B0\u30A4\u30F3\u3057\u306A\u304A\u3057\u3066\u304F\u3060\u3055\u3044\u3002",
                                    cancel: false,
                                    apply: true
                                }
                            });
                            authFailDialog.afterClosed().subscribe(function () {
                                _this.router.navigate([Path.auth.login]);
                            });
                            return [2 /*return*/];
                        }
                        if (!!this.imageForm.pristine) return [3 /*break*/, 3];
                        mainImage = _.get(this.imageForm, 'value.data');
                        param_1 = new FormData();
                        param_1.append('files', mainImage);
                        imagedata = function () {
                            return new Promise(function (imgresolve) {
                                _this.apiService.uploadFile(param_1)
                                    .subscribe(function (img) {
                                    imgresolve(img[0]);
                                });
                            });
                        };
                        return [4 /*yield*/, imagedata()];
                    case 2:
                        image = _a.sent();
                        this.memberForm.value.image1 = image;
                        _a.label = 3;
                    case 3:
                        // member.modified = authedTimestamp;
                        if (this.hasBankAccount.value) {
                            this.memberForm.value.bank_account = this.bankAccountForm.value;
                        }
                        else if (!this.hasBankAccount.value) {
                            this.memberForm.value.bank_account = null;
                        }
                        if (!this.connectionsForm.pristine || this.connectionsForm.valid) {
                            tiktok = this.connectionsForm.value.tiktok;
                            youtube = this.connectionsForm.value.youtube;
                            this.memberForm.value.connection_twitter = this.connectionsForm.value.twitter;
                            if (this.connectionsForm.value.instagram[0].username != null) {
                                this.connectionsForm.value.instagram[0].order = 0;
                                if (this.connectionsForm.value.instagram[1].username == null) {
                                    this.connectionsForm.value.instagram[1].username = '';
                                    this.connectionsForm.value.instagram[1].order = 1;
                                }
                                this.memberForm.value.connection_instagrams = this.connectionsForm.value.instagram;
                            }
                            if (this.connectionsForm.value.instagram[1].username != null) {
                                this.connectionsForm.value.instagram[1].order = 1;
                                if (this.connectionsForm.value.instagram[0].username == null) {
                                    this.connectionsForm.value.instagram[0].username = '';
                                    this.connectionsForm.value.instagram[0].order = 0;
                                }
                                this.memberForm.value.connection_instagrams = this.connectionsForm.value.instagram;
                            }
                            tiktok.uniqueId ? this.memberForm.value.connection_tik_tok = tiktok :
                                this.memberForm.value.connection_tik_tok = tiktok.uniqueId;
                            youtube.idOfResponse ? this.memberForm.value.connection_youtube = youtube :
                                this.memberForm.value.connection_youtube = youtube.idOfResponse;
                        }
                        this.memberForm.value.address = this.addressForm.value;
                        this.memberForm.value.lineId = this.lineId;
                        this.memberForm.value.lineDisplayName = this.lineDisplayName;
                        updateData = _.omit(this.memberForm.value, 'bank_account', 'order');
                        // set JST time
                        if (updateData.birthDay) {
                            updateData.birthDay = this.setJST(updateData.birthDay);
                        }
                        else {
                            updateData.birthDay = null;
                        }
                        if (updateData.children && updateData.children.length > 0) {
                            updateData.children = _.map(updateData.children, function (children) {
                                if (children.birthDay) {
                                    children.birthDay = _this.setJST(children.birthDay);
                                }
                                else {
                                    children.birthDay = null;
                                }
                                return children;
                            });
                        }
                        this.apiService.updateMember(updateData)
                            .subscribe(function (members) {
                            if (members) {
                                console.log('members update success', members);
                                _this.profileForm.markAsPristine();
                                _this.dialogService.openTextDialog({
                                    autoFocus: false,
                                    panelClass: ['dialog-panel-primary', 'align-top'],
                                    data: { text: 'プロフィールを更新しました！' }
                                });
                            }
                        }, function (error) {
                            console.log('error', error);
                            _this.dialogService.openTextDialog({
                                autoFocus: false,
                                panelClass: ['align-top'],
                                data: {
                                    text: "\u30D7\u30ED\u30D5\u30A3\u30FC\u30EB\u3092\u66F4\u65B0\u3067\u304D\u307E\u305B\u3093\u3067\u3057\u305F\u3002\n              \u6642\u9593\u3092\u7F6E\u3044\u3066\u518D\u5EA6\u304A\u8A66\u3057\u304F\u3060\u3055\u3044\u3002"
                                }
                            });
                        });
                        return [3 /*break*/, 5];
                    case 4:
                        e_1 = _a.sent();
                        console.error(e_1);
                        // Notification to user that error occurred.
                        this.dialogService.openTextDialog({
                            autoFocus: false,
                            panelClass: ['align-top'],
                            data: {
                                text: "\u30D7\u30ED\u30D5\u30A3\u30FC\u30EB\u3092\u66F4\u65B0\u3067\u304D\u307E\u305B\u3093\u3067\u3057\u305F\u3002\n        \u6642\u9593\u3092\u7F6E\u3044\u3066\u518D\u5EA6\u304A\u8A66\u3057\u304F\u3060\u3055\u3044\u3002"
                            }
                        });
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    ProfileComponent.prototype.LINELogin = function () {
        var _this = this;
        if (this.pendingLINELogin) {
            return;
        }
        this.pendingLINELogin = true;
        this.http.get(environment.apiEndpoint + "/line/oauth")
            .subscribe(function (result) {
            _this.pendingLINELogin = false;
            window.open(result.loginURL, '_self', 'rel=noopener noreferrer');
        }, function (err) {
            console.error(err);
            _this.dialogService.openTextDialog({
                autoFocus: false,
                panelClass: ['align-top'],
                data: { text: "\u30A8\u30E9\u30FC\u304C\u767A\u751F\u3057\u307E\u3057\u305F\u3002[T.T]" }
            });
            _this.pendingLINELogin = false;
        });
    };
    ProfileComponent.prototype.confirmDisconnectLINE = function () {
        var _this = this;
        this.dialogService.openConfirmDialog({
            autoFocus: false,
            data: {
                text: 'LINE連携を解除しますか？',
                applyText: '解除',
            },
        })
            .afterClosed()
            .pipe(filter(function (result) { return !!result; }))
            .subscribe(function () { return _this.disconnectLINE(); });
    };
    ProfileComponent.prototype.disconnectLINE = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var body;
            return tslib_1.__generator(this, function (_a) {
                if (this.pendingLINELogin) {
                    return [2 /*return*/];
                }
                this.pendingLINELogin = true;
                try {
                    body = {
                        lineId: null,
                        lineDisplayName: null,
                        connection_tik_tok: this.connectionsForm.value.tiktok.uniqueId ?
                            this.connectionsForm.value.tiktok : null,
                        connection_twitter: this.connectionsForm.value.twitter,
                        connection_youtube: this.connectionsForm.value.youtube.idOfResponse ?
                            this.connectionsForm.value.youtube : null,
                        connection_instagrams: this.connectionsForm.value.instagram,
                        address: this.addressForm.value
                    };
                    this.apiService.updateMember(body)
                        .subscribe(function (response) {
                        console.log(response);
                    }, function (err) { return console.error(err); });
                    this.lineId = undefined;
                    this.lineDisplayName = undefined;
                }
                catch (err) {
                    this.dialogService.openTextDialog({
                        autoFocus: false,
                        panelClass: ['align-top'],
                        data: { text: "\u30A8\u30E9\u30FC\u304C\u767A\u751F\u3057\u307E\u3057\u305F\u3002[not ok]" }
                    });
                }
                finally {
                    this.pendingLINELogin = false;
                }
                return [2 /*return*/];
            });
        });
    };
    Object.defineProperty(ProfileComponent.prototype, "childForm", {
        get: function () {
            return new FormGroup({
                id: new FormControl(null),
                gender: new FormControl(null),
                sex: new FormControl(null),
                birthDay: new FormControl(null),
            });
        },
        set: function (x) { },
        enumerable: true,
        configurable: true
    });
    ProfileComponent.prototype.watchChildrenStateChange = function () {
        var _this = this;
        merge(this.childrenForm.valueChanges, this.memberForm.get('hasChildren').valueChanges).pipe(map(function (v) {
            var isBool = typeof v === 'boolean';
            var hasChildren = isBool ? v : _this.memberForm.value.hasChildren;
            var children = isBool ? _this.childrenForm.value : v;
            return hasChildren ? children : null;
        }))
            .subscribe(function (children) {
            _this.memberForm.patchValue({ children: children });
        });
    };
    ProfileComponent.prototype.addChild = function (index) {
        if (index === void 0) { index = 0; }
        var ctrl = this.childForm;
        this.childrenForm.insert(index, ctrl);
    };
    ProfileComponent.prototype.removeChild = function (index) {
        if (index === void 0) { index = 0; }
        this.childrenForm.removeAt(index);
    };
    // debug
    ProfileComponent.prototype.log = function () {
        var data = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            data[_i] = arguments[_i];
        }
        console.log.apply(console, tslib_1.__spread(data));
    };
    ProfileComponent.prototype.getInvalidControls = function (form) {
        var _this = this;
        var ctrls = [];
        if (get(form, 'controls')) {
            forEach(form.controls, function (ctrl, i) {
                if (ctrl.invalid) {
                    ctrls.push(ctrl);
                }
                if (get(form, 'controls')) {
                    ctrls.push.apply(ctrls, tslib_1.__spread(_this.getInvalidControls(ctrl)));
                }
            });
        }
        return ctrls;
    };
    return ProfileComponent;
}());
export { ProfileComponent };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5 };
