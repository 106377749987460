import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { filter } from 'rxjs/operators';
import { cloneDeep, pick, omit, assign, isEqual } from 'lodash';

import { environment } from '@lu/environment';
import { LocationService } from '@lu/services/location.service';
import { DialogService } from './services/dialog.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    private router: Router,
    private aRoute: ActivatedRoute,
    private locationService: LocationService,
    private dialog: DialogService,
    private domSanitizer: DomSanitizer,
    private iconRegistry: MatIconRegistry,
  ) {
    this.locationService.trackRouting();
    this.locationService.navigateToErrorPage();
    const ICON_PATH = '/assets/icon';
    const ICONS = ['feed', 'notification', 'payment', 'settings', 'funnel'];
    // svg を mat-icon から参照できるように register する
    ICONS.map((key: string): void => {
      this.iconRegistry.addSvgIcon(
        key,
        this.domSanitizer.bypassSecurityTrustResourceUrl(`${ICON_PATH}/${key}.svg`)
      );
    });
  }

  ngOnInit() {
    // Development only!
    // Be careful treating this code because become hole of security.
    this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
      )
      .subscribe(() => this.overwriteEnvValues());
  }

  overwriteEnvValues() {
    // Overwrite environment values only when develop and emulate in local.
    if (!environment.firebaseConfig.projectId.match(/^dott-lu-(dev|ci)$/)) {
      return;
    }
    const values = pick(this.aRoute.snapshot.queryParams, Object.keys(environment));
    const after = assign(cloneDeep(environment), values);
    if (!Object.keys(values).length || isEqual(environment, after)) {
      return;
    }
    assign(environment, values);
    this.dialog.openTextDialog({
      autoFocus: false,
      data: {text: '環境設定値を上書きしました（開発環境のみ有効）'}
    });
  }
}
