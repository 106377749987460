import { NgModule } from '@angular/core';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { FirebaseAuthTokenInterceptor } from './firebase-auth-token.interceptor';

@NgModule({
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: FirebaseAuthTokenInterceptor,
      multi: true // 必須：HTTP_INTERCEPTORSが配列であることを示す
    }
  ],
})
export class IntercepterSharedModule { }
