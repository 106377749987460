import { Injectable } from '@angular/core';
import { DataSource } from '@angular/cdk/table';
import { CollectionViewer } from '@angular/cdk/collections';

import { BehaviorSubject, Subscription } from 'rxjs';
import { flatten } from 'lodash';

import { Notification } from '@lu/models';
import { MatchingService } from '@lu/services/matching.service';
import { NotificationService } from '@lu/services/notification.service';


@Injectable()
export class NotificationsVirutualScrollStrategy extends DataSource<(Notification & { _id: string })> {
  private dataStream = new BehaviorSubject<(Notification & { _id: string })[]>([]);
  public dataStream$ = this.dataStream.asObservable();
  private fetchedPages = new Set<number>();
  private subscription = new Subscription();
  private cachedList: (Notification & { _id: string })[];

  private lastPage = 0;
  private pageSize = 10;
  private hasNext = true;
  private pending = false;

  constructor(
    private apiService: MatchingService,
    public notificationService: NotificationService

  ) {
    super();
  }

  // reference https://material.angular.io/cdk/scrolling/overview#creating-items-in-the-viewport
  connect(collectionViewer: CollectionViewer) {
    this.subscription.add(collectionViewer.viewChange.subscribe(range => {
      const startPage = this.getPageForIndex(range.start);
      const endPage = this.getPageForIndex(range.end - 1);

      for (let i = startPage; i <= endPage; i++) {
        this.fetchPage(i);
      }
    }));
    return this.dataStream;
  }

  disconnect() {
    this.subscription.unsubscribe();
  }

  private getPageForIndex(index: number): number {
    return Math.floor(index / this.pageSize);
  }

  private fetchPage(page: number) {
    if (this.fetchedPages.has(page)) {
      return;
    }
    this.lastPage = page;
    this.fetchedPages.add(page);
    this.next();
  }

  public get theEnd(): boolean {
    return !this.hasNext;
  }

  public get isPending(): boolean {
    return this.pending;
  }

  public get dataLength(): number {
    return Array.isArray(this.cachedList) ? flatten(this.cachedList).length : 0;
  }

  updateAllRead() {
    this.pending = true;
    const params = {
      read: 'false'
    };
    this.apiService.getNotification(params).subscribe
      (data => {
        if (data && data.length > 0) {
          data.forEach(noti => {
            this.apiService.updateNotification(noti.id, { read: true })
              .subscribe(() => {
                this.subscribe();
                this.notificationService.watchUnreadState();
              });
          }, err => {
            console.error(err);
            this.pending = false;
          });
        } else {
          this.subscribe();
        }
      });
  }

  /** With refresh order list. */
  subscribe() {
    this.pending = true;
    this.hasNext = true;
    this.cachedList = null;
    this.fetchedPages.clear();
    this.dataStream.next([]);
    this.fetchPage(0);
    const params = {
      _limit: this.pageSize,
      _start: this.lastPage,
      _sort: 'created_at:DESC'
    };
    this.subscription.add(
      this.apiService.getNotification(params)
        .subscribe(
          async docs => {
            this.pending = false;
            this.cachedList = [];
            this.cachedList = docs;
            this.dataStream.next(this.cachedList);
            if (this.pageSize === docs.length) {
              this.hasNext = true;
            } else if (docs.length === 0) {
              this.hasNext = false;
            } else {
              this.hasNext = false;
            }
          }, err => {
            console.error(err);
            this.pending = false;
          }
        )
    );
  }

  /** Append results to order list */
  public next() {
    if (!this.hasNext || this.pending) {
      return;
    }
    const params = {
      _limit: this.pageSize,
      _start: this.cachedList.length,
      _sort: 'created_at:DESC'
    };
    this.pending = true;
    this.subscription.add(
      this.apiService.getNotification(params)
        .subscribe(
          docs => {
            this.pending = false;
            this.cachedList.push(...docs);
            this.dataStream.next(this.cachedList);
            if (this.pageSize === docs.length) {
              this.hasNext = true;
            } else if (docs.length === 0) {
              this.hasNext = false;
            } else {
              this.hasNext = false;
            }
          }, err => {
            console.error(err);
            this.pending = false;
          }
        )
    );
  }
}
