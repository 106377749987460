<div
  *ngIf="completed"
  class="completed-message">メールアドレスが認証されました。</div>

<mat-progress-spinner
  *ngIf="pending && !completed"
  class="loading-spinner text-side-spinner"
  color="primary"
  mode="indeterminate"
  strokeWidth="15"
  [style.fontSize.em]="1.5"></mat-progress-spinner>

<div
  class="error-message"
  [ngClass]="{'hidden': !authError}">
  <span class="message">{{authError?.message}}</span>
</div>

<div class="auth-actions">
  <span
    class="reset-password"
    [routerLink]="[path.feed.root | rootPathPrefix]">トップへ戻る</span>
</div>