import {AfterViewInit, Directive, ElementRef, Renderer2 } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { fromEvent, merge } from 'rxjs';
import { filter, takeUntil, map, tap } from 'rxjs/operators';

@Directive({
  selector: '[appSwipableTab]'
})
export class SwipableTabDirective implements AfterViewInit {
  private tabGroupElement: HTMLElement;
  private tabListElement: HTMLElement;

  constructor(
    private elementRef: ElementRef,
    private matTabGroup: MatTabGroup,
    private renderer: Renderer2,
  ) {}

  public ngAfterViewInit(): void {
    if (!(this.matTabGroup instanceof MatTabGroup)) {
      throw new Error('SwipableTabDirective require MatTabGroup');
    }
    this.tabGroupElement = this.elementRef.nativeElement;
    this.tabListElement = this.tabGroupElement.querySelector<HTMLElement>('div.mat-tab-list');
    this.tabListSwipable();
    this.tabContentSwipable(this.getActiveTabBody());
    this.matTabGroup.selectedTabChange.subscribe(() => {
      this.tabContentSwipable(this.getActiveTabBody());
    });
  }

  getActiveTabBody() {
    return this.tabGroupElement.querySelector<HTMLElement>('mat-tab-body.mat-tab-body-active');
  }

  getTabBodyIndex() {
    let index = -1;
    const tabBodies = this.tabGroupElement.querySelectorAll<HTMLElement>('mat-tab-body');
    tabBodies.forEach((elem, i) => {
      if (elem === this.getActiveTabBody()) {
        return index = i;
      }
    });
    return index;
  }

  resetSwipeTransform(animationTarget: Element) {
    this.renderer.setStyle(animationTarget, 'transition', '.3s cubic-bezier(.56,.96,.72,1)');
    this.renderer.removeStyle(animationTarget, 'transform');
    animationTarget.addEventListener('transitionend', () => {
      this.renderer.removeStyle(animationTarget, 'transition');
    }, {once: true});
  }

  addOnceAnimation(animationTarget: Element, transform: string) {
    const duration = this.matTabGroup.animationDuration;
    this.renderer.setStyle(animationTarget, 'transition', `${duration} cubic-bezier(.56,.96,.72,1)`);
    this.renderer.setStyle(animationTarget, 'transform', transform);
    animationTarget.addEventListener('transitionend', () => {
      this.renderer.removeStyle(animationTarget, 'transform');
      this.renderer.removeStyle(animationTarget, 'transition');
    }, {once: true});
  }

  tabContentSwipable(tabBody: HTMLElement) {
    let inScroll = false;
    let inSwipe = false;
    let singleTouch = false;
    let startTouch: Touch;
    let latestTouchMove: Touch;
    const config = {startThreshould: 30, moveThreshould: 25};
    const animationTarget = tabBody;
    const untilTabeChange$ = this.matTabGroup.selectedTabChange;
    const touchstart$ = fromEvent<TouchEvent>(tabBody, 'touchstart').pipe(takeUntil(untilTabeChange$));
    const touchmove$ = fromEvent<TouchEvent>(tabBody, 'touchmove').pipe(takeUntil(untilTabeChange$));
    const touchend$ = fromEvent<TouchEvent>(tabBody, 'touchend').pipe(takeUntil(untilTabeChange$));
    touchstart$
      .pipe(
        filter(e => e.touches.length === 1)
      )
      .subscribe(e => {
        singleTouch = true;
        startTouch = e.touches[0];
      });
    touchmove$
      .pipe(
        filter(() => singleTouch),
        map(e => e.touches[0]),
        filter(touch => {
          if (inSwipe) {
            return true;
          } else if (inScroll) {
            return  false;
          } else if (Math.abs(startTouch.clientX - touch.clientX) >= config.startThreshould) {
            inSwipe = true;
            return true;
          } else if (!inSwipe && Math.abs(startTouch.clientY - touch.clientY) > config.startThreshould) {
            inScroll = true;
            return false;
          }
          return  false;
        }),
      )
      .subscribe(touch => {
        const moveDistance = touch.clientX - startTouch.clientX;
        const movePercentage = moveDistance / this.tabGroupElement.offsetWidth * 100;
        latestTouchMove = touch;
        requestAnimationFrame(() => {
          this.renderer.setStyle(animationTarget, 'transform', `translate3d(${movePercentage}%,0%,1px)`);
        });
      });
    touchend$
      .pipe(
        tap(() => {
          inSwipe = false;
        }),
        filter(() => singleTouch),
        filter(() => {
          if (inScroll) {
            return inScroll = false;
          }
          if (!latestTouchMove) {
            this.resetSwipeTransform(animationTarget);
            return false;
          }
          return true;
        }),
      )
      .subscribe(() => {
        const tabBodies = this.tabGroupElement.querySelectorAll<HTMLElement>('mat-tab-body');
        const currentIndex = this.getTabBodyIndex();
        const moveDistance = latestTouchMove.clientX - startTouch.clientX;
        const movePercentage = moveDistance / this.tabGroupElement.offsetWidth * 100;
        singleTouch = false;
        if (currentIndex > 0 && movePercentage >= config.moveThreshould) { // prev
          this.addOnceAnimation(animationTarget, 'translate3d(100%,0%,1px)');
          this.matTabGroup.selectedIndex = currentIndex - 1;
          return;
        } else if (currentIndex < tabBodies.length && movePercentage <= -config.moveThreshould) { // next
          this.addOnceAnimation(animationTarget, 'translate3d(-100%,0%,1px)');
          this.matTabGroup.selectedIndex = currentIndex + 1;
          return;
        }
        requestAnimationFrame(() => {
          this.resetSwipeTransform(animationTarget);
        });
      });
  }

  tabListSwipable() {
    let inSwipe = false;
    let singleTouch = false;
    let startTouch: Touch;
    let latestTouchMove: Touch;
    let offsetX = 0;
    const animationTarget = this.tabListElement;
    const touchstart$ = fromEvent<TouchEvent>(this.tabListElement, 'touchstart');
    const touchmove$ = fromEvent<TouchEvent>(this.tabListElement, 'touchmove');
    const touchend$ = fromEvent<TouchEvent>(this.tabListElement, 'touchend');
    const getCurrentTranslateX = () => {
      const style = animationTarget.getAttribute('style');
      const match = style.match(/.*transform:\s?translateX\(((?:-?\d+)(?:\.\d+)?)(px)?(?:,\s?.+)*\)/);
      return match ? +match[1] : 0;
    };
    touchstart$
      .pipe(
        filter(e => e.touches.length === 1)
      )
      .subscribe(e => {
        singleTouch = true;
        startTouch = e.touches[0];
      });
    touchmove$
      .pipe(
        filter(() => singleTouch),
        map(e => e.touches[0]),
        filter(() => {
          if (inSwipe) {
            return true;
          }
          this.renderer.setStyle(animationTarget, 'transition', 'unset');
          offsetX = getCurrentTranslateX();
          inSwipe = true;
          return true;
        }),
      )
      .subscribe(touch => {
        const moveDistance = touch.clientX - startTouch.clientX;
        latestTouchMove = touch;
        this.renderer.setStyle(animationTarget, 'transform', `translateX(${offsetX + moveDistance}px)`);
      });
    touchend$
      .pipe(
        tap(() => {
          inSwipe = false;
        }),
        filter(() => singleTouch),
        filter(() => {
          if (!latestTouchMove) {
            this.resetSwipeTransform(animationTarget);
            return false;
          }
          return true;
        }),
      )
      .subscribe(() => {
        const currentX = getCurrentTranslateX();
        const listWidth = animationTarget.offsetWidth;
        const tabWidth = this.tabGroupElement.offsetWidth;
        const duration = this.matTabGroup.animationDuration;
        singleTouch = false;
        if (currentX > 0) {
          this.resetSwipeTransform(animationTarget);
        } else if (Math.abs(currentX) > listWidth - tabWidth) {
          this.renderer.setStyle(animationTarget, 'transition', `${duration} cubic-bezier(.56,.96,.72,1)`);
          this.renderer.setStyle(animationTarget, 'transform', `translateX(${tabWidth - listWidth}px)`);
          animationTarget.addEventListener('transitionend', () => {
            this.renderer.removeStyle(animationTarget, 'transition');
          }, {once: true});
        }
      });
  }
}
