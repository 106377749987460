<cdk-virtual-scroll-viewport
  itemSize="128"
  minBufferPx="640"
  maxBufferPx="1280">
  <div
    *ngIf="!dataSource.isPending
           && dataSource.dataLength == 0
           && dataSource.theEnd"
    class="message-empty">
    <span>該当する案件はありません</span>
  </div>

  <div
    *cdkVirtualFor="let order of dataSource"
    class="order-list-item">
    <ng-container *ngTemplateOutlet="orderListItem; context: {order: order}"></ng-container>
  </div>
  <div
    *ngIf="!dataSource.isPending
           && dataSource.dataLength > 0
           && dataSource.theEnd"
    class="message-empty">
    <span>これ以上の検索結果はありません</span>
  </div>

  <mat-progress-spinner
    *ngIf="dataSource.isPending"
    class="orders-loading-spinner"
    color="primary"
    mode="indeterminate"
    strokeWidth="15"></mat-progress-spinner>
</cdk-virtual-scroll-viewport>

<ng-template #orderListItem let-order="order">
  <app-order-favorite
    [orderId]="order.id"></app-order-favorite>
  <div
    class="order-item-inner"
    matRipple
    [routerLink]="[path.order.detailBase | rootPathPrefix, order.id]">
    <div class="order-images-thmbnail">
      <div
        class="order-image-container"
        [style.backgroundSize]="hasImage(order) ? 'contain' : null"
        [style.backgroundImage]="hasImage(order) ? 'url('+(getImage(order) | async)+')' : null"></div>
    </div>
    <div class="order-item-details">
      <app-order-status-chip
        [order]="order"
        [entry]="getOwnEntry(order) || null"
        [report]="getReport(order) || null"></app-order-status-chip>
      <div class="order-name">{{order.projectName}}</div>
      <div class="order-informations">
        <span
          class="order-event-periods"
          [innerHTML]="{startAt: order.eventStartAt, endAt: order.eventEndAt} | period:'yyyy/MM/dd':'+0900'"></span>
          <!--  | period:'yyyy/MM/dd':'+0900' -->
        <span
          *ngIf="({startAt: order.eventStartAt, endAt: order.eventEndAt} | period) && order.reward"
          class="separator">&nbsp;|&nbsp;</span>
        <span
          class="order-reward">{{
            (order.reward | head)?.amount ?
            ((order.reward | head).amount | currency:'JPY') :
            '報酬なし'
        }}</span>
      </div>
      <div class="order-remarks">{{order.remarks}}</div>
    </div>
  </div>
</ng-template>