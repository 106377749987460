import { OnInit } from '@angular/core';
import { firestore } from 'firebase/app';
import { get } from 'lodash';
import { Entry, Project } from '@lu/models';
var OrderStatusChipComponent = /** @class */ (function () {
    function OrderStatusChipComponent() {
        this.orderStatusEnum = Project.StatusEnum;
        this.entryStatusEnum = Entry.StatusEnum;
    }
    OrderStatusChipComponent.prototype.ngOnInit = function () {
        // console.log(this);
    };
    Object.defineProperty(OrderStatusChipComponent.prototype, "today", {
        get: function () {
            return new Date();
        },
        enumerable: true,
        configurable: true
    });
    OrderStatusChipComponent.prototype.toDate = function (datetime) {
        if (datetime instanceof Date) {
            return datetime;
        }
        if (typeof datetime === 'string') {
            var date = new Date(datetime);
            return !isNaN(date.getTime()) ? new Date(datetime) : null;
        }
        if (datetime instanceof firestore.Timestamp) {
            return datetime.toDate();
        }
        return null;
    };
    OrderStatusChipComponent.prototype.isFuture = function (date) {
        return date && date.valueOf() - Date.now() > 0;
    };
    OrderStatusChipComponent.prototype.isPast = function (date) {
        return date && date.valueOf() - Date.now() < 0;
    };
    OrderStatusChipComponent.prototype.withinPeriod = function (p) {
        if (this.isFuture(this.toDate(p.startAt)) || this.isPast(this.toDate(p.endAt))) {
            return false;
        }
        if ((!p.startAt || this.isPast(this.toDate(p.startAt)))
            && (!p.endAt || this.isFuture(this.toDate(p.endAt)))) {
            return true;
        }
        return false;
    };
    Object.defineProperty(OrderStatusChipComponent.prototype, "recruiting", {
        get: function () {
            return get(this.order, 'status') === Project.StatusEnum.Recruiting;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrderStatusChipComponent.prototype, "expired", {
        get: function () {
            return !this.orderCanceled
                && this.isPast(this.toDate(get(this.order, 'recruitmentEndAt')));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrderStatusChipComponent.prototype, "entried", {
        get: function () {
            return !this.orderCanceled
                && !!get(this.entry, 'entriedAt');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrderStatusChipComponent.prototype, "offered", {
        get: function () {
            return !this.orderCanceled
                && !!get(this.entry, 'offeredAt');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrderStatusChipComponent.prototype, "approved", {
        get: function () {
            return !this.orderCanceled
                && !!get(this.entry, 'approvedAt');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrderStatusChipComponent.prototype, "decided", {
        get: function () {
            return !this.orderCanceled
                && !!get(this.entry, 'decidedAt');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrderStatusChipComponent.prototype, "completed", {
        get: function () {
            return !this.orderCanceled
                && !!get(this.entry, 'completedAt');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrderStatusChipComponent.prototype, "inProgress", {
        get: function () {
            return !this.orderCanceled
                && get(this.entry, 'status') === Entry.StatusEnum.Decided
                && this.withinPeriod({
                    startAt: this.order.eventStartAt,
                    endAt: this.order.eventEndAt
                });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrderStatusChipComponent.prototype, "notReported", {
        get: function () {
            return this.decided
                && !this.entryCanceled;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrderStatusChipComponent.prototype, "reported", {
        get: function () {
            return this.decided
                && !this.entryCanceled
                && !!get(this.entry, 'reportedAt');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrderStatusChipComponent.prototype, "reReported", {
        get: function () {
            return this.reported
                && get(this.report, 'read') === false
                && get(this.report, 'rejectedAt')
                && !this.reportApproved;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrderStatusChipComponent.prototype, "reportRejected", {
        get: function () {
            return this.reported
                && get(this.report, 'read')
                && get(this.report, 'rejectedAt')
                && !this.reportApproved;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrderStatusChipComponent.prototype, "reportApproved", {
        get: function () {
            return get(this.report, 'approved') === true;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrderStatusChipComponent.prototype, "orderCanceled", {
        get: function () {
            return (this.orderCompleted || this.orderClosed)
                && this.order.closedStatus === Project.ClosedStatusEnum.Canceled;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrderStatusChipComponent.prototype, "orderClosed", {
        get: function () {
            return get(this.order, 'status') === Project.StatusEnum.Closed;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrderStatusChipComponent.prototype, "orderCompleted", {
        get: function () {
            return get(this.order, 'status') === Project.StatusEnum.Completed;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrderStatusChipComponent.prototype, "entryCanceled", {
        get: function () {
            return get(this.entry, 'status') === Entry.StatusEnum.Canceled;
        },
        enumerable: true,
        configurable: true
    });
    return OrderStatusChipComponent;
}());
export { OrderStatusChipComponent };
