import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { take, map } from 'rxjs/operators';
import { firestore, auth } from 'firebase/app';
import { cloneDeepWith } from 'lodash';

const timestampConverter = (value, key) => {
  // Ignore unchangeable timestamp fields.
  if (key === 'at') {
    return void 0;
  }
  return value instanceof firestore.Timestamp ? value.toDate() : void 0;
};

@Injectable()
export class OwnProvidersResolver implements Resolve<(any & {_id: string})[]> {
 constructor(
    private afStore: AngularFirestore,
  ) {}

 resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    const {uid} = auth().currentUser;
    return this.afStore.collection<any>(`users/${uid}/providers`)
      .valueChanges({idField: '_id'})
      .pipe(
        take(1),
        map(providers => cloneDeepWith(providers, timestampConverter) as (any & {_id: string})[])
      );
  }
}
