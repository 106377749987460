import { Component, OnInit, Input } from '@angular/core';

import { firestore } from 'firebase/app';
import { get } from 'lodash';

import { Entry,  Report, Project } from '@lu/models';

@Component({
  selector: 'app-order-status-chip',
  templateUrl: './order-status-chip.component.html',
  styleUrls: ['./order-status-chip.component.scss']
})
export class OrderStatusChipComponent implements OnInit {
  @Input() order: Project; // SearchOrderResult
  @Input() entry: Entry & { _id: string }; // 読込中の時はundefined
  @Input() report: Report & { _id: string };
  public orderStatusEnum = Project.StatusEnum;
  public entryStatusEnum = Entry.StatusEnum;

  constructor() { }

  ngOnInit() {
    // console.log(this);
  }

  get today() {
    return new Date();
  }

  toDate(datetime: any): Date | null {
    if (datetime instanceof Date) {
      return datetime;
    }
    if (typeof datetime === 'string') {
      const date = new Date(datetime);
      return !isNaN(date.getTime()) ? new Date(datetime) : null;
    }
    if (datetime instanceof firestore.Timestamp) {
      return datetime.toDate();
    }
    return null;
  }

  isFuture(date: Date | null) {
    return date && date.valueOf() - Date.now() > 0;
  }

  isPast(date: Date | null) {
    return date && date.valueOf() - Date.now() < 0;
  }

  withinPeriod(p: any): boolean {
    if (this.isFuture(this.toDate(p.startAt)) || this.isPast(this.toDate(p.endAt))) {
      return false;
    }
    if ((!p.startAt || this.isPast(this.toDate(p.startAt)))
      && (!p.endAt || this.isFuture(this.toDate(p.endAt)))) {
      return true;
    }
    return false;
  }

  get recruiting(): boolean {
    return get(this.order, 'status') === Project.StatusEnum.Recruiting;
  }

  get expired(): boolean {
    return !this.orderCanceled
      && this.isPast(this.toDate(get(this.order, 'recruitmentEndAt')));
  }

  get entried(): boolean {
    return !this.orderCanceled
      && !!get(this.entry, 'entriedAt');
  }

  get offered(): boolean {
    return !this.orderCanceled
      && !!get(this.entry, 'offeredAt');
  }

  get approved(): boolean {
    return !this.orderCanceled
      && !!get(this.entry, 'approvedAt');
  }

  get decided(): boolean {
    return !this.orderCanceled
      && !!get(this.entry, 'decidedAt');
  }

  get completed(): boolean {
    return !this.orderCanceled
      && !!get(this.entry, 'completedAt');
  }

  get inProgress(): boolean {
    return !this.orderCanceled
      && get(this.entry, 'status') === Entry.StatusEnum.Decided
      && this.withinPeriod({
        startAt: this.order.eventStartAt,
        endAt: this.order.eventEndAt
      });
  }

  get notReported(): boolean {
    return this.decided
      && !this.entryCanceled;
  }

  get reported(): boolean {
    return this.decided
      && !this.entryCanceled
      && !!get(this.entry, 'reportedAt');
  }

  get reReported(): boolean {
    return this.reported
      && get(this.report, 'read') === false
      && get(this.report, 'rejectedAt')
      && !this.reportApproved;
  }

  get reportRejected(): boolean {
    return this.reported
      && get(this.report, 'read')
      && get(this.report, 'rejectedAt')
      && !this.reportApproved;
  }

  get reportApproved(): boolean {
    return get(this.report, 'approved') === true;
  }

  get orderCanceled(): boolean {
    return (this.orderCompleted || this.orderClosed)
      && this.order.closedStatus === Project.ClosedStatusEnum.Canceled;
  }

  get orderClosed(): boolean {
    return get(this.order, 'status') === Project.StatusEnum.Closed;
  }

  get orderCompleted(): boolean {
    return get(this.order, 'status') === Project.StatusEnum.Completed;
  }

  get entryCanceled(): boolean {
    return get(this.entry, 'status') === Entry.StatusEnum.Canceled;
  }
}
