import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { take, map, first } from 'rxjs/operators';
import { cloneDeepWith } from 'lodash';
import { AngularFireAuth } from '@angular/fire/auth';
import { MatchingService } from '@lu/services/matching.service';

type withIdField<T> = T & { _id: string };

@Injectable()
export class OrderReportResolver implements Resolve<withIdField<any>> {
  constructor(
    private afAuth: AngularFireAuth,
    private afStore: AngularFirestore,
    private apiService: MatchingService
  ) { }

  async resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    let { orderId, rewardId } = route.params;
    orderId = Number(orderId);
    rewardId = Number(rewardId);
    const { uid } = this.afAuth.auth.currentUser;
    const memberId$ = () => {
      return new Promise(resolve => {
        this.apiService.getMember({ uid }).subscribe(
          doc => resolve(doc[0].id)
        );
      });
    };
    const loginUserId = await memberId$();
    const params = {
      project: orderId,
      reward: rewardId === 0 ? null : rewardId,
      member: loginUserId
    };
    const report$ = () => {
      return new Promise(resolve => {
        this.apiService.getReport(params).pipe(
          take(1),
          map(reports => reports.map(items => {
            return {
              _id: items.id,
              ...cloneDeepWith(items)
            };
          }))
        ).subscribe(doc => resolve(doc));
      });
    };
    return await report$();
  }
}
