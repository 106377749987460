<div class="drop-area"
     [ngClass]="{
       'drop-over': dropOver
     }"
     luDroppable
     (dragOver)="dropOver = true"
     (dragLeave)="dropOver = false"
     (fileDroped)="fileDroped($event)">
  <input #fileInput
         type="file"
         hidden
         [accept]="acceptType"
         (change)="fileSelectChange($event)">
  <div *ngIf="!file"
       class="action-scripts">
    <div>ファイルを<span class="require-dragdrop">ドラッグ&amp;ドロップ</span></div>
    <div>または</div>
    <span
      class="require-browse"
      (click)="browseFile();
               $event.stopPropagation();">ファイルを選択</span>
  </div>
  <ng-content></ng-content>
</div>