import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { take, map } from 'rxjs/operators';
import { firestore } from 'firebase/app';
import { cloneDeepWith } from 'lodash';
import { Reward } from '@lu/models';

const timestampConverter = (o) => o instanceof firestore.Timestamp ? o.toDate() : undefined;
type withIdField<T> = T & {_id: string};

@Injectable()
export class OrderRewardResolver implements Resolve<withIdField<Reward>> {
 constructor(
    private afStore: AngularFirestore,
  ) {}

 resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    const {orderId, rewardId} = route.params;
    // console.log(orderId, rewardId);
    return this.afStore.doc<Reward>(`orders/${orderId}/rewards/${rewardId}`)
      .snapshotChanges()
      .pipe(
        take(1),
        map(action => {
          const parserdReward: withIdField<Reward> = cloneDeepWith(action.payload.data(), timestampConverter);
          parserdReward._id = rewardId;
          return parserdReward;
        })
      );
  }
}
