import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';

import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { Path } from '@lu/path';
import { AuthAction } from '@lu/auth-action';

@Injectable({
  providedIn: 'root'
})
export class AuthActionGuard implements CanActivate {
  constructor(
    private router: Router,
    private afAuth: AngularFireAuth
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.afAuth.authState.pipe(
      take(1),
      map(user => {
        const {mode} = next.queryParams;
        const redirectTo = state.url;

        switch (mode) {
          // case AuthAction.updateEmail:
          case AuthAction.sendUpdateEmailMail:
            if (!user) {
              return this.router.createUrlTree(Path.auth.login.split('/'), {queryParams: {redirectTo}});
            }
            return true;
        }
        return true;
      })
    );
  }
}
