import { Injectable, OnDestroy } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFireAuth } from '@angular/fire/auth';

import { Observable, BehaviorSubject, from, of, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StorageService implements OnDestroy {
  private cachedURL: {
    [key: string]: {
      uid: string;
      stream$: Observable<string>;
    }
  } = {};
  private subscriptions = new Subscription();

  constructor(
    private afStorage: AngularFireStorage,
    private afAuth: AngularFireAuth,
  ) {}

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * report id joined orderId and RewardId and uid with underscore(_).
   */
  getDownloadURL(url: string): Observable<string | null> {
    const cache = this.cachedURL[url];
    const {uid} = this.afAuth.auth.currentUser;
    if (!url) {
      return of(null);
    }
    if (cache && uid === cache.uid) {
      return cache.stream$;
    }
    // Generate snapshot stream.
    const subject = new BehaviorSubject(null);
    const newURL = {
      uid,
      stream$: subject.asObservable(),
    };
    this.cachedURL[url] = newURL;
    this.subscriptions.add(
      from(this.afStorage.storage.refFromURL(url).getDownloadURL())
        .subscribe(downloadURL => {
          subject.next(downloadURL);
        }, err => {
          subject.error(err);
        })
    );
    return newURL.stream$;
  }
}
