import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { environment } from '@lu/environment';
var SendUpdateEmailMailComponent = /** @class */ (function () {
    function SendUpdateEmailMailComponent(http, dialog, apiService) {
        this.http = http;
        this.dialog = dialog;
        this.apiService = apiService;
        this.requestForm = new FormGroup({
            email: new FormControl(null, [
                Validators.required,
                Validators.email,
                Validators.pattern(/@{1}([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/),
            ]),
        });
        this.completed = false;
        this.pending = false;
        this.requestError = null;
    }
    SendUpdateEmailMailComponent.prototype.ngOnInit = function () { };
    SendUpdateEmailMailComponent.prototype.requestUpdateEmail = function () {
        var _this = this;
        var newEmail = this.requestForm.value.email;
        var member = function () {
            return new Promise(function (memberresolve) {
                _this.apiService.getMember({ email1: newEmail })
                    .subscribe(function (members) {
                    if (members.length > 0) {
                        memberresolve(true);
                    }
                });
            });
        };
        var isMember = function () { return tslib_1.__awaiter(_this, void 0, void 0, function () { return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, member()];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        }); }); };
        var redirectTo = isMember ? 'member' : 'admin';
        this.pending = true;
        this.http.post(environment.apiEndpoint + "/auth/action/request/updateemail", { newEmail: newEmail, redirectTo: redirectTo })
            .subscribe(function () {
            // 重複送信防止のため意図的に pending はそのままにする
            _this.completed = true;
        }, function (err) {
            var status = err.status, code = err.message;
            console.error(err);
            _this.pending = false;
            _this.requestError = { status: status, code: code, error: err, message: '' };
            switch (err.status) {
                case 400:
                case 409:
                    _this.requestError.message = '使用できないメールアドレスです。';
                    break;
                default:
                    _this.requestError.message = 'メールを送信できませんでした。';
            }
        });
    };
    return SendUpdateEmailMailComponent;
}());
export { SendUpdateEmailMailComponent };
