import * as tslib_1 from "tslib";
import { HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ApiService = /** @class */ (function () {
    function ApiService(http) {
        this.http = http;
        this.apiEndpoint = environment.apiEndpoint;
    }
    ApiService.prototype.getQueryFilter = function (queryObject) {
        var query = '';
        var keyArray = Object.keys(queryObject);
        var length = keyArray.length;
        keyArray.forEach(function (key, i) {
            var e_1, _a;
            if (queryObject[key] === '' || queryObject[key] === null) {
                return;
            }
            if (queryObject[key] instanceof Array) {
                var ObjectArray = queryObject[key];
                try {
                    for (var ObjectArray_1 = tslib_1.__values(ObjectArray), ObjectArray_1_1 = ObjectArray_1.next(); !ObjectArray_1_1.done; ObjectArray_1_1 = ObjectArray_1.next()) {
                        var eachObj = ObjectArray_1_1.value;
                        query += key + '=' + encodeURI(eachObj);
                        query += '&';
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (ObjectArray_1_1 && !ObjectArray_1_1.done && (_a = ObjectArray_1.return)) _a.call(ObjectArray_1);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
            }
            else {
                query += key + '=' + encodeURI(queryObject[key]);
                query += '&';
            }
        });
        if (query !== '') {
            query = query.slice(0, -1);
            query = '?' + query;
        }
        return query;
    };
    ApiService.prototype.convertEmptyValueToNull = function (queryObject) {
        var _this = this;
        // tslint:disable-next-line:curly
        if (queryObject === null || queryObject === '')
            return null;
        if (typeof queryObject === 'number' || typeof queryObject === 'boolean' || typeof queryObject === 'string') {
            return queryObject;
        }
        var keyArray = Object.keys(queryObject);
        keyArray.forEach(function (key, i) {
            if (typeof queryObject[key] === 'object' && queryObject[key] !== null) {
                if (Array.isArray(queryObject[key])) {
                }
                else {
                    queryObject[key] = _this.convertEmptyValueToNull(queryObject[key]);
                }
                return;
            }
            if (queryObject[key] === '') {
                queryObject[key] = null;
            }
        });
        return queryObject;
    };
    ApiService.prototype.apiConnecter = function (method, queryUri, body, file) {
        if (file === void 0) { file = false; }
        var headerOptions = new HttpHeaders()
            .set('Content-Type', 'application/json;charset=Shift_JIS;');
        var options = { headers: headerOptions };
        switch (method) {
            case 'GET':
                queryUri += this.getQueryFilter(body);
                // console.log('======= queryUri =======', queryUri);
                return this.http.get(queryUri, body).pipe(map(function (data) { return data; }), catchError(this.handleError));
            case 'POST':
                body = this.convertEmptyValueToNull(body);
                return this.http.post(queryUri, body).pipe(map(function (data) { return data; }), catchError(this.handleError));
            case 'PUT':
                body = this.convertEmptyValueToNull(body);
                return this.http.put(queryUri, body).pipe(map(function (data) { return data; }), catchError(this.handleError));
            case 'PATCH':
                body = this.convertEmptyValueToNull(body);
                return this.http.patch(queryUri, body).pipe(map(function (data) { return data; }), catchError(this.handleError));
            case 'DELETE':
                queryUri += this.getQueryFilter(body);
                return this.http.delete(queryUri, options).pipe(map(function (data) { return data; }), catchError(this.handleError));
            default:
                break;
        }
        return this.http.post(queryUri, body, options).pipe(map(function (data) { return data; }), catchError(this.handleError));
    };
    ApiService.prototype.handleError = function (error) {
        return throwError(error);
    };
    ApiService.prototype.handleErrorType = function (error) { };
    ApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ApiService_Factory() { return new ApiService(i0.ɵɵinject(i1.HttpClient)); }, token: ApiService, providedIn: "root" });
    return ApiService;
}());
export { ApiService };
