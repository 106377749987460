import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';

import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { Path } from '@lu/path';
import { AuthService } from '@lu/services/auth.service';
import { NotificationService } from '@lu/services/notification.service';

@Component({
  selector: 'app-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss'],
})
export class TabComponent implements OnInit, OnDestroy {
  @ViewChild('sidenav', {static: true}) sidenav: MatSidenav;

  public activeTabIndex: number;
  public readonly path = Path;
  private routerSubscripiton: Subscription;

  constructor(
    public router: Router,
    public notificationSevice: NotificationService,
  ) {
  }

  ngOnInit() {
    this.getActiveTabIndex(this.router.url);
    this.watchActivatedTab();
  }

  ngOnDestroy() {
    this.routerSubscripiton.unsubscribe();
  }

  watchActivatedTab() {
    this.routerSubscripiton = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        document.querySelector('.mat-sidenav-content').scrollTop = 0;
        this.getActiveTabIndex(event.url);
        this.sidenav.close();
      });
  }

  getActiveTabIndex(url) {
    if (url.match(/^\/(feed|order\/detail)/)) {
      this.activeTabIndex = 0;
    } else if (url.match(/^\/(notification)/)) {
      this.activeTabIndex = 1;
    } else if (url.match(/^\/(payment)/)) {
      this.activeTabIndex = 2;
    } else if (url.match(/^\/(profile)/)) {
      this.activeTabIndex = 3;
    } else {
      this.activeTabIndex = undefined;
    }
  }

  currentTerm() {
    const today = new Date();
    return `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}`;
  }
}
