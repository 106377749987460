import * as tslib_1 from "tslib";
import { OnInit, TemplateRef, QueryList, AfterViewInit } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { tap, catchError, switchMap, take, map } from 'rxjs/operators';
import { of } from 'rxjs';
var FeedComponent = /** @class */ (function () {
    function FeedComponent(dialog, afAuth, apiService) {
        this.dialog = dialog;
        this.afAuth = afAuth;
        this.apiService = apiService;
        this.tabList = {
            recruitment: {
                label: '募集中',
                isFirst: true,
                scrollOffset: 0,
            },
            entry: {
                label: 'エントリー中',
                isFirst: true,
                scrollOffset: 0,
            },
            approved: {
                label: '仮決定中',
                isFirst: true,
                scrollOffset: 0,
            },
            decided: {
                label: '決定',
                isFirst: true,
                scrollOffset: 0,
            },
            completed: {
                label: '完了済',
                isFirst: true,
                scrollOffset: 0,
            },
            canceled: {
                label: '見送り',
                isFirst: true,
                scrollOffset: 0,
            },
        };
        this.sortOption = [
            {
                name: '新着順',
                value: {
                    _sort: 'publishModifiedAt:DESC',
                }
            },
            {
                name: '謝礼の高い順',
                value: {
                    _sort: 'reward:DESC',
                }
            },
            {
                name: '募集期限が近い順',
                value: {
                    _sort: 'recruitmentEndAt:ASC',
                }
            },
            {
                name: '募集期限が遠い順',
                value: {
                    _sort: 'recruitmentEndAt:DESC',
                }
            },
        ];
        this.sortSelection = this.sortOption[0].value;
        this.tabIndexHistory = [];
        this.today = new Date().toISOString();
    }
    FeedComponent.prototype.ngOnInit = function () {
        this.tabGroup.selectedIndex = this.prevTabIndex;
    };
    FeedComponent.prototype.ngAfterViewInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var uid, getLoginMember, _a;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        uid = this.afAuth.auth.currentUser.uid;
                        getLoginMember = function () {
                            return new Promise(function (resolve) {
                                _this.apiService.getMember({ uid: uid })
                                    .pipe(take(1), map(function (docSnap) { return docSnap[0].id; }))
                                    .subscribe(function (doc) {
                                    resolve(doc);
                                });
                            });
                        };
                        _a = this;
                        return [4 /*yield*/, getLoginMember()];
                    case 1:
                        _a.userId = _b.sent();
                        this.tabIndexHistory.unshift(this.tabGroup.selectedIndex);
                        this.cacheBrandAndMarchandiseOfNonCompetitionOrder()
                            .subscribe(function () {
                            _this.search(_this.tabGroup.selectedIndex, _this.currentKeyword);
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    FeedComponent.prototype.search = function (index, keyword) {
        if (index === void 0) { index = 0; }
        var _a = tslib_1.__read(Object.entries(this.tabList)[index], 2), key = _a[0], tab = _a[1];
        var component = this.orderLists.find(function (_, i) { return index === i; });
        if (!key || !component) {
            return;
        }
        var search = this.generateQuery(key, keyword).search; // key, keyword
        // Escape ExpressionChangedAfterItHasBeenCheckedError errors..
        requestAnimationFrame(function () {
            component.search(search);
            tab.isFirst = false;
        });
    };
    FeedComponent.prototype.searchAll = function (keyword) {
        var _this = this;
        Object.keys(this.tabList).map(function (_, index) {
            _this.search(index, keyword);
        });
    };
    FeedComponent.prototype.searchFormSubmit = function (keyword) {
        this.currentKeyword = keyword;
        // Reset
        Object.values(this.tabList).forEach(function (tab) {
            tab.isFirst = true;
            tab.scrollOffset = 0;
        });
        this.search(this.tabGroup.selectedIndex, this.currentKeyword);
    };
    FeedComponent.prototype.generateORConditionForQueryObj = function (orConditionKey, orConditionValue) {
        if (orConditionKey.length === orConditionValue.length) {
            var list_1 = {};
            orConditionKey.forEach(function (orList, i) {
                list_1['_where[_or][' + i + '][' + orList + ']'] = orConditionValue[i];
            });
            return list_1;
        }
        return {};
    };
    FeedComponent.prototype.generateANDConditionForQueryObj = function (andConditionKey, andConditionValue) {
        if (andConditionKey.length === andConditionValue.length) {
            var list_2 = {};
            andConditionKey.forEach(function (andList, i) {
                list_2[andList] = andConditionValue[i];
            });
            return list_2;
        }
        return {};
    };
    FeedComponent.prototype.generateQuery = function (tab, keyword) {
        var query = {
            search: {}
        };
        query.search = tslib_1.__assign({}, query.search, this.sortSelection);
        if (keyword) {
            var keywords = keyword.split(/\s+/).filter(function (o) { return !!o; });
            var keywordComposite = {};
            keywordComposite.projectName_containss = keywords;
            // keywordComposite.remarks_containss = keywords;
            query.search = tslib_1.__assign({}, query.search, keywordComposite);
        }
        switch (tab) {
            case 'recruitment':
                var andRecruit = this.generateANDConditionForQueryObj(['status_in', 'publishStatus'], [['recruiting', 'registered'], ['public']]);
                query.search = tslib_1.__assign({}, query.search, andRecruit);
                break;
            case 'entry':
                var andEntrying = this.generateANDConditionForQueryObj(['entries.status_in', 'entries.member'], [['entried', 'offered'], this.userId]);
                query.search = tslib_1.__assign({}, query.search, andEntrying);
                break;
            case 'approved':
                var andApproveEntry = this.generateANDConditionForQueryObj(['entries.status_ne', 'entries.status', 'entries.member', 'entries.approvedAt_null', 'entries.decidedAt_null'], ['decided', 'approved', this.userId, false, true]);
                query.search = tslib_1.__assign({}, query.search, andApproveEntry);
                break;
            case 'decided':
                var decideEntry = this.generateANDConditionForQueryObj(['status_ne', 'entries.status', 'entries.reportedAt_null', 'entries.member', 'entries.approvedAt_null', 'entries.decidedAt_null'], ['completed', 'decided', true, this.userId, false, false]);
                query.search = tslib_1.__assign({}, query.search, decideEntry);
                break;
            case 'completed':
                var completeAndEntry = this.generateANDConditionForQueryObj(['entries.member', 'entries.decidedAt_null'], [this.userId, false]);
                // レポートまで提出完了したもの
                var completeOREntrywith01 = this.generateORConditionForQueryObj(['entries.status', 'entries.status', 'entries.reportedAt_null'], ['completed', 'decided', false]);
                // レポート未提出のまま案件が終了したもの
                var completeOREntrywith = this.generateORConditionForQueryObj(['entries.completedAt_null', 'closedStatus'], [false, 'deadline']);
                query.search = tslib_1.__assign({}, query.search, completeAndEntry, completeOREntrywith, completeOREntrywith01);
                break;
            case 'canceled':
                var canceledEntry = this.generateANDConditionForQueryObj(['entries.canceledAt_null', 'entries.status', 'entries.member'], [false, 'canceled', this.userId]);
                query.search = tslib_1.__assign({}, query.search, canceledEntry);
                break;
        }
        query.search._start = 0;
        query.search._limit = -1;
        return query;
    };
    // 自分のエントリーしている案件のうち、競合排除が指定されていて、エントリーがキャンセルのもの以外で本番期間のもの
    FeedComponent.prototype.generateEntriedNonCompetitionOrderQuery = function () {
        var query = {};
        var getAndCondition = this.generateANDConditionForQueryObj(['allowDuplicatedEntry', 'status_ne', 'entries.member', '_limit', '_start'], [false, 'completed', this.userId, 50, 0]);
        var getOrCondition = this.generateORConditionForQueryObj(['eventStartAt_lte', 'eventEndAt_gte', 'eventStartAt_null'], [this.today, this.today, false]);
        query = tslib_1.__assign({}, getOrCondition, getAndCondition);
        return query;
    };
    // 競合排除
    FeedComponent.prototype.generateCompetedQuery = function () {
        var notCompeted = {};
        var idlist = [];
        var brand = [];
        var productKindList = [];
        var startAfter = [];
        var endBefore = [];
        this.competedOrderList.forEach(function (competedOrder, index) {
            // get id list
            idlist.push(competedOrder.id);
            // get brand-master
            if (competedOrder.brand_master) {
                var brandmaster = competedOrder.brand_master;
                brand.push(competedOrder.brand_master ? brandmaster.id : null);
            }
            // brand.push(competedOrder.brandMaster ? competedOrder.brandMaster.id : null);
            // product master
            if (competedOrder.product_masters.length > 0) {
                var productmasters = competedOrder.product_masters;
                productKindList = tslib_1.__spread(productKindList, productmasters.map(function (list) { return list.id; }));
            }
            // productKindList = [...productKindList, ...competedOrder.productMasters.map(list => list.id)];
            // startAfter
            startAfter.push(competedOrder.eventStartAt);
            // end Before
            endBefore.push(competedOrder.eventEndAt);
        });
        var keyOfquery = [
            'id_in', 'brand-master_in', 'product-masters_in',
            'eventStartAt', 'eventEndAt'
        ];
        notCompeted = this.generateORConditionForQueryObj([], []);
        return notCompeted;
    };
    // 自分のエントリーしている案件の競合排除がtrueの案件から、ブランド、商材情報、実施期間を取得する
    FeedComponent.prototype.cacheBrandAndMarchandiseOfNonCompetitionOrder = function () {
        var _this = this;
        var body = {
            search: this.generateEntriedNonCompetitionOrderQuery()
        };
        var list = [];
        var stream$ = function (request) { return _this.apiService.getProject(request.search)
            .pipe(switchMap(function (res) {
            list.push.apply(list, tslib_1.__spread(res));
            if (list.length < body.search._limit) {
                return of(list);
            }
            body.search._start += list.length;
            return stream$(body);
        })); };
        return stream$(body)
            .pipe(catchError(function (err) {
            console.error(err);
            return of(void 0);
        }), tap(function (result) {
            _this.competedOrderList = result;
        }));
    };
    FeedComponent.prototype.openSortDialog = function () {
        var _this = this;
        var dialog = this.dialog.open(this.sortDialog, { autoFocus: false });
        dialog.afterClosed().subscribe(function () {
            Object.values(_this.tabList).forEach(function (tab) {
                tab.isFirst = true;
                tab.scrollOffset = 0;
            });
            _this.search(_this.tabGroup.selectedIndex, _this.currentKeyword);
        });
    };
    FeedComponent.prototype.sortOptionSelected = function (result) {
        this.sortSelection = result;
    };
    Object.defineProperty(FeedComponent.prototype, "prevTabIndex", {
        get: function () {
            var prevIndex = +sessionStorage.getItem('feedLastSelectedTabIndex');
            if (isNaN(prevIndex) || prevIndex > Object.entries(this.tabList).length) {
                return 0;
            }
            return prevIndex;
        },
        set: function (index) {
            sessionStorage.setItem('feedLastSelectedTabIndex', String(index));
        },
        enumerable: true,
        configurable: true
    });
    FeedComponent.prototype.selectedTabChange = function (event) {
        var _this = this;
        // console.log(event);
        var index = event.index;
        var prevIndex = this.tabIndexHistory[0];
        var prevTab = Object.values(this.tabList)[prevIndex];
        var prevCcomponent = this.orderLists.find(function (_, i) { return prevIndex === i; });
        prevTab.scrollOffset = prevCcomponent.virtualScroll.measureScrollOffset();
        this.tabIndexHistory.unshift(index);
        this.prevTabIndex = index;
        Object.values(this.tabList).forEach(function (tab, i) {
            if (i !== index) {
                return;
            }
            var component = _this.orderLists.find(function (_, j) { return index === j; });
            if (tab.isFirst) {
                _this.search(index, _this.currentKeyword);
            }
            else {
                component.virtualScroll.scrollToOffset(tab.scrollOffset);
            }
        });
    };
    return FeedComponent;
}());
export { FeedComponent };
