import { Component, OnInit } from '@angular/core';
import { LocationService } from '@lu/services/location.service';

@Component({
  selector: 'app-service-policy',
  templateUrl: './service-policy.component.html',
  styleUrls: ['./service-policy.component.scss']
})
export class ServicePolicyComponent implements OnInit {

  constructor(
    public locationService: LocationService
  ) { }

  ngOnInit() {}
}
