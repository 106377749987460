import { OnDestroy } from '@angular/core';
import { BehaviorSubject, from, of, Subscription } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/storage";
import * as i2 from "@angular/fire/auth";
var StorageService = /** @class */ (function () {
    function StorageService(afStorage, afAuth) {
        this.afStorage = afStorage;
        this.afAuth = afAuth;
        this.cachedURL = {};
        this.subscriptions = new Subscription();
    }
    StorageService.prototype.ngOnDestroy = function () {
        this.subscriptions.unsubscribe();
    };
    /**
     * report id joined orderId and RewardId and uid with underscore(_).
     */
    StorageService.prototype.getDownloadURL = function (url) {
        var cache = this.cachedURL[url];
        var uid = this.afAuth.auth.currentUser.uid;
        if (!url) {
            return of(null);
        }
        if (cache && uid === cache.uid) {
            return cache.stream$;
        }
        // Generate snapshot stream.
        var subject = new BehaviorSubject(null);
        var newURL = {
            uid: uid,
            stream$: subject.asObservable(),
        };
        this.cachedURL[url] = newURL;
        this.subscriptions.add(from(this.afStorage.storage.refFromURL(url).getDownloadURL())
            .subscribe(function (downloadURL) {
            subject.next(downloadURL);
        }, function (err) {
            subject.error(err);
        }));
        return newURL.stream$;
    };
    StorageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StorageService_Factory() { return new StorageService(i0.ɵɵinject(i1.AngularFireStorage), i0.ɵɵinject(i2.AngularFireAuth)); }, token: StorageService, providedIn: "root" });
    return StorageService;
}());
export { StorageService };
