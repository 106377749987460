import { OnInit } from '@angular/core';
var OrderFavoriteComponent = /** @class */ (function () {
    function OrderFavoriteComponent(fav) {
        this.fav = fav;
    }
    OrderFavoriteComponent.prototype.ngOnInit = function () { };
    OrderFavoriteComponent.prototype.toggleFavorite = function () {
        if (!this.orderId) {
            return;
        }
        this.favorite = !this.favorite;
    };
    Object.defineProperty(OrderFavoriteComponent.prototype, "favorite", {
        get: function () {
            return this.fav.isFavoriteOrder(this.orderId);
        },
        set: function (fav) {
            if (fav === this.favorite) {
                return;
            }
            if (fav) {
                this.fav.addOrderToFavorite(this.orderId);
            }
            else {
                this.fav.removeOrderFromFavorite(this.orderId);
            }
        },
        enumerable: true,
        configurable: true
    });
    return OrderFavoriteComponent;
}());
export { OrderFavoriteComponent };
