import * as tslib_1 from "tslib";
import { TextDialogComponent } from '@lu/components/text-dialog/text-dialog.component';
import { ConfirmDialogComponent } from '@lu/components/confirm-dialog/confirm-dialog.component';
import { LoadingDialogComponent } from '@lu/components/loading-dialog/loading-dialog.component';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
var DialogService = /** @class */ (function () {
    function DialogService(dialog) {
        this.dialog = dialog;
    }
    DialogService.prototype.openLoadingDialog = function (config) {
        return this.dialog.open(LoadingDialogComponent, config);
    };
    DialogService.prototype.openTextDialog = function (config) {
        if (config === void 0) { config = {}; }
        var dialog = this.dialog.open(TextDialogComponent, tslib_1.__assign({}, config, { maxHeight: '90vh' }));
        return dialog;
    };
    DialogService.prototype.openConfirmDialog = function (config) {
        if (config === void 0) { config = {}; }
        var dialog = this.dialog.open(ConfirmDialogComponent, tslib_1.__assign({}, config));
        return dialog;
    };
    DialogService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DialogService_Factory() { return new DialogService(i0.ɵɵinject(i1.MatDialog)); }, token: DialogService, providedIn: "root" });
    return DialogService;
}());
export { DialogService };
