import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';

import { findIndex, find, get } from 'lodash';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { Project } from '@lu/models';
import { FavoriteService, FavoriteItem } from '@lu/services/favorite.service';
import { LocationService } from '@lu/services/location.service';
import { Path } from '@lu/path';
import { MatchingService } from '@lu/services/matching.service';

@Component({
  selector: 'app-favorite-list',
  templateUrl: './favorite-list.component.html',
  styleUrls: ['./favorite-list.component.scss']
})
export class FavoriteListComponent implements OnInit {
  public favoriteList: FavoriteItem<number>[] = [];
  public orderList: Array<Project>;
  public readonly path = Path;
  public uid: number;

  constructor(
    public locationService: LocationService,
    private favService: FavoriteService,
    private afAuth: AngularFireAuth,
    private apiService: MatchingService,
  ) { }

  async ngOnInit() {
    this.favoriteList = this.favService.favoriteOrderList;
    if (this.favoriteList.length === 0) {
      this.orderList = [];
      return;
    }
    this.uid = await this.getUid();
    this.getOrders();
  }

  async getUid() {
    const { uid } = this.afAuth.auth.currentUser;
    const getLoginMember = () => {
      return new Promise<number>(resolve => {
        this.apiService.getMember({ uid })
          .pipe(take(1), map(docSnap => docSnap[0].id))
          .subscribe(
            doc => {
              resolve(doc);
            }
          );
      });
    };
    return await getLoginMember();
  }

  async getOrders() {
    const orders = [];
    this.favoriteList.forEach((fav: any) => {
      orders.push(fav.ref);
    });
    this.apiService.getProject({ id_in: orders })
      .pipe(
        map(list => list.filter(o => {
          const ownEntry = find(o.entries, ['member', this.uid]);
          if (ownEntry
            || o.status === Project.StatusEnum.Registered
            || o.status === Project.StatusEnum.Recruiting) {
            return true;
          }
          this.favService.removeOrderFromFavorite(o.id);
          return false;
        })),
        map(list => list.sort((a, b) => {
          const [aPos, bPos] = [findIndex(this.favoriteList, a.id), findIndex(this.favoriteList, b.id)];
          return aPos - bPos;
        }))
      )
      .subscribe(doc => {
        this.orderList = doc;
      }, err => console.error(err));
  }

  getOwnEntry(esOrder: Project) {
    const entries = esOrder.entries;
    if (!Array.isArray(entries)) {
      return void 0;
    }
    const ownEntry = find(entries, ['member', this.uid]);
    return ownEntry;
  }

  getReport(project: Project) {
    const reports = project.reports;
    if (!Array.isArray(reports)) {
      return void 0;
    }
    const ownReport = find(reports, ['member', this.uid]);
    return ownReport;
  }

  isImage(data: any): boolean {
    if (data === null) {
      return false;
    }
    if (typeof data.mime !== 'string'
      || !/image\/.+/.test(data.mime)) {
      return false;
    }
    return true;
  }

  hasImage(order: Project): boolean {
    if (!order
      || (!order.file1
      && !order.file2)) {
      return false;
    }
    const imageFile = [order.file1, order.file2];
    const files = imageFile as any[];
    const file = files.reduce((prev, current) => this.isImage(prev) ? prev : current);
    const url = get(file, 'url');
    return !!url;
  }

  getImage(order: any): Observable<string> {
    if (!order
      || (!order.file1
      && !order.file2)) {
      return of(void 0);
    }
    const imageFile = [order.file1, order.file2];
    const files = imageFile as any;
    const file = files.reduce((prev, current) => this.isImage(prev) ? prev : current);
    const url = get(file, 'url');
    return of(url);
  }
}
