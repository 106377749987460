<mat-toolbar class="app-header app-fixed-header">
  <app-route-progress></app-route-progress>
  <button
    mat-icon-button
    color="primary"
    (click)="locationService.backWithAppState()">
    <mat-icon>arrow_back</mat-icon>
  </button>
  <h2 class="page-title">お知らせ</h2>
  <div class="right-suffix"></div>
</mat-toolbar>

<div class="page-content">
  <div
    *ngIf="!dataSource.isPending && dataSource.dataLength === 0"
    class="notifications-empty">「お知らせ」はまだありません。</div>

  <div
    class="notification-list"
    (scroll)="onScroll($event)">
    <div
      #notificationItem
      *ngFor="let notice of (dataSource.dataStream$ | async)"
      class="notification-items"
      [ngClass]="{
        'notification-items-has-read': notice.read,
        'notification-items-with-link': notice.type === notificationType.Link,
        'notification-items-with-path': notice.type === notificationType.Path
      }"
      [routerLink]="'/' + notice?.path || ''">
      <div class="notification-image-thumbnails">
        <div class="notification-image-container"></div>
      </div>
      <div class="notification-details">
        <div
          class="notification-message"
          [innerHTML]="notice.message | linky: {
            newWindow: notice.type === notificationType.Link
          }"></div>
        <div class="notification-timestamp">
          {{
            (notice.created_at | dateAgo:60 * 60 * 48)
            || (notice.created_at | date:'yyyy年MM月dd日 HH:mm')
          }}
        </div>
      </div>
    </div>

    <mat-progress-spinner
      *ngIf="dataSource.isPending"
      class="loading-spinner"
      color="primary"
      mode="indeterminate"
      strokeWidth="15"></mat-progress-spinner>
  </div>

  <mat-progress-spinner
    *ngIf="dataSource.isPending"
    class="orders-loading-spinner"
    color="primary"
    mode="indeterminate"
    strokeWidth="15"></mat-progress-spinner>
</div>
