import { Component, ElementRef, OnInit, OnDestroy, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { FormArray, FormGroup, FormControl, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireStorage } from '@angular/fire/storage';
import { UploadTaskSnapshot } from '@angular/fire/storage/interfaces';

import { cloneDeep, get, forEach, filter as _filter, isNil } from 'lodash';
import { merge, Subject } from 'rxjs';
import { map, takeUntil, filter } from 'rxjs/operators';
import * as _ from 'lodash';

import { environment, LINEAddFriendHTMLElement } from '@lu/environment';
import {
  Member,
  BankAccount,
  Address,
  Child,
  ConnectionTwitter,
  ConnectionInstagram,
  ConnectionTikTok,
  ConnectionYoutube,
  ConnectionFetch,
  IndustryMaster,
  JobMaster,
} from '@lu/models';
import { Path } from '@lu/path';
import { AuthAction } from '@lu/auth-action';
import { LocationService } from '@lu/services/location.service';
import { DialogService } from '@lu/services/dialog.service';
import { AuthService } from '@lu/services/auth.service';
import { FileChooserComponent } from '@lu/components/file-chooser/file-chooser.component';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MatchingService } from '@lu/services/matching.service';

const URLValidator = () => Validators.pattern(/^https?:\/\/(www\.)?.+\..+(\/.+)?$/);
const FiniteValidator: ValidatorFn = (ctrl) => {
  if (ctrl.value === null) {
    return null;
  }
  if (!Number.isFinite(+ctrl.value)) {
    return {
      numberic: {
        actual: ctrl.value,
        type: typeof ctrl.value
      }
    };
  }
  return null;
};
const TwitterNameValidator = () => Validators.pattern(/^[a-zA-Z0-9_]*$/);
const IDValidator = () => Validators.pattern(/^[a-zA-Z0-9_.]*$/);
type connectionCreateFields = 'screenName' | 'created' | 'modified' | 'fetch';
const newId = (autoId = '', size = 20) => {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for (let i = 0; i < size; i++) {
    autoId += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return autoId;
};
const extension = (filename) => {
  return '.' + filename.split('.').pop();
};

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {
  @ViewChild('fileInput', { static: true }) fileInput: ElementRef<HTMLInputElement>;

  public member: any;
  public bankAccount: any;
  public address: any;
  public connections: Array<any>;
  public lineId: string;
  public lineDisplayName: string;
  public segment: any;
  public profileForm = new FormGroup({});
  public memberForm = new FormGroup({
    address: new FormControl(null),
    uid: new FormControl(null),
    order: new FormControl(0),
    id: new FormControl(0),
    fullName: new FormControl(null, Validators.required),
    fullNameKana: new FormControl(null),
    displayName: new FormControl(null, Validators.required),
    email1: new FormControl(null, [
      Validators.required,
      Validators.email,
      Validators.pattern(/@{1}([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/),
    ]),
    email2: new FormControl(null, [
      Validators.email,
      Validators.pattern(/@{1}([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/),
    ]),
    phoneNumber1: new FormControl(null, [Validators.pattern(/^(\+?\d+\s)?\d+(-?\d+)*$/), Validators.required]),
    phoneNumber2: new FormControl(null, Validators.pattern(/^(\+?\d+\s)?\d+(-?\d+)*$/)),
    summary: new FormControl(null, Validators.maxLength(10000)),
    catchphrase: new FormControl(null, Validators.maxLength(50)),
    profile: new FormControl(null, Validators.maxLength(200)),
    remarks1: new FormControl(null),
    remarks2: new FormControl(null),
    member_files: new FormControl(null),
    bank_account: new FormControl(null),

    image1: new FormControl(null),
    lineId: new FormControl(null),
    lineDisplayName: new FormControl(null),
    groups: new FormControl([]),
    occupation_master: new FormControl(null),
    industry_master: new FormControl(null),
    job_master: new FormControl(null),
    member_confidential: new FormControl(null),
    provider: new FormControl(null),
    sex: new FormControl(null),
    gender: new FormControl(null),
    height: new FormControl(null, [Validators.min(0), FiniteValidator]),
    weight: new FormControl(null, [Validators.min(0), FiniteValidator]),
    birthDay: new FormControl(null, Validators.required),
    uncertainAge: new FormControl(false),
    married: new FormControl(null),
    hasChildren: new FormControl(null),
    children: new FormControl([]),
    mainSiteURL: new FormControl(null, URLValidator()),
    otherSiteURL1: new FormControl(null, URLValidator()),
    otherSiteURL2: new FormControl(null, URLValidator()),
    otherSiteURL3: new FormControl(null, URLValidator()),
    mainSiteName: new FormControl([]),
    leaved: new FormControl(false),
    leavedAt: new FormControl(null),
    // created: new FormGroup(null),
    // modified: new FormGroup(null)
  });
  public childrenForm = new FormArray([]);
  public addressForm = new FormGroup({
    id: new FormControl(null),
    postalCode: new FormControl(null, [
      Validators.pattern(/^\d{3}-?\d{4}$/),
      Validators.required
    ]),
    address: new FormControl(null, Validators.required),
  });
  public hasBankAccount = new FormControl(false);
  // bankAccountForm don't have no use for now, but remain for format.
  public bankAccountForm = new FormGroup({
    id: new FormControl(null),
    bankName: new FormControl(null),
    bankAccountNumber: new FormControl(null, Validators.pattern(/^\d+$/)),
    bankBranchName: new FormControl(null),
    bankAccountOwnerName: new FormControl(null),
    bankAccountOwnerNameKana: new FormControl(null, Validators.pattern(/^[\u30a1-\u30f6\u30fc\uff66-\uff9f\s]*$/)),
  } as { [K in keyof BankAccount]: AbstractControl });
  public connectionsForm = new FormGroup({
    twitter: new FormGroup({
      id: new FormControl(null),
      idOfResponse: new FormControl(0),
      idStr: new FormControl(null),
      name: new FormControl(null),
      screenName: new FormControl(null, TwitterNameValidator()),
      protected: new FormControl(null),
      verified: new FormControl(null),
      followersCount: new FormControl(0),
      friendsCount: new FormControl(0),
      member: new FormControl(null),
      profileImageURLHTTPS: new FormControl(null),
    }, null),
    tiktok: new FormGroup({
      id: new FormControl(null),
      userId: new FormControl(null),
      uniqueId: new FormControl(null, IDValidator()),
      nickName: new FormControl(null),
      fans: new FormControl(0),
      hearts: new FormControl(0),
      member: new FormControl(null),
      verified: new FormControl(false),
    }, null),
    youtube: new FormGroup({
      id: new FormControl(null),
      idOfResponse: new FormControl(null),
      viewCount: new FormControl(0),
      commentCount: new FormControl(0),
      member: new FormControl(null),
      subscriberCount: new FormControl(0),
    }, null)
  });
  public instagramForm = new FormArray([
    new FormGroup({
      id: new FormControl(null),
      idOfResponse: new FormControl(null),
      username: new FormControl(null, IDValidator()),
      followersCount: new FormControl(0),
      accountType: new FormControl(null),
      member: new FormControl(null),
      order: new FormControl(0)
    }),
    new FormGroup({
      id: new FormControl(null),
      idOfResponse: new FormControl(null),
      username: new FormControl(null, IDValidator()),
      followersCount: new FormControl(0),
      accountType: new FormControl(null),
      member: new FormControl(null),
      order: new FormControl(1)
    })
  ], []);
  public imageForm = new FormGroup({ data: new FormControl(null) });
  public genderList = [
    { value: null, label: null },
    { value: Member.GenderEnum.Female, label: '女性' },
    { value: Member.GenderEnum.Male, label: '男性' },
    { value: 'unanswered', label: '未回答' },
  ];
  public occupationList: Array<any>;
  public industryList: Array<any>;
  public jobList: Array<any>;
  public instagram: Array<any>;
  public readonly path = Path;
  public readonly authAction = AuthAction;
  public pendingLINELogin = false;
  public sanitizedLINEAddFriendHTML: SafeHtml;
  private onDestroy$ = new Subject();
  public accept = '.png, .jpg, .gif, .jpe, .jpeg, .JPG, .JPEG, .PNG, .GIF';
  public acceptType = ['png', 'jpg', 'gif', 'jpe', 'jpeg', 'JPG', 'JPEG', 'PNG', 'GIF'];
  public notDeactive = false;
  public specialMediaGroups = {
    'more': ['出身地', '職業（自由入力）']
  }
  public showMedia = null;

  constructor(
    private http: HttpClient,
    private aRoute: ActivatedRoute,
    private afAuth: AngularFireAuth,
    private afStorage: AngularFireStorage,
    public locationService: LocationService,
    private dialogService: DialogService,
    private renderer2: Renderer2,
    public authService: AuthService,
    private domSanitizer: DomSanitizer,
    private apiService: MatchingService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.addForms();
    this.watchChildrenStateChange();
    this.subscribeOccupations();
    this.subscribeIndustries();
    this.subscribeJobs();
    // tslint:disable-next-line: deprecation
    this.aRoute.data.subscribe(data => {
      console.log(data);
      this.member = data.member[0];
      // set JST time
      if (this.member.birthDay) {
        this.member.birthDay = this.setJST(this.member.birthDay);
      } else {
        this.member.birthDay = null;
      }
      if (this.member.children && this.member.children.length > 0) {
        this.member.children = _.map(this.member.children, (children: any) => {
          if (children.birthDay) {
            children.birthDay = this.setJST(children.birthDay);
          } else {
            children.birthDay = null;
          }
          return children;
        });
      }
      this.address = this.member.address;
      this.connections = data.connections;
      this.segment = this.member.member_status_master;
      this.instagram = this.member.connectionInstagrams;
      this.bankAccount = this.member.bankAccount;
      this.lineId = this.member.lineId;
      this.lineDisplayName = this.member.lineDisplayName;
      const specialMedia = this.getUserSpecialMedia();
      if (specialMedia.length > 0) {
        this.showMedia = specialMedia[0];
      }
      this.assignValuesToForms();
      this.setConnectionDocumentsToForms();
      this.fixAuthDocument();
    });
    this.sanitizedLINEAddFriendHTML = this.domSanitizer.bypassSecurityTrustHtml(LINEAddFriendHTMLElement);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  fixAuthDocument() {
    // Fix auth info
    const { email } = this.afAuth.auth.currentUser;
    if (this.member.email1 !== email) {
      this.authService.setCurrentAuth();
    }
  }

  getUserSpecialMedia() {
    const specialMedia = Object.keys(this.specialMediaGroups);
    const userMedia = this.member.groups.map(group => group.groupName.toLowerCase());
    return specialMedia.filter((media) => _.includes(userMedia, media))
  }

  canDeactivate() {
    if (!this.afAuth.auth.currentUser
      || this.profileForm.pristine
      || this.notDeactive) {
      return true;
    }
    return this.dialogService.openConfirmDialog({
      autoFocus: false,
      data: {
        text: `プロフィールに未保存の変更があります。変更を破棄して移動しますか？`,
        applyText: '破棄',
        cancelText: '編集を続ける'
      }
    }).afterClosed()
      .pipe(map(result => !!result));
  }

  set today(x: Date) { }
  get today() {
    return new Date();
  }

  addForms() {
    this.connectionsForm.addControl('instagram', this.instagramForm);
    this.profileForm.addControl('member', this.memberForm);
    this.profileForm.addControl('image', this.imageForm);
    this.profileForm.addControl('bankAccount', this.bankAccountForm);
    this.profileForm.addControl('address', this.addressForm);
    this.profileForm.addControl('connections', this.connectionsForm);
    // propagate status changes
    this.profileForm.addControl('_children', this.childrenForm);
    this.profileForm.addControl('_hasBankAccount', this.hasBankAccount);

    console.log(this.connectionsForm);

  }

  assignValuesToForms() {
    const { uid } = this.afAuth.auth.currentUser;
    const currentmember: any = this.member;
    const occupation: any = currentmember.occupation_master;
    const industry: any = currentmember.industry_master;
    const job: any = currentmember.job_master;
    this.memberForm.patchValue(cloneDeep(this.member));
    this.memberForm.patchValue({
      occupation_master: occupation ? occupation.id : ''
    });
    this.memberForm.patchValue({
      industry_master: industry ? industry.id : ''
    });
    this.memberForm.patchValue({
      job_master: job ? job.id : ''
    });
    if (this.bankAccount) {
      this.hasBankAccount.setValue(true);
      this.bankAccountForm.patchValue({ ownerId: uid }); // Set current user if bank owner not defined.
      this.bankAccountForm.patchValue(this.bankAccount);
    }
    if (this.address) {
      this.memberForm.patchValue({
        address: this.address
      });
      this.addressForm.patchValue(this.address);
    }
    if (this.member.hasChildren) {
      forEach(this.member.children, () => {
        this.addChild();
      });
      this.childrenForm.patchValue(this.member.children);
    }
  }

  setConnectionDocumentsToForms() {
    const memberAcc: any = this.member;
    memberAcc.connection_twitter !== null ?
      this.connectionsForm.patchValue({
        twitter: memberAcc.connection_twitter
      }) :
      this.connectionsForm.value.twitter = {};

    if (memberAcc.connection_instagrams && memberAcc.connection_instagrams.length > 0) {
      this.member.connection_instagrams.forEach
        ((insta: any) => {
          if (insta.order === 0) {
            this.instagramForm.at(Number(0)).patchValue(_.cloneDeep(insta));
          } else {
            this.instagramForm.at(Number(1)).patchValue(_.cloneDeep(insta));
          }
        });
    }

    if (memberAcc.connection_tik_tok) {
      this.connectionsForm.patchValue({
        tiktok: memberAcc.connection_tik_tok
      });
    }

    if (memberAcc.connection_youtube) {
      this.connectionsForm.patchValue({
        youtube: memberAcc.connection_youtube
      });
    }
  }

  async subscribeOccupations() {
    await this.apiService.getMaster(
      'occupation-masters',
      {
        parentMasterGroupId_null: false, _sort: 'order:ASC'
      })
      .pipe(
        takeUntil(this.onDestroy$),
      )
      // tslint:disable-next-line: deprecation
      .subscribe(list => this.occupationList = list, err => console.error(err));
  }

  subscribeIndustries() {
    this.apiService.getMaster(
      'industry-masters',
      { parentMasterGroupId_null: false, _sort: 'order:ASC' })
      .pipe(
        takeUntil(this.onDestroy$),
      )
      // tslint:disable-next-line: deprecation
      .subscribe(list => this.industryList = list, err => console.error(err));
  }

  subscribeJobs() {
    this.apiService.getMaster(
      'job-masters',
      { parentMasterGroupId_null: false, _sort: 'order:ASC' })
      .pipe(
        takeUntil(this.onDestroy$),
      )
      // tslint:disable-next-line: deprecation
      .subscribe(list => this.jobList = list, err => console.error(err));
  }

  // buildConnectionControl(serviceName: string, ...args: [[string, AbstractControl] | any]) {
  //   const commonCtrl = new FormGroup({
  //     _id: new FormControl(null), // Client only temporary control.
  //     serviceName: new FormControl(serviceName),
  //     order: new FormControl(0),
  //     created: new FormGroup(null),
  //     modified: new FormGroup(null),
  //     fetch: new FormGroup({
  //       id: new FormControl(null),
  //       statusCode: new FormControl(null),
  //       message: new FormControl(null),
  //       at: new FormControl(null)
  //     } as { [K in keyof ConnectionFetch]: AbstractControl }),
  //   } as { [K in keyof Pick<ConnectionTwitter, connectionCreateFields>]: AbstractControl });

  //   // add specified controls
  //   for (const arg of args) {
  //     // Additional form controls
  //     if (Array.isArray(arg)
  //       && typeof arg[0] === 'string'
  //       && arg[1] instanceof AbstractControl) {
  //       commonCtrl.addControl(arg[0], arg[1]);
  //     }
  //   }
  //   return commonCtrl;
  // }

  isEmptyConnectionIdentifier(value: any) {
    switch (value.serviceName) {
      case 'twitter':
        return !(value as ConnectionTwitter).screenName;
      case 'instagram':
        return !(value as ConnectionInstagram).username;
      case 'tiktok':
        return !(value as ConnectionTikTok).uniqueId;
      case 'youtube':
        return !(value as ConnectionYoutube).id;
      default:
        return true;
    }
  }

  fileSelectChange(event: Event) {
    const target = event.target as HTMLInputElement;
    if (target.files.length === 0
      || this.imageForm.value.data === target.files[0]) {
      return;
    }
    this.imageForm.value.data = target.files[0];
    const maxSize = 50 * 1024 ** 2;
    const fileType = this.imageForm.value.data.name.split('.').splice(-1)[0];
    if (this.imageForm.value.data instanceof File
      && this.imageForm.value.data.size > maxSize) {
      if (_.includes(this.acceptType, fileType)) {
        this.removeFile();
        return alert('ファイルサイズが50MBを越えています');
      }
    }
    const isInclude = _.includes(this.acceptType, fileType);
    if (this.imageForm.value.data instanceof File
      && !isInclude) {
      this.removeFile();
      return alert('フォーマットが異なっています。正しいフォーマットをアップロードしてください。\n[png, jpg, gif]');
    }
    this.imageForm.markAsDirty();
  }

  browseFile(event: MouseEvent) {
    this.fileInput.nativeElement.click();
    event.preventDefault();
    event.stopPropagation();
  }

  removeFile() {
    this.imageForm.value.data = undefined;
    this.fileInput.nativeElement.value = '';
  }

  async displayPreview(event: Event, container: HTMLElement) {
    if (this.imageForm.value.data instanceof File && this.imageForm.value.data.type.match(/image\/.*/)) {
      const component = new FileChooserComponent();
      const url = await component.convertToDataURL(this.imageForm.value.data);
      this.renderer2.setStyle(container, 'background-image', `url(${url})`);
    } else {
      this.renderer2.setStyle(container, 'background-image', `url(${this.member.image1}`);
    }
  }

  async uploadObject(path: string, data): Promise<{ task: UploadTaskSnapshot, url: string }> {
    const task = await this.afStorage.upload(path, data);
    const url: string = (await task.ref.getDownloadURL()).replace(/&?token=.+&?/, ''); // remove auth token from query.;
    return { task, url };
  }

  /**
   * Upload files if some new file attached.
   */
  async prepareFiles(path: string): Promise<any> {
    return new Promise(resolve => {
      const emptyModelFile: any = {
        url: null,
        thumbnailURL: null,
        fileName: null,
        contentType: null,
        size: 0,
      };
      const data = this.imageForm.value.data;
      const isFile = data instanceof File;
      const prevModelFile = _.nth(_.get(this.member, 'images'), 0) as any;
      if (!isFile) {
        // if image isn't specified, treat as empty item.
        resolve(emptyModelFile);
      } else if (this.imageForm.pristine && prevModelFile) {
        // if image is unchanged and exists, return current data;
        resolve(prevModelFile);
      } else {
        this.uploadObject(`${path}/${newId() + extension(data.name)}`, data)
          .then(({ task, url }) => {
            const [fileName, contentType, size] = [data.name, task.metadata.contentType, task.totalBytes];
            resolve({ ...emptyModelFile, url, fileName, contentType, size });
          });
      }
      // Upload new file.
    });
  }

  getControls(ctrl: any) {
    return get(ctrl, 'controls') as AbstractControl[] || ctrl;
  }

  setJST(birthDate: any) {
    const date = new Date(birthDate);
    // console.log('current : ', date);
    const localTime = date.getTime();
    const localOffset = date.getTimezoneOffset() * 60000;
    const utc = localTime + localOffset;
    // japan UTC + 9
    const offset = 9;
    const japanTime = utc + (3600000 * offset);
    const nd = new Date(japanTime);
    nd.setHours(0);
    nd.setMinutes(0);
    nd.setSeconds(0);
    // console.log('nihon : ', nd);
    return nd;
  }

  async updateProfile() {
    if (this.profileForm.pristine || this.profileForm.invalid) {
      return;
    }
    try {
      const { uid } = this.afAuth.auth.currentUser;
      // const authedTimestamp: Created | Modified = { at: firestore.FieldValue.serverTimestamp() as any, by: uid };
      const { member } = this.profileForm.value as { member: Member };

      if (isNil(this.afAuth.auth.currentUser)) {
        this.notDeactive = true;
        const authFailDialog = this.dialogService.openConfirmDialog({
          autoFocus: false,
          panelClass: ['align-top'],
          data: {
            text: `認証の期限が切れました。再度ログインしなおしてください。`,
            cancel: false,
            apply: true
          }
        });
        authFailDialog.afterClosed().subscribe(() => {
          this.router.navigate([Path.auth.login]);
        });
        return;
      }
      if (!this.imageForm.pristine) {
        const mainImage = _.get(this.imageForm, 'value.data');
        const param = new FormData();
        param.append('files', mainImage);
        const imagedata = () => {
          return new Promise((imgresolve) => {
            this.apiService.uploadFile(param)
              .subscribe(img => {
                imgresolve(img[0]);
              });
          });
        };
        const image = await imagedata();
        this.memberForm.value.image1 = image;
      }

      // member.modified = authedTimestamp;
      if (this.hasBankAccount.value) {
        this.memberForm.value.bank_account = this.bankAccountForm.value;
      } else if (!this.hasBankAccount.value) {
        this.memberForm.value.bank_account = null;
      }

      if (!this.connectionsForm.pristine || this.connectionsForm.valid) {
        const tiktok = this.connectionsForm.value.tiktok;
        const youtube = this.connectionsForm.value.youtube;
        this.memberForm.value.connection_twitter = this.connectionsForm.value.twitter;
        if (this.connectionsForm.value.instagram[0].username != null) {
          this.connectionsForm.value.instagram[0].order = 0;
          if (this.connectionsForm.value.instagram[1].username == null) {
            this.connectionsForm.value.instagram[1].username = '';
            this.connectionsForm.value.instagram[1].order = 1;
          }
          this.memberForm.value.connection_instagrams = this.connectionsForm.value.instagram;
        }

        if (this.connectionsForm.value.instagram[1].username != null) {
          this.connectionsForm.value.instagram[1].order = 1;
          if (this.connectionsForm.value.instagram[0].username == null) {
            this.connectionsForm.value.instagram[0].username = '';
            this.connectionsForm.value.instagram[0].order = 0;
          }
          this.memberForm.value.connection_instagrams = this.connectionsForm.value.instagram;
        }
        tiktok.uniqueId ? this.memberForm.value.connection_tik_tok = tiktok :
          this.memberForm.value.connection_tik_tok = tiktok.uniqueId;
        youtube.idOfResponse ? this.memberForm.value.connection_youtube = youtube :
          this.memberForm.value.connection_youtube = youtube.idOfResponse;
      }
      this.memberForm.value.address = this.addressForm.value;
      this.memberForm.value.lineId = this.lineId;
      this.memberForm.value.lineDisplayName = this.lineDisplayName;
      const updateData = _.omit(this.memberForm.value, 'bank_account', 'order');
      // set JST time
      if (updateData.birthDay) {
        updateData.birthDay = this.setJST(updateData.birthDay);
      } else {
        updateData.birthDay = null;
      }
      if (updateData.children && updateData.children.length > 0) {
        updateData.children = _.map(updateData.children, children => {
          if (children.birthDay) {
            children.birthDay = this.setJST(children.birthDay);
          } else {
            children.birthDay = null;
          }
          return children;
        });
      }
      this.apiService.updateMember(updateData)
        .subscribe(members => {
          if (members) {
            console.log('members update success', members);
            this.profileForm.markAsPristine();
            this.dialogService.openTextDialog({
              autoFocus: false,
              panelClass: ['dialog-panel-primary', 'align-top'],
              data: { text: 'プロフィールを更新しました！' }
            });

          }
        },
          error => {
            console.log('error', error);
            this.dialogService.openTextDialog({
              autoFocus: false,
              panelClass: ['align-top'],
              data: {
                text: `プロフィールを更新できませんでした。
              時間を置いて再度お試しください。`}
            });
          });
    } catch (e) {
      console.error(e);
      // Notification to user that error occurred.
      this.dialogService.openTextDialog({
        autoFocus: false,
        panelClass: ['align-top'],
        data: {
          text: `プロフィールを更新できませんでした。
        時間を置いて再度お試しください。`}
      });
    }
  }

  LINELogin() {
    if (this.pendingLINELogin) {
      return;
    }
    this.pendingLINELogin = true;
    this.http.get(`${environment.apiEndpoint}/line/oauth`)
      .subscribe((result: { loginURL: string }) => {
        this.pendingLINELogin = false;
        window.open(result.loginURL, '_self', 'rel=noopener noreferrer');
      }, (err: HttpErrorResponse) => {
        console.error(err);
        this.dialogService.openTextDialog({
          autoFocus: false,
          panelClass: ['align-top'],
          data: { text: `エラーが発生しました。[T.T]` }
        });
        this.pendingLINELogin = false;
      });
  }

  confirmDisconnectLINE() {
    this.dialogService.openConfirmDialog({
      autoFocus: false,
      data: {
        text: 'LINE連携を解除しますか？',
        applyText: '解除',
      },
    })
      .afterClosed()
      .pipe(filter(result => !!result))
      .subscribe(() => this.disconnectLINE());
  }

  async disconnectLINE() {

    if (this.pendingLINELogin) {
      return;
    }
    this.pendingLINELogin = true;
    try {
      const body = {
        lineId: null,
        lineDisplayName: null,
        connection_tik_tok: this.connectionsForm.value.tiktok.uniqueId ?
          this.connectionsForm.value.tiktok : null,
        connection_twitter: this.connectionsForm.value.twitter,
        connection_youtube: this.connectionsForm.value.youtube.idOfResponse ?
          this.connectionsForm.value.youtube : null,
        connection_instagrams: this.connectionsForm.value.instagram,
        address: this.addressForm.value
      };
      this.apiService.updateMember(body)
        .subscribe((response) => {
          console.log(response);
        }, err => console.error(err));
      this.lineId = undefined;
      this.lineDisplayName = undefined;
    } catch (err) {
      this.dialogService.openTextDialog({
        autoFocus: false,
        panelClass: ['align-top'],
        data: { text: `エラーが発生しました。[not ok]` }
      });
    } finally {
      this.pendingLINELogin = false;
    }
  }

  set childForm(x: FormGroup) { }
  get childForm(): FormGroup {
    return new FormGroup({
      id: new FormControl(null),
      gender: new FormControl(null),
      sex: new FormControl(null),
      birthDay: new FormControl(null),
    } as { [K in keyof Child]: AbstractControl });
  }

  watchChildrenStateChange() {
    merge(
      this.childrenForm.valueChanges,
      this.memberForm.get('hasChildren').valueChanges
    ).pipe(
      map(v => {
        const isBool = typeof v === 'boolean';
        const hasChildren = isBool ? v : this.memberForm.value.hasChildren;
        const children = isBool ? this.childrenForm.value : v;
        return hasChildren ? children : null;
      })
    )
      .subscribe(children => {
        this.memberForm.patchValue({ children });
      });
  }

  addChild(index = 0) {
    const ctrl = this.childForm;
    this.childrenForm.insert(index, ctrl);
  }

  removeChild(index = 0) {
    this.childrenForm.removeAt(index);
  }

  // debug
  log(...data) {
    console.log(...data);
  }

  getInvalidControls(form: FormGroup | FormArray) {
    const ctrls: AbstractControl[] = [];
    if (get(form, 'controls')) {
      forEach(form.controls, (ctrl: AbstractControl, i) => {
        if (ctrl.invalid) {
          ctrls.push(ctrl);
        }
        if (get(form, 'controls')) {
          ctrls.push(...this.getInvalidControls(ctrl as FormGroup | FormArray));
        }
      });
    }
    return ctrls;
  }
}
