import { PipeTransform } from '@angular/core';
var RootPathPrefixPipe = /** @class */ (function () {
    function RootPathPrefixPipe() {
    }
    RootPathPrefixPipe.prototype.transform = function (path) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        return '/' + path;
    };
    return RootPathPrefixPipe;
}());
export { RootPathPrefixPipe };
