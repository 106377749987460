import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { take, map } from 'rxjs/operators';
import { firestore, auth } from 'firebase/app';
import { cloneDeepWith } from 'lodash';
import { BankAccount } from '@lu/models';

const timestampConverter = (o) => o instanceof firestore.Timestamp ? o.toDate() : undefined;

@Injectable()
export class OwnBankAccountResolver implements Resolve<BankAccount> {
 constructor(
    private afStore: AngularFirestore,
  ) {}

 resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    const {uid} = auth().currentUser;
    // return this.afStore
    return this.afStore
      // .collection<BankAccount>(`members/${uid}/bankAccounts`, ref => ref.limit(1))
      .collection<BankAccount>(`members/${uid}/bankAccounts`, ref => ref.where('__name__', '==', '_metadata').limit(1))
      .valueChanges({idField: '_id'})
      .pipe(
        take(1),
        map(results => results.length ? results[0] : null),
        map(bankAccount => cloneDeepWith(bankAccount, timestampConverter) as BankAccount)
      );
  }
}
