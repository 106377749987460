import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';

@Injectable()
export class SleepResolver implements Resolve<null> {
 constructor() {}

 resolve() {
    return of(null).pipe(delay(1000));
  }
}
