import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { take, map } from 'rxjs/operators';
import { firestore } from 'firebase/app';
import { cloneDeepWith } from 'lodash';
import { Reward } from '@lu/models';

const timestampConverter = (o) => o instanceof firestore.Timestamp ? o.toDate() : undefined;
type withIdField<T> = T & {_id: string};


@Injectable()
export class OrderRewardsResolver implements Resolve<withIdField<Reward>[]> {
 constructor(
    private afStore: AngularFirestore,
  ) {}

 resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    const {orderId} = route.params;
    return this.afStore.collection<Reward>(`orders/${orderId}/rewards`)
      .snapshotChanges()
      .pipe(
        take(1),
        map(action => action.map(act => {
          return {
            _id: act.payload.doc.id,
            ...cloneDeepWith(act.payload.doc.data(), timestampConverter) as Reward
          };
        }))
      );
  }
}
