<span
  *ngIf="data.text"
  class="dialog-text"
  [innerHTML]="data.text"></span>

<div class="action-confirm">
  <button
    *ngIf="data.cancel"
    mat-flat-button
    mat-dialog-close>{{data.cancelText || 'キャンセル'}}</button>
  <button
    *ngIf="data.apply"
    class="button-confirm-primary"
    mat-flat-button
    [mat-dialog-close]="true">{{data.applyText || 'OK'}}</button>
</div>