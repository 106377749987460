import * as tslib_1 from "tslib";
import { NativeDateAdapter, } from '@angular/material';
// extend mat datapicker
var MY_DATE_FORMATS = {
    parse: {
        dateInput: { month: 'short', year: 'numeric', day: 'numeric' }
    },
    display: {
        dateInput: 'input',
        monthYearLabel: { year: 'numeric', month: 'short' },
        dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
        monthYearA11yLabel: { year: 'numeric', month: 'long' },
    }
};
// extend mat datapicker
var MyDateAdapter = /** @class */ (function (_super) {
    tslib_1.__extends(MyDateAdapter, _super);
    function MyDateAdapter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    MyDateAdapter.prototype.getDateNames = function () {
        var dateNames = [];
        for (var i = 0; i < 31; i++) {
            dateNames[i] = String(i + 1);
        }
        return dateNames;
    };
    MyDateAdapter.prototype.format = function (date, displayFormat) {
        var padZero = function (char) { return char.padStart(2, '0'); };
        var day = date.getDate();
        var month = date.getMonth() + 1;
        var year = date.getFullYear();
        if (displayFormat === 'input') {
            return year + "-" + padZero(String(month)) + "-" + padZero(String(day));
        }
        else {
            return year + "\u5E74" + padZero(String(month)) + "\u6708";
        }
    };
    return MyDateAdapter;
}(NativeDateAdapter));
export { MyDateAdapter };
var ɵ0 = MY_DATE_FORMATS;
var MatSharedModule = /** @class */ (function () {
    function MatSharedModule() {
    }
    return MatSharedModule;
}());
export { MatSharedModule };
export { ɵ0 };
