import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';

import { firestore } from 'firebase/app';
import { map as _map } from 'lodash';
import * as moment from 'moment';
import { filter } from 'rxjs/operators';
import { MatchingService } from '@lu/services/matching.service';

import { LocationService } from '@lu/services/location.service';
import { DialogService } from '@lu/services/dialog.service';
import { Path } from '@lu/path';
import { CurrencyPipe } from '@angular/common';

import { environment } from '@lu/environment';

export interface QueryObjPayment {
  status: string;
  eventEndAt_gte: string;
  eventEndAt_lte: string;
}

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
  providers: [CurrencyPipe],
})
export class PaymentComponent implements OnInit {
  @ViewChild('htmlPdf', { static: false }) htmlPdf: ElementRef;

  public termOptions: any[];
  public paymentForm = new FormGroup({
    term: new FormControl(null)
  });
  public payments: any[] = [];
  public reports = null;

  public amount = [];
  public path = Path;
  public pending = false;
  public currentTerm: any;
  public currencyTotal: string;
  public pdfData: any;

  constructor(
    private aRoute: ActivatedRoute,
    private router: Router,
    private afAuth: AngularFireAuth,
    public locationService: LocationService,
    private http: HttpClient,
    private dialog: DialogService,
    private currencyPipe: CurrencyPipe,
    private apiService: MatchingService,
  ) { }

  ngOnInit() {
    const { term } = this.aRoute.snapshot.queryParams;
    const termMoment = moment(term);
    this.assignQueryTerms();
    this.getTermSelectOptions();
    this.searchPayment(termMoment.toISOString(true));
    // Update query params.
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(event => {
        this.assignQueryTerms();
      });
    this.currentTerm = moment(this.paymentForm.value.term).startOf('M').format('YYYY年MM月');
    this.paymentForm.get('term').valueChanges
      .subscribe(date => {
        this.currentTerm = moment(date).startOf('M').format('YYYY年MM月');
        const searchTerm = moment(date).format('YYYY-MM');
        this.router.navigate([location.pathname], {
          queryParams: { term: searchTerm }
        });
        this.searchPayment(date);
      });
  }

  get uid() {
    return this.afAuth.auth.currentUser.uid;
  }

  get total() {
    let total = 0;
    this.payments.map((data, i) => {
      // tslint:disable-next-line: radix
      total += parseInt(data.amount) || 0;
    });
    return total;
  }

  getFirstDate(date: Date) {
    const currentDate = date;
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;
    const currentMonths = currentMonth < 10 ? '0' + currentMonth : currentMonth;
    const current = currentYear + '-' + currentMonths + '-01T00:00:00';

    return current;
  }

  isCurrentTerm(term: string) {
    const projectDate = this.getFirstDate(new Date(term));
    const currentDate = this.getFirstDate(new Date());
    return moment(projectDate).isSameOrAfter(currentDate);
  }

  assignQueryTerms() {
    const { term } = this.aRoute.snapshot.queryParams;
    const currentTerm = moment(this.paymentForm.value.term).startOf('M').format('YYYY-MM');
    const termMoment = moment(term);
    // Retrun if term has been not updated.
    if (termMoment.format('YYYY-MM') === currentTerm) {
      return;
    }
    this.paymentForm.patchValue({
      term: (termMoment.isValid() ? termMoment : moment()).startOf('M').add(0, 'days').toISOString()
    });
  }

  async getTermSelectOptions() {
    // NOTE: Refactor and reducing requests are needed!!
    const routeData = { uid: this.uid, };
    const getMemberList = () => {
      return new Promise((memberResolve) => {
        this.apiService.getMember(routeData)
          .subscribe(member => {
            if (member.length > 0) {
              memberResolve(member[0].id);
            }
          });
      });
    };

    const memberId = await getMemberList();

    this.apiService.getPayment({
      member: memberId,
      _limit: -1,
      _sort: 'created_at:ASC',
      'project.status': 'completed',
      'project.closedStatus': 'deadline',
    }).subscribe(
      results => {
        const firstTerm = moment(results[0].created_at).startOf('M');
        const latestTerm = moment(results[results.length - 1].created_at).startOf('M');
        const term = moment(firstTerm.toDate());
        const termOptions = [];
        while (term.unix() <= latestTerm.unix()) {
          termOptions.push(term.toISOString());
          term.add(1, 'M').startOf('M');
        }
        this.termOptions = termOptions;
      });
  }

  toDate(value): Date | null {
    if (value instanceof Date) {
      return value;
    }
    if (value instanceof firestore.Timestamp) {
      return value.toDate();
    }
    const date = new Date(value);
    return isNaN(date.valueOf()) ? null : date;
  }

  lastday(year, month) {
    return new Date(year, month, 0).getDate();
  }

  /**
   * @param term ISO Date String or formatted string that available parse as date.
   */
  async searchPayment(term: string) {
    this.pending = true;
    this.payments = void 0;
    const size = '_limit';
    const from = '_start';
    const orderBy = '_sort';
    // const body = { search: this.generateBaseOrderQuery() as AnyOfSearchQuery[], size: 50, from: 0 };
    const queryObj = {} as QueryObjPayment;

    const formValue = term;
    if (formValue) {
      const date = new Date(formValue);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const months = month < 10 ? '0' + month : month;
      const lastday = this.lastday(year, month);
      queryObj[from] = 0;
      queryObj[size] = 50;
      queryObj[orderBy] = 'paidAt:ASC';
      queryObj['project.status'] = 'completed';
      queryObj['project.closedStatus'] = 'deadline';
      queryObj['project.eventEndAt_gte'] = year + '-' + months + '-01T00:00:00.000Z';
      queryObj['project.eventEndAt_lte'] = year + '-' + months + '-' + lastday + 'T23:59:59.000Z';
    } else {
      return {};
    }

    const routedata = { uid: this.uid, };

    let datalist = [];

    const memberlist = () => {
      return new Promise((memberresolve) => {
        this.apiService.getMember(routedata)
          .subscribe(member => {
            if (member.length > 0) {
              memberresolve(member[0].id);
            }
          });
      });
    };

    const memberid = await memberlist();
    console.log('queryObj', queryObj);
    const paymentparam = 'member';
    queryObj[paymentparam] = memberid;


    const paymentlist = () => {
      return new Promise<any[]>(payresolve => {
        this.apiService.getPayment(queryObj)
          .subscribe(pay => {
            payresolve(pay);
          });
      });
    };

    const payment = await paymentlist();

    if (payment && payment.length > 0) {
      payment.forEach(pay => {
        if (pay.member.id === memberid) {
          datalist.push(pay);
        }
      });
    }
    const pjid = payment.map(paydoc => {
      return paydoc.project.id;
    });
    const mmid = payment.map(paydoc => {
      return paydoc.member.id;
    });

    const entryparams = {
      member: memberid,
      project_in: pjid
    };

    const entryList = () => {
      return new Promise<any[]>(payresolve => {
        this.apiService.getEntries(entryparams)
          .subscribe(rp => {
            payresolve(rp);
          });
      });
    };

    const entry = await entryList();

    entry.map(rp => {
      payment.map(pay => {
        const key = 'reportedAt';
        if (rp.project.id === pay.project.id && rp.member.id === pay.member.id) {
          pay[key] = rp.reportedAt;
        }
      });
    });

    datalist = payment;

    if (datalist) {
      this.payments = datalist;
      this.pending = false;
    }

    this.currencyTotal = this.currencyPipe.transform(this.total, 'JPY');
  }


  getBase64FromFile(file: File | Blob) {
    // tslint:disable-next-line: no-shadowed-variable
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      try {
        reader.readAsDataURL(file);
      } catch (err) {
        reject(err);
      }
    });
  }

  async getDataURL(path: string) {
    const response: Blob = await this.http.get(path, { responseType: 'blob' }).toPromise();
    return this.getBase64FromFile(response);
  }

  async getTableDefinition() {
    const currentTerm = moment(this.paymentForm.value.term).startOf('M').format('YYYY年MM月');
    const column = ['完了報告日', '案件名', '金額'];
    const logoData = await this.getDataURL('/assets/icon/happyplus_community_logo_A.png');
    const body = [];
    const docDefinition = {
      pageSize: 'A4',
      pageMargins: [80, 30, 80, 30],
      header: {
        text: '集英社ハピコミュ',
        fontSize: 8,
        alignment: 'right',
        margin: [0, 10, 30, 0]
      },
      content: [
        {
          image: logoData,
          width: 180,
          margin: [20, 30, 0, 15]
        },
        {
          text: `稼働案件実績`,
          bold: true,
          fontSize: 10,
          margin: [0, 0, 0, 24]
        },
        {
          text: currentTerm,
          alignment: 'left',
        },
        {
          text: this.currencyPipe.transform(this.total, 'JPY'),
          style: 'total',
          margin: [0, 0, 0, 12],
        },
        {
          style: 'table',
          table: {
            widths: [60, '*', 80],
            headerRows: 1,
            body,
          }
        }
      ],
      styles: {
        tableHeader: {
          bold: false,
          fillColor: '#f2f5fc',
        },
        total: {
          bold: true,
          fontSize: 18,
          alignment: 'right',
        }
      },
      defaultStyle: {
        fontSize: 10,
        lineHeight: 1,
        alignment: 'center',
        font: 'GenShinGothic',
        columnGap: 30
      }
    };

    body.push(column.map((text, i) => {
      return {
        text,
        style: 'tableHeader'
      };
    }));
    this.payments.forEach((payment) => {
      body.push([
        payment.reportedAt ? moment(payment.reportedAt).format('YYYY/MM/DD') : '-',
        payment.project.projectName,
        this.currencyPipe.transform(payment.amount || 0, payment.currency)]);
    });
    return docDefinition;
  }

  async downloadPdf() {
    const dialog = this.dialog.openLoadingDialog({
      data: {
        text: 'PDFを作成しています...',
      },
      disableClose: true
    });
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    const docDefinition = await this.getTableDefinition();
    const termStr = moment(this.paymentForm.value.term).startOf('M').format('YYYY年MM月');
    let projectID = '';
    if (!environment.production) {
      projectID = 'dott-matching-develop';
    } else if (environment.production && environment.envChip) {
      projectID = 'dott-matching-staging';
    } else {
      projectID = 'dott-matching-production';
    }
    const apiEndpoint = `https://asia-northeast1-${projectID}.cloudfunctions.net/api`;
    const endPoint = new URL('apiNode', apiEndpoint);
    this.http.post(`${endPoint}/tools/pdf`, docDefinition, { headers, responseType: 'arraybuffer' })
      .subscribe((data: ArrayBuffer) => {
        const blob = new Blob([data], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.setAttribute('href', window.URL.createObjectURL(blob));
        link.setAttribute('download', `案件稼働実績_${termStr}.pdf`);
        link.click();
        link.remove();
        dialog.close();
      }, err => {
        dialog.close();
        console.error(err);
      });
  }

  log(v) {
    console.log(v);
  }
}
