import { map, take } from 'rxjs/operators';
import { Path } from '@lu/path';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/fire/auth";
var AuthGuard = /** @class */ (function () {
    function AuthGuard(router, afAuth) {
        this.router = router;
        this.afAuth = afAuth;
    }
    AuthGuard.prototype.canActivate = function (next, state) {
        var _this = this;
        return this.afAuth.authState.pipe(take(1), map(function (user) {
            var redirectTo = state.url;
            if (!user) {
                return _this.router.createUrlTree(Path.auth.login.split('/'), { queryParams: { redirectTo: redirectTo } });
            }
            else {
                return true;
            }
        }));
    };
    AuthGuard.prototype.canActivateChild = function (next, state) {
        var _this = this;
        return this.afAuth.authState.pipe(take(1), map(function (user) {
            var redirectTo = state.url;
            if (!user) {
                return _this.router.createUrlTree(Path.auth.login.split('/'), { queryParams: { redirectTo: redirectTo } });
            }
            else {
                return true;
            }
        }));
    };
    AuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.AngularFireAuth)); }, token: AuthGuard, providedIn: "root" });
    return AuthGuard;
}());
export { AuthGuard };
