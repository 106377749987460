import { Injectable } from '@angular/core';
import { DataSource } from '@angular/cdk/table';
import { CollectionViewer } from '@angular/cdk/collections';

import { BehaviorSubject, Subscription } from 'rxjs';
import { cloneDeep, forEach, get } from 'lodash';

import {
  Project
} from '@lu/models';
import { MatchingService } from '@lu/services/matching.service';

@Injectable()
export class EsOrderVirutualScrollStrategy extends DataSource<Project> {
  private dataStream = new BehaviorSubject<Project[]>([]);
  public dataStream$ = this.dataStream.asObservable();
  private fetchedPages = new Set<number>();
  private subscription = new Subscription();
  private cachedList: Project[];
  private queryTotal: number;
  private pageSize = 50;
  private hasNext = true;
  private pending = false;
  private query = { search: {} as any };

  constructor(
    private apiService: MatchingService,
  ) {
    super();
  }

  // reference https://material.angular.io/cdk/scrolling/overview#creating-items-in-the-viewport
  connect(collectionViewer: CollectionViewer) {
    // tslint:disable-next-line: deprecation
    this.subscription.add(collectionViewer.viewChange.subscribe(range => {
      const startPage = this.getPageForIndex(range.start);
      const endPage = this.getPageForIndex(range.end);
      for (let i = startPage; i <= endPage; i++) {
        this.fetchPage(i);
      }
    }));
    return this.dataStream;
  }

  disconnect() {
    this.subscription.unsubscribe();
  }

  private getPageForIndex(index: number): number {
    return Math.floor(index / this.pageSize);
  }

  private fetchPage(page: number) {
    if (this.fetchedPages.has(page)) {
      return;
    }
    this.fetchedPages.add(page);
    this.searchNextOrders();
  }

  public get theEnd(): boolean {
    return !this.hasNext;
  }

  public get isPending(): boolean {
    return this.pending;
  }

  public get dataLength(): number {
    return Array.isArray(this.cachedList) ? this.cachedList.length : 0;
  }

  public get hitLength(): number {
    return this.queryTotal;
  }

  public sortedOrderByReward(order: Project[]): Project[] {
    const orderList = order.sort((a, b) => {
      if (a.reward && b.reward) {
        const reward1: any = a.reward;
        const reward2: any = b.reward;
        return Number(reward2.amount) - Number(reward1.amount);
      }
      return -1;
    });
    return orderList;
  }

  /** With refresh order list. */
  async searchOrders(selectedTab, uid, query = {
    search: {} as any
    // tslint:disable-next-line: align
  }, nextData) {
    this.pending = true;
    this.hasNext = true;
    this.queryTotal = null;
    this.cachedList = null;
    this.query = cloneDeep(query);
    this.pageSize = this.query.search._limit;
    this.fetchedPages.clear();
    this.dataStream.next([]);
    this.fetchPage(0);
    await this.apiService.getProject(this.query.search)
      // tslint:disable-next-line: deprecation
      .subscribe(
        result => {
          if (selectedTab === 0) {
            const entrieddata = result.filter(data => {
              for (const entry of data.entries) {
                if (entry.member === uid) {
                  return data;
                }
              }
            });
            result = result.filter(item => {
              return !entrieddata.includes(item);
            });
          } else if (selectedTab === 1) {
            result = result.filter(data => {
              return !((data.status === 'closed' || data.status === 'completed') && data.closedStatus === 'canceled');
            });
          } else if (selectedTab === 2) {
            result = result.filter(data => {
              return !((data.status === 'closed' || data.status === 'completed') && data.closedStatus === 'canceled');
            });
          } else if (selectedTab === 3) {
            result = result.filter(data => {
              return !((data.status === 'closed' || data.status === 'completed') && data.closedStatus === 'canceled');
            });
          } else if (selectedTab === 4) {
            result = result.filter(data => {
              return !((data.status === 'closed' || data.status === 'completed') && data.closedStatus === 'canceled');
            });
          } else if (selectedTab === 5) {
            result = result.filter(data => {
              return !((data.status === 'closed' || data.status === 'completed') && data.closedStatus === 'canceled');
            });
          }
          this.pending = false;
          if (nextData) {
            this.cachedList = [...this.cachedList, ...result];
            // tslint:disable-next-line: no-string-literal
            this.cachedList = this.cachedList.filter((v, i, a) => a.findIndex(t => (t['_id'] === v['_id'])) === i);
            this.queryTotal += this.cachedList.length;
          } else {
            this.queryTotal = result.total;
            this.cachedList = result;
          }
          this.cachedList = result;
          if (this.query.search._sort === 'reward:DESC') {
            this.cachedList = this.sortedOrderByReward(this.cachedList);
          }
          this.dataStream.next(this.cachedList);
          if (this.pageSize === result.length) {
            this.hasNext = true;
          } else if (result.length === 0) {
            this.hasNext = false;
          } else {
            this.hasNext = false;
          }
        }, err => {
          console.error(err);
          this.pending = false;
        }
      );
  }

  /** Append results to order list */
  private searchNextOrders() {
    if (!this.query
      || !this.hasNext
      || this.pending) {
      return;
    }
    this.pending = true;
    this.query.search._start = this.cachedList.length;
    // tslint:disable-next-line: deprecation
    this.apiService.getProject(this.query.search).subscribe(
      orderItem => {
        this.pending = false;
        this.cachedList.push(...orderItem);
        this.dataStream.next(this.cachedList);
        if (this.pageSize === orderItem.length) {
          this.hasNext = true;
        } else if (orderItem.length === 0) {
          this.hasNext = false;
        } else {
          this.hasNext = false;
        }
      }, error => {
        console.error(error);
        this.pending = false;
      }
    );
  }
}
