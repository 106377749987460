<mat-toolbar class="app-header app-fixed-header">
  <app-route-progress></app-route-progress>
  <button
    mat-icon-button
    color="primary"
    (click)="locationService.backWithAppState()">
    <mat-icon>arrow_back</mat-icon>
  </button>
  <h2 class="page-title">完了報告</h2>
  <div class="right-suffix"></div>
</mat-toolbar>

<div class="page-content">
  <h2 class="order-name">{{order.projectName}}</h2>
  <!-- エントリー締切 -->
  <div class="order-detail">
    <div class="order-detail-inner">
      <span class="label">エントリー締切</span>
      <span class="value">{{order.eventEndAt | date:'yyyy/MM/dd HH:mm':'+0900'}}</span>
    </div>
  </div>
  <!-- 本番期間 -->
  <div class="order-detail">
    <div class="order-detail-inner">
      <span class="label">実施期間</span>
      <span class="value" [innerHTML]="{startAt: order.eventStartAt, endAt: order.eventEndAt} | period:'yyyy/MM/dd':'GMT'"></span>
    </div>
  </div>
  <!-- ブランド名 -->
  <div class="order-detail">
    <div class="order-detail-inner">
      <span class="label">ブランド名</span>
      <span class="value">{{brand ? brand.name : '-'}}</span>
    </div>
  </div>
  <!-- 謝礼 -->
  <div class="order-detail">
    <div class="order-detail-inner">
      <span class="label">謝礼</span>
      <span class="value">{{
        reward?.amount ? (reward?.amount | currency:'JPY') : '報酬なし'
      }}</span>
    </div>
  </div>
  <!-- レポート -->
  <div class="order-detail order-detail-textarea">
    <div class="order-detail-inner">
      <span class="label">報告</span>
      <mat-form-field
        appearance="outline"
        [formGroup]="reportForm"
        class="custom-form-field">
        <textarea
          matInput
          matTextareaAutosize
          [readonly]="reportStatus === reportStatusEnum.Approved"
          [cdkAutosizeMinRows]="15"
          formControlName="message"
          placeholder="例）○月○日にTwitterで投稿しました。内容は「○○○」、写真は添付の通りです。
https://twitter.com/sample-url"
          class="value"></textarea>
      </mat-form-field>
    </div>
  </div>
  <!-- 添付ファイル -->
  <div class="order-detail order-detail-attempt-files">
    <div class="order-detail-inner">
      <span class="label">添付ファイル（３つまで）</span>
      <ng-container *ngFor="let ctrl of getControls(attemptFilesForm);
                            let index = index">
        <div
          *ngIf="reportStatus !== reportStatusEnum.Approved || ctrl.value.data"
          class="value attempt-file"
          matRipple
          [matRippleDisabled]="reportStatus === reportStatusEnum.Approved
                               || ctrl.value.fileModel
                               || ctrl.value.data"
          (click)="(reportStatus === reportStatusEnum.Approved ? null : filechooser.browseFile());
                   $event.stopPropagation();">
          <a
            *ngIf="ctrl.value.fileModel || ctrl.value.data"
            class="file-name"
            (click)="$event.preventDefault();">{{ctrl.value.fileModel?.fileName || ctrl.value.data.name}}</a>
          <span class="file-info">{{
            (ctrl.value.fileModel || ctrl.value.data) ?
            '(' + (ctrl.value.fileModel?.size || ctrl.value.data.size | fileSize) + ')' :
            '画像・動画など'
          }}</span>
          <button
            *ngIf="!ctrl.value.fileModel && !ctrl.value.data"
            class="file-add-button"
            mat-icon-button>
            <mat-icon color="primary">add</mat-icon>
          </button>
          <button
            *ngIf="reportStatus !== reportStatusEnum.Approved
                   && (ctrl.value.fileModel || ctrl.value.data)"
            class="file-remove-button"
            mat-icon-button
            (click)="filechooser.removeFile();
                     reportForm.markAsDirty();
                     $event.stopPropagation();">
            <mat-icon>close</mat-icon>
          </button>
          <app-file-chooser
            #filechooser
            [file]="ctrl.value.data"
            (fileChange)="fileSelectChange($event, index);
                          reportForm.markAsDirty();">
          </app-file-chooser>
        </div>
      </ng-container>
    </div>
  </div>
  <!-- 送信 -->
  <button
    *ngIf="!report?.approved"
    mat-flat-button
    class="app-button-submit"
    color="primary"
    type="submit"
    [disabled]="reportForm.invalid || reportForm.pristine"
    (click)="submitReport()">完了報告を{{
      reportStatus == reportStatusEnum.Reported
      || reportStatus == reportStatusEnum.Rejected ? '再' : ''
    }}提出する</button>
  <div
    *ngIf="report?.approved"
    class="report-approved">この完了報告は受領済みです</div>
</div>