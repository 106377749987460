import * as tslib_1 from "tslib";
import { ApiService } from './api.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var MatchingService = /** @class */ (function (_super) {
    tslib_1.__extends(MatchingService, _super);
    function MatchingService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    // project
    MatchingService.prototype.getProject = function (data) {
        var queryUri = this.apiEndpoint + '/projects';
        var body = data;
        return this.apiConnecter('GET', queryUri, body);
    };
    MatchingService.prototype.getOneProject = function (id) {
        var queryUri = this.apiEndpoint + '/projects/' + id;
        var body = {};
        return this.apiConnecter('GET', queryUri, body);
    };
    MatchingService.prototype.getAdminUser = function (data) {
        var queryUri = this.apiEndpoint + '/_admin-users';
        return this.apiConnecter('GET', queryUri, data);
    };
    /*for member*/
    MatchingService.prototype.getMember = function (data) {
        var queryUri = this.apiEndpoint + '/' + 'members';
        return this.apiConnecter('GET', queryUri, data);
    };
    MatchingService.prototype.getEachMember = function (id, data) {
        var queryUri = this.apiEndpoint + '/' + 'members' + '/' + id;
        return this.apiConnecter('GET', queryUri, data);
    };
    MatchingService.prototype.updateMember = function (data) {
        var queryUri = this.apiEndpoint + '/' + 'members/me';
        return this.apiConnecter('PUT', queryUri, data);
    };
    MatchingService.prototype.createAdminUser = function (data) {
        var queryUri = this.apiEndpoint + '/_admin-users';
        var body = data;
        return this.apiConnecter('POST', queryUri, data);
    };
    MatchingService.prototype.getAdminUserWithId = function (id) {
        var queryUri = this.apiEndpoint + '/_admin-users/' + id;
        var data = {};
        return this.apiConnecter('GET', queryUri, data);
    };
    MatchingService.prototype.getAdminUserWithEmail = function (data) {
        var queryUri = this.apiEndpoint + '/_admin-users';
        var body = data;
        return this.apiConnecter('GET', queryUri, data);
    };
    /* update connection account */
    MatchingService.prototype.updateConnection = function (type, id, data) {
        var queryUri = this.apiEndpoint + '/' + type + '/' + id;
        var body = data;
        return this.apiConnecter('PUT', queryUri, body);
    };
    /* add connection account */
    MatchingService.prototype.createConnection = function (type, data) {
        var queryUri = this.apiEndpoint + '/' + type;
        var body = data;
        return this.apiConnecter('POST', queryUri, body);
    };
    /* delete connection account */
    MatchingService.prototype.deleteConnection = function (id, accType) {
        var queryUri = this.apiEndpoint + '/' + accType + '/' + id;
        var body = {};
        return this.apiConnecter('DELETE', queryUri, body);
    };
    /* for address */
    MatchingService.prototype.getAddress = function (data) {
        var queryUri = this.apiEndpoint + '/' + 'addresses';
        return this.apiConnecter('GET', queryUri, data);
    };
    MatchingService.prototype.updateAddress = function (id, data) {
        var queryUri = this.apiEndpoint + '/addresses/' + id;
        var body = data;
        return this.apiConnecter('PUT', queryUri, body);
    };
    /* for login */
    MatchingService.prototype.loginUser = function (data) {
        var queryUri = this.apiEndpoint + '/auth' + '/local';
        var body = data;
        console.log('data ==> ', data);
        return this.apiConnecter('POST', queryUri, body);
    };
    MatchingService.prototype.getLoginUser = function () {
        var queryUri = this.apiEndpoint + '/users' + '/me';
        var body = {};
        return this.apiConnecter('GET', queryUri, body);
    };
    // for master
    MatchingService.prototype.getMaster = function (kind, data) {
        var queryUri = this.apiEndpoint + '/' + kind;
        var body = data;
        return this.apiConnecter('GET', queryUri, body);
    };
    /**
     *
     * For Entry
     */
    MatchingService.prototype.getEntries = function (data) {
        var queryUri = this.apiEndpoint + '/entries';
        var body = data;
        return this.apiConnecter('GET', queryUri, body);
    };
    MatchingService.prototype.createEntry = function (data) {
        var queryUri = this.apiEndpoint + '/entries';
        var body = data;
        return this.apiConnecter('POST', queryUri, body);
    };
    MatchingService.prototype.deleteEntry = function (id) {
        var queryUri = this.apiEndpoint + '/entries/' + id;
        var body = {};
        return this.apiConnecter('DELETE', queryUri, body);
    };
    MatchingService.prototype.editEntry = function (id, data) {
        var queryUri = this.apiEndpoint + '/entries/' + id;
        var body = data;
        return this.apiConnecter('PUT', queryUri, body);
    };
    /**
     *
     * For Reward
     */
    MatchingService.prototype.getReward = function (data) {
        var queryUri = this.apiEndpoint + '/rewards';
        var body = data;
        return this.apiConnecter('GET', queryUri, body);
    };
    /**
     *
     * For Report
     */
    MatchingService.prototype.getReport = function (data) {
        var queryUri = this.apiEndpoint + '/reports';
        var body = data;
        return this.apiConnecter('GET', queryUri, body);
    };
    MatchingService.prototype.createReport = function (data) {
        var queryUri = this.apiEndpoint + '/reports';
        var body = data;
        return this.apiConnecter('POST', queryUri, body);
    };
    MatchingService.prototype.editReport = function (id, data) {
        var queryUri = this.apiEndpoint + '/reports/' + id;
        var body = data;
        return this.apiConnecter('PUT', queryUri, body);
    };
    /**
     * file upload
     */
    MatchingService.prototype.uploadFile = function (data) {
        var queryUri = this.apiEndpoint + '/upload';
        var body = data;
        return this.apiConnecter('POST', queryUri, body);
    };
    MatchingService.prototype.getUploadFile = function () {
        var queryUri = this.apiEndpoint + '/upload/files';
        var body = {};
        return this.apiConnecter('GET', queryUri, body);
    };
    MatchingService.prototype.removeFile = function (id) {
        var queryUri = this.apiEndpoint + '/upload/files/' + id;
        var body = {};
        return this.apiConnecter('DELETE', queryUri, body);
    };
    /* for payment */
    MatchingService.prototype.getPayment = function (data) {
        var queryUri = this.apiEndpoint + '/payments';
        var body = data;
        return this.apiConnecter('GET', queryUri, body);
    };
    /**
     * for notification
     */
    MatchingService.prototype.getNotification = function (data) {
        var queryUri = this.apiEndpoint + '/notifications/me';
        var body = data;
        return this.apiConnecter('GET', queryUri, body);
    };
    MatchingService.prototype.updateNotification = function (id, data) {
        var queryUri = this.apiEndpoint + '/notifications/' + id;
        var body = data;
        return this.apiConnecter('PUT', queryUri, body);
    };
    // for Line
    MatchingService.prototype.getLineAuth = function (data) {
        var queryUri = this.apiEndpoint + '/line/oauth';
        var body = data;
        return this.apiConnecter('GET', queryUri, body);
    };
    MatchingService.prototype.deleteProvider = function (id) {
        var queryUri = this.apiEndpoint + '/providers/' + id;
        var body = {};
        return this.apiConnecter('DELETE', queryUri, body);
    };
    // for password and email update
    MatchingService.prototype.sendResetPasswordMail = function (data) {
        var queryUri = this.apiEndpoint + '/auth/action/request/resetpassword';
        var body = data;
        return this.apiConnecter('POST', queryUri, body);
    };
    MatchingService.prototype.sendUpdateEamilMail = function (data) {
        var queryUri = this.apiEndpoint + '/auth/action/request/updateemail';
        var body = data;
        return this.apiConnecter('POST', queryUri, body);
    };
    MatchingService.prototype.applyEmailUpdate = function (data) {
        var queryUri = this.apiEndpoint + '/auth/action/update/email';
        var body = data;
        return this.apiConnecter('POST', queryUri, body);
    };
    MatchingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MatchingService_Factory() { return new MatchingService(i0.ɵɵinject(i1.HttpClient)); }, token: MatchingService, providedIn: "root" });
    return MatchingService;
}(ApiService));
export { MatchingService };
