import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { of, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { find, get } from 'lodash';
import { Path } from '@lu/path';
import { EsOrderVirutualScrollStrategy } from './es-order-virutual-scroll-strategy';
var OrderListComponent = /** @class */ (function () {
    function OrderListComponent(dataSource, afAuth, storageService, apiService) {
        this.dataSource = dataSource;
        this.afAuth = afAuth;
        this.storageService = storageService;
        this.apiService = apiService;
        this.path = Path;
    }
    OrderListComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.getUid()];
                    case 1:
                        _a.uid = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    OrderListComponent.prototype.getUid = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var uid, getLoginMember;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        uid = this.afAuth.auth.currentUser.uid;
                        getLoginMember = function () {
                            return new Promise(function (resolve) {
                                _this.apiService.getMember({ uid: uid })
                                    .pipe(take(1), map(function (docSnap) { return docSnap[0].id; }))
                                    // tslint:disable-next-line: deprecation
                                    .subscribe(function (doc) {
                                    resolve(doc);
                                });
                            });
                        };
                        return [4 /*yield*/, getLoginMember()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    OrderListComponent.prototype.search = function (search, nextData) {
        if (nextData === void 0) { nextData = false; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.dataSource.searchOrders(this.tabGroup.selectedIndex, this.uid, { search: search }, nextData)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    OrderListComponent.prototype.getOwnEntry = function (esOrder) {
        var entries = esOrder.entries;
        if (!Array.isArray(entries)) {
            return void 0;
        }
        var ownEntry = find(entries, ['member', this.uid]);
        return ownEntry;
    };
    OrderListComponent.prototype.getReport = function (project) {
        var reports = project.reports;
        if (!Array.isArray(reports)) {
            return void 0;
        }
        var ownReport = find(reports, ['member', this.uid]);
        return ownReport;
    };
    OrderListComponent.prototype.isImage = function (data) {
        if (data === null) {
            return false;
        }
        if (typeof data.mime !== 'string'
            || !/image\/.+/.test(data.mime)) {
            return false;
        }
        return true;
    };
    OrderListComponent.prototype.hasImage = function (order) {
        var _this = this;
        if (!order
            || (!order.file1
                && !order.file2)) {
            return false;
        }
        var imageFile = [order.file1, order.file2];
        var files = imageFile;
        var file = files.reduce(function (prev, current) { return _this.isImage(prev) ? prev : current; });
        var url = get(file, 'url');
        return !!url;
    };
    OrderListComponent.prototype.getImage = function (order) {
        var _this = this;
        if (!order
            || (!order.file1
                && !order.file2)) {
            return of(void 0);
        }
        var imageFile = [order.file1, order.file2];
        var files = imageFile;
        var file = files.reduce(function (prev, current) { return _this.isImage(prev) ? prev : current; });
        var url = get(file, 'url');
        return of(url);
    };
    return OrderListComponent;
}());
export { OrderListComponent };
