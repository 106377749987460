<form [formGroup]="requestForm">
  <div *ngIf="completed else mailForm">入力いただいたメールアドレスに承認用のリンクをお送りしました。</div>

  <ng-template #mailForm>
    <div class="form-hint">入力いただいたメールアドレスに、承認用のリンクをお送りします。</div>
    <mat-form-field
      appearance="outline">
      <mat-label>
        <span class="label-outline">新しいメールアドレス</span>
        新しいメールアドレス
      </mat-label>
      <input
        matInput
        placeholder="example@email.com"
        oninput="this.value = this.value.toLowerCase()"
        [formControl]="requestForm.get('email')">
    </mat-form-field>

    <div
      class="error-message"
      [ngClass]="{'hidden': !requestError}">
      <span class="message">{{requestError?.message}}</span>
    </div>

    <button
      class="app-button-submit"
      mat-flat-button
      color="primary"
      type="submit"
      [disabled]="requestForm.invalid || pending"
      (click)="requestUpdateEmail()">送信</button>
  </ng-template>
</form>