/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./route-progress.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/progress-bar/typings/index.ngfactory";
import * as i3 from "@angular/material/progress-bar";
import * as i4 from "@angular/platform-browser/animations";
import * as i5 from "./route-progress.component";
import * as i6 from "@angular/router";
var styles_RouteProgressComponent = [i0.styles];
var RenderType_RouteProgressComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RouteProgressComponent, data: {} });
export { RenderType_RouteProgressComponent as RenderType_RouteProgressComponent };
export function View_RouteProgressComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-progress-bar", [["aria-valuemax", "100"], ["aria-valuemin", "0"], ["class", "mat-progress-bar"], ["color", "primary"], ["role", "progressbar"]], [[1, "aria-valuenow", 0], [1, "mode", 0], [2, "_mat-animation-noopable", null]], null, null, i2.View_MatProgressBar_0, i2.RenderType_MatProgressBar)), i1.ɵdid(1, 4374528, null, 0, i3.MatProgressBar, [i1.ElementRef, i1.NgZone, [2, i4.ANIMATION_MODULE_TYPE], [2, i3.MAT_PROGRESS_BAR_LOCATION]], { color: [0, "color"], value: [1, "value"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = "primary"; var currVal_4 = _co.value; _ck(_v, 1, 0, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_0 = (((i1.ɵnov(_v, 1).mode === "indeterminate") || (i1.ɵnov(_v, 1).mode === "query")) ? null : i1.ɵnov(_v, 1).value); var currVal_1 = i1.ɵnov(_v, 1).mode; var currVal_2 = i1.ɵnov(_v, 1)._isNoopAnimation; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_RouteProgressComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-route-progress", [], null, null, null, View_RouteProgressComponent_0, RenderType_RouteProgressComponent)), i1.ɵdid(1, 245760, null, 0, i5.RouteProgressComponent, [i6.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RouteProgressComponentNgFactory = i1.ɵccf("app-route-progress", i5.RouteProgressComponent, View_RouteProgressComponent_Host_0, {}, {}, []);
export { RouteProgressComponentNgFactory as RouteProgressComponentNgFactory };
