/**
 * DOCUMENTATION
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact-email@something.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ConnectionInstagram {
    id: string;
    idOfResponse?: string;
    username: string;
    followersCount?: number;
    accountType?: ConnectionInstagram.AccountTypeEnum;
    connectionFetch?: object;
    member?: object;
    order?: number;
}
// tslint:disable-next-line: no-namespace
export namespace ConnectionInstagram {
    export type AccountTypeEnum = 'BUSINESS' | 'MEDIA_CREATOR' | 'PERSONAL';
    export const AccountTypeEnum = {
        BUSINESS: 'BUSINESS' as AccountTypeEnum,
        MEDIACREATOR: 'MEDIA_CREATOR' as AccountTypeEnum,
        PERSONAL: 'PERSONAL' as AccountTypeEnum
    };
}


