import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'period'
})
export class PeriodPipe implements PipeTransform {
  transform(period: any, ...args: any): any {
    let result = '';
    if (!period || typeof period !== 'object' || (!period.startAt && !period.endAt)) {
      return null;
    }
    if (period.startAt) {
      result += new DatePipe('en-US').transform(period.startAt, ...args);
    }
    result += '&nbsp;-&nbsp;';
    if (period.endAt) {
      result += new DatePipe('en-US').transform(period.endAt, ...args);
    }
    return result;
  }

}
