import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'rootPathPrefix'
})
export class RootPathPrefixPipe implements PipeTransform {

  transform(path: string, ...args: any[]): any {
    return '/' + path;
  }

}
