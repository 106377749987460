import * as tslib_1 from "tslib";
import { ElementRef, OnInit } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { FormGroup, FormControl } from '@angular/forms';
import { NavigationEnd } from '@angular/router';
import { firestore } from 'firebase/app';
import * as moment from 'moment';
import { filter } from 'rxjs/operators';
import { Path } from '@lu/path';
import { CurrencyPipe } from '@angular/common';
import { environment } from '@lu/environment';
var PaymentComponent = /** @class */ (function () {
    function PaymentComponent(aRoute, router, afAuth, locationService, http, dialog, currencyPipe, apiService) {
        this.aRoute = aRoute;
        this.router = router;
        this.afAuth = afAuth;
        this.locationService = locationService;
        this.http = http;
        this.dialog = dialog;
        this.currencyPipe = currencyPipe;
        this.apiService = apiService;
        this.paymentForm = new FormGroup({
            term: new FormControl(null)
        });
        this.payments = [];
        this.reports = null;
        this.amount = [];
        this.path = Path;
        this.pending = false;
    }
    PaymentComponent.prototype.ngOnInit = function () {
        var _this = this;
        var term = this.aRoute.snapshot.queryParams.term;
        var termMoment = moment(term);
        this.assignQueryTerms();
        this.getTermSelectOptions();
        this.searchPayment(termMoment.toISOString(true));
        // Update query params.
        this.router.events
            .pipe(filter(function (event) { return event instanceof NavigationEnd; }))
            .subscribe(function (event) {
            _this.assignQueryTerms();
        });
        this.currentTerm = moment(this.paymentForm.value.term).startOf('M').format('YYYY年MM月');
        this.paymentForm.get('term').valueChanges
            .subscribe(function (date) {
            _this.currentTerm = moment(date).startOf('M').format('YYYY年MM月');
            var searchTerm = moment(date).format('YYYY-MM');
            _this.router.navigate([location.pathname], {
                queryParams: { term: searchTerm }
            });
            _this.searchPayment(date);
        });
    };
    Object.defineProperty(PaymentComponent.prototype, "uid", {
        get: function () {
            return this.afAuth.auth.currentUser.uid;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaymentComponent.prototype, "total", {
        get: function () {
            var total = 0;
            this.payments.map(function (data, i) {
                // tslint:disable-next-line: radix
                total += parseInt(data.amount) || 0;
            });
            return total;
        },
        enumerable: true,
        configurable: true
    });
    PaymentComponent.prototype.getFirstDate = function (date) {
        var currentDate = date;
        var currentYear = currentDate.getFullYear();
        var currentMonth = currentDate.getMonth() + 1;
        var currentMonths = currentMonth < 10 ? '0' + currentMonth : currentMonth;
        var current = currentYear + '-' + currentMonths + '-01T00:00:00';
        return current;
    };
    PaymentComponent.prototype.isCurrentTerm = function (term) {
        var projectDate = this.getFirstDate(new Date(term));
        var currentDate = this.getFirstDate(new Date());
        return moment(projectDate).isSameOrAfter(currentDate);
    };
    PaymentComponent.prototype.assignQueryTerms = function () {
        var term = this.aRoute.snapshot.queryParams.term;
        var currentTerm = moment(this.paymentForm.value.term).startOf('M').format('YYYY-MM');
        var termMoment = moment(term);
        // Retrun if term has been not updated.
        if (termMoment.format('YYYY-MM') === currentTerm) {
            return;
        }
        this.paymentForm.patchValue({
            term: (termMoment.isValid() ? termMoment : moment()).startOf('M').add(0, 'days').toISOString()
        });
    };
    PaymentComponent.prototype.getTermSelectOptions = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var routeData, getMemberList, memberId;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        routeData = { uid: this.uid, };
                        getMemberList = function () {
                            return new Promise(function (memberResolve) {
                                _this.apiService.getMember(routeData)
                                    .subscribe(function (member) {
                                    if (member.length > 0) {
                                        memberResolve(member[0].id);
                                    }
                                });
                            });
                        };
                        return [4 /*yield*/, getMemberList()];
                    case 1:
                        memberId = _a.sent();
                        this.apiService.getPayment({
                            member: memberId,
                            _limit: -1,
                            _sort: 'created_at:ASC',
                            'project.status': 'completed',
                            'project.closedStatus': 'deadline',
                        }).subscribe(function (results) {
                            var firstTerm = moment(results[0].created_at).startOf('M');
                            var latestTerm = moment(results[results.length - 1].created_at).startOf('M');
                            var term = moment(firstTerm.toDate());
                            var termOptions = [];
                            while (term.unix() <= latestTerm.unix()) {
                                termOptions.push(term.toISOString());
                                term.add(1, 'M').startOf('M');
                            }
                            _this.termOptions = termOptions;
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    PaymentComponent.prototype.toDate = function (value) {
        if (value instanceof Date) {
            return value;
        }
        if (value instanceof firestore.Timestamp) {
            return value.toDate();
        }
        var date = new Date(value);
        return isNaN(date.valueOf()) ? null : date;
    };
    PaymentComponent.prototype.lastday = function (year, month) {
        return new Date(year, month, 0).getDate();
    };
    /**
     * @param term ISO Date String or formatted string that available parse as date.
     */
    PaymentComponent.prototype.searchPayment = function (term) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var size, from, orderBy, queryObj, formValue, date, year, month, months, lastday, routedata, datalist, memberlist, memberid, paymentparam, paymentlist, payment, pjid, mmid, entryparams, entryList, entry;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.pending = true;
                        this.payments = void 0;
                        size = '_limit';
                        from = '_start';
                        orderBy = '_sort';
                        queryObj = {};
                        formValue = term;
                        if (formValue) {
                            date = new Date(formValue);
                            year = date.getFullYear();
                            month = date.getMonth() + 1;
                            months = month < 10 ? '0' + month : month;
                            lastday = this.lastday(year, month);
                            queryObj[from] = 0;
                            queryObj[size] = 50;
                            queryObj[orderBy] = 'paidAt:ASC';
                            queryObj['project.status'] = 'completed';
                            queryObj['project.closedStatus'] = 'deadline';
                            queryObj['project.eventEndAt_gte'] = year + '-' + months + '-01T00:00:00.000Z';
                            queryObj['project.eventEndAt_lte'] = year + '-' + months + '-' + lastday + 'T23:59:59.000Z';
                        }
                        else {
                            return [2 /*return*/, {}];
                        }
                        routedata = { uid: this.uid, };
                        datalist = [];
                        memberlist = function () {
                            return new Promise(function (memberresolve) {
                                _this.apiService.getMember(routedata)
                                    .subscribe(function (member) {
                                    if (member.length > 0) {
                                        memberresolve(member[0].id);
                                    }
                                });
                            });
                        };
                        return [4 /*yield*/, memberlist()];
                    case 1:
                        memberid = _a.sent();
                        console.log('queryObj', queryObj);
                        paymentparam = 'member';
                        queryObj[paymentparam] = memberid;
                        paymentlist = function () {
                            return new Promise(function (payresolve) {
                                _this.apiService.getPayment(queryObj)
                                    .subscribe(function (pay) {
                                    payresolve(pay);
                                });
                            });
                        };
                        return [4 /*yield*/, paymentlist()];
                    case 2:
                        payment = _a.sent();
                        if (payment && payment.length > 0) {
                            payment.forEach(function (pay) {
                                if (pay.member.id === memberid) {
                                    datalist.push(pay);
                                }
                            });
                        }
                        pjid = payment.map(function (paydoc) {
                            return paydoc.project.id;
                        });
                        mmid = payment.map(function (paydoc) {
                            return paydoc.member.id;
                        });
                        entryparams = {
                            member: memberid,
                            project_in: pjid
                        };
                        entryList = function () {
                            return new Promise(function (payresolve) {
                                _this.apiService.getEntries(entryparams)
                                    .subscribe(function (rp) {
                                    payresolve(rp);
                                });
                            });
                        };
                        return [4 /*yield*/, entryList()];
                    case 3:
                        entry = _a.sent();
                        entry.map(function (rp) {
                            payment.map(function (pay) {
                                var key = 'reportedAt';
                                if (rp.project.id === pay.project.id && rp.member.id === pay.member.id) {
                                    pay[key] = rp.reportedAt;
                                }
                            });
                        });
                        datalist = payment;
                        if (datalist) {
                            this.payments = datalist;
                            this.pending = false;
                        }
                        this.currencyTotal = this.currencyPipe.transform(this.total, 'JPY');
                        return [2 /*return*/];
                }
            });
        });
    };
    PaymentComponent.prototype.getBase64FromFile = function (file) {
        // tslint:disable-next-line: no-shadowed-variable
        return new Promise(function (resolve, reject) {
            var reader = new FileReader();
            reader.onload = function () { return resolve(reader.result); };
            try {
                reader.readAsDataURL(file);
            }
            catch (err) {
                reject(err);
            }
        });
    };
    PaymentComponent.prototype.getDataURL = function (path) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.get(path, { responseType: 'blob' }).toPromise()];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, this.getBase64FromFile(response)];
                }
            });
        });
    };
    PaymentComponent.prototype.getTableDefinition = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var currentTerm, column, logoData, body, docDefinition;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        currentTerm = moment(this.paymentForm.value.term).startOf('M').format('YYYY年MM月');
                        column = ['完了報告日', '案件名', '金額'];
                        return [4 /*yield*/, this.getDataURL('/assets/icon/happyplus_community_logo_A.png')];
                    case 1:
                        logoData = _a.sent();
                        body = [];
                        docDefinition = {
                            pageSize: 'A4',
                            pageMargins: [80, 30, 80, 30],
                            header: {
                                text: '集英社ハピコミュ',
                                fontSize: 8,
                                alignment: 'right',
                                margin: [0, 10, 30, 0]
                            },
                            content: [
                                {
                                    image: logoData,
                                    width: 180,
                                    margin: [20, 30, 0, 15]
                                },
                                {
                                    text: "\u7A3C\u50CD\u6848\u4EF6\u5B9F\u7E3E",
                                    bold: true,
                                    fontSize: 10,
                                    margin: [0, 0, 0, 24]
                                },
                                {
                                    text: currentTerm,
                                    alignment: 'left',
                                },
                                {
                                    text: this.currencyPipe.transform(this.total, 'JPY'),
                                    style: 'total',
                                    margin: [0, 0, 0, 12],
                                },
                                {
                                    style: 'table',
                                    table: {
                                        widths: [60, '*', 80],
                                        headerRows: 1,
                                        body: body,
                                    }
                                }
                            ],
                            styles: {
                                tableHeader: {
                                    bold: false,
                                    fillColor: '#f2f5fc',
                                },
                                total: {
                                    bold: true,
                                    fontSize: 18,
                                    alignment: 'right',
                                }
                            },
                            defaultStyle: {
                                fontSize: 10,
                                lineHeight: 1,
                                alignment: 'center',
                                font: 'GenShinGothic',
                                columnGap: 30
                            }
                        };
                        body.push(column.map(function (text, i) {
                            return {
                                text: text,
                                style: 'tableHeader'
                            };
                        }));
                        this.payments.forEach(function (payment) {
                            body.push([
                                payment.reportedAt ? moment(payment.reportedAt).format('YYYY/MM/DD') : '-',
                                payment.project.projectName,
                                _this.currencyPipe.transform(payment.amount || 0, payment.currency)
                            ]);
                        });
                        return [2 /*return*/, docDefinition];
                }
            });
        });
    };
    PaymentComponent.prototype.downloadPdf = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var dialog, headers, docDefinition, termStr, projectID, apiEndpoint, endPoint;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        dialog = this.dialog.openLoadingDialog({
                            data: {
                                text: 'PDFを作成しています...',
                            },
                            disableClose: true
                        });
                        headers = new HttpHeaders().append('Content-Type', 'application/json');
                        return [4 /*yield*/, this.getTableDefinition()];
                    case 1:
                        docDefinition = _a.sent();
                        termStr = moment(this.paymentForm.value.term).startOf('M').format('YYYY年MM月');
                        projectID = '';
                        if (!environment.production) {
                            projectID = 'dott-matching-develop';
                        }
                        else if (environment.production && environment.envChip) {
                            projectID = 'dott-matching-staging';
                        }
                        else {
                            projectID = 'dott-matching-production';
                        }
                        apiEndpoint = "https://asia-northeast1-" + projectID + ".cloudfunctions.net/api";
                        endPoint = new URL('apiNode', apiEndpoint);
                        this.http.post(endPoint + "/tools/pdf", docDefinition, { headers: headers, responseType: 'arraybuffer' })
                            .subscribe(function (data) {
                            var blob = new Blob([data], { type: 'application/pdf' });
                            var link = document.createElement('a');
                            link.setAttribute('href', window.URL.createObjectURL(blob));
                            link.setAttribute('download', "\u6848\u4EF6\u7A3C\u50CD\u5B9F\u7E3E_" + termStr + ".pdf");
                            link.click();
                            link.remove();
                            dialog.close();
                        }, function (err) {
                            dialog.close();
                            console.error(err);
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    PaymentComponent.prototype.log = function (v) {
        console.log(v);
    };
    return PaymentComponent;
}());
export { PaymentComponent };
