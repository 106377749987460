<mat-sidenav-container>
  <mat-sidenav
    #sidenav
    mode="over"
    (closedStart)="getActiveTabIndex(router.url)"
    (openedStart)="activeTabIndex = 3">
    <app-side-nav></app-side-nav>
  </mat-sidenav>
  <mat-sidenav-content>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
<nav class="main-nav-tab">
  <!-- 案件情報 -->
  <div
    class="tab-button"
    [ngClass]="{'tab-active': activeTabIndex === 0}">
    <button
      mat-flat-button
      disableRipple
      [routerLink]="[path.feed.root | rootPathPrefix]">
      <div class="main-tab-menu-icon tab-icon">
        <!-- フィードアイコン -->
        <mat-icon svgIcon="feed"></mat-icon>
      </div>
      <span class="ruby">案件情報</span>
    </button>
  </div>
  <!-- お知らせ -->
  <div
    class="tab-button"
    [ngClass]="{'tab-active': activeTabIndex === 1}">
    <button
      mat-flat-button
      disableRipple
      [routerLink]="[path.notification.list | rootPathPrefix]">
      <div class="main-tab-menu-icon tab-icon" [ngClass]="{ 'notification-badge': notificationSevice.unreadLatest }">
        <!-- ベルアイコン -->
        <mat-icon svgIcon="notification"></mat-icon>
      </div>
      <span class="ruby">お知らせ</span>
    </button>
  </div>
  <!-- 案件稼働実績 -->
  <div
    class="tab-button"
    [ngClass]="{'tab-active': activeTabIndex === 2}">
    <button
      mat-flat-button
      disableRipple
      [routerLink]="[path.payment.detailBase | rootPathPrefix]"
      [queryParams]="{term: currentTerm()}">
      <div class="main-tab-menu-icon tab-icon">
        <!-- 支払いアイコン -->
        <mat-icon svgIcon="payment"></mat-icon>
      </div>
      <span class="ruby">案件稼働実績</span>
    </button>
  </div>
  <!-- 設定 -->
  <div
    class="tab-button"
    [ngClass]="{'tab-active': activeTabIndex === 3}">
    <button
      mat-flat-button
      disableRipple
      (click)="sidenav.toggle()">
      <div class="main-tab-menu-icon">
        <!-- 設定アイコン -->
        <mat-icon svgIcon="settings"></mat-icon>
      </div>
      <span class="ruby">設定</span>
    </button>
  </div>
</nav>
