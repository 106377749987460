import * as tslib_1 from "tslib";
import { assign, cloneDeep, pick, isEqual } from 'lodash';
import { of } from 'rxjs';
import { map, take, switchMap, delayWhen } from 'rxjs/operators';
import { Path } from '@lu/path';
import { environment } from '@lu/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/router";
import * as i3 from "@angular/fire/auth";
import * as i4 from "../services/dialog.service";
var EmailVerificationGuard = /** @class */ (function () {
    function EmailVerificationGuard(http, router, aRoute, afAuth, dialog) {
        this.http = http;
        this.router = router;
        this.aRoute = aRoute;
        this.afAuth = afAuth;
        this.dialog = dialog;
    }
    EmailVerificationGuard.prototype.canActivateChild = function (next, state) {
        var _this = this;
        return this.afAuth.authState.pipe(take(1), 
        // ルーティングの関係でここで呼ぶ
        delayWhen(function () { return _this.overwriteEnvValues(); }), switchMap(function (user) {
            if (user.emailVerified) {
                return of(true);
            }
            else {
                var dalog = _this.dialog.openConfirmDialog({
                    disableClose: true,
                    data: {
                        text: 'メールアドレスが認証されていません。登録アドレスに認証メールを送信しますか？',
                        applyText: '送信',
                    }
                });
                return dalog.afterClosed()
                    .pipe(map(function (send) {
                    if (send) {
                        var redirectTo = 'member';
                        _this.http.post(environment.apiEndpoint + "/auth/action/request/verifyemail", { redirectTo: redirectTo })
                            .subscribe(function () { }, function (err) { return console.error(err); });
                    }
                    _this.router.navigate(["/" + Path.auth.login]);
                    return false;
                }));
            }
        }));
    };
    EmailVerificationGuard.prototype.overwriteEnvValues = function () {
        // Overwrite environment values only when develop and emulate in local.
        if (!environment.firebaseConfig.projectId.match(/^dott-lu-(dev|ci)$/)) {
            return of(null);
        }
        // ActivatedRouteが呼ばれるタイミングで動いてないため
        // https://qiita.com/ttiger55/items/22e0f676ff6101336eaf
        var queryParams = window.location.search
            .substring(1).split('&').map(function (p) { return p.split('='); })
            .reduce(function (obj, e) {
            var _a;
            return (tslib_1.__assign({}, obj, (_a = {}, _a[e[0]] = e[1], _a)));
        }, {});
        var values = pick(queryParams, Object.keys(environment));
        var after = assign(cloneDeep(environment), values);
        if (!Object.keys(values).length || isEqual(environment, after)) {
            return of(null);
        }
        assign(environment, values);
        return this.dialog.openTextDialog({
            autoFocus: false,
            data: { text: '環境設定値を上書きしました（開発環境のみ有効）' }
        }).afterClosed();
    };
    EmailVerificationGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EmailVerificationGuard_Factory() { return new EmailVerificationGuard(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i2.ActivatedRoute), i0.ɵɵinject(i3.AngularFireAuth), i0.ɵɵinject(i4.DialogService)); }, token: EmailVerificationGuard, providedIn: "root" });
    return EmailVerificationGuard;
}());
export { EmailVerificationGuard };
