/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./text-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "@angular/material/dialog";
import * as i7 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i8 from "@angular/material/icon";
import * as i9 from "./text-dialog.component";
var styles_TextDialogComponent = [i0.styles];
var RenderType_TextDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TextDialogComponent, data: {} });
export { RenderType_TextDialogComponent as RenderType_TextDialogComponent };
export function View_TextDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "span", [["class", "dialog-text"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "button", [["class", "button-close-dialog"], ["disableRipple", ""], ["mat-dialog-close", ""], ["mat-icon-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0], [1, "type", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).dialogRef.close(i1.ɵnov(_v, 3).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(2, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], { disableRipple: [0, "disableRipple"] }, null), i1.ɵdid(3, 606208, null, 0, i6.MatDialogClose, [[2, i6.MatDialogRef], i1.ElementRef, i6.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵeld(4, 0, null, 0, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i7.View_MatIcon_0, i7.RenderType_MatIcon)), i1.ɵdid(5, 9158656, null, 0, i8.MatIcon, [i1.ElementRef, i8.MatIconRegistry, [8, null], [2, i8.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["close"]))], function (_ck, _v) { var currVal_5 = ""; _ck(_v, 2, 0, currVal_5); var currVal_6 = ""; _ck(_v, 3, 0, currVal_6); _ck(_v, 5, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.text; _ck(_v, 0, 0, currVal_0); var currVal_1 = (i1.ɵnov(_v, 2).disabled || null); var currVal_2 = (i1.ɵnov(_v, 2)._animationMode === "NoopAnimations"); var currVal_3 = (i1.ɵnov(_v, 3).ariaLabel || null); var currVal_4 = i1.ɵnov(_v, 3).type; _ck(_v, 1, 0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_7 = i1.ɵnov(_v, 5).inline; var currVal_8 = (((i1.ɵnov(_v, 5).color !== "primary") && (i1.ɵnov(_v, 5).color !== "accent")) && (i1.ɵnov(_v, 5).color !== "warn")); _ck(_v, 4, 0, currVal_7, currVal_8); }); }
export function View_TextDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-text-dialog", [], null, null, null, View_TextDialogComponent_0, RenderType_TextDialogComponent)), i1.ɵdid(1, 114688, null, 0, i9.TextDialogComponent, [i6.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TextDialogComponentNgFactory = i1.ɵccf("app-text-dialog", i9.TextDialogComponent, View_TextDialogComponent_Host_0, {}, {}, []);
export { TextDialogComponentNgFactory as TextDialogComponentNgFactory };
