import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';

import { from } from 'rxjs';

import { Path } from '@lu/path';
import { environment } from '@lu/environment';
import { AuthService } from '@lu/services/auth.service';

@Component({
  selector: 'app-update-email',
  templateUrl: './update-email.component.html',
  styleUrls: ['./update-email.component.scss']
})
export class UpdateEmailComponent implements OnInit {
  public authForm = new FormGroup({
    email: new FormControl(null, Validators.required),
  });
  public readonly path = Path;
  public pending = false;
  public completed = false;
  public authError: {
    status: number;
    code: string;
    error: any;
    message: string;
  } = null;

  constructor(
    private router: Router,
    private aRote: ActivatedRoute,
    private http: HttpClient,
    private authService: AuthService,
  ) { }

  ngOnInit() {
    const { code } = this.aRote.snapshot.queryParams;
    if (!code) {
      this.router.navigateByUrl('/');
      return;
    }
    this.updateEmail(code);
  }

  updateEmail(uid: string) {
    const code = uid.toString();
    this.pending = true;
    const fn = () => {
      if (!this.pending && this.completed) {
        clearInterval(checkPandingStatus);
      }
    };
    const checkPandingStatus = setInterval(fn, 3000);
    this.http.post(`${environment.apiEndpoint}/auth/action/update/email`, { code }, { responseType: 'text' })
      .subscribe((customToken: any) => {
        // 重複送信防止のため意図的に pending はそのままにする
        this.completed = true;
        console.log('update email is complete', customToken);
        from(this.authService.signInWithCustomtoken(String(customToken)))
          // update auth info in Firestore.
          .subscribe(() => {
            console.log('signInWithCustomtoken');
            this.pending = false;
            this.completed = true;
          }, err => {
            console.error(err);
            clearInterval(checkPandingStatus);
            this.authError = { status: 200, code: 'auth/document-update-dailed', error: err, message: '' };
            this.authError.message = 'メールアドレスの更新は完了しましたが、プロフィールを更新できませんでした。';
          });
      }, (err: HttpErrorResponse) => {
        const { status, message: authCode } = err;
        console.error(err);
        clearInterval(checkPandingStatus);
        this.pending = false;
        this.authError = { status, code: authCode, error: err, message: '' };
        switch (err.status) {
          case 400:
            this.authError.message = '認証コードの有効期限が切れています。';
            break;
          default:
            this.authError.message = 'メールアドレスを更新できませんでした。';
        }
      });
  }
}
