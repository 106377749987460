/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./loading-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/progress-bar/typings/index.ngfactory";
import * as i3 from "@angular/material/progress-bar";
import * as i4 from "@angular/platform-browser/animations";
import * as i5 from "@angular/common";
import * as i6 from "./loading-dialog.component";
import * as i7 from "@angular/material/dialog";
var styles_LoadingDialogComponent = [i0.styles];
var RenderType_LoadingDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoadingDialogComponent, data: {} });
export { RenderType_LoadingDialogComponent as RenderType_LoadingDialogComponent };
function View_LoadingDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "dialog-content dialog-text-content"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", "\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.text; _ck(_v, 1, 0, currVal_0); }); }
function View_LoadingDialogComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "dialog-content"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.html; _ck(_v, 0, 0, currVal_0); }); }
function View_LoadingDialogComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-progress-bar", [["aria-valuemax", "100"], ["aria-valuemin", "0"], ["class", "mat-progress-bar"], ["role", "progressbar"]], [[1, "aria-valuenow", 0], [1, "mode", 0], [2, "_mat-animation-noopable", null]], null, null, i2.View_MatProgressBar_0, i2.RenderType_MatProgressBar)), i1.ɵdid(1, 4374528, null, 0, i3.MatProgressBar, [i1.ElementRef, i1.NgZone, [2, i4.ANIMATION_MODULE_TYPE], [2, i3.MAT_PROGRESS_BAR_LOCATION]], { color: [0, "color"], value: [1, "value"], bufferValue: [2, "bufferValue"], mode: [3, "mode"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = (((_co.data == null) ? null : _co.data.color) || "primary"); var currVal_4 = ((_co.data == null) ? null : _co.data.value); var currVal_5 = ((_co.data == null) ? null : _co.data.bufferValue); var currVal_6 = (((_co.data == null) ? null : _co.data.mode) || "query"); _ck(_v, 1, 0, currVal_3, currVal_4, currVal_5, currVal_6); }, function (_ck, _v) { var currVal_0 = (((i1.ɵnov(_v, 1).mode === "indeterminate") || (i1.ɵnov(_v, 1).mode === "query")) ? null : i1.ɵnov(_v, 1).value); var currVal_1 = i1.ɵnov(_v, 1).mode; var currVal_2 = i1.ɵnov(_v, 1)._isNoopAnimation; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_LoadingDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_LoadingDialogComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LoadingDialogComponent_2)), i1.ɵdid(3, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LoadingDialogComponent_3)), i1.ɵdid(5, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.data == null) ? null : _co.data.text); _ck(_v, 1, 0, currVal_0); var currVal_1 = ((_co.data == null) ? null : _co.data.html); _ck(_v, 3, 0, currVal_1); var currVal_2 = !((_co.data == null) ? null : _co.data.hiddenBar); _ck(_v, 5, 0, currVal_2); }, null); }
export function View_LoadingDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-loading-dialog", [], null, null, null, View_LoadingDialogComponent_0, RenderType_LoadingDialogComponent)), i1.ɵdid(1, 114688, null, 0, i6.LoadingDialogComponent, [i7.MatDialogRef, i7.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoadingDialogComponentNgFactory = i1.ɵccf("app-loading-dialog", i6.LoadingDialogComponent, View_LoadingDialogComponent_Host_0, {}, {}, []);
export { LoadingDialogComponentNgFactory as LoadingDialogComponentNgFactory };
