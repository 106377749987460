import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';

import { AuthAction } from '@lu/auth-action';
import { Path } from '@lu/path';
import { environment } from '@lu/environment';

@Component({
  selector: 'app-send-reset-password-mail',
  templateUrl: './send-reset-password-mail.component.html',
  styleUrls: ['./send-reset-password-mail.component.scss']
})
export class SendResetPasswordMailComponent implements OnInit {
  public requestForm = new FormGroup({
    email: new FormControl(null, [
      Validators.required,
      Validators.email,
      Validators.pattern(/@{1}([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/),
    ]),
  });
  public completed = false;
  public pending = false;
  public requestError: {
    status: number;
    code: string;
    message: string;
    error: any;
  } = null;
  public authAction = AuthAction;
  public mode;
  public readonly path = Path;


  constructor(
    private afAuth: AngularFireAuth,
    private aRoute: ActivatedRoute,
    private http: HttpClient,
  ) { }

  ngOnInit() {
    this.mode = this.aRoute.snapshot.queryParams.mode;
    if (this.mode === this.authAction.sendSetPasswordMail
      && this.afAuth.auth.currentUser) {
      const { email } = this.afAuth.auth.currentUser;
      // this.requestForm.patchValue({email});
    }
  }

  async sendResetPasswordMail() {
    const { email } = this.requestForm.value;
    const redirectTo = 'member';
    localStorage.setItem('passwordResetRequestedMail', email);

    this.pending = true;
    await this.http.post(`${environment.apiEndpoint}/auth/action/request/resetpassword`, { email, redirectTo })
      .subscribe(() => {
        this.completed = true;
      }, (err: HttpErrorResponse) => {
        const { status, message: code } = err;
        console.error(err);
        this.pending = false;
        this.requestError = { status, code, error: err, message: '' };
        switch (err.status) {
          default:
            this.requestError.message = 'メールを送信できませんでした。';
        }
      });
  }
}
