<mat-toolbar class="app-header">
  <app-route-progress></app-route-progress>
  <button
    mat-icon-button
    color="primary"
    (click)="locationService.backWithAppState()">
    <mat-icon>arrow_back</mat-icon>
  </button>
  <h2 class="page-title">サービスポリシー</h2>
  <div class="right-suffix"></div>
</mat-toolbar>

<article class="page-content article">
  <h2 class="article-title">
    サービスポリシー
    <br /><span class="article-timestamp">最終更新日: 2017年10月25日</span>
  </h2>
  <h3 class="section-title">サービスポリシー</h3>
  <section
    class="section"
    [innerHTML]="'本サービス内で入手できるすべてのポリシーを遵守してください。

    本サービスを不正に利用しないでください。たとえば、本サービスの妨害や、Google が提供するインターフェースおよび手順以外の方法による本サービスへのアクセスを試みてはなりません。ユーザーは、法律（輸出、再輸出に関して適用される法規制を含みます）で認められている場合に限り、本サービスを利用することができます。ユーザーが Google の規約やポリシーを遵守しない場合、または Google が不正行為と疑う行為について調査を行う場合に、Google はユーザーに対する本サービスの提供を一時停止または停止することができます。

    ユーザーは、本サービスを利用することによって、本サービスまたはアクセスするコンテンツに対するいかなる知的財産権も取得することはありません。ユーザーは、本サービスのコンテンツの所有者から許可を得た場合や、法律によって認められる場合を除き、そのコンテンツを利用することはできません。本規約は、本サービスで使用されている、いかなるブランドまたはロゴを利用する権利もユーザーに与えるものではありません。本サービス内に表示される、または、本サービスに伴って表示されるいかなる法的通知も、削除したり、隠したり、改ざんしてはなりません。

    本サービスで表示されるコンテンツの一部は、Google の所有物ではありません。こうしたコンテンツについては、そのコンテンツを提供する当事者が単独で責任を負います。Google は、コンテンツが違法か否か、または Google のポリシーに違反しているか否かを判断するために、コンテンツをレビューすることができます。さらに、Google は、そのポリシーまたは法律に違反していると合理的に判断したコンテンツを削除したり、その表示を拒否することができます。ただし、このことは、Google がコンテンツをレビューしていることを必ずしも意味するものではありません。Google がコンテンツをレビューしていると想定しないでください。

    本サービスの利用に関して、Google はユーザーに対してサービスの告知、管理上のメッセージ、およびその他の情報を送信することができます。ユーザーは、これらの通知について、受け取らないことを選択できる場合があります。

    本サービスの一部は携帯端末でご利用いただけます。注意散漫になり交通や安全に関する法律を遵守できなくなる状態で本サービスを利用しないでください。'
    | breakLine">
  </section>
</article>