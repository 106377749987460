import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Path } from '@lu/path';
var ResetPasswordComponent = /** @class */ (function () {
    function ResetPasswordComponent(aRoute, afAuth) {
        this.aRoute = aRoute;
        this.afAuth = afAuth;
        this.resetPasswordForm = new FormGroup({
            email: new FormControl(null, Validators.required),
            password: new FormControl(null, [Validators.required, Validators.minLength(6)]),
            confirmedPassword: new FormControl(null, [Validators.required, Validators.minLength(6)]),
        });
        this.authError = null;
        this.path = Path;
        this.codeVerified = false;
        this.pending = false;
        this.completed = false;
    }
    ResetPasswordComponent.prototype.ngOnInit = function () {
        var _this = this;
        var oobCode = this.aRoute.snapshot.queryParams.oobCode;
        this.verifyPasswordResetCode(oobCode)
            .then(function (email) { return _this.resetPasswordForm.patchValue({ email: email }); });
    };
    ResetPasswordComponent.prototype.checkButtonIsDisabled = function () {
        var resetFormValue = this.resetPasswordForm.value;
        if (resetFormValue.password && resetFormValue.confirmedPassword
            && resetFormValue.password === resetFormValue.confirmedPassword
            && this.resetPasswordForm.valid) {
            return false;
        }
        else {
            return true;
        }
    };
    ResetPasswordComponent.prototype.verifyPasswordResetCode = function (code) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var email, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.afAuth.auth.verifyPasswordResetCode(code)];
                    case 1:
                        email = _a.sent();
                        this.codeVerified = true;
                        return [2 /*return*/, email];
                    case 2:
                        err_1 = _a.sent();
                        console.error(err_1);
                        switch (err_1.code) {
                            case 'auth/expired-action-code':
                                this.authError = { status: 400, code: err_1.code, message: '', error: err_1 };
                                this.authError.message = '認証コード有効期限が切れています。';
                                break;
                            default:
                                this.authError = { status: 400, code: err_1.code, message: '', error: err_1 };
                                this.authError.message = '無効な認証コードです。';
                        }
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ResetPasswordComponent.prototype.resetPasswordAndSignInDirectly = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var oobCode, _a, email, newPassword, err_2;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        oobCode = this.aRoute.snapshot.queryParams.oobCode;
                        _a = this.resetPasswordForm.value, email = _a.email, newPassword = _a.password;
                        this.pending = true;
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, this.afAuth.auth.confirmPasswordReset(oobCode, newPassword)];
                    case 2:
                        _b.sent();
                        this.completed = true;
                        // sign-in the user directly because the page belongs to the same domain as the app.
                        this.afAuth.auth.signInWithEmailAndPassword(email, newPassword)
                            .catch(function (err) { return console.error(err); });
                        return [3 /*break*/, 5];
                    case 3:
                        err_2 = _b.sent();
                        console.error(err_2);
                        switch (err_2.code) {
                            default:
                                this.authError = { status: 400, code: err_2.code, message: '', error: err_2 };
                                this.authError.message = 'パスワードを更新できませんでした。';
                        }
                        return [3 /*break*/, 5];
                    case 4:
                        this.pending = false;
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    return ResetPasswordComponent;
}());
export { ResetPasswordComponent };
