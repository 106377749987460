<div
  *ngIf="data?.text"
  class="dialog-content dialog-text-content">
  {{data.text}}
</div>

<div
  *ngIf="data?.html"
  class="dialog-content"
  [innerHTML]="data.html"></div>

<mat-progress-bar
  *ngIf="!data?.hiddenBar"
  [mode]="data?.mode || 'query'"
  [color]="data?.color || 'primary'"
  [value]="data?.value"
  [bufferValue]="data?.bufferValue"></mat-progress-bar>
