/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./not-found.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../components/search-header/search-header.component.ngfactory";
import * as i3 from "../../components/search-header/search-header.component";
import * as i4 from "./not-found.component";
var styles_NotFoundComponent = [i0.styles];
var RenderType_NotFoundComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NotFoundComponent, data: {} });
export { RenderType_NotFoundComponent as RenderType_NotFoundComponent };
export function View_NotFoundComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-search-header", [], [[2, "search-field-focus", null]], null, null, i2.View_SearchHeaderComponent_0, i2.RenderType_SearchHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.SearchHeaderComponent, [], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "page-content-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h3", [["class", "text-oops"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["OOPS!"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u3053\u306E\u30DA\u30FC\u30B8\u306F\u3054\u5229\u7528\u3044\u305F\u3060\u3051\u307E\u305B\u3093\u3002"])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["class", "sub-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u30EA\u30F3\u30AF\u306B\u554F\u984C\u304C\u3042\u308B\u304B\u3001\u30DA\u30FC\u30B8\u304C\u524A\u9664\u3055\u308C\u305F\u53EF\u80FD\u6027\u304C\u3042\u308A\u307E\u3059\u3002"]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).searchFocused; _ck(_v, 0, 0, currVal_0); }); }
export function View_NotFoundComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-not-found", [], null, null, null, View_NotFoundComponent_0, RenderType_NotFoundComponent)), i1.ɵdid(1, 114688, null, 0, i4.NotFoundComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NotFoundComponentNgFactory = i1.ɵccf("app-not-found", i4.NotFoundComponent, View_NotFoundComponent_Host_0, {}, {}, []);
export { NotFoundComponentNgFactory as NotFoundComponentNgFactory };
