import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

export interface ConfirmDialogComponentData {
  text?: string;
  applyText?: string;
  cancelText?: string;
  cancel?: boolean;
  apply?: boolean;
}

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogComponentData,
  ) {
    if (!this.data) {
      this.data = {};
    }
    if (typeof this.data.cancel !== 'boolean') {
      this.data.cancel = true;
    }
    if (typeof this.data.apply !== 'boolean') {
      this.data.apply = true;
    }
  }

  ngOnInit() {
  }
}
