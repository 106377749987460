<mat-toolbar class="app-header app-fixed-header">
  <app-route-progress></app-route-progress>
  <button
    mat-icon-button
    color="primary"
    (click)="locationService.backWithAppState()">
    <mat-icon>arrow_back</mat-icon>
  </button>
  <h2 class="page-title">{{order.projectName}}</h2>
  <div class="right-suffix"></div>
</mat-toolbar>


<div class="page-content">
  <form
    class="order-entry-form"
    [formGroup]="entryForm">
    <div class="order-image">
      <div
        class="order-image-container"
        [style.backgroundSize]="hasImage(order) ? 'cover' : null"
        [style.backgroundPosition]="hasImage(order) ? 'center center' : null"
        [style.backgroundImage]="hasImage(order) ? 'url('+(getImage(order) | async)+')' : null"></div>
    </div>
    <!-- 案件名 -->
    <div class="order-head">
      <app-order-favorite [orderId]="order.id"></app-order-favorite>
      <app-order-status-chip
        [order]="order"
        [entry]="entry"
        [report]="report"></app-order-status-chip>
      <h3 class="order-name">{{order.projectName}}</h3>
    </div>
    <!-- エントリー締切 -->
    <div class="order-detail">
      <div class="order-detail-inner">
        <span class="label">エントリー締切</span>
        <span class="value">{{order.recruitmentEndAt | date:'yyyy/MM/dd HH:mm':'+0900'}}</span>
      </div>
    </div>
    <!-- 本番期間 -->
    <div class="order-detail">
      <div class="order-detail-inner">
        <span class="label">実施期間</span>
        <span
          class="value"
          [innerHTML]="{startAt: order.eventStartAt, endAt: order.eventEndAt} | period:'yyyy/MM/dd':'+0900'"></span>
      </div>
    </div>
    <!-- クライアント名 -->
    <div
      *ngIf="order.client?.clientName"
      class="order-detail">
      <div class="order-detail-inner">
        <span class="label">クライアント名</span>
        <span class="value">{{order.client.clientName}}</span>
      </div>
    </div>
    <!-- アンケートURL -->
    <div
      *ngIf="order.questionnaireURL"
      class="order-detail">
      <div class="order-detail-inner">
        <span class="label">アンケートURL</span>
        <a
          class="value"
          target="_blank"
          rel="noopener noreferrer"
          [href]="order.questionnaireURL">{{order.questionnaireURL}}</a>
      </div>
    </div>
    <!-- ブランド名 -->
    <div
      *ngIf="brand"
      class="order-detail">
      <div class="order-detail-inner">
        <span class="label">ブランド名</span>
        <span class="value">{{brand.name ? brand.name : '-'}}</span>
      </div>
    </div>
    <!-- 謝礼 -->
    <div
      *ngIf="reward?.amount"
      class="order-detail">
      <div class="order-detail-inner">
        <span class="label">謝礼</span>
        <span class="value">{{
          reward?.amount ? (reward.amount | currency:'JPY') : '報酬なし'
        }}</span>
      </div>
    </div>
    <!-- アンケートURL -->
    <div
      *ngIf="publicConfidential?.data?.questionnaire.value"
      class="order-detail">
      <div class="order-detail-inner">
        <span class="label">{{publicConfidential.data.questionnaire.name}}</span>
        <a
          class="value"
          target="_blank"
          [href]="publicConfidential.data.questionnaire.value">{{publicConfidential.data.questionnaire.value}}</a>
      </div>
    </div>
    <!-- 備考 -->
    <div class="order-detail order-detail-textarea">
      <div class="order-detail-inner">
        <span class="label">内容</span>
        <span class="value">{{order.remarks}}</span>
      </div>
    </div>
    <div
      *ngIf="order.file1 || order.file2"
      class="order-detail order-detail-attempt-files">
      <div class="order-detail-inner">
        <span class="label">添付ファイル</span>
        <a
          *ngIf="order.file1"
          class="value attempt-file"
          [ngClass]="{
            'link-with-open-dialog': isImage(order.file1),
            'link-with-download': !isImage(order.file1)
          }"
          (click)="isImage(order.file1) ?
                  openPreviewDialog(order.file1) :
                  downloadAttemptFile(order.file1);">{{
            order.file1?.name}}&nbsp;({{order.file1?.size
            }})</a>
        <a
          *ngIf="order.file2"
          class="value attempt-file"
          [ngClass]="{
            'link-with-open-dialog': isImage(order.file2),
            'link-with-download': !isImage(order.file2)
          }"
          (click)="isImage(order.file2) ?
                  openPreviewDialog(order.file2) :
                  downloadAttemptFile(order.file2);">{{
            order.file2?.name}}&nbsp;({{order.file2?.size
            }})</a>
      </div>
    </div>
    <!-- Entry -->
    <ng-container
      *ngIf="!pending
          && !entry
          && order.status === orderStatusEnum.Recruiting">
      <button
        *ngIf="!isCompeted()"
        mat-flat-button
        class="app-button-submit"
        color="primary"
        type="submit"
        (click)="entryToOrder()">エントリーする</button>
      <div
        *ngIf="isCompeted()"
        class="exists-competition-message">すでに本決定した他の案件の実施期間と競合するため、この案件にはエントリーできません。</div>
    </ng-container>
    <ng-container
      *ngIf="!pending
          && entry
          && entry.status === entryStatusEnum.Entried">
      <button
        mat-flat-button
        class="app-button-submit"
        color="primary"
        type="submit"
        (click)="cancelEntry()">エントリーをキャンセルする</button>
      <div
        *ngIf="isCompeted()"
        class="exists-competition-message">すでに本決定した他の案件の実施期間と競合しているため、この案件のエントリーはキャンセルのみ可能です。</div>
    </ng-container>
    <ng-container
      *ngIf="!pending
          && entry
          && entry.status === entryStatusEnum.Offered">
      <button
        [disabled]="isCompeted()"
        mat-flat-button
        class="app-button-submit"
        color="primary"
        type="submit"
        (click)="approveOffer()">内定したエントリーを確定する</button>
      <button
        mat-flat-button
        class="app-button-submit"
        color="primary"
        type="submit"
        (click)="cancelEntry()">内定したエントリーをキャンセルする</button>
      <div
        *ngIf="isCompeted()"
        class="exists-competition-message">すでに本決定した他の案件の実施期間と競合しているため、この案件のエントリーはキャンセルのみ可能です。</div>
    </ng-container>
    <ng-container
      *ngIf="!pending
          && entry
          && entry.status === entryStatusEnum.Approved">
      <div
        *ngIf="isCompeted()"
        class="exists-competition-message">すでに本決定した他の案件の実施期間と競合しているため、この案件のエントリーは無効になりました。</div>
    </ng-container>
    <!-- Report -->
    <button
      *ngIf="entry?.decidedAt
             && !entry?.reportedAt
             && entry?.status!='canceled'" 
      mat-flat-button
      class="app-button-submit"
      color="primary"
      [routerLink]="[path.order.reportBase | rootPathPrefix, order.id, IdofReward]">完了報告を提出する</button>
    <button
      *ngIf="entry?.decidedAt
             && entry?.reportedAt"
      mat-flat-button
      class="app-button-submit"
      color="primary"
      [routerLink]="[path.order.reportBase | rootPathPrefix, order.id, IdofReward]">提出した報告を確認する</button>
  </form>
</div>

<ng-template
  #previewDialog
  let-data>
  <button
    class="button-close-dialog"
    mat-icon-button
    mat-dialog-close
    disableRipple>
    <mat-icon>close</mat-icon>
  </button>
  <div class="image-container">
    <img
      *ngIf="data.downloadURL"
      [src]="data.downloadURL"
      [alt]="data.file.fileName">
    <mat-progress-spinner
      *ngIf="!data.downloadURL"
      mode="indeterminate"
      color="primary"></mat-progress-spinner>
  </div>
  <button
    class="button-download"
    mat-flat-button
    (click)="downloadAttemptFile(data.file)">
    <mat-icon>vertical_align_bottom</mat-icon>ダウンロード
  </button>
</ng-template>