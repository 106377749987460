/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./side-nav.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../pipes/root-path-prefix.pipe";
import * as i3 from "@angular/router";
import * as i4 from "@angular/common";
import * as i5 from "./side-nav.component";
import * as i6 from "@angular/fire/auth";
var styles_SideNavComponent = [i0.styles];
var RenderType_SideNavComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SideNavComponent, data: {} });
export { RenderType_SideNavComponent as RenderType_SideNavComponent };
export function View_SideNavComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.RootPathPrefixPipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 19, "nav", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "h1", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 16384, null, 0, i3.RouterLink, [i3.Router, i3.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵppd(4, 1), i1.ɵpad(5, 1), (_l()(), i1.ɵeld(6, 0, null, null, 0, "img", [["alt", "SHUEISHA \u30CF\u30C3\u30D4\u30FC\u30D7\u30E9\u30B9 COMMUNITY"], ["class", "logo"], ["src", "assets/icon/happyplus_community_logo_A.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 4, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i4.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵppd(9, 1), i1.ɵpad(10, 1), (_l()(), i1.ɵted(-1, null, ["\u30D7\u30ED\u30D5\u30A3\u30FC\u30EB"])), (_l()(), i1.ɵeld(12, 0, null, null, 4, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 13).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(13, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i4.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵppd(14, 1), i1.ɵpad(15, 1), (_l()(), i1.ɵted(-1, null, ["\u30E1\u30F3\u30D0\u30FC\u898F\u7D04"])), (_l()(), i1.ɵeld(17, 0, null, null, 1, "a", [["href", "https://www2.shueisha.co.jp/privacy/privacy.html"], ["rel", "noopener noreferrer"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u96C6\u82F1\u793E\u30D7\u30E9\u30A4\u30D0\u30B7\u30FC\u30AC\u30A4\u30C9\u30E9\u30A4\u30F3"])), (_l()(), i1.ɵeld(19, 0, null, null, 1, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.signOut() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\u30ED\u30B0\u30A2\u30A6\u30C8"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 5, 0, i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v, 0), _co.path.feed.root))); _ck(_v, 3, 0, currVal_0); var currVal_3 = _ck(_v, 10, 0, i1.ɵunv(_v, 8, 0, _ck(_v, 9, 0, i1.ɵnov(_v, 0), _co.path.profile.root))); _ck(_v, 8, 0, currVal_3); var currVal_6 = _ck(_v, 15, 0, i1.ɵunv(_v, 13, 0, _ck(_v, 14, 0, i1.ɵnov(_v, 0), _co.path.terms.root))); _ck(_v, 13, 0, currVal_6); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 8).target; var currVal_2 = i1.ɵnov(_v, 8).href; _ck(_v, 7, 0, currVal_1, currVal_2); var currVal_4 = i1.ɵnov(_v, 13).target; var currVal_5 = i1.ɵnov(_v, 13).href; _ck(_v, 12, 0, currVal_4, currVal_5); }); }
export function View_SideNavComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-side-nav", [], null, null, null, View_SideNavComponent_0, RenderType_SideNavComponent)), i1.ɵdid(1, 114688, null, 0, i5.SideNavComponent, [i3.Router, i6.AngularFireAuth], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SideNavComponentNgFactory = i1.ɵccf("app-side-nav", i5.SideNavComponent, View_SideNavComponent_Host_0, {}, {}, []);
export { SideNavComponentNgFactory as SideNavComponentNgFactory };
