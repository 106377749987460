import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { take, map } from 'rxjs/operators';
import { firestore, auth } from 'firebase/app';
import { cloneDeepWith } from 'lodash';
import { Address } from '@lu/models';

const timestampConverter = (o) => o instanceof firestore.Timestamp ? o.toDate() : undefined;

@Injectable()
export class OwnAddressResolver implements Resolve<Address> {
 constructor(
    private afStore: AngularFirestore,
  ) {}

 resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    const {uid} = auth().currentUser;
    return this.afStore.collection<Address>(`members/${uid}/addresses`, ref => ref.limit(1))
      .valueChanges({idField: '_id'})
      .pipe(
        take(1),
        map(results => results.length ? results[0] : null),
        map(address => cloneDeepWith(address, timestampConverter) as Address)
      );
  }
}
