import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';

import { MatchingService } from '@lu/services/matching.service';

import { firestore, auth } from 'firebase/app';
import { of } from 'rxjs';
import {
  take,
  tap,
  skip,
  catchError,
  switchMap,
  filter,
} from 'rxjs/operators';

import { environment } from '@lu/environment';
import { Member } from '@lu/models';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public signInProvider: 'email' | 'emailpassword' | null = null;

  constructor(
    private router: Router,
    private afAuth: AngularFireAuth,
    private afStore: AngularFirestore,
    private apiService: MatchingService,
  ) {
    this.watchAuthState();
    this.fetchSigninMethod();
    this.afAuth.authState.pipe(
      take(1),
      filter(user => !!user) // 未ログインなら何もしない
    )
    // tslint:disable-next-line: deprecation
    .subscribe(() => {
      this.isMember();
    });
  }

  fetchSigninMethod() {
    this.afAuth.authState
      // tslint:disable-next-line: deprecation
      .subscribe(async user => {
        if (!user || !user.email) {
          this.signInProvider = null;
          return;
        }
        const method = await this.afAuth.auth.fetchSignInMethodsForEmail(user.email);
        if (method.includes(auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD)) {
          this.signInProvider = 'email';
        }
        if (method.includes(auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD)) {
          this.signInProvider = 'emailpassword';
        }
      });
  }

  watchAuthState() {
    this.afAuth.authState
      .pipe(
        tap(user => environment.production ? null : console.log(user)),
        skip(1), // 読み込み直後の1回をスキップ
      )
      // tslint:disable-next-line: deprecation
      .subscribe(user => {
        // ログインしてなくても良いページにいるかどうかをチェック
        // 認証が必要なページにいた場合はログインページに飛ばす
        // console.log(this.router.url);
        if (!user && !this.router.url.startsWith('/auth')) {
          const redirectTo = this.router.url;
          this.router.navigate(['auth/login'], { queryParams: { redirectTo } });
        }
      });
  }

  isMember() {
    this.afAuth.authState
      .pipe(
        take(1),
        switchMap(user =>
          this.apiService.getMember({ uid: user.uid })
            .pipe(take(1))),
        catchError(err => {
          console.error(err);
          return of(null);
        })
      )
      // tslint:disable-next-line: deprecation
      .subscribe(member => {
        if (member.length === 0 || !member) {
          alert('ログイン権限がありません。');
          this.afAuth.auth.signOut();
          this.router.navigate(['auth/login']);
        }
      });
  }

  async login(email: string, password: string) {
    return await this.afAuth.auth.signInWithEmailAndPassword(email, password)
      .catch(err => {
        console.error(err);
        throw new Error('メールアドレスまたはパスワードが違います。');
      });
  }

  async logout() {
    await this.afAuth.auth.signOut()
      .catch(err => {
        console.error(err);
        throw new Error('ログアウトエラー');
      });
  }

  signInWithCustomtoken(token: string) {
    console.log('=====auth.service.signInWithCustomtoken=====')
    return this.afAuth.auth.signInWithCustomToken(token);
  }

  async setCurrentAuth() {
    await this.afAuth.auth.currentUser.getIdToken(true);
    const { uid, email, emailVerified, phoneNumber } = this.afAuth.auth.currentUser;
    const modified = { by: uid, at: firestore.FieldValue.serverTimestamp() as any };
    const [userRef, memberRef] = [
      firestore().doc(`users/${uid}`),
      firestore().doc(`members/${uid}`)
    ];
    await this.afStore.firestore.runTransaction(tx => {
      return tx.get(memberRef).then(snap => {
        const { email1 } = snap.data() as Member;
        // contact.emails.shift();
        // contact.emails.unshift(email);
        // console.log({email, emailVerified, phoneNumber}, {contact});
        tx.update(memberRef, { contaxc: snap.data().contact, modified });
        tx.update(userRef, {
          email,
          emailVerified,
          phoneNumber,
          modified,
        });
        tx.update(memberRef, {
          email1,
        } as Member);
      });
    });
  }
}
