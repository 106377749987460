/**
 * DOCUMENTATION
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact-email@something.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Notification {
    id: string;
    thumbnailURL?: string;
    message?: string;
    path?: string;
    URL?: string;
    type?: Notification.TypeEnum;
    read: boolean;
    member?: object;
}
// tslint:disable-next-line: no-namespace
export namespace Notification {
    export type TypeEnum = 'message' | 'link' | 'path';
    export const TypeEnum = {
        Message: 'message' as TypeEnum,
        Link: 'link' as TypeEnum,
        Path: 'path' as TypeEnum
    };
}


