import * as tslib_1 from "tslib";
import { ElementRef, EventEmitter, OnInit, } from '@angular/core';
import * as _ from 'lodash';
import { forEach } from 'lodash';
var FileChooserComponent = /** @class */ (function () {
    function FileChooserComponent() {
        this.fileChange = new EventEmitter();
        this.dropOver = false;
        this.acceptType = '.jpg, .png, .gif, .jpe, .jpeg, .JPG, .JPEG, .PNG, .GIF, .pdf, .mov, .mpeg, .mpg, .mp4, .webm, .ogg';
        this.acceptFormats = ['png', 'jpg', 'gif', 'pdf', 'mov', 'mpeg', 'mpg',
            'mp4', 'webm', 'ogg', 'jpe', 'jpeg', 'JPG', 'JPEG', 'PNG', 'GIF'];
    }
    FileChooserComponent.prototype.ngOnInit = function () {
    };
    FileChooserComponent.prototype.fileDroped = function (files) {
        if (files.length === 0
            || this.file === files[0]) {
            return;
        }
        // Filter by accept subs
        var file = files[0];
        if (typeof this.accept === 'string') {
            var match_1 = false;
            var accepts = this.accept.split(',').map(function (ac) { return ac.toLocaleLowerCase(); });
            forEach(accepts, function (accept) {
                var wildcard = accept.match(/(^.+\/)\*$/);
                if (wildcard && file.type.match('^' + wildcard[1])) {
                    return match_1 = true;
                }
                if (file.type.match('^' + accept + '$')) {
                    return match_1 = true;
                }
                if (accept === 'image/jpg'
                    && file.type.match(/^image\/jpeg$/)) {
                    return match_1 = true;
                }
            });
            if (!match_1) {
                return;
            }
        }
        this.file = file;
        this.fileChange.emit(this.file);
    };
    FileChooserComponent.prototype.fileSelectChange = function (event) {
        var target = event.target;
        if (target.files.length === 0
            || this.file === target.files[0]) {
            return;
        }
        var fileType = target.files[0].name.split('.').splice(-1)[0];
        var isInclude = _.includes(this.acceptFormats, fileType);
        if (target.files.length > 0 && !isInclude) {
            this.removeFile();
            return alert('フォーマットが異なっています。正しいフォーマットをアップロードしてください。\n[png, jpg, gif, pdf, mov, mpeg, mp4, webm, ogg]');
        }
        this.file = target.files[0];
        this.fileChange.emit(this.file);
    };
    FileChooserComponent.prototype.browseFile = function () {
        this.fileInput.nativeElement.click();
    };
    FileChooserComponent.prototype.removeFile = function () {
        this.file = undefined;
        this.fileInput.nativeElement.value = '';
        this.fileChange.emit(this.file);
    };
    FileChooserComponent.prototype.convertToDataURL = function (file) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var reader;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        reader = new FileReader();
                        return [4 /*yield*/, new Promise(function (resolve, reject) {
                                reader.onload = function () {
                                    resolve(reader.result);
                                };
                                reader.onerror = function () {
                                    reject(reader.error);
                                };
                                reader.readAsDataURL(file);
                            })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    FileChooserComponent.prototype.convertToFile = function (dataURI, fileName) {
        var byteString = atob(dataURI.split(',')[1]);
        var mimeType = dataURI.match(/:([a-z]+\/[a-z]+);/)[1];
        var content = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
            content[i] = byteString.charCodeAt(i);
        }
        return new File([content], fileName, { type: mimeType });
    };
    return FileChooserComponent;
}());
export { FileChooserComponent };
