<mat-toolbar class="app-header app-fixed-header app-flat-header">
  <app-route-progress></app-route-progress>
  <button
    mat-icon-button
    color="primary"
    (click)="locationService.backWithAppState()">
    <mat-icon>arrow_back</mat-icon>
  </button>
</mat-toolbar>

<div class="page-content-wrapper">
  <div class="headline">
    <h1>
      <img
        class="logo"
        alt="SHUEISHA ハッピープラス COMMUNITY"
        src="assets/icon/happyplus_community_logo_A.png">
    </h1>
    <p
      class="headline-text"
      [ngSwitch]="mode">
      <ng-container *ngSwitchCase="authAaction.sendUpdateEmailMail">メールアドレスの変更</ng-container>
      <ng-container *ngSwitchCase="authAaction.sendSetPasswordMail">パスワードを設定する</ng-container>
      <ng-container *ngSwitchCase="authAaction.sendChangePasswordMail">パスワードを変更する</ng-container>
      <ng-container *ngSwitchCase="authAaction.sendResetPasswordMail">パスワードを忘れた場合</ng-container>
      <ng-container *ngSwitchCase="authAaction.resetPassword">パスワードの再設定</ng-container>
      <ng-container *ngSwitchCase="authAaction.updateEmail">メールアドレスの更新</ng-container>
      <ng-container *ngSwitchCase="authAaction.verifyEmail">メールアドレスの認証</ng-container>
    </p>
  </div>

  <div class="auth-form">
    <ng-container *ngIf="formComponent">
      <ng-container *ngComponentOutlet="formComponent"></ng-container>
    </ng-container>

    <div class="link-items">
      <span class="link-item"><a [routerLink]="[path.terms.root | rootPathPrefix]">メンバー規約</a></span>
      <span class="link-item">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www2.shueisha.co.jp/privacy/privacy.html">集英社プライバシーガイドライン</a>
      </span>
    </div>
  </div>
</div>
