import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { take, map } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { firestore, auth } from 'firebase/app';
import { cloneDeepWith } from 'lodash';
import { Member } from '@lu/models';
import { MatchingService } from '@lu/services/matching.service';

// const timestampConverter = (val, key) => {
//   // skip document timestamps, ex. created.at, modified.at...
//   if (key === 'at') {
//     return void 0;
//   }
//   return val instanceof firestore.Timestamp ? val.toDate() : void 0;
// };

@Injectable()
export class OwnselfAsMemberResolver implements Resolve<Member> {
  private members: Member[] = [];

  constructor(
    private apiService: MatchingService
  ) { }

  resolve() {
    const { uid } = auth().currentUser;
    const resolve$ = new Subject<Member[]>();

    // tslint:disable-next-line: object-literal-shorthand
    const routedata = { uid: uid };
    return this.apiService.getMember(routedata)
      .pipe(
        take(1),
        map(order => order as Member)
      );
  }
}
