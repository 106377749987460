import * as tslib_1 from "tslib";
import { OnInit, TemplateRef, OnDestroy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { Subject } from 'rxjs';
import { takeUntil, catchError, switchMap, finalize, take } from 'rxjs/operators';
import { cloneDeepWith, get, find } from 'lodash';
import { Path } from '@lu/path';
import { Entry, BrandMaster, Project, Report, } from '@lu/models';
import * as _ from 'lodash';
import * as moment from 'moment';
var OrderDetailComponent = /** @class */ (function () {
    function OrderDetailComponent(http, router, aRoute, dialogService, afAuth, afStorage, dialog, locationService, apiService) {
        this.http = http;
        this.router = router;
        this.aRoute = aRoute;
        this.dialogService = dialogService;
        this.afAuth = afAuth;
        this.afStorage = afStorage;
        this.dialog = dialog;
        this.locationService = locationService;
        this.apiService = apiService;
        this.order = {};
        this.reward = {};
        this.entryForm = new FormGroup({
            entriedAt: new FormControl(null),
            offeredAt: new FormControl(null),
            offeredBy: new FormControl(null),
            approvedAt: new FormControl(null),
            canceledAt: new FormControl(null),
            decidedAt: new FormControl(null),
            decidedBy: new FormControl(null),
            reportedAt: new FormControl(null),
            completedAt: new FormControl(null),
            completedBy: new FormControl(null),
            status: new FormControl(null),
            member: new FormControl(null),
            project: new FormControl(null),
            reward: new FormControl(null),
            created_by: new FormControl(null),
            updated_by: new FormControl(null),
            created_at: new FormControl(null),
            updated_at: new FormControl(null)
        });
        this.downloadProcessURL = {};
        this.requireReport = false;
        this.orderStatusEnum = Project.StatusEnum;
        this.orderClosedReasonEnum = Project.ClosedStatusEnum;
        this.entryStatusEnum = Entry.StatusEnum;
        this.path = Path;
        this.pending = true;
        this.onDestroy$ = new Subject();
        this.competedOrderList = [];
    }
    OrderDetailComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.aRoute.data.subscribe(function (data) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _a, _b, _c;
            return tslib_1.__generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        this.order = data.order;
                        this.order.reward ? this.reward = this.order.reward : this.reward.id = null;
                        _a = this;
                        return [4 /*yield*/, this.getLoginMember()];
                    case 1:
                        _a.memberId = _d.sent();
                        _b = this;
                        return [4 /*yield*/, this.getOwnEntry(this.order)];
                    case 2:
                        _b.entry = (_d.sent()) || null;
                        _c = this;
                        return [4 /*yield*/, this.getReport(this.order)];
                    case 3:
                        _c.report = (_d.sent()) || null;
                        this.IdofReward = this.reward.id === null ? 0 : this.reward.id;
                        this.publicConfidential = this.order.project_confidential;
                        this.brand = this.order.brand_master;
                        this.subscribeReport(this.order.id, this.reward.id);
                        this.pending = true;
                        this.getCompetitionOrder();
                        return [2 /*return*/];
                }
            });
        }); });
    };
    OrderDetailComponent.prototype.ngOnDestroy = function () {
        this.onDestroy$.next();
    };
    OrderDetailComponent.prototype.getLoginMember = function () {
        var _this = this;
        var uid = this.afAuth.auth.currentUser.uid;
        return new Promise(function (resolve) {
            _this.apiService.getMember({ uid: uid })
                .pipe(take(1))
                .subscribe(function (doc) {
                resolve(doc[0].id);
            });
        });
    };
    OrderDetailComponent.prototype.subscribeEntry = function (orderId, rewardId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var params;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                params = {
                    project: orderId,
                    reward: rewardId,
                    member: this.memberId
                };
                this.apiService.getEntries(params)
                    .pipe(takeUntil(this.onDestroy$))
                    .subscribe(function (docSnap) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    var _a;
                    return tslib_1.__generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                if (!(docSnap && docSnap.length > 0)) return [3 /*break*/, 2];
                                _a = this;
                                return [4 /*yield*/, tslib_1.__assign({ _id: docSnap[0].id }, docSnap[0])];
                            case 1:
                                _a.entry = _b.sent();
                                this.entryForm.reset();
                                this.entryForm.patchValue(cloneDeepWith(docSnap[0]));
                                return [3 /*break*/, 3];
                            case 2:
                                console.log('no entry in this project');
                                _b.label = 3;
                            case 3: return [2 /*return*/];
                        }
                    });
                }); }, function () {
                    _this.entry = null;
                });
                return [2 /*return*/];
            });
        });
    };
    OrderDetailComponent.prototype.subscribeReport = function (orderId, rewardId) {
        var _this = this;
        var params = {
            id: rewardId,
            member: this.memberId,
            project: orderId
        };
        this.apiService.getReport(params)
            .pipe(take(1), takeUntil(this.onDestroy$))
            .subscribe(function (docSnap) {
            if (docSnap && docSnap.length > 0) {
                _this.report = tslib_1.__assign({ _id: docSnap[0].id }, docSnap[0]);
            }
        }, function () {
            _this.report = null;
        });
    };
    OrderDetailComponent.prototype.isCompeted = function () {
        var _this = this;
        if (this.order.allowDuplicatedEntry && this.competedOrderList.length <= 0) {
            return false;
        }
        var filterdata = [];
        var thisOrder = _.find(this.competedOrderList, ['id', this.order.id]);
        _.forEach(this.competedOrderList, function (pj) {
            var startevent = new Date(pj.eventStartAt);
            startevent.setHours(0, 0, 0, 0);
            var endevent = new Date(pj.eventEndAt);
            endevent.setHours(0, 0, 0, 0);
            var currentStart = new Date(_this.order.eventStartAt);
            currentStart.setHours(0, 0, 0, 0);
            var currentEnd = new Date(_this.order.eventEndAt);
            currentEnd.setHours(0, 0, 0, 0);
            var samestart = moment(endevent).isSame(currentEnd);
            var startbefore = moment(currentStart).isBefore(startevent);
            var startafter = moment(currentStart).isAfter(startevent);
            var startbetween = currentStart <= endevent && currentStart >= startevent;
            var sameend = moment(endevent).isSame(currentEnd);
            var endbefore = moment(currentEnd).isBefore(endevent);
            var endafter = moment(currentEnd).isAfter(endevent);
            var endbetween = currentEnd <= endevent && currentEnd >= startevent;
            var filtered = (startbefore && endbetween) || (samestart && sameend) ||
                (startbetween && endafter) || (startbefore && endafter) || (startbetween && endbetween);
            filterdata.push(filtered);
        });
        var includeCandi = _.includes(filterdata, true);
        if (includeCandi === true) {
            return this.competedOrderList.length > 0 && !thisOrder;
        }
        else {
            return false;
        }
    };
    OrderDetailComponent.prototype.getCompetitionOrder = function () {
        var _this = this;
        if (this.order.allowDuplicatedEntry) {
            this.competedOrderList = [];
            this.pending = false;
        }
        else {
            var body_1 = {
                search: this.generateCompetedActiveOrderQuery(this.order)
            };
            var list_1 = [];
            var stream$_1 = function (request) { return _this.apiService.getProject(request.search)
                .pipe(switchMap(function (res) {
                list_1.push.apply(list_1, tslib_1.__spread(res));
                if (list_1.length < body_1.search._limit) {
                    return of(list_1);
                }
                body_1.search._start += list_1.length;
                return stream$_1(body_1);
            })); };
            return stream$_1(body_1)
                .pipe(catchError(function (err) {
                console.error(err);
                return of(void 0);
            })).subscribe(function (result) {
                _this.competedOrderList = result.map(function (results) {
                    return tslib_1.__assign({ _id: results.id }, cloneDeepWith(results));
                });
                _this.pending = false;
            });
        }
    };
    // 競合排除
    // この案件と同じ商材・ブランドで、本番期間中のエントリー中のもの
    OrderDetailComponent.prototype.generateCompetedActiveOrderQuery = function (order) {
        var brand = order.brand_master;
        var product = order.product_masters;
        var completed = {};
        completed.allowDuplicatedEntry = false;
        completed['entries.member'] = this.memberId;
        completed['entries.status_in'] = [Entry.StatusEnum.Decided, Entry.StatusEnum.Completed];
        completed['_where[_or][0][brand_master]'] = brand ? brand.id : null;
        completed['_where[_or][1][product_masters_in]'] = product.map(function (p) { return p.id; });
        completed._start = 0;
        completed._limit = 50;
        return completed;
    };
    OrderDetailComponent.prototype.entryToOrder = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, orderId, rewardId, memberId, params;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                _a = tslib_1.__read([this.order.id, this.reward.id, this.memberId], 3), orderId = _a[0], rewardId = _a[1], memberId = _a[2];
                params = {};
                params = {
                    project: orderId,
                    member: memberId,
                    reward: rewardId,
                    entriedAt: new Date().toISOString(),
                    status: 'entried'
                };
                this.entryForm.patchValue({
                    memberId: memberId,
                    orderId: orderId,
                    rewardId: rewardId,
                    entriedAt: new Date().toISOString(),
                    status: 'entried'
                });
                this.apiService.createEntry(params)
                    .pipe(finalize(function () { return _this.getCompetitionOrder(); }))
                    .subscribe(function (doc) {
                    if (doc) {
                        _this.entry = doc;
                        _this.dialogService.openTextDialog({
                            // backdropClass: 'dialog-backdrop-primary',
                            panelClass: ['dialog-panel-primary', 'align-top'],
                            autoFocus: false,
                            data: {
                                text: "\u30A8\u30F3\u30C8\u30EA\u30FC\u3042\u308A\u304C\u3068\u3046\u3054\u3056\u3044\u307E\u3057\u305F\uFF01\n                  \u30A8\u30F3\u30C8\u30EA\u30FC\u7DE0\u3081\u5207\u308A\u5F8C\u306B\u3001\u5BE9\u67FB\u7D50\u679C\u3092\u9806\u6B21\u3054\u9023\u7D61\u3044\n                  \u305F\u3057\u307E\u3059\u306E\u3067\u304A\u5F85\u3061\u304F\u3060\u3055\u3044\u3002"
                            }
                        });
                        _this.subscribeEntry(orderId, rewardId);
                    }
                }, function (error) {
                    console.error(error);
                    _this.dialogService.openTextDialog({
                        panelClass: ['dialog-panel-primary', 'align-top'],
                        autoFocus: false,
                        data: {
                            text: "\u30A8\u30F3\u30C8\u30EA\u30FC\u3067\u304D\u307E\u305B\u3093\u3067\u3057\u305F\u3002\u6642\u9593\u3092\u7F6E\u3044\u3066\u304A\u8A66\u3057\u304F\u3060\u3055\u3044\u3002"
                        }
                    });
                });
                return [2 /*return*/];
            });
        });
    };
    OrderDetailComponent.prototype.approveOffer = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, orderId, rewardId, memberId, currentEntry, params;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                _a = tslib_1.__read([this.order.id, this.reward.id, this.memberId], 3), orderId = _a[0], rewardId = _a[1], memberId = _a[2];
                currentEntry = _.find(this.order.entries, {
                    project: orderId,
                    member: memberId,
                    reward: rewardId
                });
                if (currentEntry) {
                    params = {
                        approvedAt: new Date().toISOString(),
                        status: Entry.StatusEnum.Approved
                    };
                    this.apiService.editEntry(currentEntry.id, params)
                        .pipe(finalize(function () { return _this.getCompetitionOrder(); }))
                        .subscribe(function (editEntry) {
                        if (editEntry) {
                            _this.entry = tslib_1.__assign({}, _this.entryForm.value, { approvedAt: new Date().toISOString() });
                            _this.dialogService.openTextDialog({
                                // backdropClass: 'dialog-backdrop-primary',
                                panelClass: ['dialog-panel-primary', 'align-top'],
                                autoFocus: false,
                                data: {
                                    text: "\u6848\u4EF6\u3078\u306E\u53C2\u52A0\u3092\u78BA\u5B9A\u3057\u3066\u9802\u304D\u3042\u308A\u304C\u3068\u3046\u3054\u3056\u3044\u307E\u3057\u305F\uFF01\n                              \u6848\u4EF6\u3078\u306E\u30A2\u30B5\u30A4\u30F3\u304C\u78BA\u5B9A\u3057\u307E\u3057\u305F\u3089\u6539\u3081\u3066\u3054\n                              \u9023\u7D61\u3044\u305F\u3057\u307E\u3059\u306E\u3067\u304A\u5F85\u3061\u304F\u3060\u3055\u3044\u3002"
                                }
                            });
                            _this.subscribeEntry(orderId, rewardId);
                        }
                    }, function (err) {
                        console.error(err);
                        _this.dialogService.openTextDialog({
                            panelClass: ['dialog-panel-primary', 'align-top'],
                            autoFocus: false,
                            data: {
                                text: "\u6848\u4EF6\u3078\u306E\u53C2\u52A0\u3092\u78BA\u5B9A\u3067\u304D\u307E\u305B\u3093\u3067\u3057\u305F\u3002\u6642\u9593\u3092\u7F6E\u3044\u3066\u304A\u8A66\u3057\u304F\u3060\u3055\u3044\u3002"
                            }
                        });
                    });
                }
                return [2 /*return*/];
            });
        });
    };
    OrderDetailComponent.prototype.cancelEntry = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var currentEntry;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                currentEntry = this.entry;
                if (currentEntry) {
                    this.apiService.deleteEntry(currentEntry.id)
                        .pipe(finalize(function () { return _this.getCompetitionOrder(); }))
                        .subscribe(function (deleteEntry) {
                        if (deleteEntry) {
                            _this.entry = null;
                            _this.entryForm.reset();
                            _this.dialogService.openTextDialog({
                                // backdropClass: 'dialog-backdrop-primary',
                                panelClass: ['dialog-panel-primary', 'align-top'],
                                autoFocus: false,
                                data: { text: "\u30A8\u30F3\u30C8\u30EA\u30FC\u3092\u30AD\u30E3\u30F3\u30BB\u30EB\u3057\u307E\u3057\u305F\u3002" }
                            });
                        }
                    }, function (err) {
                        console.error(err);
                        _this.dialogService.openTextDialog({
                            // backdropClass: 'dialog-backdrop-primary',
                            panelClass: ['dialog-panel-primary', 'align-top'],
                            autoFocus: false,
                            data: { text: "\u30A8\u30F3\u30C8\u30EA\u30FC\u3092\u30AD\u30E3\u30F3\u30BB\u30EB\u3067\u304D\u307E\u305B\u3093\u3067\u3057\u305F\u3002\u6642\u9593\u3092\u7F6E\u3044\u3066\u304A\u8A66\u3057\u304F\u3060\u3055\u3044\u3002" }
                        });
                    });
                }
                return [2 /*return*/];
            });
        });
    };
    OrderDetailComponent.prototype.getOwnEntry = function (esOrder) {
        var entries = esOrder.entries;
        if (!Array.isArray(entries)) {
            return void 0;
        }
        var ownEntry = find(entries, ['member', this.memberId]);
        return ownEntry;
    };
    OrderDetailComponent.prototype.getReport = function (project) {
        var reports = project.reports;
        if (!Array.isArray(reports)) {
            return void 0;
        }
        var ownReport = find(reports, ['member', this.memberId]);
        return ownReport;
    };
    OrderDetailComponent.prototype.cancelRequestToEntry = function () {
        console.log('cancel request to entry.');
    };
    OrderDetailComponent.prototype.downloadAttemptFile = function (file) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var blob, link;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.downloadProcessURL[file.url]) {
                            return [2 /*return*/];
                        }
                        this.downloadProcessURL[file.url] = true;
                        return [4 /*yield*/, fetch(file.url)];
                    case 1: return [4 /*yield*/, (_a.sent()).blob()
                            .then(function (blobData) { return blobData; })
                            .then(function (data) {
                            return data;
                        })];
                    case 2:
                        blob = _a.sent();
                        link = document.createElement('a');
                        link.setAttribute('href', window.URL.createObjectURL(blob));
                        link.setAttribute('download', file.name);
                        document.body.appendChild(link);
                        link.click();
                        link.remove();
                        this.downloadProcessURL[file.url] = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    OrderDetailComponent.prototype.openPreviewDialog = function (file) {
        var data = { file: file, downloadURL: null };
        data.downloadURL = file.url;
        this.dialog.open(this.previewDialogTemplateRef, {
            autoFocus: false,
            maxWidth: '100vw',
            panelClass: 'dialog-panel-preview',
            data: data,
        });
    };
    OrderDetailComponent.prototype.isImage = function (data) {
        if (data === null) {
            return false;
        }
        if (typeof data.mime !== 'string'
            || !/image\/.+/.test(data.mime)) {
            return false;
        }
        return true;
    };
    OrderDetailComponent.prototype.hasImage = function (order) {
        var _this = this;
        if (!order
            || (!order.file1
                && !order.file2)) {
            return false;
        }
        var imageFile = [order.file1, order.file2];
        var files = imageFile;
        var file = files.reduce(function (prev, current) { return _this.isImage(prev) ? prev : current; });
        var url = get(file, 'url');
        return !!url;
    };
    OrderDetailComponent.prototype.getImage = function (order) {
        var _this = this;
        if (!order
            || (!order.file1
                && !order.file2)) {
            return of(void 0);
        }
        var imageFile = [order.file1, order.file2];
        var files = imageFile;
        var file = files.reduce(function (prev, current) { return _this.isImage(prev) ? prev : current; });
        var url = get(file, 'url');
        return of(url);
    };
    return OrderDetailComponent;
}());
export { OrderDetailComponent };
