import * as tslib_1 from "tslib";
import { firestore, auth } from 'firebase/app';
import { of } from 'rxjs';
import { take, tap, skip, catchError, switchMap, filter, } from 'rxjs/operators';
import { environment } from '@lu/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/fire/auth";
import * as i3 from "@angular/fire/firestore";
import * as i4 from "./matching.service";
var AuthService = /** @class */ (function () {
    function AuthService(router, afAuth, afStore, apiService) {
        var _this = this;
        this.router = router;
        this.afAuth = afAuth;
        this.afStore = afStore;
        this.apiService = apiService;
        this.signInProvider = null;
        this.watchAuthState();
        this.fetchSigninMethod();
        this.afAuth.authState.pipe(take(1), filter(function (user) { return !!user; }) // 未ログインなら何もしない
        )
            // tslint:disable-next-line: deprecation
            .subscribe(function () {
            _this.isMember();
        });
    }
    AuthService.prototype.fetchSigninMethod = function () {
        var _this = this;
        this.afAuth.authState
            // tslint:disable-next-line: deprecation
            .subscribe(function (user) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var method;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!user || !user.email) {
                            this.signInProvider = null;
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.afAuth.auth.fetchSignInMethodsForEmail(user.email)];
                    case 1:
                        method = _a.sent();
                        if (method.includes(auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD)) {
                            this.signInProvider = 'email';
                        }
                        if (method.includes(auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD)) {
                            this.signInProvider = 'emailpassword';
                        }
                        return [2 /*return*/];
                }
            });
        }); });
    };
    AuthService.prototype.watchAuthState = function () {
        var _this = this;
        this.afAuth.authState
            .pipe(tap(function (user) { return environment.production ? null : console.log(user); }), skip(1))
            // tslint:disable-next-line: deprecation
            .subscribe(function (user) {
            // ログインしてなくても良いページにいるかどうかをチェック
            // 認証が必要なページにいた場合はログインページに飛ばす
            // console.log(this.router.url);
            if (!user && !_this.router.url.startsWith('/auth')) {
                var redirectTo = _this.router.url;
                _this.router.navigate(['auth/login'], { queryParams: { redirectTo: redirectTo } });
            }
        });
    };
    AuthService.prototype.isMember = function () {
        var _this = this;
        this.afAuth.authState
            .pipe(take(1), switchMap(function (user) {
            return _this.apiService.getMember({ uid: user.uid })
                .pipe(take(1));
        }), catchError(function (err) {
            console.error(err);
            return of(null);
        }))
            // tslint:disable-next-line: deprecation
            .subscribe(function (member) {
            if (member.length === 0 || !member) {
                alert('ログイン権限がありません。');
                _this.afAuth.auth.signOut();
                _this.router.navigate(['auth/login']);
            }
        });
    };
    AuthService.prototype.login = function (email, password) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.afAuth.auth.signInWithEmailAndPassword(email, password)
                            .catch(function (err) {
                            console.error(err);
                            throw new Error('メールアドレスまたはパスワードが違います。');
                        })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    AuthService.prototype.logout = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.afAuth.auth.signOut()
                            .catch(function (err) {
                            console.error(err);
                            throw new Error('ログアウトエラー');
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AuthService.prototype.signInWithCustomtoken = function (token) {
        console.log('=====auth.service.signInWithCustomtoken=====');
        return this.afAuth.auth.signInWithCustomToken(token);
    };
    AuthService.prototype.setCurrentAuth = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, uid, email, emailVerified, phoneNumber, modified, _b, userRef, memberRef;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, this.afAuth.auth.currentUser.getIdToken(true)];
                    case 1:
                        _c.sent();
                        _a = this.afAuth.auth.currentUser, uid = _a.uid, email = _a.email, emailVerified = _a.emailVerified, phoneNumber = _a.phoneNumber;
                        modified = { by: uid, at: firestore.FieldValue.serverTimestamp() };
                        _b = tslib_1.__read([
                            firestore().doc("users/" + uid),
                            firestore().doc("members/" + uid)
                        ], 2), userRef = _b[0], memberRef = _b[1];
                        return [4 /*yield*/, this.afStore.firestore.runTransaction(function (tx) {
                                return tx.get(memberRef).then(function (snap) {
                                    var email1 = snap.data().email1;
                                    // contact.emails.shift();
                                    // contact.emails.unshift(email);
                                    // console.log({email, emailVerified, phoneNumber}, {contact});
                                    tx.update(memberRef, { contaxc: snap.data().contact, modified: modified });
                                    tx.update(userRef, {
                                        email: email,
                                        emailVerified: emailVerified,
                                        phoneNumber: phoneNumber,
                                        modified: modified,
                                    });
                                    tx.update(memberRef, {
                                        email1: email1,
                                    });
                                });
                            })];
                    case 2:
                        _c.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.AngularFireAuth), i0.ɵɵinject(i3.AngularFirestore), i0.ɵɵinject(i4.MatchingService)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
