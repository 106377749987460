import { Component, OnInit, ViewChild, TemplateRef, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireStorage } from '@angular/fire/storage';

import { Observable, of, NEVER } from 'rxjs';
import { Subject } from 'rxjs';
import { takeUntil, catchError, switchMap, finalize, take } from 'rxjs/operators';
import { cloneDeepWith, get, find } from 'lodash';

import { Path } from '@lu/path';
import {
  Entry,
  BrandMaster,
  Project,
  NewEntry,
  Report,
  ProjectConfidential,
} from '@lu/models';
import { DialogService } from '@lu/services/dialog.service';
import { LocationService } from '@lu/services/location.service';
import { MatchingService } from '@lu/services/matching.service';
import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.scss']
})
export class OrderDetailComponent implements OnInit, OnDestroy {
  @ViewChild('previewDialog', { static: true }) previewDialogTemplateRef: TemplateRef<HTMLElement>;
  public order = {} as any;
  public entry: any;
  public report: Report;
  public reward = {} as any;
  public publicConfidential: any;
  public entryForm = new FormGroup({
    entriedAt: new FormControl(null),
    offeredAt: new FormControl(null),
    offeredBy: new FormControl(null),
    approvedAt: new FormControl(null),
    canceledAt: new FormControl(null),
    decidedAt: new FormControl(null),
    decidedBy: new FormControl(null),
    reportedAt: new FormControl(null),
    completedAt: new FormControl(null),
    completedBy: new FormControl(null),
    status: new FormControl(null),
    member: new FormControl(null),
    project: new FormControl(null),
    reward: new FormControl(null),
    created_by: new FormControl(null),
    updated_by: new FormControl(null),
    created_at: new FormControl(null),
    updated_at: new FormControl(null)
  });
  public downloadProcessURL: { [key: string]: boolean } = {};
  public requireReport = false;
  public orderStatusEnum = Project.StatusEnum;
  public orderClosedReasonEnum = Project.ClosedStatusEnum;
  public entryStatusEnum = Entry.StatusEnum;
  public brand: BrandMaster & { _id: string };
  public readonly path = Path;
  public pending = true;
  private onDestroy$ = new Subject();
  private competedOrderList: (Project & { _id: string })[] = [];
  public memberId: number;
  public IdofReward: number;

  constructor(
    private http: HttpClient,
    private router: Router,
    private aRoute: ActivatedRoute,
    private dialogService: DialogService,
    private afAuth: AngularFireAuth,
    private afStorage: AngularFireStorage,
    private dialog: MatDialog,
    public locationService: LocationService,
    private apiService: MatchingService,
  ) { }

  ngOnInit() {
    this.aRoute.data.subscribe(async data => {
      this.order = data.order;
      this.order.reward ? this.reward = this.order.reward : this.reward.id = null;
      this.memberId = await this.getLoginMember();
      this.entry = await this.getOwnEntry(this.order) || null;
      this.report = await this.getReport(this.order) || null;
      this.IdofReward = this.reward.id === null ? 0 : this.reward.id;
      this.publicConfidential = this.order.project_confidential;
      this.brand=this.order.brand_master;
      this.subscribeReport(this.order.id, this.reward.id);
      this.pending = true;
      this.getCompetitionOrder();
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  getLoginMember() {
    const { uid } = this.afAuth.auth.currentUser;
    return new Promise<number>(resolve => {
      this.apiService.getMember({ uid })
        .pipe(take(1))
        .subscribe(
          doc => {
            resolve(doc[0].id);
          }
        );
    });
  }

  async subscribeEntry(orderId: number, rewardId: number) {
    const params = {
      project: orderId,
      reward: rewardId,
      member: this.memberId
    };
    this.apiService.getEntries(params)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(async (docSnap) => {
        if (docSnap && docSnap.length > 0) {
          this.entry = await { _id: docSnap[0].id, ...docSnap[0] };
          this.entryForm.reset();
          this.entryForm.patchValue(cloneDeepWith(docSnap[0]));
        } else {
          console.log('no entry in this project');
        }
      }, () => {
        this.entry = null;
      }
      );
  }

  subscribeReport(orderId: number, rewardId: number) {
    const params = {
      id: rewardId,
      member: this.memberId,
      project: orderId
    };
    this.apiService.getReport(params)
      .pipe(take(1), takeUntil(this.onDestroy$))
      .subscribe(
        docSnap => {
          if (docSnap && docSnap.length > 0) {
            this.report = { _id: docSnap[0].id, ...docSnap[0] };
          }
        }, () => {
          this.report = null;
        }
      );
  }

    isCompeted() {
    if (this.order.allowDuplicatedEntry && this.competedOrderList.length <= 0) {
      return false;
    }
    const filterdata = [];
    const thisOrder = _.find(this.competedOrderList, ['id', this.order.id]);
    _.forEach(this.competedOrderList, pj => {
      const startevent = new Date(pj.eventStartAt);
      startevent.setHours(0, 0, 0, 0);

      const endevent = new Date(pj.eventEndAt);
      endevent.setHours(0, 0, 0, 0);

      const currentStart = new Date(this.order.eventStartAt);
      currentStart.setHours(0, 0, 0, 0);

      const currentEnd = new Date(this.order.eventEndAt);
      currentEnd.setHours(0, 0, 0, 0);

      const samestart = moment(endevent).isSame(currentEnd);
      const startbefore = moment(currentStart).isBefore(startevent);
      const startafter = moment(currentStart).isAfter(startevent);
      const startbetween = currentStart <= endevent && currentStart >= startevent;

      const sameend = moment(endevent).isSame(currentEnd);
      const endbefore = moment(currentEnd).isBefore(endevent);
      const endafter = moment(currentEnd).isAfter(endevent);
      const endbetween = currentEnd <= endevent && currentEnd >= startevent;
      const filtered = (startbefore && endbetween) || (samestart && sameend) ||
                        (startbetween && endafter) || (startbefore && endafter) || (startbetween && endbetween)
      filterdata.push(filtered);
    });
    const includeCandi = _.includes(filterdata, true);
    if (includeCandi === true) {
      return this.competedOrderList.length > 0 && !thisOrder;
    } else {
      return false;
    }
  }

  getCompetitionOrder() {
    if (this.order.allowDuplicatedEntry) {
      this.competedOrderList = [];
      this.pending = false;
    } else {
      const body = {
        search: this.generateCompetedActiveOrderQuery(this.order)
      };
      const list: any[] = [];
      const stream$ = (request: any) => this.apiService.getProject(request.search)
        .pipe(
          switchMap((res) => {
            list.push(...res);
            if (list.length < body.search._limit) {
              return of(list);
            }
            body.search._start += list.length;
            return stream$(body);
          })
        );
      return stream$(body)
        .pipe(
          catchError(err => {
            console.error(err);
            return of(void 0);
          })
        ).subscribe(result => {
          this.competedOrderList = result.map(results => {
            return { _id: results.id, ...cloneDeepWith(results) };
          });
          this.pending = false;
        });
    }
  }

  // 競合排除
  // この案件と同じ商材・ブランドで、本番期間中のエントリー中のもの
  generateCompetedActiveOrderQuery(order: any) {
    const brand: any = order.brand_master;
    const product: any = order.product_masters;
    const completed = {} as any;
    completed.allowDuplicatedEntry = false;
    completed['entries.member'] = this.memberId;
    completed['entries.status_in'] = [Entry.StatusEnum.Decided, Entry.StatusEnum.Completed];
    completed['_where[_or][0][brand_master]'] = brand ? brand.id : null;
    completed['_where[_or][1][product_masters_in]'] = product.map((p: any) => p.id);
    completed._start = 0;
    completed._limit = 50;
    return completed;
  }

  async entryToOrder() {
    const [orderId, rewardId, memberId] = [this.order.id, this.reward.id, this.memberId];
    let params = {} as any;
    params = {
      project: orderId,
      member: memberId,
      reward: rewardId,
      entriedAt: new Date().toISOString(),
      status: 'entried'
    };
    this.entryForm.patchValue({
      memberId,
      orderId,
      rewardId,
      entriedAt: new Date().toISOString(),
      status: 'entried'
    });

    this.apiService.createEntry(params)
      .pipe(
        finalize(() => this.getCompetitionOrder())
      )
      .subscribe(
        doc => {
          if (doc) {
            this.entry = doc;
            this.dialogService.openTextDialog({
              // backdropClass: 'dialog-backdrop-primary',
              panelClass: ['dialog-panel-primary', 'align-top'],
              autoFocus: false,
              data: {
                text: `エントリーありがとうございました！
                  エントリー締め切り後に、審査結果を順次ご連絡い
                  たしますのでお待ちください。`}
            });
            this.subscribeEntry(orderId, rewardId);
          }
        },
        error => {
          console.error(error);
          this.dialogService.openTextDialog({
            panelClass: ['dialog-panel-primary', 'align-top'],
            autoFocus: false,
            data: {
              text: `エントリーできませんでした。時間を置いてお試しください。`
            }
          });
        }
      );
  }

  async approveOffer() {
    const [orderId, rewardId, memberId] = [this.order.id, this.reward.id, this.memberId];
    const currentEntry = _.find(this.order.entries, {
      project: orderId,
      member: memberId,
      reward: rewardId
    });
    if (currentEntry) {
      const params = {
        approvedAt: new Date().toISOString(),
        status: Entry.StatusEnum.Approved
      };
      this.apiService.editEntry(currentEntry.id, params)
        .pipe(
          finalize(() => this.getCompetitionOrder())
        )
        .subscribe(
          editEntry => {
            if (editEntry) {
              this.entry = { ...this.entryForm.value, approvedAt: new Date().toISOString() };
              this.dialogService.openTextDialog({
                // backdropClass: 'dialog-backdrop-primary',
                panelClass: ['dialog-panel-primary', 'align-top'],
                autoFocus: false,
                data: {
                  text: `案件への参加を確定して頂きありがとうございました！
                              案件へのアサインが確定しましたら改めてご
                              連絡いたしますのでお待ちください。`}
              });
              this.subscribeEntry(orderId, rewardId);
            }
          }, err => {
            console.error(err);
            this.dialogService.openTextDialog({
              panelClass: ['dialog-panel-primary', 'align-top'],
              autoFocus: false,
              data: {
                text: `案件への参加を確定できませんでした。時間を置いてお試しください。`
              }
            });
          }
        );
    }
  }

  async cancelEntry() {
    const currentEntry = this.entry;
    if (currentEntry) {
      this.apiService.deleteEntry(currentEntry.id)
        .pipe(
          finalize(() => this.getCompetitionOrder())
        )
        .subscribe(
          deleteEntry => {
            if (deleteEntry) {
              this.entry = null;
              this.entryForm.reset();
              this.dialogService.openTextDialog({
                // backdropClass: 'dialog-backdrop-primary',
                panelClass: ['dialog-panel-primary', 'align-top'],
                autoFocus: false,
                data: { text: `エントリーをキャンセルしました。` }
              });
            }
          }, err => {
            console.error(err);
            this.dialogService.openTextDialog({
              // backdropClass: 'dialog-backdrop-primary',
              panelClass: ['dialog-panel-primary', 'align-top'],
              autoFocus: false,
              data: { text: `エントリーをキャンセルできませんでした。時間を置いてお試しください。` }
            });
          }
        );
    }
  }

  getOwnEntry(esOrder: Project) {
    const entries = esOrder.entries;
    if (!Array.isArray(entries)) {
      return void 0;
    }
    const ownEntry = find(entries, ['member', this.memberId]);
    return ownEntry;
  }

  getReport(project: Project) {
    const reports = project.reports;
    if (!Array.isArray(reports)) {
      return void 0;
    }
    const ownReport = find(reports, ['member', this.memberId]);
    return ownReport as Report;
  }

  cancelRequestToEntry() {
    console.log('cancel request to entry.');
  }

  async downloadAttemptFile(file: any) {
    if (this.downloadProcessURL[file.url]) {
      return;
    }
    this.downloadProcessURL[file.url] = true;
    const blob = await(await fetch(file.url)).blob()
      .then(blobData => blobData)
      .then(data => {
        return data;
      });
    const link = document.createElement('a');
    link.setAttribute('href', window.URL.createObjectURL(blob));
    link.setAttribute('download', file.name);
    document.body.appendChild(link);
    link.click();
    link.remove();
    this.downloadProcessURL[file.url] = false;
  }

  openPreviewDialog(file: any) {
    const data = { file, downloadURL: null };
    data.downloadURL = file.url;
    this.dialog.open(this.previewDialogTemplateRef, {
      autoFocus: false,
      maxWidth: '100vw',
      panelClass: 'dialog-panel-preview',
      data,
    });
  }

  isImage(data: any): boolean {
    if (data === null) {
      return false;
    }
    if (typeof data.mime !== 'string'
      || !/image\/.+/.test(data.mime)) {
      return false;
    }
    return true;
  }

  hasImage(order: Project): boolean {
    if (!order
      || (!order.file1
        && !order.file2)) {
      return false;
    }
    const imageFile = [order.file1, order.file2];
    const files = imageFile as any[];
    const file = files.reduce((prev, current) => this.isImage(prev) ? prev : current);
    const url = get(file, 'url');
    return !!url;
  }

  getImage(order: any): Observable<string> {
    if (!order
      || (!order.file1
        && !order.file2)) {
      return of(void 0);
    }
    const imageFile = [order.file1, order.file2];
    const files = imageFile as any[];
    const file = files.reduce((prev, current) => this.isImage(prev) ? prev : current);
    const url = get(file, 'url');
    return of(url);
  }
}
