import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'breakLine'
})
export class BreakLinePipe implements PipeTransform {

  transform(value: string, replaceTag?: string, wrapperTag?: string): string {
    if (typeof value !== 'string') {
      return value;
    }
    return value.replace(/(\r?\n)/g, replaceTag || '<br />');
  }

}
