import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { FormArray, FormGroup, FormControl, AbstractControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';

import { get, isNil, cloneDeep, forEach, range } from 'lodash';

import {
  BrandMaster,
  Reward,
  Project,
  Report,
} from '@lu/models';
import { DialogService } from '@lu/services/dialog.service';
import { LocationService } from '@lu/services/location.service';
import { FileChooserComponent } from '@lu/components/file-chooser/file-chooser.component';
import { LodingDialogData } from '@lu/components/loading-dialog/loading-dialog.component';
import { Path } from '@lu/path';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MatchingService } from '@lu/services/matching.service';

const newId = (autoId = '') => {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for (let i = 0; i < 20; i++) {
    autoId += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return autoId;
};
enum ReportStatusEnum {
  UnReported = 'unReported',
  Reported = 'reported',
  Rejected = 'rejected',
  Approved = 'approved',
}

@Component({
  selector: 'app-order-report',
  templateUrl: './order-report.component.html',
  styleUrls: ['./order-report.component.scss']
})
export class OrderReportComponent implements OnInit {
  @ViewChildren('filechooser') filechoosers: QueryList<FileChooserComponent>;
  public order: Project;
  public reward: any;
  public report: Report;
  public reportForm = new FormGroup({
    message: new FormControl('', Validators.required),
    read: new FormControl(false),
    rejected: new FormControl(false),
    rejectedMessage: new FormControl(null),
    rejectedAt: new FormControl(null),
    rejectedBy: new FormControl(null),
    approved: new FormControl(false),
    approvedAt: new FormControl(null),
    approvedBy: new FormControl(null),
    member: new FormControl(''),
    project: new FormControl(''),
    reward: new FormControl(''),
    created_by: new FormControl(null),
    updated_by: new FormControl(null),
    created_at: new FormControl(null),
    updated_at: new FormControl(null),
    file1: new FormControl(null),
    file2: new FormControl(null),
    file3: new FormControl(null),
  });
  public attemptFilesForm = new FormArray([
    new FormGroup({
      data: new FormControl(null),
      fileModel: new FormControl(null),
      prevIndex: new FormControl(0)
    }),
    new FormGroup({
      data: new FormControl(null),
      fileModel: new FormControl(null),
      prevIndex: new FormControl(1)
    }),
    new FormGroup({
      data: new FormControl(null),
      fileModel: new FormControl(null),
      prevIndex: new FormControl(2)
    }),
  ]);
  public brand: BrandMaster & { _id: string };
  private path = Path;
  public reportStatusEnum = ReportStatusEnum;
  private onDestroy$ = new Subject();
  private loginMemberId: number;
  private reportFiles = [];

  constructor(
    private aRoute: ActivatedRoute,
    private afAuth: AngularFireAuth,
    public locationService: LocationService,
    private dialog: DialogService,
    private router: Router,
    private apiService: MatchingService
  ) { }

  ngOnInit() {
    this.aRoute.data.subscribe(async data => {
      console.log('route data ====> ', data);
      this.order = data.order;
      // this.reward = data.reward;
      this.reward = this.order.reward;
      const brand: any = this.order.brand_master;
      if (this.order.reward) {
        this.reward = this.order.reward;
      }
      this.report = data.report[0];
      if (this.report) {
        this.reportFiles = this.report.file1 ?
          [...this.reportFiles, this.report.file1] :
          [...this.reportFiles, ...[null]];
        this.reportFiles = this.report.file2 ?
          [...this.reportFiles, this.report.file2] :
          [...this.reportFiles, ...[null]];
        this.reportFiles = this.report.file3 ?
          [...this.reportFiles, this.report.file3] :
          [...this.reportFiles, ...[null]];
      }
      this.subscribeBrand(brand ? brand.id : null);
      this.setDocumentsToForms();
      switch (this.reportStatus) {
        // Open message dialog
        case ReportStatusEnum.Rejected:
          this.dialog.openTextDialog({
            panelClass: ['dialog-panel-report-rejected', 'align-top'],
            data: {
              text: `レポート内容に不備がありました。お手数ですが、以下の項目を修正して再提出をお願いします。\n\n
              ${get(this.report, 'rejectedMessage')}`,
            },
          });
          break;
      }
      const { uid } = this.afAuth.auth.currentUser;
      const loginMember = () => {
        return new Promise<number>(resolve => {
          this.apiService.getMember({ uid })
            .pipe(take(1))
            .subscribe(
              doc => {
                resolve(doc[0].id);
              }
            );
        });
      };
      this.loginMemberId = await loginMember();
    });
  }

  subscribeBrand(docId: number) {
    if (!docId) {
      return;
    }
    this.apiService.getMaster(
      'brand-masters',
      {
        parentMasterGroupId_null: false
      }
    )
      .pipe(
        takeUntil(this.onDestroy$)
      )
      .subscribe((brands:[]) =>{
        const childBrandName:BrandMaster[]=brands.filter((brand:any)=>brand.id===docId);
        this.brand={...childBrandName[0],_id:docId.toString()}
      }, err => console.error(err));
  }

  get reportStatus(): ReportStatusEnum {
    if (isNil(this.report)) {
      return this.reportStatusEnum.UnReported;
    }
    if (this.report.approved) {
      return this.reportStatusEnum.Approved;
    }
    if (this.report.rejected) {
      return this.reportStatusEnum.Rejected;
    }
    return this.reportStatusEnum.Reported; // reported and read or not
  }

  getControls(ctrl: any) {
    return get(ctrl, 'controls') as AbstractControl[] || ctrl;
  }

  disableControl(ctrl: AbstractControl, field: string) {
    return ctrl.get(`${field}`).disable();
  }

  setDocumentsToForms() {
    if (!this.report) {
      return;
    }
    this.reportForm.patchValue(cloneDeep(this.report));
    forEach(this.reportFiles, async (fileModel: any, i) => {
      const fileObj = (fileModel ? new File([''], fileModel.name, { type: fileModel.mime }) : null);
      const ctrl = this.attemptFilesForm.at(i);
      // add control or patch value to control.
      if (!ctrl) {
        this.addFileForm({ data: fileObj, fileModel });
      } else {
        ctrl.patchValue({ data: fileObj, fileModel });
      }
    });
  }

  addFileForm(value: { data: File, fileModel?: any } = { data: null }) {
    const currentLength = this.attemptFilesForm.controls.length;
    const ctrl = new FormGroup({
      data: new FormControl(value.data || null),
      fileModel: new FormControl(value.fileModel || null),
      prevIndex: new FormControl(currentLength),
    });
    this.attemptFilesForm.push(ctrl);
  }

  fileSelectChange(file: File, index: number) {
    const ctrl = this.attemptFilesForm.at(index);
    // Attach file
    if (file instanceof File) {
      ctrl.patchValue({ data: file, fileModel: null });
    } else {
      ctrl.patchValue({ data: null, fileModel: null });
    }
    ctrl.markAsDirty();
  }

  /**
   * Upload files if some new file attached.
   */
  async prepareFiles() { // (path: string): Promise<ModelFile[]>
    const fileList = [];
    const removeFile = (doc: any) => {
      if (doc) {
        this.apiService.removeFile(doc.id)
          .subscribe(
            data => {
              if (data) {
                return true;
              }
            }, err => console.error(err)
          );
      }
    };
    this.attemptFilesForm.controls.forEach(async (ctrl, i) => {
      const data = ctrl.value.data;
      let fileData: any;
      if (data !== null) {
        if (data.size === 0) {
          fileData = null;
        } else if (data.size > 0) {
          fileData = data;
        }
      } else {
        fileData = null;
        if (this.report) {
          switch (i) {
            case 0:
              removeFile(this.reportForm.get('file1').value);
              this.reportForm.patchValue({
                file1: null
              });
              break;
            case 1:
              removeFile(this.reportForm.get('file2').value);
              this.reportForm.patchValue({
                file2: null
              });
              break;
            case 2:
              removeFile(this.reportForm.get('file3').value);
              this.reportForm.patchValue({
                file3: null
              });
              break;
            default:
              break;
          }
        }
      }
      fileList.push(fileData);
    });
    return fileList;
  }

  async submitReport() {
    const dialogData: LodingDialogData = { text: '' };
    const dialog = this.dialog.openLoadingDialog({
      data: dialogData,
      disableClose: true
    });
    const textRe = this.reportStatus === this.reportStatusEnum.Reported
      || this.reportStatus === this.reportStatusEnum.Rejected ? '再' : '';

    try {
      dialogData.text = `完了報告を${textRe}提出しています...`;
      if (this.reportStatus === this.reportStatusEnum.UnReported) {
        await this.createReport();
      } else {
        await this.updateReport();
        await new Promise<void>(resolve => setTimeout(() => { resolve(); }, 1000));
      }

      dialogData.text = `完了報告の${textRe}提出が完了しました。`;
      dialogData.hiddenBar = true;
      await new Promise<void>(resolve => setTimeout(() => { resolve(); }, 1500));

      dialog.close();
      this.router.navigate([this.path.feed.root]);
      return;
    } catch (err) {
      console.error(err);
      dialog.close();
      // Waiting for dialog closing because native alertdialog make block to scripts.
      await new Promise(resolve => {
        dialog.afterClosed().subscribe(resolve);
        dialog.close();
      });
      alert(`完了報告の${textRe}提出ができませんでした。`);
    }
  }

  async createReport() {

    const member = this.loginMemberId;
    const project = this.order.id;
    const reward = this.reward ? this.reward.id : null;
    const fileList = await this.prepareFiles();

    this.reportForm.patchValue({
      member,
      project,
      reward,
      // report_files, coming soon
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString()
    });

    this.apiService.getEntries({
      member,
      project,
      reward
    }).subscribe(
      docEntry => {
        this.apiService.editEntry(docEntry[0].id, { reportedAt: new Date().toISOString() })
          .subscribe(edit => {
            this.apiService.createReport(this.reportForm.value).subscribe(
              report => {
                if (fileList.length > 0) {
                  fileList.forEach((list, i) => {
                    if (list !== null) {
                      const fieldPath = `file${i + 1}`;
                      const body = new FormData();
                      body.append('files', list);
                      body.append('ref', 'report');
                      body.append('field', fieldPath);
                      body.append('refId', report.id);
                      JSON.stringify(body);
                      this.apiService.uploadFile(body)
                        .subscribe(
                          data => {
                            return true;
                          }, err => console.error(err)
                        );
                    }
                  });
                }
              }, err => console.error('error in upload file')
            );
          }, err => console.error('error in update entry in create report'));
      }, err => console.error('error in get entry of create report ', err)
    );
  }

  async updateReport() {
    const reportId = this.report.id;
    const member = this.loginMemberId;
    const project = this.order.id;
    const reward = this.reward ? this.reward.id : null;
    const fileList = await this.prepareFiles();

    this.reportForm.patchValue({
      read: false,
      updated_at: new Date().toISOString()
    });

    this.apiService.getEntries({
      member,
      project,
      reward
    }).subscribe(
      docEntry => {
        this.apiService.editEntry(docEntry[0].id, { reportedAt: new Date().toISOString() })
          .subscribe(edit => {
            this.apiService.editReport(reportId, this.reportForm.value).subscribe(
              report => {
                if (fileList.length > 0) {
                  fileList.forEach((list, i) => {
                    const fieldPath = `file${i + 1}`;
                    const body = new FormData();
                    if (list !== null) {
                      body.append('files', list);
                      body.append('ref', 'report');
                      body.append('field', fieldPath);
                      body.append('refId', report.id);
                      JSON.stringify(body);
                      this.apiService.uploadFile(body)
                        .subscribe(
                          data => {
                            return true;
                          }, err => console.error(err)
                        );
                    }
                  });
                }
              }, err => console.error('error in upload file')
            );
          }, err => console.error('error in update entry in create report'));
      }, err => console.error('error in get entry of create report ', err)
    );
  }
}
