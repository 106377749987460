/**
 * DOCUMENTATION
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact-email@something.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AdminUser { 
    id: string;
    uid?: string;
    order: number;
    groups?: Array<object>;
    email: string;
    displayName?: string;
    summary?: string;
    role: AdminUser.RoleEnum;
    leaved?: boolean;
}
export namespace AdminUser {
    export type RoleEnum = 'serviceAdmin' | 'groupAdmin' | 'groupEditor';
    export const RoleEnum = {
        ServiceAdmin: 'serviceAdmin' as RoleEnum,
        GroupAdmin: 'groupAdmin' as RoleEnum,
        GroupEditor: 'groupEditor' as RoleEnum
    };
}


