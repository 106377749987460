import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgHeadPipeModule, NgWherePipeModule, NgEmptyPipeModule, NgToArrayPipeModule } from 'angular-pipes';
import { LinkyModule } from 'ngx-linky';

import { FileSizePipe } from './file-size.pipe';
import { RootPathPrefixPipe } from './root-path-prefix.pipe';
import { GetPipe } from './get.pipe';
import { PeriodPipe } from './period.pipe';
import { DateAgoPipe } from './date-ago.pipe';
import { CircleNumberPipe } from './circle-number.pipe';
import { BreakLinePipe } from './break-line.pipe';

@NgModule({
  declarations: [
    FileSizePipe,
    RootPathPrefixPipe,
    GetPipe,
    PeriodPipe,
    DateAgoPipe,
    CircleNumberPipe,
    BreakLinePipe,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    FileSizePipe,
    RootPathPrefixPipe,
    GetPipe,
    PeriodPipe,
    DateAgoPipe,
    CircleNumberPipe,
    BreakLinePipe,
    NgHeadPipeModule,
    NgWherePipeModule,
    NgEmptyPipeModule,
    NgToArrayPipeModule,
    LinkyModule,
  ]
})
export class PipesModule { }
