import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthAction } from '@lu/auth-action';
import { Path } from '@lu/path';
import { environment } from '@lu/environment';
var SendResetPasswordMailComponent = /** @class */ (function () {
    function SendResetPasswordMailComponent(afAuth, aRoute, http) {
        this.afAuth = afAuth;
        this.aRoute = aRoute;
        this.http = http;
        this.requestForm = new FormGroup({
            email: new FormControl(null, [
                Validators.required,
                Validators.email,
                Validators.pattern(/@{1}([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/),
            ]),
        });
        this.completed = false;
        this.pending = false;
        this.requestError = null;
        this.authAction = AuthAction;
        this.path = Path;
    }
    SendResetPasswordMailComponent.prototype.ngOnInit = function () {
        this.mode = this.aRoute.snapshot.queryParams.mode;
        if (this.mode === this.authAction.sendSetPasswordMail
            && this.afAuth.auth.currentUser) {
            var email = this.afAuth.auth.currentUser.email;
            // this.requestForm.patchValue({email});
        }
    };
    SendResetPasswordMailComponent.prototype.sendResetPasswordMail = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var email, redirectTo;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        email = this.requestForm.value.email;
                        redirectTo = 'member';
                        localStorage.setItem('passwordResetRequestedMail', email);
                        this.pending = true;
                        return [4 /*yield*/, this.http.post(environment.apiEndpoint + "/auth/action/request/resetpassword", { email: email, redirectTo: redirectTo })
                                .subscribe(function () {
                                _this.completed = true;
                            }, function (err) {
                                var status = err.status, code = err.message;
                                console.error(err);
                                _this.pending = false;
                                _this.requestError = { status: status, code: code, error: err, message: '' };
                                switch (err.status) {
                                    default:
                                        _this.requestError.message = 'メールを送信できませんでした。';
                                }
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return SendResetPasswordMailComponent;
}());
export { SendResetPasswordMailComponent };
