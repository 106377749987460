import { NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Path } from '@lu/path';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
var LocationService = /** @class */ (function () {
    function LocationService(router, location) {
        this.router = router;
        this.location = location;
        this.tracking = false;
        this.path = Path;
    }
    LocationService.prototype.trackRouting = function () {
        var _this = this;
        if (this.tracking) {
            return;
        }
        this.tracking = true;
        this.router.events
            .pipe(filter(function (event) { return event instanceof NavigationEnd
            || event instanceof NavigationCancel; }))
            .subscribe(function (event) {
            _this.prevLocation = _this.currentLocation;
            _this.currentLocation = event.url;
        }, function (err) {
            console.error(err);
        }, function () {
            _this.tracking = false;
        });
    };
    LocationService.prototype.navigateToErrorPage = function () {
        var _this = this;
        this.router.events
            .pipe(filter(function (event) { return event instanceof NavigationError; }))
            .subscribe(function (event) {
            _this.router.navigate(["/" + _this.path.error.root]);
        });
    };
    LocationService.prototype.backWithAppState = function () {
        if (!this.prevLocation) {
            this.router.navigate(['/']);
        }
        else {
            this.location.back();
        }
    };
    LocationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LocationService_Factory() { return new LocationService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.Location)); }, token: LocationService, providedIn: "root" });
    return LocationService;
}());
export { LocationService };
