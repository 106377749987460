import { Pipe, PipeTransform } from '@angular/core';
import { get } from 'lodash';

@Pipe({
  name: 'get'
})
export class GetPipe implements PipeTransform {

  transform(value: any, fieldPath: string): any {
    return get(value, fieldPath);
  }

}
