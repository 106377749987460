import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute } from '@angular/router';

import { Path } from '@lu/path';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  public resetPasswordForm = new FormGroup({
    email: new FormControl(null, Validators.required),
    password: new FormControl(null, [Validators.required, Validators.minLength(6)]),
    confirmedPassword: new FormControl(null, [Validators.required, Validators.minLength(6)]),
  });
  public authError: {
    status: number;
    code: string;
    message: string;
    error: any;
  } = null;
  public readonly path = Path;
  public codeVerified = false;
  public pending = false;
  public completed = false;

  constructor(
    private aRoute: ActivatedRoute,
    private afAuth: AngularFireAuth,
  ) { }

  ngOnInit() {
    const { oobCode } = this.aRoute.snapshot.queryParams;
    this.verifyPasswordResetCode(oobCode)
      .then(email => this.resetPasswordForm.patchValue({ email }));
  }

  checkButtonIsDisabled() {
    const resetFormValue = this.resetPasswordForm.value;
    if (resetFormValue.password && resetFormValue.confirmedPassword
      && resetFormValue.password === resetFormValue.confirmedPassword
      && this.resetPasswordForm.valid) {
      return false;
    } else {
      return true;
    }
  }

  async verifyPasswordResetCode(code: string): Promise<string> {
    try {
      const email = await this.afAuth.auth.verifyPasswordResetCode(code);
      this.codeVerified = true;
      return email;
    } catch (err) {
      console.error(err);
      switch (err.code) {
        case 'auth/expired-action-code':
          this.authError = { status: 400, code: err.code, message: '', error: err };
          this.authError.message = '認証コード有効期限が切れています。';
          break;
        default:
          this.authError = { status: 400, code: err.code, message: '', error: err };
          this.authError.message = '無効な認証コードです。';
      }
    }
  }

  async resetPasswordAndSignInDirectly() {
    // https://firebase.google.com/docs/auth/custom-email-handler?hl=ja#create_the_email_action_handler_page
    const { oobCode } = this.aRoute.snapshot.queryParams;
    const { email, password: newPassword } = this.resetPasswordForm.value;
    this.pending = true;
    try {
      await this.afAuth.auth.confirmPasswordReset(oobCode, newPassword);
      this.completed = true;
      // sign-in the user directly because the page belongs to the same domain as the app.
      this.afAuth.auth.signInWithEmailAndPassword(email, newPassword)
        .catch(err => console.error(err));
    } catch (err) {
      console.error(err);
      switch (err.code) {
        default:
          this.authError = { status: 400, code: err.code, message: '', error: err };
          this.authError.message = 'パスワードを更新できませんでした。';
      }
    } finally {
      this.pending = false;
    }
  }
}
