import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute } from '@angular/router';

import { Path } from '@lu/path';


@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent implements OnInit {
  public authError: {
    status: number;
    code: string;
    message: string;
    error: any;
  } = null;
  public readonly path = Path;
  public pending = false;
  public completed = false;

  constructor(
    private aRoute: ActivatedRoute,
    private afAuth: AngularFireAuth,
  ) { }

  ngOnInit() {
    const {oobCode} = this.aRoute.snapshot.queryParams;
    this.applyActionCode(oobCode);
  }

  async applyActionCode(code: string): Promise<string> {
    this.pending = true;
    try {
      const resp = await this.afAuth.auth.applyActionCode(code);
      this.completed = true;
      console.log(resp);
      return;
    } catch (err) {
      console.error(err);
      switch (err.code) {
        case 'auth/expired-action-code':
          this.authError = {status: 400, code: err.code, message: '', error: err};
          this.authError.message = '認証コード有効期限が切れています。';
          break;
        default:
          this.authError = {status: 400, code: err.code, message: '', error: err};
          this.authError.message = '無効な認証コードです。';
      }
    } finally {
      this.pending = false;
    }
  }

}
