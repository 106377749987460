import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { NavigationEnd } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { auth } from 'firebase/app';
import { filter, map } from 'rxjs/operators';
import { Path } from '@lu/path';
import { AuthAction } from '@lu/auth-action';
import { environment } from '@lu/environment';
var AuthLoginComponent = /** @class */ (function () {
    function AuthLoginComponent(aRoute, router, afAuth, authService, dialog) {
        this.aRoute = aRoute;
        this.router = router;
        this.afAuth = afAuth;
        this.authService = authService;
        this.dialog = dialog;
        this.authForm = new FormGroup({
            email: new FormControl(null, Validators.required),
            password: new FormControl(null, Validators.required),
        });
        this.authPersistence = auth.Auth.Persistence.SESSION;
        this.path = Path;
        this.pending = false;
        this.authAction = AuthAction;
        this.authError = null;
        this.mode = null;
        this.environment = environment;
    }
    AuthLoginComponent.prototype.ngOnInit = function () {
        var _this = this;
        var mode = this.aRoute.snapshot.queryParams.mode;
        this.updateAuthActionMode(mode);
        // Watch url update
        this.router.events
            .pipe(filter(function (e) { return e instanceof NavigationEnd; }), map(function () { return _this.aRoute.snapshot.queryParams.mode; }))
            .subscribe(function (currentMode) {
            console.log(currentMode);
            _this.updateAuthActionMode(currentMode);
        });
    };
    AuthLoginComponent.prototype.signIn = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, email, password, redirectTo, err_1;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.authForm.value, email = _a.email, password = _a.password;
                        this.pending = true;
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 7, 8, 9]);
                        if (!(this.mode === 'signIn')) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.afAuth.auth.signInWithEmailLink(email, window.location.href)];
                    case 2:
                        _b.sent();
                        return [3 /*break*/, 6];
                    case 3: return [4 /*yield*/, this.afAuth.auth.setPersistence(this.authPersistence)];
                    case 4:
                        _b.sent();
                        return [4 /*yield*/, this.afAuth.auth.signInWithEmailAndPassword(email, password)];
                    case 5:
                        _b.sent();
                        _b.label = 6;
                    case 6:
                        redirectTo = this.aRoute.snapshot.queryParams.redirectTo;
                        if (redirectTo) {
                            this.router.navigateByUrl(redirectTo);
                        }
                        else {
                            this.router.navigate([this.path.feed.root]);
                        }
                        this.authService.isMember(); // Check roles.
                        return [3 /*break*/, 9];
                    case 7:
                        err_1 = _b.sent();
                        console.error(err_1);
                        switch (err_1.code) {
                            case 'auth/argument-error':
                                this.authError = { value: err_1, message: 'ログインコードの有効期限が切れています。' };
                                break;
                            case 'auth/wrong-password':
                            case 'auth/user-not-found':
                                this.authError = { value: err_1, message: 'メールアドレスまたはパスワードが違います' };
                                break;
                            default:
                                this.authError = { value: err_1, message: 'ログインに失敗しました。' };
                        }
                        return [3 /*break*/, 9];
                    case 8:
                        this.pending = false;
                        return [7 /*endfinally*/];
                    case 9: return [2 /*return*/];
                }
            });
        });
    };
    AuthLoginComponent.prototype.persistanceChange = function (event) {
        if (event.checked) {
            this.authPersistence = auth.Auth.Persistence.LOCAL;
        }
        else {
            this.authPersistence = auth.Auth.Persistence.SESSION;
        }
    };
    AuthLoginComponent.prototype.updateAuthActionMode = function (mode) {
        this.mode = mode;
        switch (mode) {
            case 'signIn':
                this.authForm.get('password').clearValidators();
                break;
            default:
                this.authForm.get('password').setValidators(Validators.required);
                break;
        }
        this.authForm.get('password').updateValueAndValidity();
    };
    return AuthLoginComponent;
}());
export { AuthLoginComponent };
