import { Injectable } from '@angular/core';
import { findIndex, cloneDeep } from 'lodash';

export interface FavoriteItem<T> {
  ref: T;
  modifiedAt: Date;
}

@Injectable({
  providedIn: 'root'
})
export class FavoriteService {
  private privateFavoriteOrderList: FavoriteItem<number>[];

  constructor() {
    this.favoriteOrderList = this.getFavoriteOrderList();
  }

  public set favoriteOrderList(list: FavoriteItem<number>[]) {
    this.privateFavoriteOrderList = cloneDeep(list);
  }
  public get favoriteOrderList(): FavoriteItem<number>[] {
    return cloneDeep(this.privateFavoriteOrderList);
  }

  private getFavoriteOrderList() {
    const listStr = localStorage.getItem('list/favoriteOrder');
    try {
      const list: {id: number, modifiedAt: string}[] = JSON.parse(listStr);
      return list.map(data => {
        return {
          ref: data.id,
          modifiedAt: new Date(data.modifiedAt)
        };
      });
    } catch {
      return [];
    }
  }

  private setFavoriteOrderList(list: FavoriteItem<number>[]) {
    const newlist: {id: number, modifiedAt: string}[] = [];
    list.forEach(item => {
      newlist.push({id: item.ref, modifiedAt: item.modifiedAt.toString()});
    });
    localStorage.setItem('list/favoriteOrder', JSON.stringify(newlist));
  }

  addOrderToFavorite(orderId: number) {
    const list = cloneDeep(this.favoriteOrderList);
    list.unshift({
      ref: orderId as number,
      modifiedAt: new Date()
    });
    this.favoriteOrderList = list;
    this.setFavoriteOrderList(this.favoriteOrderList);
  }

  removeOrderFromFavorite(orderId: number) {
    const list = cloneDeep(this.favoriteOrderList);
    const index = findIndex(list, ['ref', orderId]);
    if (index === -1) {
      return;
    }
    list.splice(index, 1);
    this.favoriteOrderList = list;
    this.setFavoriteOrderList(this.favoriteOrderList);
  }

  isFavoriteOrder(orderId: number) {
    return this.privateFavoriteOrderList.some(item => item.ref === orderId);
  }

}
