import * as tslib_1 from "tslib";
import { PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
var PeriodPipe = /** @class */ (function () {
    function PeriodPipe() {
    }
    PeriodPipe.prototype.transform = function (period) {
        var _a, _b;
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        var result = '';
        if (!period || typeof period !== 'object' || (!period.startAt && !period.endAt)) {
            return null;
        }
        if (period.startAt) {
            result += (_a = new DatePipe('en-US')).transform.apply(_a, tslib_1.__spread([period.startAt], args));
        }
        result += '&nbsp;-&nbsp;';
        if (period.endAt) {
            result += (_b = new DatePipe('en-US')).transform.apply(_b, tslib_1.__spread([period.endAt], args));
        }
        return result;
    };
    return PeriodPipe;
}());
export { PeriodPipe };
