<mat-toolbar class="app-header">
  <app-route-progress></app-route-progress>
  <button
    mat-icon-button
    color="primary"
    (click)="locationService.backWithAppState()">
    <mat-icon>arrow_back</mat-icon>
  </button>
  <h2 class="page-title">お気に入り</h2>
  <div class="right-suffix"></div>
</mat-toolbar>

<div
  *ngIf="orderList?.length === 0"
  class="message-empty">
  <span>該当する案件はありません</span>
</div>

<div
  class="order-list-item"
  *ngFor="let order of orderList">
  <app-order-favorite
    [orderId]="order.id"></app-order-favorite>
  <div
    class="order-item-inner"
    matRipple
    [routerLink]="[path.order.detailBase | rootPathPrefix, order.id]">
    <div class="order-images-thmbnail">
      <div
        class="order-image-container"
        [style.backgroundSize]="hasImage(order) ? 'contain' : null"
        [style.backgroundImage]="hasImage(order) ? 'url('+(getImage(order) | async)+')' : null"></div>
    </div>
    <div class="order-item-details">
      <app-order-status-chip
        [order]="order"
        [entry]="getOwnEntry(order) || null"
        [report]="getReport(order) || null"></app-order-status-chip>
      <div class="order-name">{{order.projectName}}</div>
      <div class="order-informations">
        <span
          class="order-event-periods"
          [innerHTML]="{startAt: order.eventStartAt, endAt: order.eventEndAt} | period:'yyyy/MM/dd':'+0900'"></span>
        <span
          *ngIf="({startAt: order.eventStartAt, endAt: order.eventEndAt} | period) && order.reward"
          class="separator">&nbsp;|&nbsp;</span>
        <span
          class="order-reward">{{
            (order.rewards | head)?.amount ?
            ((order.rewards | head).amount | currency:(order.rewards | head).currency) :
            '報酬なし'
        }}</span>
      </div>
      <div class="order-remarks">{{order.remarks}}</div>
    </div>
  </div>
</div>
