<!-- undefinedをloading, nnullをepmtyとして扱う -->
<span
  *ngIf="order !== undefined
        && entry !== undefined
        && report !== undefined"
  class="order-status-chip"
  [ngClass]="{
    'status-recruiting': recruiting,
    'status-entried': entried,
    'status-offered': offered,
    'status-approved': approved,
    'status-decided': decided,
    'status-inprogress': inProgress,
    'status-not-reported': notReported,
    'status-rejected': reportRejected,
    'status-reported': reportApproved || reported,
    'status-entry-canceled': entryCanceled,
    'status-order-canceled': orderCanceled
  }">
  <!-- OrderCanceled -->
  <ng-container *ngIf="orderCanceled; else EntryCanceled">案件が中止になりました</ng-container>
  <!-- EntryCanceled -->
  <ng-template #EntryCanceled>
    <ng-container *ngIf="entryCanceled; else ReportApproved">不採用</ng-container>
  </ng-template>
  <ng-template #ReportApproved>
    <ng-container *ngIf="reportApproved; else ReportRejected">{{
      (toDate(entry?.reportedAt) | date: 'M/d') + 'に完了報告提出済'
    }}</ng-container>
  </ng-template>
  <ng-template #ReportRejected>
    <ng-container *ngIf="reportRejected; else Reported">完了報告の再提出が必要です</ng-container>
  </ng-template>
  <ng-template #Reported>
    <ng-container *ngIf="reported; else InProgress">{{
      (toDate(entry?.reportedAt) | date: 'M/d') + 'に完了報告' + (reReported ? '再' : '') + '提出済'
    }}</ng-container>
  </ng-template>
  <ng-template #InProgress>
    <ng-container *ngIf="inProgress; else Decided">進行中</ng-container>
  </ng-template>
  <ng-template #Decided>
    <ng-container *ngIf="!orderCompleted && !completed && notReported && decided && approved; else NotReported">本決定</ng-container>
  </ng-template>
  <ng-template #NotReported>
    <ng-container *ngIf="(completed && decided && notReported) || (orderCompleted && decided && notReported); else Approved">
      <mat-icon class="inner-icon">error_outline</mat-icon>完了報告が未提出です
    </ng-container>
  </ng-template>
  <ng-template #Approved>
    <ng-container *ngIf="approved; else Offered">{{
      (toDate(entry?.approvedAt) | date: 'M/d') + 'に仮決定しました'
    }}</ng-container>
  </ng-template>
  <ng-template #Offered>
    <ng-container *ngIf="offered; else Entried">{{
      (toDate(entry?.offeredAt) | date: 'M/d') + 'に内定しました'
    }}</ng-container>
  </ng-template>
  <ng-template #Entried>
    <ng-container *ngIf="entried; else Recruiting">{{
      (toDate(entry?.entriedAt) | date: 'M/d') + 'にエントリー済みです'
    }}</ng-container>
  </ng-template>
  <ng-template #Recruiting>
    <ng-container *ngIf="recruiting; else OrderCompleted">募集中</ng-container>
  </ng-template>
  <ng-template #OrderCompleted>
    <ng-container *ngIf="orderCompleted; else Expired">終了しました</ng-container>
  </ng-template>
  <ng-template #Expired>
    <ng-container *ngIf="expired; else Registered">募集は終了しました</ng-container>
  </ng-template>
  <ng-template #Registered>公開中</ng-template>
</span>
