import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Path } from '@lu/path';
var SideNavComponent = /** @class */ (function () {
    function SideNavComponent(router, afAuth) {
        this.router = router;
        this.afAuth = afAuth;
        this.path = Path;
    }
    SideNavComponent.prototype.ngOnInit = function () {
    };
    SideNavComponent.prototype.signOut = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var redirectTo;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.afAuth.auth.signOut()];
                    case 1:
                        _a.sent();
                        redirectTo = 'feed';
                        this.router.navigate(['auth/login'], { queryParams: { redirectTo: redirectTo } });
                        return [2 /*return*/];
                }
            });
        });
    };
    return SideNavComponent;
}());
export { SideNavComponent };
