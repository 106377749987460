import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  Router,
  NavigationStart,
  RoutesRecognized,
  GuardsCheckStart,
  GuardsCheckEnd,
  ResolveStart,
  ResolveEnd,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
} from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-route-progress',
  templateUrl: './route-progress.component.html',
  styleUrls: ['./route-progress.component.scss']
})
export class RouteProgressComponent implements OnInit, OnDestroy {
  public value = 0;
  private onDestroy$ = new Subject();

  constructor(
    private router: Router
  ) {}

  ngOnInit() {
    this.router.events
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(event => {
        if (event instanceof NavigationCancel
          || event instanceof NavigationError
          || event instanceof NavigationEnd) {
          this.value = 0;
        }
        if (event instanceof NavigationStart) {
          this.value = 25;
        }
        if (event instanceof RoutesRecognized) {
          this.value = 50;
        }
        if (event instanceof GuardsCheckStart) {
          this.value = 50;
        }
        if (event instanceof GuardsCheckEnd) {
          this.value = 70;
        }
        if (event instanceof ResolveStart) {
          this.value = 80;
        }
        if (event instanceof ResolveEnd) {
          this.value = 100;
        }
      });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

}
