export const Path = {
  error: {
    root: 'oops'
  },
  auth: {
    root: 'auth',
    login: 'auth/login',
    action: 'auth/action',
  },
  tabs: {
  },
  feed: {
    root: 'feed',
  },
  order: {
    list: 'order/list',
    /**
     * @param orderId Firestore Document ID
     */
    detail: 'order/detail/:orderId',
    detailBase: 'order/detail',
    /**
     * @param orderId Firestore Document ID
     * @param rewardId Firestore Document ID
     */
    report: 'order/report/:orderId/:rewardId',
    reportBase: 'order/report',
  },
  notification: {
    list: 'notification/list',
  },
  favorite: {
    root: 'favorites',
  },
  payment: {
    /**
     * @param term quryParams for search. format "yyyy-MM"
     */
    detail: 'payment/detail',
    detailBase: 'payment/detail',
  },
  profile: {
    root: 'profile',
  },
  terms: {
    root: 'terms',
  },
  policy: {
    root: 'service-policy',
  },
};
