import * as tslib_1 from "tslib";
import { take, map } from 'rxjs/operators';
import { cloneDeepWith } from 'lodash';
var OrderReportResolver = /** @class */ (function () {
    function OrderReportResolver(afAuth, afStore, apiService) {
        this.afAuth = afAuth;
        this.afStore = afStore;
        this.apiService = apiService;
    }
    OrderReportResolver.prototype.resolve = function (route, state) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, orderId, rewardId, uid, memberId$, loginUserId, params, report$;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = route.params, orderId = _a.orderId, rewardId = _a.rewardId;
                        orderId = Number(orderId);
                        rewardId = Number(rewardId);
                        uid = this.afAuth.auth.currentUser.uid;
                        memberId$ = function () {
                            return new Promise(function (resolve) {
                                _this.apiService.getMember({ uid: uid }).subscribe(function (doc) { return resolve(doc[0].id); });
                            });
                        };
                        return [4 /*yield*/, memberId$()];
                    case 1:
                        loginUserId = _b.sent();
                        params = {
                            project: orderId,
                            reward: rewardId === 0 ? null : rewardId,
                            member: loginUserId
                        };
                        report$ = function () {
                            return new Promise(function (resolve) {
                                _this.apiService.getReport(params).pipe(take(1), map(function (reports) { return reports.map(function (items) {
                                    return tslib_1.__assign({ _id: items.id }, cloneDeepWith(items));
                                }); })).subscribe(function (doc) { return resolve(doc); });
                            });
                        };
                        return [4 /*yield*/, report$()];
                    case 2: return [2 /*return*/, _b.sent()];
                }
            });
        });
    };
    return OrderReportResolver;
}());
export { OrderReportResolver };
