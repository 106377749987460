import * as tslib_1 from "tslib";
import { OnInit, QueryList } from '@angular/core';
import { FormArray, FormGroup, FormControl, Validators } from '@angular/forms';
import { get, isNil, cloneDeep, forEach } from 'lodash';
import { BrandMaster, Project, Report, } from '@lu/models';
import { Path } from '@lu/path';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
var newId = function (autoId) {
    if (autoId === void 0) { autoId = ''; }
    var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (var i = 0; i < 20; i++) {
        autoId += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return autoId;
};
var ɵ0 = newId;
var ReportStatusEnum;
(function (ReportStatusEnum) {
    ReportStatusEnum["UnReported"] = "unReported";
    ReportStatusEnum["Reported"] = "reported";
    ReportStatusEnum["Rejected"] = "rejected";
    ReportStatusEnum["Approved"] = "approved";
})(ReportStatusEnum || (ReportStatusEnum = {}));
var OrderReportComponent = /** @class */ (function () {
    function OrderReportComponent(aRoute, afAuth, locationService, dialog, router, apiService) {
        this.aRoute = aRoute;
        this.afAuth = afAuth;
        this.locationService = locationService;
        this.dialog = dialog;
        this.router = router;
        this.apiService = apiService;
        this.reportForm = new FormGroup({
            message: new FormControl('', Validators.required),
            read: new FormControl(false),
            rejected: new FormControl(false),
            rejectedMessage: new FormControl(null),
            rejectedAt: new FormControl(null),
            rejectedBy: new FormControl(null),
            approved: new FormControl(false),
            approvedAt: new FormControl(null),
            approvedBy: new FormControl(null),
            member: new FormControl(''),
            project: new FormControl(''),
            reward: new FormControl(''),
            created_by: new FormControl(null),
            updated_by: new FormControl(null),
            created_at: new FormControl(null),
            updated_at: new FormControl(null),
            file1: new FormControl(null),
            file2: new FormControl(null),
            file3: new FormControl(null),
        });
        this.attemptFilesForm = new FormArray([
            new FormGroup({
                data: new FormControl(null),
                fileModel: new FormControl(null),
                prevIndex: new FormControl(0)
            }),
            new FormGroup({
                data: new FormControl(null),
                fileModel: new FormControl(null),
                prevIndex: new FormControl(1)
            }),
            new FormGroup({
                data: new FormControl(null),
                fileModel: new FormControl(null),
                prevIndex: new FormControl(2)
            }),
        ]);
        this.path = Path;
        this.reportStatusEnum = ReportStatusEnum;
        this.onDestroy$ = new Subject();
        this.reportFiles = [];
    }
    OrderReportComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.aRoute.data.subscribe(function (data) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var brand, uid, loginMember, _a;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        console.log('route data ====> ', data);
                        this.order = data.order;
                        // this.reward = data.reward;
                        this.reward = this.order.reward;
                        brand = this.order.brand_master;
                        if (this.order.reward) {
                            this.reward = this.order.reward;
                        }
                        this.report = data.report[0];
                        if (this.report) {
                            this.reportFiles = this.report.file1 ? tslib_1.__spread(this.reportFiles, [this.report.file1]) : tslib_1.__spread(this.reportFiles, [null]);
                            this.reportFiles = this.report.file2 ? tslib_1.__spread(this.reportFiles, [this.report.file2]) : tslib_1.__spread(this.reportFiles, [null]);
                            this.reportFiles = this.report.file3 ? tslib_1.__spread(this.reportFiles, [this.report.file3]) : tslib_1.__spread(this.reportFiles, [null]);
                        }
                        this.subscribeBrand(brand ? brand.id : null);
                        this.setDocumentsToForms();
                        switch (this.reportStatus) {
                            // Open message dialog
                            case ReportStatusEnum.Rejected:
                                this.dialog.openTextDialog({
                                    panelClass: ['dialog-panel-report-rejected', 'align-top'],
                                    data: {
                                        text: "\u30EC\u30DD\u30FC\u30C8\u5185\u5BB9\u306B\u4E0D\u5099\u304C\u3042\u308A\u307E\u3057\u305F\u3002\u304A\u624B\u6570\u3067\u3059\u304C\u3001\u4EE5\u4E0B\u306E\u9805\u76EE\u3092\u4FEE\u6B63\u3057\u3066\u518D\u63D0\u51FA\u3092\u304A\u9858\u3044\u3057\u307E\u3059\u3002\n\n\n              " + get(this.report, 'rejectedMessage'),
                                    },
                                });
                                break;
                        }
                        uid = this.afAuth.auth.currentUser.uid;
                        loginMember = function () {
                            return new Promise(function (resolve) {
                                _this.apiService.getMember({ uid: uid })
                                    .pipe(take(1))
                                    .subscribe(function (doc) {
                                    resolve(doc[0].id);
                                });
                            });
                        };
                        _a = this;
                        return [4 /*yield*/, loginMember()];
                    case 1:
                        _a.loginMemberId = _b.sent();
                        return [2 /*return*/];
                }
            });
        }); });
    };
    OrderReportComponent.prototype.subscribeBrand = function (docId) {
        var _this = this;
        if (!docId) {
            return;
        }
        this.apiService.getMaster('brand-masters', {
            parentMasterGroupId_null: false
        })
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(function (brands) {
            var childBrandName = brands.filter(function (brand) { return brand.id === docId; });
            _this.brand = tslib_1.__assign({}, childBrandName[0], { _id: docId.toString() });
        }, function (err) { return console.error(err); });
    };
    Object.defineProperty(OrderReportComponent.prototype, "reportStatus", {
        get: function () {
            if (isNil(this.report)) {
                return this.reportStatusEnum.UnReported;
            }
            if (this.report.approved) {
                return this.reportStatusEnum.Approved;
            }
            if (this.report.rejected) {
                return this.reportStatusEnum.Rejected;
            }
            return this.reportStatusEnum.Reported; // reported and read or not
        },
        enumerable: true,
        configurable: true
    });
    OrderReportComponent.prototype.getControls = function (ctrl) {
        return get(ctrl, 'controls') || ctrl;
    };
    OrderReportComponent.prototype.disableControl = function (ctrl, field) {
        return ctrl.get("" + field).disable();
    };
    OrderReportComponent.prototype.setDocumentsToForms = function () {
        var _this = this;
        if (!this.report) {
            return;
        }
        this.reportForm.patchValue(cloneDeep(this.report));
        forEach(this.reportFiles, function (fileModel, i) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var fileObj, ctrl;
            return tslib_1.__generator(this, function (_a) {
                fileObj = (fileModel ? new File([''], fileModel.name, { type: fileModel.mime }) : null);
                ctrl = this.attemptFilesForm.at(i);
                // add control or patch value to control.
                if (!ctrl) {
                    this.addFileForm({ data: fileObj, fileModel: fileModel });
                }
                else {
                    ctrl.patchValue({ data: fileObj, fileModel: fileModel });
                }
                return [2 /*return*/];
            });
        }); });
    };
    OrderReportComponent.prototype.addFileForm = function (value) {
        if (value === void 0) { value = { data: null }; }
        var currentLength = this.attemptFilesForm.controls.length;
        var ctrl = new FormGroup({
            data: new FormControl(value.data || null),
            fileModel: new FormControl(value.fileModel || null),
            prevIndex: new FormControl(currentLength),
        });
        this.attemptFilesForm.push(ctrl);
    };
    OrderReportComponent.prototype.fileSelectChange = function (file, index) {
        var ctrl = this.attemptFilesForm.at(index);
        // Attach file
        if (file instanceof File) {
            ctrl.patchValue({ data: file, fileModel: null });
        }
        else {
            ctrl.patchValue({ data: null, fileModel: null });
        }
        ctrl.markAsDirty();
    };
    /**
     * Upload files if some new file attached.
     */
    OrderReportComponent.prototype.prepareFiles = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var fileList, removeFile;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                fileList = [];
                removeFile = function (doc) {
                    if (doc) {
                        _this.apiService.removeFile(doc.id)
                            .subscribe(function (data) {
                            if (data) {
                                return true;
                            }
                        }, function (err) { return console.error(err); });
                    }
                };
                this.attemptFilesForm.controls.forEach(function (ctrl, i) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    var data, fileData;
                    return tslib_1.__generator(this, function (_a) {
                        data = ctrl.value.data;
                        if (data !== null) {
                            if (data.size === 0) {
                                fileData = null;
                            }
                            else if (data.size > 0) {
                                fileData = data;
                            }
                        }
                        else {
                            fileData = null;
                            if (this.report) {
                                switch (i) {
                                    case 0:
                                        removeFile(this.reportForm.get('file1').value);
                                        this.reportForm.patchValue({
                                            file1: null
                                        });
                                        break;
                                    case 1:
                                        removeFile(this.reportForm.get('file2').value);
                                        this.reportForm.patchValue({
                                            file2: null
                                        });
                                        break;
                                    case 2:
                                        removeFile(this.reportForm.get('file3').value);
                                        this.reportForm.patchValue({
                                            file3: null
                                        });
                                        break;
                                    default:
                                        break;
                                }
                            }
                        }
                        fileList.push(fileData);
                        return [2 /*return*/];
                    });
                }); });
                return [2 /*return*/, fileList];
            });
        });
    };
    OrderReportComponent.prototype.submitReport = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var dialogData, dialog, textRe, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        dialogData = { text: '' };
                        dialog = this.dialog.openLoadingDialog({
                            data: dialogData,
                            disableClose: true
                        });
                        textRe = this.reportStatus === this.reportStatusEnum.Reported
                            || this.reportStatus === this.reportStatusEnum.Rejected ? '再' : '';
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 8, , 10]);
                        dialogData.text = "\u5B8C\u4E86\u5831\u544A\u3092" + textRe + "\u63D0\u51FA\u3057\u3066\u3044\u307E\u3059...";
                        if (!(this.reportStatus === this.reportStatusEnum.UnReported)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.createReport()];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 6];
                    case 3: return [4 /*yield*/, this.updateReport()];
                    case 4:
                        _a.sent();
                        return [4 /*yield*/, new Promise(function (resolve) { return setTimeout(function () { resolve(); }, 1000); })];
                    case 5:
                        _a.sent();
                        _a.label = 6;
                    case 6:
                        dialogData.text = "\u5B8C\u4E86\u5831\u544A\u306E" + textRe + "\u63D0\u51FA\u304C\u5B8C\u4E86\u3057\u307E\u3057\u305F\u3002";
                        dialogData.hiddenBar = true;
                        return [4 /*yield*/, new Promise(function (resolve) { return setTimeout(function () { resolve(); }, 1500); })];
                    case 7:
                        _a.sent();
                        dialog.close();
                        this.router.navigate([this.path.feed.root]);
                        return [2 /*return*/];
                    case 8:
                        err_1 = _a.sent();
                        console.error(err_1);
                        dialog.close();
                        // Waiting for dialog closing because native alertdialog make block to scripts.
                        return [4 /*yield*/, new Promise(function (resolve) {
                                dialog.afterClosed().subscribe(resolve);
                                dialog.close();
                            })];
                    case 9:
                        // Waiting for dialog closing because native alertdialog make block to scripts.
                        _a.sent();
                        alert("\u5B8C\u4E86\u5831\u544A\u306E" + textRe + "\u63D0\u51FA\u304C\u3067\u304D\u307E\u305B\u3093\u3067\u3057\u305F\u3002");
                        return [3 /*break*/, 10];
                    case 10: return [2 /*return*/];
                }
            });
        });
    };
    OrderReportComponent.prototype.createReport = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var member, project, reward, fileList;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        member = this.loginMemberId;
                        project = this.order.id;
                        reward = this.reward ? this.reward.id : null;
                        return [4 /*yield*/, this.prepareFiles()];
                    case 1:
                        fileList = _a.sent();
                        this.reportForm.patchValue({
                            member: member,
                            project: project,
                            reward: reward,
                            // report_files, coming soon
                            created_at: new Date().toISOString(),
                            updated_at: new Date().toISOString()
                        });
                        this.apiService.getEntries({
                            member: member,
                            project: project,
                            reward: reward
                        }).subscribe(function (docEntry) {
                            _this.apiService.editEntry(docEntry[0].id, { reportedAt: new Date().toISOString() })
                                .subscribe(function (edit) {
                                _this.apiService.createReport(_this.reportForm.value).subscribe(function (report) {
                                    if (fileList.length > 0) {
                                        fileList.forEach(function (list, i) {
                                            if (list !== null) {
                                                var fieldPath = "file" + (i + 1);
                                                var body = new FormData();
                                                body.append('files', list);
                                                body.append('ref', 'report');
                                                body.append('field', fieldPath);
                                                body.append('refId', report.id);
                                                JSON.stringify(body);
                                                _this.apiService.uploadFile(body)
                                                    .subscribe(function (data) {
                                                    return true;
                                                }, function (err) { return console.error(err); });
                                            }
                                        });
                                    }
                                }, function (err) { return console.error('error in upload file'); });
                            }, function (err) { return console.error('error in update entry in create report'); });
                        }, function (err) { return console.error('error in get entry of create report ', err); });
                        return [2 /*return*/];
                }
            });
        });
    };
    OrderReportComponent.prototype.updateReport = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var reportId, member, project, reward, fileList;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        reportId = this.report.id;
                        member = this.loginMemberId;
                        project = this.order.id;
                        reward = this.reward ? this.reward.id : null;
                        return [4 /*yield*/, this.prepareFiles()];
                    case 1:
                        fileList = _a.sent();
                        this.reportForm.patchValue({
                            read: false,
                            updated_at: new Date().toISOString()
                        });
                        this.apiService.getEntries({
                            member: member,
                            project: project,
                            reward: reward
                        }).subscribe(function (docEntry) {
                            _this.apiService.editEntry(docEntry[0].id, { reportedAt: new Date().toISOString() })
                                .subscribe(function (edit) {
                                _this.apiService.editReport(reportId, _this.reportForm.value).subscribe(function (report) {
                                    if (fileList.length > 0) {
                                        fileList.forEach(function (list, i) {
                                            var fieldPath = "file" + (i + 1);
                                            var body = new FormData();
                                            if (list !== null) {
                                                body.append('files', list);
                                                body.append('ref', 'report');
                                                body.append('field', fieldPath);
                                                body.append('refId', report.id);
                                                JSON.stringify(body);
                                                _this.apiService.uploadFile(body)
                                                    .subscribe(function (data) {
                                                    return true;
                                                }, function (err) { return console.error(err); });
                                            }
                                        });
                                    }
                                }, function (err) { return console.error('error in upload file'); });
                            }, function (err) { return console.error('error in update entry in create report'); });
                        }, function (err) { return console.error('error in get entry of create report ', err); });
                        return [2 /*return*/];
                }
            });
        });
    };
    return OrderReportComponent;
}());
export { OrderReportComponent };
export { ɵ0 };
