import { Pipe, PipeTransform } from '@angular/core';
import circleNumber from 'circle-number';

@Pipe({
  name: 'circleNumber'
})
export class CircleNumberPipe implements PipeTransform {

  transform(value: number): string {
    return circleNumber(value);
  }

}
