import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { AngularFireAuth } from '@angular/fire/auth';

import { auth } from 'firebase/app';
import { filter, map } from 'rxjs/operators';

import { Path } from '@lu/path';
import { AuthAction } from '@lu/auth-action';
import { AuthService } from '@lu/services/auth.service';
import { environment } from '@lu/environment';
import { DialogService } from '@lu/services/dialog.service';

@Component({
  selector: 'app-auth-login',
  templateUrl: './auth-login.component.html',
  styleUrls: ['./auth-login.component.scss']
})
export class AuthLoginComponent implements OnInit {
  public authForm = new FormGroup({
    email: new FormControl(null, Validators.required),
    password: new FormControl(null, Validators.required),
  });
  public authPersistence: auth.Auth.Persistence = auth.Auth.Persistence.SESSION;
  public readonly path = Path;
  public pending = false;
  public authAction = AuthAction;
  public authError: {value: any, message: string} = null;
  public mode: 'signIn' = null;
  public readonly environment = environment;

  constructor(
    private aRoute: ActivatedRoute,
    private router: Router,
    private afAuth: AngularFireAuth,
    private authService: AuthService,
    private dialog: DialogService,
  ) { }

  ngOnInit() {
    const {mode} = this.aRoute.snapshot.queryParams;
    this.updateAuthActionMode(mode);
    // Watch url update
    this.router.events
      .pipe(
        filter(e => e instanceof NavigationEnd),
        map(() => this.aRoute.snapshot.queryParams.mode),
      )
      .subscribe(currentMode => {
        console.log(currentMode);
        this.updateAuthActionMode(currentMode);
      });
  }

  async signIn() {
    const {email, password} = this.authForm.value;
    this.pending = true;
    try {
      if (this.mode === 'signIn') {
        await this.afAuth.auth.signInWithEmailLink(email, window.location.href);
      } else {
        await this.afAuth.auth.setPersistence(this.authPersistence);
        await this.afAuth.auth.signInWithEmailAndPassword(email, password);
      }
      const {redirectTo} = this.aRoute.snapshot.queryParams;
      if (redirectTo) {
        this.router.navigateByUrl(redirectTo);
      } else {
        this.router.navigate([this.path.feed.root]);
      }
      this.authService.isMember(); // Check roles.
    } catch (err) {
      console.error(err);
      switch (err.code) {
        case 'auth/argument-error':
          this.authError = {value: err, message: 'ログインコードの有効期限が切れています。'};
          break;
        case 'auth/wrong-password':
        case 'auth/user-not-found':
          this.authError = {value: err, message: 'メールアドレスまたはパスワードが違います'};
          break;
        default:
          this.authError = {value: err, message: 'ログインに失敗しました。'};
      }
    } finally {
      this.pending = false;
    }
  }

  persistanceChange(event: MatCheckboxChange) {
    if (event.checked) {
      this.authPersistence = auth.Auth.Persistence.LOCAL;
    } else {
      this.authPersistence = auth.Auth.Persistence.SESSION;
    }
  }

  updateAuthActionMode(mode: any) {
    this.mode = mode;
    switch (mode) {
      case 'signIn':
        this.authForm.get('password').clearValidators();
        break;
      default:
        this.authForm.get('password').setValidators(Validators.required);
        break;
    }
    this.authForm.get('password').updateValueAndValidity();
  }
}
