import { Component, OnInit, ViewChild, TemplateRef, ViewChildren, QueryList, AfterViewInit } from '@angular/core';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { MatDialog } from '@angular/material/dialog';
import { AngularFireAuth } from '@angular/fire/auth';
import { OrderListComponent } from '@lu/components/order-list/order-list.component';
import {
  Entry,
  Candidate,
  Project,
} from '@lu/models';
import { tap, catchError, switchMap, take, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { MatchingService } from '@lu/services/matching.service';

// type ResponseFields = 'orderNumber' | 'orderName' | 'clientId' | 'client' |
//   'categories' | 'segment' | 'groups' | 'recruitmentPeriod' | 'eventPeriod' |
//   'remarks' | 'files' | 'examination' | 'publish' | 'closed' | 'status';

type ResponseFields =  'projectName' | 'client' | 'category_masters' |
'projectKindMaster' | 'groups' | 'recruitmentStartAt' | 'recruitmentEndAt' |
'eventStartAt' | 'eventEndAt' |  'remarks' | 'file1'| 'file2' | 'publishStatus' |
'publishModifiedAt' | 'closedStatus' | 'closedModifiedAt' | 'status';

export type SearchOrderResult =
  Pick<Project, ResponseFields>
  & { entries: (Entry & { _id: string })[] }
  & { candidates: (Candidate & { _id: string })[] }
  & { _id: string };

@Component({
  selector: 'app-feed',
  templateUrl: './feed.component.html',
  styleUrls: ['./feed.component.scss'],
})
export class FeedComponent implements OnInit, AfterViewInit {
  @ViewChild(MatTabGroup, { static: true }) tabGroup: MatTabGroup;
  @ViewChild('sortDialog', { static: true }) sortDialog: TemplateRef<HTMLElement>;
  @ViewChildren('orderList') orderLists: QueryList<OrderListComponent>;
  public tabList = {
    recruitment: {
      label: '募集中',
      isFirst: true,
      scrollOffset: 0,
    },
    entry: {
      label: 'エントリー中',
      isFirst: true,
      scrollOffset: 0,
    },
    approved: {
      label: '仮決定中',
      isFirst: true,
      scrollOffset: 0,
    },
    decided: {
      label: '決定',
      isFirst: true,
      scrollOffset: 0,
    },
    completed: {
      label: '完了済',
      isFirst: true,
      scrollOffset: 0,
    },
    canceled: {
      label: '見送り',
      isFirst: true,
      scrollOffset: 0,
    },
  };
  public sortOption = [
    {
      name: '新着順',
      value: {
        _sort: 'publishModifiedAt:DESC',
      }
    },
    {
      name: '謝礼の高い順',
      value: {
        _sort: 'reward:DESC',
      }
    },
    {
      name: '募集期限が近い順',
      value: {
        _sort: 'recruitmentEndAt:ASC',
      }
    },
    {
      name: '募集期限が遠い順',
      value: {
        _sort: 'recruitmentEndAt:DESC',
      }
    },
  ];
  public sortSelection = this.sortOption[0].value;
  public currentKeyword: string;
  private tabIndexHistory: number[] = [];
  private competedOrderList: Project[];
  public userId: number;
  private today: string = new Date().toISOString();

  constructor(
    private dialog: MatDialog,
    private afAuth: AngularFireAuth,
    private apiService: MatchingService,
  ) { }

  ngOnInit() {
    this.tabGroup.selectedIndex = this.prevTabIndex;
  }

  async ngAfterViewInit() {
    const { uid } = this.afAuth.auth.currentUser;
    const getLoginMember = () => {
      return new Promise<number>(resolve => {
        this.apiService.getMember({ uid })
          .pipe(take(1), map(docSnap => docSnap[0].id))
          .subscribe(
            doc => {
              resolve(doc);
            }
          );
      });
    };
    this.userId = await getLoginMember();
    this.tabIndexHistory.unshift(this.tabGroup.selectedIndex);
    this.cacheBrandAndMarchandiseOfNonCompetitionOrder()
      .subscribe(() => {
        this.search(this.tabGroup.selectedIndex, this.currentKeyword);
      });
  }

  search(index = 0, keyword?: string) {
    const [key, tab] = Object.entries(this.tabList)[index];
    const component = this.orderLists.find((_, i) => index === i);
    if (!key || !component) {
      return;
    }
    const { search } = this.generateQuery(key, keyword); // key, keyword
    // Escape ExpressionChangedAfterItHasBeenCheckedError errors..
    requestAnimationFrame(() => {
      component.search(search);
      tab.isFirst = false;
    });
  }

  searchAll(keyword?: string) {
    Object.keys(this.tabList).map((_, index) => {
      this.search(index, keyword);
    });
  }

  searchFormSubmit(keyword?: string) {
    this.currentKeyword = keyword;
    // Reset
    Object.values(this.tabList).forEach(tab => {
      tab.isFirst = true;
      tab.scrollOffset = 0;
    });
    this.search(this.tabGroup.selectedIndex, this.currentKeyword);
  }

  generateORConditionForQueryObj(orConditionKey, orConditionValue) {
    if (orConditionKey.length === orConditionValue.length) {
      const list = {} as any;
      orConditionKey.forEach((orList, i) => {
        list['_where[_or][' + i + '][' + orList + ']'] = orConditionValue[i];
      });
      return list;
    }
    return {};
  }

  generateANDConditionForQueryObj(andConditionKey, andConditionValue) {
    if (andConditionKey.length === andConditionValue.length) {
      const list = {} as any;
      andConditionKey.forEach((andList, i) => {
        list[andList] = andConditionValue[i];
      });
      return list;
    }
    return {};
  }

  generateQuery(tab: string, keyword?: string) {
    const query = {
      search: {} as any
    };
    query.search = { ...query.search, ...this.sortSelection };
    if (keyword) {
      const keywords = keyword.split(/\s+/).filter(o => !!o);
      const keywordComposite = {} as any;
      keywordComposite.projectName_containss = keywords;
      // keywordComposite.remarks_containss = keywords;
      query.search = { ...query.search, ...keywordComposite };
    }
    switch (tab) {
      case 'recruitment':
        const andRecruit = this.generateANDConditionForQueryObj(
            ['status_in', 'publishStatus'],  [['recruiting', 'registered'], ['public']]
            );
        query.search = { ...query.search, ...andRecruit };
        break;
      case 'entry':
        const andEntrying = this.generateANDConditionForQueryObj(
          ['entries.status_in', 'entries.member'],
          [['entried', 'offered'], this.userId]
        );
        query.search = { ...query.search, ...andEntrying };
        break;
      case 'approved':
        const andApproveEntry = this.generateANDConditionForQueryObj(
          ['entries.status_ne', 'entries.status', 'entries.member', 'entries.approvedAt_null', 'entries.decidedAt_null'],
          ['decided', 'approved', this.userId, false, true]
        );
        query.search = { ...query.search, ...andApproveEntry };
        break;
      case 'decided':
        const decideEntry = this.generateANDConditionForQueryObj(
          ['status_ne', 'entries.status', 'entries.reportedAt_null', 'entries.member', 'entries.approvedAt_null', 'entries.decidedAt_null'],
          ['completed', 'decided', true, this.userId, false, false]
        );
        query.search = { ...query.search, ...decideEntry };
        break;
      case 'completed':
        const completeAndEntry = this.generateANDConditionForQueryObj(
          ['entries.member', 'entries.decidedAt_null' ],
          [this.userId, false]
        );
        // レポートまで提出完了したもの
        const completeOREntrywith01 = this.generateORConditionForQueryObj(
          ['entries.status', 'entries.status', 'entries.reportedAt_null'],
          [ 'completed', 'decided', false ]
        );
        // レポート未提出のまま案件が終了したもの
        const completeOREntrywith = this.generateORConditionForQueryObj(
          [ 'entries.completedAt_null', 'closedStatus'],
          [ false, 'deadline' ]
        );
        query.search = { ...query.search, ...completeAndEntry, ...completeOREntrywith, ...completeOREntrywith01 };
        break;
      case 'canceled':
        const canceledEntry = this.generateANDConditionForQueryObj(
          ['entries.canceledAt_null', 'entries.status', 'entries.member'],
          [false, 'canceled', this.userId]
        );
        query.search = { ...query.search, ...canceledEntry };
        break;
    }
    query.search._start = 0;
    query.search._limit = -1;
    return query;
  }

  // 自分のエントリーしている案件のうち、競合排除が指定されていて、エントリーがキャンセルのもの以外で本番期間のもの
  generateEntriedNonCompetitionOrderQuery() {
    let query = {} as any;
    const getAndCondition = this.generateANDConditionForQueryObj(
      ['allowDuplicatedEntry', 'status_ne', 'entries.member', '_limit', '_start'],
      [false, 'completed', this.userId, 50, 0]
    );
    const getOrCondition = this.generateORConditionForQueryObj(
      ['eventStartAt_lte', 'eventEndAt_gte', 'eventStartAt_null'],
      [this.today, this.today, false]
    );
    query = { ...getOrCondition, ...getAndCondition };
    return query;
  }

  // 競合排除
  generateCompetedQuery() {
    let notCompeted = {} as any;
    const idlist = [];
    const brand = [];
    let productKindList = [];
    const startAfter = [];
    const endBefore = [];
    this.competedOrderList.forEach((competedOrder, index) => {
      // get id list
      idlist.push(competedOrder.id);

      // get brand-master
      if (competedOrder.brand_master ) {
        const brandmaster: any = competedOrder.brand_master;
        brand.push(competedOrder.brand_master ? brandmaster.id : null);
        }
      // brand.push(competedOrder.brandMaster ? competedOrder.brandMaster.id : null);

      // product master
      if (competedOrder.product_masters.length > 0 ) {
        const productmasters: any = competedOrder.product_masters;
        productKindList = [...productKindList, ...productmasters.map(list => list.id)];
      }
      // productKindList = [...productKindList, ...competedOrder.productMasters.map(list => list.id)];

      // startAfter
      startAfter.push(competedOrder.eventStartAt);

      // end Before
      endBefore.push(competedOrder.eventEndAt);
    });

    const keyOfquery = [
      'id_in', 'brand-master_in', 'product-masters_in',
      'eventStartAt', 'eventEndAt'
    ];

    notCompeted = this.generateORConditionForQueryObj([], []);
    return notCompeted;
  }

  // 自分のエントリーしている案件の競合排除がtrueの案件から、ブランド、商材情報、実施期間を取得する
  cacheBrandAndMarchandiseOfNonCompetitionOrder() {
    const body = {
      search: this.generateEntriedNonCompetitionOrderQuery()
    };
    const list: any[] = [];
    const stream$ = (request: any) => this.apiService.getProject(request.search)
      .pipe(
        switchMap((res) => {
          list.push(...res);
          if (list.length < body.search._limit) {
            return of(list);
          }
          body.search._start += list.length;
          return stream$(body);
        })
      );
    return stream$(body)
      .pipe(
        catchError(err => {
          console.error(err);
          return of(void 0);
        }),
        tap((result: any) => {
          this.competedOrderList = result;
        })
      );
  }

  openSortDialog() {
    const dialog = this.dialog.open(this.sortDialog, { autoFocus: false });
    dialog.afterClosed().subscribe(() => {
      Object.values(this.tabList).forEach(tab => {
        tab.isFirst = true;
        tab.scrollOffset = 0;
      });
      this.search(this.tabGroup.selectedIndex, this.currentKeyword);
    });
  }

  sortOptionSelected(result) {
    this.sortSelection = result;
  }

  set prevTabIndex(index: number) {
    sessionStorage.setItem('feedLastSelectedTabIndex', String(index));
  }

  get prevTabIndex() {
    const prevIndex = +sessionStorage.getItem('feedLastSelectedTabIndex');
    if (isNaN(prevIndex) || prevIndex > Object.entries(this.tabList).length) {
      return 0;
    }
    return prevIndex;
  }

  selectedTabChange(event: MatTabChangeEvent) {
    // console.log(event);
    const { index } = event;
    const prevIndex = this.tabIndexHistory[0];
    const prevTab = Object.values(this.tabList)[prevIndex];
    const prevCcomponent = this.orderLists.find((_, i) => prevIndex === i);

    prevTab.scrollOffset = prevCcomponent.virtualScroll.measureScrollOffset();
    this.tabIndexHistory.unshift(index);
    this.prevTabIndex = index;
    Object.values(this.tabList).forEach((tab, i) => {
      if (i !== index) {
        return;
      }
      const component = this.orderLists.find((_, j) => index === j);
      if (tab.isFirst) {
        this.search(index, this.currentKeyword);
      } else {
        component.virtualScroll.scrollToOffset(tab.scrollOffset);
      }
    });
  }
}
