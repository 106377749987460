/**
 * DOCUMENTATION
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact-email@something.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface NewChild {
    birthDay?: Date;
    gender?: NewChild.GenderEnum;
    members?: Array<string>;
    createdBy?: string;
    updatedBy?: string;
}
// tslint:disable-next-line: no-namespace
export namespace NewChild {
    export type GenderEnum = 'male' | 'female' | 'unanswered' | 'notKnown';
    export const GenderEnum = {
        Male: 'male' as GenderEnum,
        Female: 'female' as GenderEnum,
        Unanswered: 'unanswered' as GenderEnum,
        NotKnown: 'notKnown' as GenderEnum
    };
}


