import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { Path } from '@lu/path';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {
  public readonly path = Path;

  constructor(
    private router: Router,
    private afAuth: AngularFireAuth,
  ) { }

  ngOnInit() {
  }

  async signOut() {
    await this.afAuth.auth.signOut();
    const redirectTo = 'feed';
    this.router.navigate(['auth/login'], { queryParams: { redirectTo } });
  }

}
