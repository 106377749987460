
export const environment = {
  production: true,
  envChip: {
    project: 'staging',
    color: 'white',
      backgroundColor: '#f7000d',
  },
  firebaseConfig: {
    apiKey: 'AIzaSyCnqId1blvTBh5npv5qwYBg_Vs912sxZnk',
    authDomain: 'dott-matching-shueisha-staging.firebaseapp.com',
    projectId: 'dott-matching-shueisha-staging',
    storageBucket: 'dott-matching-shueisha-staging.appspot.com',
    messagingSenderId: '74204782405',
    appId: '1:74204782405:web:d0918fd8e06a2303f9a82c',
    measurementId: 'G-6TQXRWHVW9'
  },
  hosting: '',
  apiEndpoint: 'https://matching-strapi-urglz2tpna-an.a.run.app',
};

export const LINEAddFriendHTMLElement = `
<a href="https://lin.ee/17GGFQ1qU" target="_blank">
  <img src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png" alt="友だち追加" height="36" border="0">
</a>`;
